import { useMutation } from '@apollo/client';
import {
  CreatePluviometerReadingDocument,
  CreatePluviometerReadingMutation,
  CreatePluviometerReadingMutationVariables
} from '../../graphql/generated/createPluviometerReading.mutation';
import { ListReadingByPluviometerPagDocument } from '../../graphql/query/generated/listReadingByPluviometerPag.query';

export function useCreatePluviometerReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createReading, { data, error, loading }] = useMutation<
    CreatePluviometerReadingMutation,
    CreatePluviometerReadingMutationVariables
  >(CreatePluviometerReadingDocument, {
    refetchQueries: [ListReadingByPluviometerPagDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createReading,
    data,
    error,
    loading
  };
}
