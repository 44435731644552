import { useMutation } from '@apollo/client';
import {
  EditSurfaceMarkersReadingDocument,
  EditSurfaceMarkersReadingMutation,
  EditSurfaceMarkersReadingMutationVariables
} from '../../graphql/generated/editSurfaceMarkerReading.mutation';
import { ListReadingBySurfaceMarkersPagDocument } from '../../graphql/query/generated/listReadingBySurfaceMarkersPag.query';

export function useEditSurfaceMarkersReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editSurfaceMarkerReading, { data, error, loading }] = useMutation<
    EditSurfaceMarkersReadingMutation,
    EditSurfaceMarkersReadingMutationVariables
  >(EditSurfaceMarkersReadingDocument, {
    refetchQueries: [ListReadingBySurfaceMarkersPagDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editSurfaceMarkerReading,
    data,
    error,
    loading
  };
}
