import { useMutation, useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkDone, IoSyncSharp } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SetAtoCanceledDocument,
  SetAtoCanceledMutation,
  SetAtoCanceledMutationVariables
} from '../../../../data/graphql/generated/canceledAto';
import {
  SetAtoDoneDocument,
  SetAtoDoneMutation,
  SetAtoDoneMutationVariables
} from '../../../../data/graphql/generated/doneAto';
import {
  ReactivateAtoDocument,
  ReactivateAtoMutation,
  ReactivateAtoMutationVariables
} from '../../../../data/graphql/generated/reactivateAto';
import {
  FindAllAtoDocument,
  FindAllAtoQuery,
  FindAllAtoQueryVariables
} from '../../../../data/graphql/query/generated/findAllAto.query';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AtoForm from '../../Organisms/AtoForm/AtoForm';
import { AtoFormType } from '../../Organisms/AtoForm/AtoForm.interfaces';
import TemplateAtoAdmin from '../../Templates/TemplateAtoAdmin/TemplateAtoAdmin';
import { lisAtoDataProps } from './AtoAdminPage.interfaces';

const AtoAdminPage = () => {
  const { t } = useTranslation();
  const { structureId } = useParams();
  const { data: listAtos } = useQuery<
    FindAllAtoQuery,
    FindAllAtoQueryVariables
  >(FindAllAtoDocument, {
    variables: {
      structureId: structureId ? structureId : ''
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
      toastfyError(errorMessage);
    }
  });
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  // const [showAtoCanceled, setShowAtoCanceled] = useState<boolean>(false);
  const [atoToEdit, setAtoToEdit] = useState<AtoFormType | null>(null);

  const columnsTable = [
    {
      key: 'title',
      label: `${t('title')}`
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'contract',
      label: `${t('contractNumber')}`
    },
    {
      key: 'responsibleCompanyName',
      label: `${t('responsibleCompany')}`
    },
    {
      key: 'initialDate',
      label: `${t('startDate')}`
    },
    {
      key: 'finalDate',
      label: `${t('finalDate')}`
    }
  ];

  const actionsTable: AvailableContextActionsProps<lisAtoDataProps>[] = [
    {
      name: `${t('Edit')}`,
      icon: <MdEdit />,
      onClick: (ato: lisAtoDataProps) => handleAtoEdit(ato),
      canShow: 'canEdit'
    },
    {
      name: `${t('conclude')}`,
      icon: <IoCheckmarkDone />,
      onClick: (ato: lisAtoDataProps) => handleDoneAto(ato.id),
      canShow: 'canFinish'
    },
    {
      name: `${t('Cancel')}`,
      icon: <TiDelete />,
      onClick: (ato: lisAtoDataProps) => handleCancelAto(ato.id),
      canShow: 'canCancel'
    },
    {
      name: `${t('reactivate')}`,
      icon: <IoSyncSharp />,
      onClick: (ato: lisAtoDataProps) => handleReactivateAto(ato.id),
      canShow: 'canReactivate'
    }
  ];

  const [cancelAto] = useMutation<
    SetAtoCanceledMutation,
    SetAtoCanceledMutationVariables
  >(SetAtoCanceledDocument);
  const [atoDone] = useMutation<
    SetAtoDoneMutation,
    SetAtoDoneMutationVariables
  >(SetAtoDoneDocument);
  const [atoReactivate] = useMutation<
    ReactivateAtoMutation,
    ReactivateAtoMutationVariables
  >(ReactivateAtoDocument);

  const lisAtoData = listAtos?.findAllAto.map((ato) => ({
    title: ato.title,
    contract: ato.contract,
    responsibleCompanyName: ato.responsibleCompany.name,
    initialDate: new Date(ato.initialDate).toLocaleDateString(),
    finalDate: new Date(ato.finalDate).toLocaleDateString(),
    status: ato.situation,
    id: ato.id,
    canFinish: ato.canFinish
  }));

  const handlePushAtoIdToUrl = (atoId: string) => {
    navigate(`/${structureId}/ato/${atoId}`);
  };

  const handleReactivateAto = (atoId: string) => {
    atoReactivate({
      variables: { reactivateAtoId: atoId },
      onCompleted: () => {
        toastfySuccess(t('reactivatedSuccessfully'));
      },
      onError: () => {
        toastfyError(t('reactivatedError'));
      },
      refetchQueries: [FindAllAtoDocument]
    });
  };

  const handleDoneAto = (atoId: string) => {
    const selectedAto = listAtos?.findAllAto.find((ato) => ato.id === atoId);

    if (selectedAto?.canFinish) {
      atoDone({
        variables: { setAtoDoneId: atoId },
        onCompleted: () => {
          toastfySuccess(t('doneSuccessfully'));
        },
        refetchQueries: [FindAllAtoDocument],
        onError: () => {
          toastfyError(t('error'));
        }
      });
    } else {
      toastfyError(t('toCompleteAtoIsNecessary'));
    }
  };

  const handleCancelAto = (atoId: string) => {
    cancelAto({
      variables: { setAtoCanceledId: atoId },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
      },
      refetchQueries: [FindAllAtoDocument],
      onError: () => {
        toastfyError(t('deletedError'));
      }
    });
  };

  const handleAtoEdit = (atoRole: lisAtoDataProps) => {
    const ato = listAtos?.findAllAto.find((ato) => ato.id === atoRole.id);
    if (!ato) return;
    const newAto = {
      atoId: ato.id,
      contract: ato.contract,
      title: ato.title,
      finalDate: ato.finalDate,
      startDate: ato.initialDate,
      verifier: { id: ato.verifier.id, name: ato.verifier.name },
      clientApprover: {
        id: ato.clientApprover?.id || '',
        name: ato.clientApprover?.name || ''
      },
      responsibleCompany: {
        id: ato.responsibleCompany.id,
        name: ato.responsibleCompany.name
      },
      docs: ato.docs,
      client: { id: ato.client.id, name: ato.client.name }
    };
    setAtoToEdit(newAto);
    setShowModal(true);
  };

  return (
    <>
      <TemplateAtoAdmin
        title={<Text type="h1">ATO</Text>}
        button={
          <Button
            variant="primary"
            text={t('createAto')}
            onClick={() => {
              setAtoToEdit(null);
              setShowModal(true);
            }}
          />
        }
        content={
          <>
            <TableComponent
              tableData={lisAtoData || []}
              columns={columnsTable}
              actions={actionsTable}
              onSearch={() => {}}
              filterComponent={<></>}
              onRow={(ato) => handlePushAtoIdToUrl(ato.id)}
              paginator={true}
              totalPages={10}
              currentPage={1}
              nextPage={() => {}}
              previousPage={() => {}}
              countForPage={{ label: '10', value: 10 }}
              setCountForPage={() => {}}
              exportTable={() => {}}
            />
          </>
        }
      />
      {showModal && (
        <ViewModal
          title={atoToEdit ? `${t('editAto')}` : `${t('createAto')}`}
          onClose={() => {
            setShowModal(false);
            setAtoToEdit(null);
          }}
          component={<AtoForm ato={atoToEdit} setShowModal={setShowModal} />}
          width={60}
          height={85}
        />
      )}
      {/* TODO: finalizar logica de cancelamento */}
      {/* {showAtoCanceled && (
        <ModalConfirm
          title={t('AtoCancel')}
          text={t('ConfirmDelete')}
          onClose={() => setShowAtoCanceled(false)}
          onConfirm={(ato) => handleCancelAto(ato.id || '')}
        />
      )} */}
    </>
  );
};

export default AtoAdminPage;
