import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  DeleteRecordWorkShiftDocument,
  DeleteRecordWorkShiftMutation,
  DeleteRecordWorkShiftMutationVariables
} from '../../../../data/graphql/generated/deleteRecordWorkShift';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import WorkScheduleTable from '../../Molecules/WorkScheduleTable/WorkScheduleTable';
import AddWorkScheduleAtoForm from '../AddWorkScheduleAtoForm/AddWorkScheduleAtoForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoWorkScheduleProps,
  RowToEditType,
  WorkShiftsItem
} from './CardAtoInfoWorkSchedule.interface';

export const CardAtoInfoWorkSchedule = ({
  workShifts,
  rdoFormsData
}: CardAtoInfoWorkScheduleProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [deleteRecordWorkShift] = useMutation<
    DeleteRecordWorkShiftMutation,
    DeleteRecordWorkShiftMutationVariables
  >(DeleteRecordWorkShiftDocument);

  const [rowToEdit, setRowToEdit] = useState<RowToEditType>();

  const handleDeleteRow = (row: RowToEditType) => {
    if (workShifts.length > 0) {
      deleteRecordWorkShift({
        variables: {
          deleteRecordWorkShiftId: row.id
        },
        onCompleted: () => {
          toastfySuccess(t('deletedSuccessfully'));
          setUpdateDelete(!updateDelete);
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              workShifts: existingData.findDataByRecord.workShifts.filter(
                (item: WorkShiftsItem) => item.id !== row.id
              )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    } else {
      return;
    }
  };

  const handleEditRow = (row: RowToEditType) => {
    if (workShifts.length > 0) {
      setRowToEdit(row);
      setShowEditModal(true);
    } else {
      return;
    }
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  return (
    <>
      {showModal && (
        <ViewModal
          width={40}
          height={50}
          title={t('addworkSchedule')}
          onClose={() => setShowModal(false)}
          component={
            <AddWorkScheduleAtoForm
              setShowModal={setShowModal}
              recordId={recordId?.toString() || ''}
              verifyIfDataExists={
                (rdoFormsData?.findDataByRecord?.workShifts ?? []).length > 0
              }
            />
          }
        />
      )}
      {showEditModal && (
        <ViewModal
          width={40}
          height={50}
          title={t('editworkSchedule')}
          onClose={() => setShowEditModal(false)}
          component={
            <>
              {workShifts.length > 0 && rowToEdit && (
                <AddWorkScheduleAtoForm
                  setShowModal={setShowEditModal}
                  recordId={recordId?.toString() || ''}
                  editWorkSchedule={{
                    id: rowToEdit?.id,
                    entry: new Date(rowToEdit.entry).toLocaleString(),
                    exit: new Date(rowToEdit.exit).toLocaleString(),
                    observation: rowToEdit.observation,
                    totalHours: rowToEdit.totalHours,
                    exitInterval: new Date(
                      rowToEdit.exitInterval
                    ).toLocaleString(),
                    entryInterval: new Date(
                      rowToEdit.entryInterval
                    ).toLocaleString()
                  }}
                />
              )}
            </>
          }
        />
      )}
      <CardAtoInfo
        title={t('workingDay')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
        onClickEdit={() => handleEditRow(workShifts[0])}
        onClickDelete={() => handleDeleteRow(workShifts[0])}
      >
        <WorkScheduleTable workShifts={workShifts} />
      </CardAtoInfo>
    </>
  );
};
