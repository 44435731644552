import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../Components/Forms/FormInspectionsStyle';
import {
  ConservationStateNoteEnum,
  ConservationStateTitleEnum,
  SaveConservationStateInput
} from '../../../data/graphql/base-schema';
import {
  ContainerSeventhStep,
  InspectionTextArea,
  RegisterArea,
  SectionSevenStep
} from '../InspectionsStyle';

const NOTE_VALUES = {
  Zero: 0,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Ten: 10
};

const TITLES = [
  ConservationStateTitleEnum.ReliabilityExtravasationStructures,
  ConservationStateTitleEnum.Percolation,
  ConservationStateTitleEnum.Deformations,
  ConservationStateTitleEnum.SlopeAndParametersDeterioration,
  ConservationStateTitleEnum.SurfaceDrainage
];

const CARDS = [
  [
    {
      text: 'ReliabilityExtravasationStructures_0',
      value: 0,
      title: 'ReliabilityExtravasationStructures'
    },
    {
      text: 'ReliabilityExtravasationStructures_3',
      value: 3,
      title: 'ReliabilityExtravasationStructures'
    },
    {
      text: 'ReliabilityExtravasationStructures_6',
      value: 6,
      title: 'ReliabilityExtravasationStructures'
    },
    {
      text: 'ReliabilityExtravasationStructures_10',
      value: 10,
      title: 'ReliabilityExtravasationStructures'
    }
  ],
  [
    {
      text: 'Percolation_0',
      value: 0,
      title: 'Percolation'
    },
    {
      text: 'Percolation_3',
      value: 3,
      title: 'Percolation'
    },
    {
      text: 'Percolation_6',
      value: 6,
      title: 'Percolation'
    },
    {
      text: 'Percolation_10',
      value: 10,
      title: 'Percolation'
    }
  ],
  [
    {
      text: 'Deformations_0',
      value: 0,
      title: 'Deformations'
    },
    {
      text: 'Deformations_2',
      value: 2,
      title: 'Deformations'
    },
    {
      text: 'Deformations_6',
      value: 6,
      title: 'Deformations'
    },
    {
      text: 'Deformations_10',
      value: 10,
      title: 'Deformations'
    }
  ],
  [
    {
      text: 'SlopeAndParametersDeterioration_0',
      value: 0,
      title: 'SlopeAndParametersDeterioration'
    },
    {
      text: 'SlopeAndParametersDeterioration_2',
      value: 2,
      title: 'SlopeAndParametersDeterioration'
    },
    {
      text: 'SlopeAndParametersDeterioration_6',
      value: 6,
      title: 'SlopeAndParametersDeterioration'
    },
    {
      text: 'SlopeAndParametersDeterioration_10',
      value: 10,
      title: 'SlopeAndParametersDeterioration'
    }
  ],
  [
    {
      text: 'SurfaceDrainage_0',
      value: 0,
      title: 'SurfaceDrainage'
    },
    {
      text: 'SurfaceDrainage_2',
      value: 2,
      title: 'SurfaceDrainage'
    },
    {
      text: 'SurfaceDrainage_4',
      value: 4,
      title: 'SurfaceDrainage'
    },
    {
      text: 'SurfaceDrainage_5',
      value: 5,
      title: 'SurfaceDrainage'
    }
  ]
];

export function SeventhStepInspection({
  stepSevenStates,
  setStepSevenStates,
  setGeneralComments,
  generalComments
}: {
  stepSevenStates: SaveConservationStateInput[];
  setStepSevenStates: Dispatch<SetStateAction<SaveConservationStateInput[]>>;
  setGeneralComments: Dispatch<SetStateAction<string>>;
  generalComments: string;
}) {
  const { t } = useTranslation();
  const [selectedCardIndexes, setSelectedCardIndexes] = useState<number[]>(
    Array(TITLES.length).fill(0)
  );

  useEffect(() => {
    stepSevenStates.forEach((state, index) => {
      const cardIndex = CARDS[index].findIndex(
        (card) => card.value === NOTE_VALUES[state.note]
      );
      setSelectedCardIndexes((prevState) => {
        const updatedIndexes = [...prevState];
        updatedIndexes[index] = CARDS[index][cardIndex].value;
        return updatedIndexes;
      });
    });
  }, []);

  const handleConservationState = (value: number, title: any, text: string) => {
    const note = Object.keys(NOTE_VALUES).find(
      (key) => NOTE_VALUES[key as keyof typeof NOTE_VALUES] === value
    ) as ConservationStateNoteEnum;
    const topicIndex = TITLES.indexOf(title);
    if (topicIndex > -1) {
      setStepSevenStates((prevState) => {
        const updatedStates = [...prevState];
        updatedStates[topicIndex] = {
          ...updatedStates[topicIndex],
          note,
          topic: text
        };
        return updatedStates;
      });
    }
  };

  const handleRadioChange = (
    cardIndex: number,
    value: number,
    title: any,
    text: string
  ) => {
    const updatedIndexes = [...selectedCardIndexes];
    updatedIndexes[cardIndex] = value;
    setSelectedCardIndexes(updatedIndexes);
    handleConservationState(value, title, text);
  };

  const sum = selectedCardIndexes.reduce((acc, curr) => acc + curr, 0);

  return (
    <ContainerSeventhStep>
      {TITLES.map((title, index) => (
        <Title style={{ textAlign: 'center' }} key={index}>
          {t(title)}
        </Title>
      ))}
      {CARDS.map((card, cardIndex) => (
        <SectionSevenStep key={cardIndex}>
          {card.map((c) => (
            <div
              key={c.value}
              className={
                selectedCardIndexes[cardIndex] === c.value ? 'selected' : ''
              }
              onClick={() =>
                handleRadioChange(cardIndex, c.value, c.title, c.text)
              }
            >
              <label htmlFor={`${c.value.toString()}${cardIndex}`}>
                {t(c.text)}
              </label>
              <p>{c.value}</p>
            </div>
          ))}
        </SectionSevenStep>
      ))}
      <RegisterArea style={{ gridColumn: 'span 5' }}>
        <span style={{ margin: '25px' }}>∑EC = {sum}</span>
        <span>{t('ConservationStateGeralObservation')}</span>
        <InspectionTextArea
          placeholder={t('Observation')}
          spellCheck={false}
          style={{ width: '90%', height: '150px' }}
          value={generalComments}
          onChange={(e) => setGeneralComments(e.target.value)}
        />
      </RegisterArea>
    </ContainerSeventhStep>
  );
}
