import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { IoNotifications } from 'react-icons/io5';
import { NotificationListType } from '../../@Types/types';
import { useSetInstrumentNotificationUnreadStatus } from '../../data/hooks/use-set-instrument-notification-unread-status';
import { formatDate } from '../../utils/formatDate';
import { NotificationContext } from '../UserBadge';
import {
  ButtonNotificationAccordion,
  DivNotificationAccordionPanel,
  DivNotificationDateUserNameSpanModal,
  DivNotificationItemContainer,
  DivNotificationItemModalContainer,
  DivNotificationPlusMinusContainer,
  DivNotificationPropsContainer,
  SpanNotificationTitle
} from './NotificationStyle';

export default function NotificationItemModal({
  index,
  title,
  date,
  instrument,
  controlLevel,
  structure,
  installationLocation,
  reading,
  controlLevelValue,
  unread
}: {
  index: number;
  title: string;
  date: string;
  instrument: string;
  controlLevel: string;
  structure: string;
  installationLocation: string;
  reading: number;
  controlLevelValue: number;
  unread: boolean;
}) {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(false);
  const { allNotificationItemsList, setAllNotificationItemsList } = useContext(
    NotificationContext
  ) as {
    allNotificationItemsList: NotificationListType[];
    setAllNotificationItemsList: Dispatch<
      SetStateAction<NotificationListType[]>
    >;
  };
  const { setInstrumentNotificationUnreadStatus } =
    useSetInstrumentNotificationUnreadStatus();

  const handleOpenAccordion = () => {
    setActive(!active);

    const updatedAllNotificationItemsList = [...allNotificationItemsList];
    updatedAllNotificationItemsList[index].unread = false;
    setAllNotificationItemsList(updatedAllNotificationItemsList);

    //TODO manda a requisicao para falar que a mensagem ja foi lida
  };

  const handleBlurAccordion = () => {
    const updatedAllNotificationItemsList = [...allNotificationItemsList];
    updatedAllNotificationItemsList[index].unread = false;
    setAllNotificationItemsList(updatedAllNotificationItemsList);
  };

  return (
    <DivNotificationItemModalContainer>
      <ButtonNotificationAccordion
        onClick={() => handleOpenAccordion()}
        unread={unread}
        onBlur={() => handleBlurAccordion()}
      >
        <DivNotificationItemContainer>
          <IoNotifications style={{ fontSize: '35px' }} />
          <div>
            <SpanNotificationTitle>{title}</SpanNotificationTitle>
            <DivNotificationDateUserNameSpanModal>
              <span>{formatDate(new Date(date))}</span>
            </DivNotificationDateUserNameSpanModal>
          </div>
        </DivNotificationItemContainer>
        <DivNotificationPlusMinusContainer>
          {active ? <FaMinus size={20} /> : <FaPlus size={20} />}
        </DivNotificationPlusMinusContainer>
      </ButtonNotificationAccordion>
      <DivNotificationAccordionPanel active={active}>
        <DivNotificationPropsContainer>
          <span>
            {t('Instrument')}: {instrument}
          </span>
          <span>
            {t('ControlLevel')}: {controlLevel}
          </span>
          <span>
            {t('Structure')}: {structure}
          </span>
          <span>
            {t('InstallationLocation')}: {installationLocation}
          </span>
          <span>
            {t('Reading')}: {reading}
          </span>
          <span>
            {t('ControlLevelValue')}: {controlLevelValue}
          </span>
        </DivNotificationPropsContainer>
      </DivNotificationAccordionPanel>
    </DivNotificationItemModalContainer>
  );
}
