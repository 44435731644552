import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonAreaDelete } from '../../../Screens/AdminScreen/AdminScreenStyle';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../Buttons/ButtonsStyle';
import GraphHeader from '../../Graphs/Monitoring/Instrument/GraphHeader';
import { DefaultInput } from '../../Inputs/InputsStyle';
import { RegisterReadingsArea } from '../../Instruments/Readings/DataBaseStyles';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../../Instruments/Register/RegisterInstrumentStyle';

export default function EditValueResolutionModal({
  showModal,
  setShowModal,
  data,
  index,
  setInstrumentEspecificData,
  instrumentEspecificData
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  data: any;
  index: any;
  setInstrumentEspecificData: any;
  instrumentEspecificData: any;
}) {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(data.name);
  const [drained, setDrained] = useState<string>(data.drained);
  const [undrained, setUndrained] = useState<string>(data.undrained);
  const [coteValue, setCoteValue] = useState<string>(data.coteValue);

  const handleSave = () => {
    const updatedAlertLevels = instrumentEspecificData.alertLevels;
    updatedAlertLevels[index] = {
      name: name,
      drained: drained,
      undrained: undrained,
      coteValue: Number(coteValue)
    };

    setInstrumentEspecificData((prev: any) => ({
      ...prev,
      alertLevels: updatedAlertLevels
    }));
    setShowModal(false);
  };

  return (
    // <RegisterInstrumentBackground>
    <RegisterInstrumentModal
      style={{
        width: '50%',
        minWidth: 600,
        maxWidth: 650,
        height: 'max-content',
        minHeight: '300px'
      }}
    >
      <GraphHeader
        showModal={showModal}
        setShowModal={setShowModal}
        title={t('Edit')}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <RegisterReadingsArea>
          <DefaultInput
            onChange={(e) => setName(e.target.value)}
            type="string"
            placeholder={t('Reading')}
            value={name}
          />
          <DefaultInput
            onChange={(e) => setDrained(e.target.value)}
            type="string"
            placeholder={t('Reading')}
            value={drained}
          />
          <DefaultInput
            onChange={(e) => setUndrained(e.target.value)}
            type="string"
            placeholder={t('Reading')}
            value={undrained}
          />
          <DefaultInput
            onChange={(e) => setCoteValue(e.target.value)}
            type="string"
            placeholder={t('Reading')}
            value={coteValue}
          />
        </RegisterReadingsArea>
      </div>
      <ButtonAreaDelete>
        <CancelButtonStyled onClick={() => setShowModal(false)}>
          {' '}
          {t('Cancel').toLocaleUpperCase()}
        </CancelButtonStyled>
        <AdvanceButtonStyled
          onClick={() => {
            handleSave();
          }}
        >
          {' '}
          {t('Save').toLocaleUpperCase()}
        </AdvanceButtonStyled>
      </ButtonAreaDelete>
    </RegisterInstrumentModal>
    // </RegisterInstrumentBackground>
  );
}
