import styled from 'styled-components';
import { ImageStylesProps } from './Image.interfaces';

export const StyledImage = styled.img<ImageStylesProps>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  border-radius: ${(props) => props.borderRadius || '0'};
  object-fit: ${(props) => props.objectFit || 'cover'};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  max-height: ${(props) => props.maxHeight || 'auto'};

  &:hover {
    transform: ${(props) => (props.hover ? 'scale(1.1)' : 'none')};
    transition: transform 0.3s;
  }
`;
