import { useMutation } from '@apollo/client';
import {
  GenerateInspectionPdfDocument,
  GenerateInspectionPdfMutationVariables,
  GenerateInspectionPdfMutation
} from '../graphql/generated/generateInspectionPdf.mutation';

export function useGenerateInspectionPdf(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [generateInspectionPdf, { data, error, loading }] = useMutation<
    GenerateInspectionPdfMutation,
    GenerateInspectionPdfMutationVariables
  >(GenerateInspectionPdfDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    generateInspectionPdf,
    data,
    error,
    loading
  };
}
