import { useMutation } from '@apollo/client';
import {
  EditPluviometerAndWaterLevelAlertDocument,
  EditPluviometerAndWaterLevelAlertMutation,
  EditPluviometerAndWaterLevelAlertMutationVariables
} from '../../graphql/generated/editPluviometerAndWaterLevelAlert';
import { ListPluviometersByStructureDocument } from '../../graphql/query/generated/listPluviometersByStructure.query';
import { ListWaterLevelByStructureDocument } from '../../graphql/query/generated/listWaterLevelByStructure.query';

export function useEditPluviometerAndWaterLevelAlert(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editPluviometerAndWaterLevelAlert, { data, error, loading }] =
    useMutation<
      EditPluviometerAndWaterLevelAlertMutation,
      EditPluviometerAndWaterLevelAlertMutationVariables
    >(EditPluviometerAndWaterLevelAlertDocument, {
      awaitRefetchQueries: true,
      refetchQueries: [
        ListPluviometersByStructureDocument,
        ListWaterLevelByStructureDocument
      ],
      errorPolicy: 'all',
      onCompleted: onCompleted,
      onError: onError
    });

  return {
    editPluviometerAndWaterLevelAlert,
    data,
    error,
    loading
  };
}

// O hook acima edita as infos gerais do instrumento pluviômetro, tais quais:
// é possível ver essas variáveis ao clicar em EditPluviometerMutationVariables.
// Essas infos serão passadas em dois objetos, um de infos gerais e um de infos específicas
// como mostrado em EditPluviometerMutationVariables.
