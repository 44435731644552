import styled from 'styled-components';
import { Color, FontSize } from '../../../Styles/Styles';

export const EditInstrumentsModalArea = styled.div`
  color: ${Color.Brown1};
  display: flex;
  width: 100%;
  min-height: 600px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const HolderInfosEditInstrument = styled.div`
  width: 100%;
  padding-top: 5px;
  padding-right: 15px;
  padding-left: 15px;
  min-height: max-content;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  font-size: 12px;
  min-height: 560px;
`;

export const EditBasicInstrumentInfo = styled.div`
  width: 51%;
  min-height: max-content;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
`;

export const EditEspecifcInstrumentInfo = styled.div`
  width: 46%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  row-gap: 10px;
`;

export const ButtonsaveEspecificInfo = styled.button`
  border: none;
  background-color: ${Color.Brown1};
  color: ${Color.White};
  cursor: pointer;
  font-size: 12px;
  padding: 8px 15px 8px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
`;

export const HolderEspecificInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const GeralInfo = styled.span``;

export const HolderButtonSaveEspecificInfo = styled.div`
  width: 85%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const TitleBasicInfo = styled.span`
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-color: ${Color.Brown2};
  border-radius: 5px;
  width: 99%;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const EspecificOption = styled.option`
  font-size: 9pt;
  background-color: ${Color.Brown3};
  cursor: pointer;
  padding: 10px 5px 10px 5px;
  margin-top: -5px;
`;

export const EspecificSelect = styled.select`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${Color.Brown1};
  border-radius: none;
  box-sizing: border-box;
  box-shadow: none;
  padding: none;
  font-size: 9pt;
  cursor: pointer;
  margin-bottom: 13px;
  user-select: none;
  &:hover {
    border-bottom: 1px solid ${Color.Brown1};
  }
`;

export const CardEditAlertLevel = styled.div`
  width: 80%;
  font-size: 12px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 7px;
  border: 1px solid ${Color.Brown1};
`;

export const InputEditEspecificInfo = styled.input`
  color: ${Color.Brown1};
  border: none;
  border-bottom: 1px solid;
  padding: 3px 10px 3px 5px;
  font-size: 12px;
  width: 150px;
`;

export const HolderCardAlertTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// export const HolderEditAlertLevel = styled.div`
// width:100%;
// background-color:yellow;
// display:flex;
// flex-direction:column;
// row-gap:5px;
// `;
