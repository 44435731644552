import { useMutation } from '@apollo/client';
import {
  EditPluviometerDocument,
  EditPluviometerMutation,
  EditPluviometerMutationVariables
} from '../../graphql/generated/editPluviometer.mutation';
import { ListPluviometersByStructureDocument } from '../../graphql/query/generated/listPluviometersByStructure.query';

export function useEditPluviometer(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editPluviometer, { data, error, loading }] = useMutation<
    EditPluviometerMutation,
    EditPluviometerMutationVariables
  >(EditPluviometerDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListPluviometersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editPluviometer,
    data,
    error,
    loading
  };
}

// O hook acima edita as infos gerais do instrumento pluviômetro, tais quais:
// é possível ver essas variáveis ao clicar em EditPluviometerMutationVariables.
// Essas infos serão passadas em dois objetos, um de infos gerais e um de infos específicas
// como mostrado em EditPluviometerMutationVariables.
