import styled from 'styled-components';

export const DivContainerPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 5px 30px 30px 30px;
  box-sizing: border-box;
  overflow: auto;
  row-gap: 20px;
`;

export const DivHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DivPageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
