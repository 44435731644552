import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListPluviometersByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListPluviometersByStructureQuery = { __typename?: 'Query', listPluviometersByStructure: Array<{ __typename?: 'PluviometerListType', activationDate?: Date | null, coordinateE: number, coordinateN: number, deactivationDate?: Date | null, id?: string | null, installLocation?: string | null, installationDate?: Date | null, isUTM: boolean, name: string, operationalStatus: Types.InstrumentStatusEnum, pluviometerType?: string | null, readingType: string, status: boolean, alertLevels: Array<{ __typename?: 'PluviometerWaterLevelAlertLevelsType', id: string, name: string, status: boolean, value: number }>, type?: { __typename?: 'InstrumentType', id: string, instrumentType: string } | null }> };


export const ListPluviometersByStructureDocument = gql`
    query ListPluviometersByStructure($structureInfo: StructureInfoInput!) {
  listPluviometersByStructure(structureInfo: $structureInfo) {
    activationDate
    alertLevels {
      id
      name
      status
      value
    }
    coordinateE
    coordinateN
    deactivationDate
    id
    installLocation
    installationDate
    isUTM
    name
    operationalStatus
    pluviometerType
    readingType
    status
    type {
      id
      instrumentType
    }
  }
}
    `;
export type ListPluviometersByStructureQueryResult = Apollo.QueryResult<ListPluviometersByStructureQuery, ListPluviometersByStructureQueryVariables>;