import { useLazyQuery, useQuery } from '@apollo/client';
import {
  PageInput,
  PiezometerInaFilterInput,
  SortInput,
  StructureInfoInput
} from '../../graphql/base-schema';
import {
  ListReadingByPluviometerPagDocument,
  ListReadingByPluviometerPagQuery,
  ListReadingByPluviometerPagQueryVariables
} from '../../graphql/query/generated/listReadingByPluviometerPag.query';

export interface ListReadingByPluviometerPagVariablesModel {
  structureInfo: StructureInfoInput;
  filters: PiezometerInaFilterInput;
  pageInfo?: PageInput;
  sortInfo?: SortInput[];
}

export function useListReadingByPluviometerPag(
  input: ListReadingByPluviometerPagVariablesModel,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { structureInfo, filters, pageInfo, sortInfo } = input;

  const { data, loading, error } = useQuery<
    ListReadingByPluviometerPagQuery,
    ListReadingByPluviometerPagQueryVariables
  >(ListReadingByPluviometerPagDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError,
    variables: {
      structureInfo,
      sortInfo,
      filters,
      pageInfo
    }
  });

  return {
    data,
    loading,
    error
  };
}

export function useListReadingByPluviometerPagLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listReadingPluviometer, { data, loading, error }] = useLazyQuery<
    ListReadingByPluviometerPagQuery,
    ListReadingByPluviometerPagQueryVariables
  >(ListReadingByPluviometerPagDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listReadingPluviometer,
    data,
    loading,
    error
  };
}

//lembrando que também é possível usar o useLazyQuery, como descrito no arquivo use-list-pluviometers-by-structure.query

//Esse hook acima lista as leituras do pluviômetro de modo paginado. Os dados listados são:
// date
// rainfall
// instrumentId
// name
// associatedStructure
// coordinateE
// coordinateN
// installLocation
// readingType
// operationalStatus
