import { styled } from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const DivContainerCard = styled.div`
  box-shadow: 0 4px 4px ${Color.Gray1};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  justify-content: center;
`;

export const DivContainerLabelCard = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
`;

export const SpanCard = styled.span`
  color: ${Color.Disabled};
`;

export const SpanCardValue = styled.span`
  color: ${Color.Brown1};
  font-size: ${FontSize.Large};
`;

export const DivContainerSpanCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
`;
