import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoFilter } from 'react-icons/io5';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import Table from '../../Atoms/Table/Table';
import TableBody from '../../Atoms/TableBody/TableBody';
import TableCell from '../../Atoms/TableCell/TableCell';
import TableHead from '../../Atoms/TableHead/TableHead';
import TableHeaderRow from '../../Atoms/TableHeaderRow/TableHeaderRow';
import TableRow from '../../Atoms/TableRow/TableRow';
import InputSearch from '../../Organisms/InputSearch/InputSearch';
import ActionMenu from '../ActionMenu/ActionMenu';
import Paginator from '../Paginator/Paginator';
import TableHeaderCell from '../TableHeaderCell/TableHeaderCell';
import ViewModal from '../ViewModal/ViewModal';
import { TableComponentProps } from './TableComponent.interface';
import {
  DivContainerActions,
  DivContentFooter,
  DivMessageNoData,
  TableHeaderCellTh
} from './TableComponent.styles';
import ExportTableModal from '../../Organisms/ExportTable/ExportTableComponent';

const TableComponent = ({
  columns,
  tableData,
  onRow,
  onSearch,
  filterComponent,
  paginator,
  optionsPaginator,
  countForPage,
  setCountForPage,
  currentPage,
  nextPage,
  previousPage,
  totalPages,
  exportTable,
  actions
}: TableComponentProps) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'ascending' | 'descending';
  } | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const { t: translate } = useTranslation();
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];

  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }

    setSortConfig({ key, direction });
  };

  const handleExport = (selectedColumns: string[]) => {
    if (exportTable) {
      exportTable(selectedColumns);
    }
  };

  return (
    <>
      <DivContainerActions>
        {filterComponent && tableData.length > 0 && (
          <Icon
            Icon={IoFilter}
            onClick={() => setShowModal(true)}
            variant="small"
          />
        )}
        {onSearch && tableData.length > 0 && (
          <InputSearch onSearch={onSearch} />
        )}
      </DivContainerActions>
      <Table>
        <TableHead>
          <TableHeaderRow className="header-row">
            {columns.map((column) => (
              <TableHeaderCell
                key={column.key}
                onClick={() => handleSort(column.key)}
                isSorted={sortConfig?.key === column.key}
                isSortedDesc={
                  sortConfig?.key === column.key &&
                  sortConfig?.direction === 'descending'
                }
              >
                {column.label}
              </TableHeaderCell>
            ))}
            {actions && <TableHeaderCellTh></TableHeaderCellTh>}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {tableData.map((item, index) => (
            <TableRow
              key={index}
              onClick={onRow ? () => onRow(item) : undefined}
            >
              {columns.map((column) => (
                <TableCell key={column.key} color={item.color}>
                  {item[column.key]}
                </TableCell>
              ))}
              {actions && (
                <TableCell>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <ActionMenu
                      availableContextActions={actions}
                      dataDrivenPermissions={item}
                      dataActions={item}
                    />
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DivContentFooter>
        {exportTable && tableData.length > 0 && (
          <Button
            text={translate('ExportTable')}
            variant="secondary"
            size="large"
            onClick={() => setShowExportModal(true)}
          />
        )}
        {paginator && tableData.length > 0 && (
          <Paginator
            totalPages={totalPages}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            countForPage={countForPage}
            setCountForPage={setCountForPage}
            options={optionsPaginator || optionsPaginatorDefault}
          />
        )}
      </DivContentFooter>
      {tableData.length === 0 && (
        <DivMessageNoData>{translate('noData')}</DivMessageNoData>
      )}
      {showModal && (
        <ViewModal
          title={translate('Filters')}
          onClose={() => setShowModal(false)}
          component={filterComponent}
        />
      )}
      {showExportModal && (
        <ExportTableModal
          columns={columns}
          onExport={handleExport}
          onClose={() => setShowExportModal(false)}
        />
      )}
    </>
  );
};

export default TableComponent;
