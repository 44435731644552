import { useMutation } from '@apollo/client';

import {
  EditSurfaceMarkerReferenceDocument,
  EditSurfaceMarkerReferenceMutation,
  EditSurfaceMarkerReferenceMutationVariables
} from '../../graphql/generated/editSurfaceMarkerReference';
import { ListSurfaceMarkersByStructureDocument } from '../../graphql/query/generated/listSurfaceMarkersByStructure.query';

export function useEditSurfaceMarkerReferences(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editSurfaceMarkerReferences, { data, error, loading }] = useMutation<
    EditSurfaceMarkerReferenceMutation,
    EditSurfaceMarkerReferenceMutationVariables
  >(EditSurfaceMarkerReferenceDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListSurfaceMarkersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editSurfaceMarkerReferences,
    data,
    error,
    loading
  };
}
