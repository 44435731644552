import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../../Components/Buttons/DefaultButton';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  AdminContainer,
  AdminFilter,
  AdminSearch,
  AdminTitle,
  DivContainer,
  SearchIcon,
  StyledInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { useListCompany } from '../../../data/hooks/admin-company/use-list-company';
import { useListPermissionsUsers } from '../../../data/hooks/admin-permission/use-list-users-permission';
import { AdminDeleteModal } from '../AdminDeleteModal';
import AdminTable from '../AdminTable';
import {
  PermissionsData,
  __initialPermissionsData
} from './PermissionsInterface';
import { PermissionModal } from './PermissionsModal';

function Permissions() {
  const { t }: any = useTranslation();
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [search, setSearch] = useState('');
  const [showPermissionsModal, setShowPermissionsModal] =
    useState<boolean>(false);
  const {
    data: listPermissions,
    loading: loadingList,
    error: listPermissionsError
  } = useListPermissionsUsers();
  const [tableValues, setTableValues] = useState<
    {
      company: string;
      Structures: string;
      User: string;
      module: string;
      Permission: string;
    }[]
  >([]);
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [companies, setCompanies] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const { data: listClient } = useListCompany(true);
  const [permissionData, setPermissionData] = useState<PermissionsData>(
    __initialPermissionsData
  );

  useEffect(() => {
    if (listPermissionsError) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loadingList) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (listPermissions) {
      const filteredTableValues = listPermissions.listUsersPermissions.map(
        (item: any) => {
          const filteredItem: any = {};
          filteredItem['company'] = item.companyName;
          filteredItem['Structures'] = item.structureName;
          filteredItem['User'] = item.userName;
          filteredItem['module'] = item.moduleName;
          filteredItem['Permission'] = item.moduleRoleName;
          return filteredItem;
        }
      );

      setTableValues(filteredTableValues);
      toastfyDimiss('toastLoading');
      if (listClient) {
        const activeCompanies = listClient.listCompanies
          .filter((company: any) => company.status === true) // Filtra apenas as empresas ativas
          .map((company: any) => ({
            id: company.id,
            label: company.name,
            value: company.id
          }));

        setCompanies(activeCompanies);
      }
    }
  }, [listPermissions, listClient]);

  return (
    <DivContainer>
      <AdminContainer>
        <AdminTitle>{t('Permissions')}</AdminTitle>
        <AdminFilter>
          <div>
            <DefaultButton
              title={`+ ${t('Add')}`}
              action={() => setShowPermissionsModal(true)}
            />
          </div>
          <AdminSearch>
            <StyledInput
              type="text"
              id="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon size={20} />
          </AdminSearch>
        </AdminFilter>
        <AdminTable
          tableColumns={[
            'company',
            'Structures',
            'User',
            'module',
            'Permission'
          ]}
          tableValues={tableValues.filter(
            (e) =>
              t(e.Permission)
                .toLocaleLowerCase()
                .includes(t(search.toLocaleLowerCase())) ||
              t(e.Structures)
                .toLocaleLowerCase()
                .includes(t(search.toLocaleLowerCase())) ||
              t(e.User)
                .toLocaleLowerCase()
                .includes(t(search.toLocaleLowerCase())) ||
              t(e.company)
                .toLocaleLowerCase()
                .includes(t(search.toLocaleLowerCase())) ||
              t(e.module)
                .toLocaleLowerCase()
                .includes(t(search.toLocaleLowerCase()))
          )}
          edit={edit}
          setEdit={setEdit}
          deleting={deleting}
          setDeleting={setDeleting}
        />
        {(showPermissionsModal || edit.status) && (
          <PermissionModal
            setShowModal={setShowPermissionsModal}
            edit={edit}
            setEdit={setEdit}
            permissionData={permissionData}
            setPermissionData={setPermissionData}
            companies={companies}
          />
        )}
        {deleting.status && (
          <AdminDeleteModal
            id={deleting.data.id}
            name={`${deleting.data.Permission} ${t('ofUser')} ${deleting.data.User} ${t('ofStructure')} ${deleting.data.Structures}`}
            setShowModal={setShowPermissionsModal}
            setDeleting={setDeleting}
            type="Permission"
            idsPermission={{
              moduleAssignmentId:
                listPermissions?.listUsersPermissions.find(
                  (e) =>
                    e.moduleName === deleting.data.module &&
                    e.structureName === deleting.data.Structures
                )?.moduleAssignmentId ?? '',
              structureUserPermissionId:
                listPermissions?.listUsersPermissions.find(
                  (e) =>
                    e.moduleName === deleting.data.module &&
                    e.structureName === deleting.data.Structures
                )?.structureUserPermissionId ?? ''
            }}
            title={t('Permission')}
          />
        )}
      </AdminContainer>
    </DivContainer>
  );
}

export default Permissions;
