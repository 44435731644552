import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindActionPlanResponsibleQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;


export type FindActionPlanResponsibleQuery = { __typename?: 'Query', findActionPlanResponsible: Array<{ __typename?: 'ActionPlanResponsibleType', email: string, id: string, name: string }> };


export const FindActionPlanResponsibleDocument = gql`
    query FindActionPlanResponsible($structureId: String!) {
  findActionPlanResponsible(structureId: $structureId) {
    email
    id
    name
  }
}
    `;
export type FindActionPlanResponsibleQueryResult = Apollo.QueryResult<FindActionPlanResponsibleQuery, FindActionPlanResponsibleQueryVariables>;