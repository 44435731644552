import { useMutation } from '@apollo/client';
import {
  AddPluviometerAlertLevelsDocument,
  AddPluviometerAlertLevelsMutation,
  AddPluviometerAlertLevelsMutationVariables
} from '../../graphql/generated/addPluviometerAlertLevels.mutation';
import { ListPluviometersByStructureDocument } from '../../graphql/query/generated/listPluviometersByStructure.query';

export function useAddPluviometerControlLevels(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addPluviometerControlLevels, { data, error, loading }] = useMutation<
    AddPluviometerAlertLevelsMutation,
    AddPluviometerAlertLevelsMutationVariables
  >(AddPluviometerAlertLevelsDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListPluviometersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addPluviometerControlLevels,
    data,
    error,
    loading
  };
}
