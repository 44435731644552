import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ListUserNotificationDocument,
  ListUserNotificationQuery,
  ListUserNotificationQueryVariables
} from '../graphql/query/generated/listUserNotification';

export function useListUserNotification(
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListUserNotificationQuery,
    ListUserNotificationQueryVariables
  >(ListUserNotificationDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export function useListUserNotificationLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listUserNotification, { data, loading, error }] = useLazyQuery<
    ListUserNotificationQuery,
    ListUserNotificationQueryVariables
  >(ListUserNotificationDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listUserNotification,
    data,
    loading,
    error
  };
}
