import { useState } from 'react';
import { ExportTableModalProps } from './ExportTable.interface';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import Button from '../../Atoms/Button/Button';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import { useTranslation } from 'react-i18next';
import {
  HolderCheckbox,
  HolderCheckboxLis,
  HolderExports,
  HolderFooter
} from './ExportTableStyles';

const ExportTableModal = ({
  columns,
  onExport,
  onClose
}: ExportTableModalProps) => {
  const { t: translate } = useTranslation();
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  const handleCheckboxChange = (checked: boolean, key: string) => {
    setSelectedColumns((prevSelected) =>
      checked
        ? [...prevSelected, key]
        : prevSelected.filter((colKey) => colKey !== key)
    );
  };

  const handleExport = () => {
    onExport(selectedColumns);
    onClose();
  };

  return (
    <ViewModal
      width={50}
      component={
        <HolderExports>
          <HolderCheckboxLis>
            {columns.map(({ key, label }) => (
              <HolderCheckbox key={key}>
                <InputCheckbox
                  label={label}
                  name={key}
                  value={selectedColumns.includes(key)}
                  onChange={(newValue) => handleCheckboxChange(newValue, key)}
                />
              </HolderCheckbox>
            ))}
          </HolderCheckboxLis>
          <HolderFooter>
            <Button
              text={translate('ExportTable')}
              variant="primary"
              onClick={handleExport}
            />
          </HolderFooter>
        </HolderExports>
      }
      title={translate('ExportTable')}
      onClose={onClose}
    ></ViewModal>
  );
};

export default ExportTableModal;
