import React, { CSSProperties, Dispatch } from 'react';
import { Color, FontSize } from '../Styles/Styles';

interface TextAreaComponentProps {
  placeholder?: string;
  text: string;
  setText: Dispatch<string>;
}

const textAreaStyle: CSSProperties = {
  resize: 'none',
  outline: 'none',
  borderRadius: '0px',
  color: Color.Brown1,
  position: 'relative',
  width: '100%',
  height: '100%'
};

export const TextAreaComponent = ({
  placeholder,
  text,
  setText
}: TextAreaComponentProps) => {
  return (
    <>
      <textarea
        onChange={(e) => {
          setText(e.target.value);
        }}
        value={text}
        placeholder={placeholder}
        style={textAreaStyle}
        maxLength={255}
      />
      <span
        style={{
          fontSize: FontSize.Xsmall,
          color: Color.Disabled,
          float: 'right'
        }}
      >
        {text.length} / 255
      </span>
    </>
  );
};
