import { useMutation, useQuery } from '@apollo/client';
import { t } from 'i18next';
import { API_URL } from '../../utils/const';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';
import {
  DeletePsbFileDocument,
  DeletePsbFileMutation,
  DeletePsbFileMutationVariables
} from '../graphql/generated/deletePsbFile.mutation';
import {
  FindFilesPagDocument,
  FindFilesPagQuery,
  FindFilesPagQueryVariables
} from '../graphql/query/generated/findFilesPag.query';
import {
  FindAllPsbDataDocument,
  FindAllPsbDataQuery,
  FindAllPsbDataQueryVariables
} from '../graphql/query/generated/findAllPsbData.query';

export const sendPSBFile = async (formData: FormData, structureId: string) => {
  try {
    if (!formData) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }

    toastifyLoading(`${t('registering')} ${t('PSB')}...`);
    const response = await fetch(`${API_URL}/psb/upload/files/${structureId}`, {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: localStorage.getItem('user')
          ? JSON.parse(localStorage.user).token
          : ''
      }
    });
    if (response.status === 200) {
      toastfyDimiss('toastLoading');
      toastfySuccess(`${t('PSB')} ${t('registeredSuccessfully')}!`);
    }

    return response.status;
  } catch (error) {
    console.error(error);
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const useDeletePsbFile = (
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [deletePsbFile, { data, error, loading }] = useMutation<
    DeletePsbFileMutation,
    DeletePsbFileMutationVariables
  >(DeletePsbFileDocument, {
    errorPolicy: 'all',
    refetchQueries: [FindFilesPagDocument],
    awaitRefetchQueries: true,
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deletePsbFile,
    data,
    error,
    loading
  };
};

export const useFindFilesPag = (
  psbId: string,
  structureId: string,
  pageInfo?: { limit: number; offset: number; page: number },
  onCompleted?: () => void,
  onError?: () => void
) => {
  const { data, loading, error, refetch } = useQuery<
    FindFilesPagQuery,
    FindFilesPagQueryVariables
  >(FindFilesPagDocument, {
    variables: {
      psbId,
      structureId,
      pageInfo
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error,
    refetch
  };
};

export const useFindAllPsbData = (
  structureId: string,
  onCompleted?: () => void,
  onError?: () => void
) => {
  const { data, loading, error, refetch } = useQuery<
    FindAllPsbDataQuery,
    FindAllPsbDataQueryVariables
  >(FindAllPsbDataDocument, {
    variables: {
      structureId
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error,
    refetch
  };
};

export const downloadPSBFile = (row: any) => {
  fetch(row.url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = row.file;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
};
