import { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa6';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { InstrumentItemType } from '../../../../@Types/Instruments/instruments';
import { useListReadingByPiezometerLazy } from '../../../../data/hooks/piezometer/use-list-reading-by-piezometer';
import { useListReadingByPluviometerLazy } from '../../../../data/hooks/pluviometer/use-list-reading-by-pluviometer.query';
import { useListSurfaceMarkerReadingLazy } from '../../../../data/hooks/surface-marker/use-list-surface-marker-reading';
import { useListReadingByWaterLevelsLazy } from '../../../../data/hooks/water-level/use-list-reading-by-water-levels';
import { getRandomColor } from '../../../../utils/getRandomColor';
import { toastfyDimiss, toastifyLoading } from '../../../Toastify';
import {
  ASubTitle,
  InstrumentInputSelect,
  LiSubMenu,
  LiSubMenuMap
} from './StyleInstrumentGraph';

interface GraphMenuProps {
  selectedGraphs: InstrumentItemType[];
  setSelectedGraphs: Dispatch<InstrumentItemType[]>;
  text: string;
  state: InstrumentItemType[];
  setState: Dispatch<InstrumentItemType[]>;
  instruments: any;
  startDate: Date;
  endDate: Date;
}

export default function SubMenuGraphs({
  text,
  state,
  setState,
  selectedGraphs,
  setSelectedGraphs,
  instruments,
  startDate,
  endDate
}: GraphMenuProps) {
  const { t } = useTranslation();
  const { structureId } = useParams();
  const [showState, setShowState] = useState<boolean>(false);
  const { listReadingByPiezometer } = useListReadingByPiezometerLazy();
  const { listReadingByPluviometer } = useListReadingByPluviometerLazy();
  const { listReadingByWaterLevels } = useListReadingByWaterLevelsLazy();
  const { listReadingSurfaceMarker } = useListSurfaceMarkerReadingLazy();
  const [selectAll, setSelectAll] = useState<boolean>(false);

  //TODO: testar alertas
  async function handleFetchPiezometers(instruments: any, id?: string) {
    if (id) {
      const instrumentState: any[] = [];
      const readingsCote: any[] = [];
      const alertLevelsState: any[] = [];
      let bottomCote;
      let topCote;

      const response = await listReadingByPiezometer({
        variables: {
          endDate: endDate,
          startDate: startDate,
          instrumentIds: id
        }
      });

      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        response.data.listReadingByPiezometer.map(({ name, alertLevels }) => {
          alertLevels.map((e: any) => {
            if (e.name === 'EMERGÊNCIA') {
              alertLevelsState.push({
                label: `${name} - ${e.name} (m)`,
                type: 'line',
                yAxisID: 'piezometer',
                borderColor: 'red',
                backgroundColor: 'red',
                borderWidth: 2,
                data: [
                  {
                    x: startDate,
                    y: e.coteValue
                  },
                  {
                    x: endDate,
                    y: e.coteValue
                  }
                ]
              });
            } else if (e.name === 'ATENÇÃO') {
              alertLevelsState.push({
                label: `${name} - ${e.name} (m)`,
                type: 'line',
                yAxisID: 'piezometer',
                borderColor: 'yellow',
                backgroundColor: 'yellow',
                borderWidth: 2,
                data: [
                  {
                    x: startDate,
                    y: e.coteValue
                  },
                  {
                    x: endDate,
                    y: e.coteValue
                  }
                ]
              });
            } else if (e.name === 'ALERTA') {
              alertLevelsState.push({
                label: `${name} - ${e.name} (m)`,
                type: 'line',
                yAxisID: 'piezometer',
                borderColor: 'orange',
                backgroundColor: 'orange',
                borderWidth: 2,
                data: [
                  {
                    x: startDate,
                    y: e.coteValue
                  },
                  {
                    x: endDate,
                    y: e.coteValue
                  }
                ]
              });
            } else {
              alertLevelsState.push({
                label: `${name} - ${e.name} (m)`,
                type: 'line',
                yAxisID: 'piezometer',
                borderColor: getRandomColor('primary'),
                backgroundColor: getRandomColor('random'),
                borderWidth: 2,
                data: [
                  {
                    x: startDate,
                    y: e.coteValue
                  },
                  {
                    x: endDate,
                    y: e.coteValue
                  }
                ]
              });
            }
          });
        });

        response.data.listReadingByPiezometer.map(
          ({ name, instrumentId, readings, cotes }: any) => {
            readings.map((e: any) =>
              readingsCote.push({ x: e.date, y: e.cote })
            );
            bottomCote = cotes[0].bottomCote;
            topCote = cotes[0].topCote;
            instrumentState.push({
              name: name,
              instrument: 'piezometer',
              id: instrumentId,
              data: {
                axis: {
                  piezometer: {
                    type: 'linear',
                    position: 'left',
                    display: true,
                    title: {
                      display: true,
                      text: 'Nível piezométrico ( m )',
                      position: 'top',
                      align: 'center'
                    }
                  }
                },
                dataSet: [
                  {
                    label: `${name} - Nível piezométrico (m)`,
                    type: 'line',
                    yAxisID: 'piezometer',
                    borderColor: getRandomColor('primary'),
                    backgroundColor: getRandomColor('random'),
                    borderWidth: 2,
                    data: readingsCote
                  },
                  ...alertLevelsState,
                  {
                    label: `${name} - Cota de topo (m)`,
                    type: 'line',
                    yAxisID: 'piezometer',
                    backgroundColor: 'black',
                    borderColor: 'black',
                    borderWidth: 5,
                    borderDash: [5, 5],
                    data: [
                      {
                        x: startDate,
                        y: topCote
                      },
                      {
                        x: endDate,
                        y: topCote
                      }
                    ]
                  },
                  {
                    label: `${name} - Cota de base (m)`,
                    type: 'line',
                    yAxisID: 'piezometer',
                    backgroundColor: 'black',
                    borderColor: 'black',
                    borderWidth: 5,
                    borderDash: [5, 5],
                    data: [
                      {
                        x: startDate,
                        y: bottomCote
                      },
                      {
                        x: endDate,
                        y: bottomCote
                      }
                    ]
                  }
                ]
              },
              isChecked: true
            });
          }
        );
        toastfyDimiss('toastLoading');
        return instrumentState[0];
      }
    } else {
      const newState = instruments.map((element: any) => ({
        name: element.name,
        instrument: 'piezometer',
        id: element.id,
        isChecked: false
      }));
      setState(newState);
    }
  }

  async function handleFetchPluviometers(instruments: any, id?: string) {
    if (id) {
      const instrumentState: any[] = [];
      const readingsRainfall: any[] = [];

      const response = await listReadingByPluviometer({
        variables: {
          endDate: endDate,
          startDate: startDate,
          instrumentIds: id
        }
      });

      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        response.data.listReadingByPluviometer.map(
          ({ name, instrumentId, readings }: any) => {
            readings.map((e: any) =>
              readingsRainfall.push({ x: e.date, y: e.rainfall })
            );
            instrumentState.push({
              name: name,
              instrument: 'rainGauge',
              id: instrumentId,
              data: {
                axis: {
                  rainGauge: {
                    type: 'linear',
                    position: 'left',
                    display: true,
                    title: {
                      display: true,
                      text: 'Pluviometria ( mm )',
                      position: 'top',
                      align: 'center'
                    },
                    ticks: {
                      beginAtZero: true
                    }
                  }
                },
                dataSet: [
                  {
                    label: `${name} - Pluviometria (mm)`,
                    type: 'bar',
                    yAxisID: 'rainGauge',
                    borderColor: getRandomColor('primary'),
                    backgroundColor: getRandomColor('random'),
                    borderWidth: 2,
                    data: readingsRainfall
                  }
                ]
              },
              isChecked: true
            });
          }
        );
        toastfyDimiss('toastLoading');
        return instrumentState[0];
      }
    } else {
      const newState = instruments.map((element: any) => ({
        name: element.name,
        instrument: 'rainGauge',
        id: element.id,
        isChecked: false
      }));
      setState(newState);
    }
  }

  //TODO: nao tem elevação soleira extravasor
  async function handleFetchWaterLevels(instruments: any, id?: string) {
    if (id) {
      const instrumentState: any[] = [];
      const readingsWaterLevel: any[] = [];

      const response = await listReadingByWaterLevels({
        variables: {
          structureId: structureId!,
          endDate: endDate,
          startDate: startDate,
          instrumentIds: id
        }
      });

      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        response.data.listReadingByWaterLevel.map(
          ({ name, instrumentId, readings }: any) => {
            readings.map((e: any) =>
              readingsWaterLevel.push({ x: e.date, y: e.value })
            );
            instrumentState.push({
              name: name,
              instrument: 'reservoirWaterLevel',
              id: instrumentId,
              data: {
                axis: {
                  reservoirWaterLevel: {
                    type: 'linear',
                    position: 'left',
                    display: true,
                    title: {
                      display: true,
                      text: "Nível d'água do reservatório ( m )",
                      position: 'top',
                      align: 'center'
                    }
                  }
                },
                dataSet: [
                  {
                    label: `${name} - Nível d'água do reservatório (m)`,
                    type: 'line',
                    yAxisID: 'reservoirWaterLevel',
                    borderColor: getRandomColor('primary'),
                    backgroundColor: getRandomColor('random'),
                    borderWidth: 2,
                    data: readingsWaterLevel
                  }
                  // {
                  //   label: `${name} - Elevação soleira extravasor (m)`,
                  //   type: "line",
                  //   yAxisID: "reservoirWaterLevel",
                  //   // backgroundColor: getRandomColor(),
                  //   borderColor: getRandomColor(),
                  //   backgroundColor: "#ff8800",
                  //   // borderColor: "#925500",
                  //   borderWidth: 2,
                  //   data: [{
                  //     "x": "2019-04-22T12:06:21-07:00",
                  //     "y": 1028
                  //   },
                  //   {
                  //     "x": "2019-05-21T12:07:21-07:00",
                  //     "y": 1028
                  //   },
                  //   ]
                  // }
                ]
              },
              isChecked: true
            });
          }
        );
        toastfyDimiss('toastLoading');
        return instrumentState[0];
      }
    } else {
      const newState = instruments.map((element: any) => ({
        name: element.name,
        instrument: 'reservoirWaterLevel',
        id: element.id,
        isChecked: false
      }));
      setState(newState);
    }
  }

  async function handleFetchSurfaceMarkers(instruments: any, id?: string) {
    if (id) {
      const instrumentState: any[] = [];
      const readingsDisplacement: any[] = [];
      const readingsElevation: any[] = [];

      const response = await listReadingSurfaceMarker({
        variables: {
          endDate: endDate,
          startDate: startDate,
          instrumentIds: id
        }
      });

      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        response.data.listReadingBySurfaceMarkers.map(
          ({
            name,
            instrumentId,
            displacementElevationData,
            elevationData
          }: any) => {
            displacementElevationData.map((e: any) =>
              readingsDisplacement.push({ x: e.date, y: e.value })
            );
            elevationData.map((e: any) =>
              readingsElevation.push({ x: e.date, y: e.value })
            );
            instrumentState.push({
              name: name,
              instrument: 'shallowLandmark',
              id: instrumentId,
              data: {
                axis: {
                  shallowLandmark: {
                    type: 'linear',
                    position: 'left',
                    display: true,
                    title: {
                      display: true,
                      text: 'Elevação ( m )',
                      position: 'top',
                      align: 'center'
                    }
                  },
                  displacement: {
                    type: 'linear',
                    position: 'right',
                    display: true,
                    title: {
                      display: true,
                      text: 'Deslocamento acumulado ( mm )',
                      position: 'top',
                      align: 'center'
                    }
                  }
                },
                dataSet: [
                  {
                    label: `${name} - Elevação (m)`,
                    type: 'line',
                    yAxisID: 'shallowLandmark',
                    borderColor: getRandomColor('primary'),
                    backgroundColor: getRandomColor('random'),
                    borderWidth: 2,
                    data: readingsElevation
                  },
                  {
                    label: `${name} - Deslocamento acumulado (mm)`,
                    type: 'line',
                    yAxisID: 'displacement',
                    borderColor: getRandomColor('primary'),
                    backgroundColor: getRandomColor('random'),
                    borderWidth: 2,
                    data: readingsDisplacement
                  }
                ]
              },
              isChecked: true
            });
          }
        );
        toastfyDimiss('toastLoading');
        return instrumentState[0];
      }
    } else {
      const newState = instruments.map((element: any) => ({
        name: element.name,
        instrument: 'shallowLandmark',
        id: element.id,
        isChecked: false
      }));
      setState(newState);
    }
  }

  const handleFetchInstrumentsData = (
    text: string,
    instruments: any,
    id?: string
  ) => {
    const strategy = {
      piezometer: () => handleFetchPiezometers(instruments, id),
      pluviometer: () => handleFetchPluviometers(instruments, id),
      ina: () => handleFetchPiezometers(instruments, id),
      waterlevel: () => handleFetchWaterLevels(instruments, id),
      surfacemarker: () => handleFetchSurfaceMarkers(instruments, id)
    };
    return strategy[text.toLowerCase() as keyof typeof strategy]();
  };

  function showMenu(
    event: React.MouseEvent<HTMLLIElement> | any,
    showState: boolean,
    setShowState: Dispatch<boolean>
  ): void {
    event.stopPropagation();
    if (setShowState) {
      setShowState(!showState);
    }
  }

  useEffect(() => {
    setSelectedGraphs([]);
    handleFetchInstrumentsData(text, instruments);
  }, [startDate, endDate]);

  const handleChangeInstrument = async (
    event: React.MouseEvent<HTMLLIElement> | any,
    element: InstrumentItemType
  ) => {
    event.stopPropagation();
    const newState = [...state];
    newState.map((item) => {
      if (item.id === element.id) {
        item.isChecked = !element.isChecked;
      }
    });
    setState(newState);

    if (element.isChecked === true) {
      const responseFetchInstrumentsData = await handleFetchInstrumentsData(
        text,
        instruments,
        element.id.toString()
      );
      setSelectedGraphs([
        ...selectedGraphs,
        { ...responseFetchInstrumentsData, ...element }
      ]);
    } else {
      setSelectedGraphs(
        selectedGraphs.filter((item: any) => item.id !== element.id)
      );
    }
  };

  const handleSelectAll = async (
    event: React.MouseEvent<HTMLLIElement> | any
  ) => {
    event.stopPropagation();
    const newSelectAllState = !selectAll;
    setSelectAll(newSelectAllState);

    const newState = state.map((item) => ({
      ...item,
      isChecked: newSelectAllState
    }));

    setState(newState);

    if (newSelectAllState) {
      const newSelectedGraphs = [];
      for (const element of newState) {
        const responseFetchInstrument = await handleFetchInstrumentsData(
          text,
          instruments,
          element.id.toString()
        );
        newSelectedGraphs.push({ ...responseFetchInstrument, ...element });
      }

      setSelectedGraphs(newSelectedGraphs);
    } else {
      setSelectedGraphs([]);
    }
  };

  return (
    <LiSubMenu
      onClick={(element) => {
        showMenu(element, showState, setShowState);
      }}
    >
      <ASubTitle>
        {showState ? <FaAngleDown /> : <FaAngleRight />} {t(`${text}`)}{' '}
      </ASubTitle>
      {showState && (
        <ul>
          <LiSubMenuMap onClick={(event) => handleSelectAll(event)}>
            <span>{t('selectAll')}</span>
            <InstrumentInputSelect>
              {selectAll ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
            </InstrumentInputSelect>
          </LiSubMenuMap>
          {state.map((element, index) => {
            return (
              <LiSubMenuMap
                key={index}
                onClick={(event) => {
                  handleChangeInstrument(event, element);
                }}
              >
                {element.name}{' '}
                <InstrumentInputSelect>
                  {element.isChecked ? (
                    <MdCheckBox />
                  ) : (
                    <MdCheckBoxOutlineBlank />
                  )}
                </InstrumentInputSelect>
              </LiSubMenuMap>
            );
          })}
        </ul>
      )}
    </LiSubMenu>
  );
}
