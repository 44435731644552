import { AiOutlineSearch } from 'react-icons/ai';
import { StylesConfig } from 'react-select';
import styled, { CSSProperties } from 'styled-components';
import { Color, FontSize } from '../../Styles/Styles';

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${Color.White};
`;

export const AdminContainer = styled.div`
  background-color: ${Color.White};
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

export const AdminTitle = styled.h2`
  color: ${Color.Brown1};
  width: 90%;
  display: flex;
  justify-content: flex-start;
`;

export const AdminFilter = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    gap: 25px;

    span {
      color: ${Color.Brown1};
      font-weight: bold;
    }
  }
`;

export const AdminSearch = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.Brown1};
  margin: 5px;
`;

export const StyledInput = styled.input`
  padding: 10px;
  border: 0;
`;

export const SearchIcon = styled(AiOutlineSearch)`
  color: ${Color.Brown1};
  cursor: pointer;
`;

export const RequiredInput = styled.div`
  width: 100%;
  position: relative;
`;

export const AdminInputs = styled.div`
  background-color: ${Color.White};
  position: relative;
  flex: 2;
  margin: 3px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputArea = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  align-items: center;
  margin: 25px 0;
`;

export const ButtonAreaAdmin = styled.div`
  display: flex;
  padding: 25px 25px 25px 0;
  justify-content: space-between;
`;

export const ActionsRow = styled.span`
  display: flex;
  color: ${Color.Brown1};
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
`;

export const CheckboxAdmin = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: start;
  gap: 10px;
  cursor: pointer;
  width: 100%;
`;

export const AdminOptionsDiv = styled.div`
  position: absolute;
  width: 65%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const AdminOption = styled.button`
  color: ${Color.White};
  background-color: transparent;
  border: 0;
  border-width: 1px;
  padding: 3px 10px 3px 10px;
  border-radius: 2px;
  font-size: ${FontSize.Small};
  cursor: pointer;
  user-select: none;
`;

export const PermissionModules = styled.div`
  background-color: ${Color.White};
  margin-left: 4px;
  margin-top: 2px;
  grid-area: 2 / 2 / 4 / 3;
  position: relative;
  display: flex;

  span:nth-child(2) {
    margin-top: 5px;
  }
`;

export const PermissionUserList = styled.div`
  grid-area: 1 / 1 / 4 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Color.White};
  position: relative;

  .check-icon {
    position: absolute;
    left: -20px;
  }
  .button-users-structure {
    position: absolute;
    border: none;
    background-color: transparent;
    right: 10px;
    bottom: 10px;
    color: ${Color.Title2};
    cursor: pointer;
  }

  .change-user-list {
    top: 10px;
    bottom: unset;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  input {
    margin-top: 30px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${Color.Title2};
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    cursor: pointer;
    margin: 5px;
    position: relative;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
  }
`;

export const AreaSelectableModules = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  user-select: none;
  overflow: auto;

  div {
    display: flex;
    align-items: center;
  }
  label {
    cursor: pointer;
  }
  span {
    min-width: 100px;
  }
  .timeContractInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    input {
      margin-right: 5px;
      width: 120px;
    }
  }
  .save-permission {
    position: fixed;
    border: none;
    background-color: transparent;
    right: 10px;
    bottom: 10px;
    color: ${Color.Title2};
    cursor: pointer;
  }
`;

export const IconRequired: CSSProperties = {
  width: '8px',
  position: 'absolute',
  right: '25%',
  top: '35%',
  color: '#967442'
};

export const AdminInput = styled.input`
  margin: 15px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${Color.Brown1};
  width: 50%;
  color: ${Color.Brown1};
  height: 20px;
  padding-bottom: 7px;
  box-sizing: border-box;
  &:focus {
    color: ${Color.Brown1};
    outline: none;
  }
`;

export const InputMaskStyle: CSSProperties = {
  margin: '15px',
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: `1px solid ${Color.Brown1}`,
  width: '50%',
  color: Color.Brown1
};

export const TableAdmin = styled.table`
  border-collapse: collapse;
  text-align: center;
  font-size: ${FontSize.Small};
  color: ${Color.Text1};
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 90%;

  th {
    position: sticky;
    top: 0;
    background-color: ${Color.Brown3};
    color: ${Color.Brown1};
    padding: 10px 5px 10px 5px;
    user-select: none;
    width: 200px;
    height: 20px;
  }
`;

export const TableHeader = styled.thead`
  background-color: ${Color.Brown3};
  color: ${Color.Brown1};
`;

export const TableBody = styled.tbody`
  max-height: 300px;
  overflow-y: auto;
`;

export const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const TableHeaderCell = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableCell = styled.td`
  padding: 10px;
  width: 200px;
`;

export const TableCollumName = styled.span`
  cursor: pointer;
`;

export const ArrowIconStyle: any = {
  width: '10px',
  position: 'absolute',
  cursor: 'pointer',
  right: 0
};

export const AdminDateContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StructureConatiner = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${Color.White};
`;

export const ContainerGeneralData = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  width: 100%;
`;

export const PartData = styled.div`
  width: 100%;
  color: ${Color.Brown1};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableDocs = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  tr {
    display: flex;
    gap: 35px;
    align-items: center;
    flex-direction: row;
  }
  td {
    display: flex;
    align-items: center;

    a {
      color: ${Color.Text1};
    }
  }
`;

export const ButtonAreaDelete = styled.div`
  display: flex;
  padding: 65px 25px 25px 0;
  justify-content: space-between;
`;
