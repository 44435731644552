import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearch } from 'react-icons/io5';
import Icon from '../../Atoms/Icon/Icon';
import InputText from '../../Molecules/InputText/InputText';
import { InputSearchProps } from './InputSearch.interface';
import { SearchContainer, SearchInput } from './InputSearch.styles';

const InputSearch = ({ onSearch }: InputSearchProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [query, setQuery] = useState('');
  const { t: translate } = useTranslation();

  const handleSearch = () => {
    onSearch(query);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <SearchContainer isExpanded={isExpanded}>
      <Icon
        Icon={IoSearch}
        onClick={() => setIsExpanded(!isExpanded)}
        variant="small"
      />
      <SearchInput isExpanded={isExpanded}>
        <InputText
          type="text"
          value={query}
          onChange={handleInputChange}
          onKeyDown={(event) => event.key === 'Enter' && handleSearch()}
          label={translate('search')}
          name="search"
        />
      </SearchInput>
    </SearchContainer>
  );
};

export default InputSearch;
