import styled from 'styled-components';
import { Color } from '../../Styles/Styles';

interface NotificationModalProps {
  width?: number;
  height?: number;
}

export const DivNotificationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 20px;
`;

export const DivNotificationItemContainer = styled.div`
  display: flex;
  gap: 20px;
  color: ${Color.Brown1};
  width: 100%;
`;

export const DivNotificationPropsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 5px;
  min-height: 90px;
  max-height: 90px;

  span {
    color: ${Color.Gray4};
    font-size: 14px;
    font-weight: 300;
  }
`;

export const NotificationModalContainer = styled.div<NotificationModalProps>`
  position: fixed;
  background-color: ${Color.White};
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width || 80}%;
  height: ${(props) => props.height || 80}%;
  max-width: 90%;
  max-height: 90%;
  z-index: 100;
`;

export const ModalFilterDateContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 20px;
`;

export const DivNotificationListModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

export const DivNotificationItemModalContainer = styled.div`
  width: 100%;
  border-top: 2px solid ${Color.Brown3};
`;

export const SpanNotificationTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

export const DivNotificationDateUserName = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${Color.White};
`;

export const DivNotificationDateUserNameSpanModal = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${Color.Gray4};
`;

export const ButtonNotificationAccordion = styled.button<{ unread: boolean }>`
  position: relative;
  background-color: ${({ unread }) => (unread ? Color.Gray1 : Color.White)};
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;

  &:hover {
    background-color: #ccc;
  }
`;

export const DivNotificationAccordionPanel = styled.div<{ active: boolean }>`
  padding: 0 18px;
  background-color: white;
  max-height: ${({ active }) => (active ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.4s ease-out;
`;

export const DivNotificationPlusMinusContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  color: ${Color.Brown1};
`;
