import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../Components/Buttons/ButtonsStyle';
import GraphHeader from '../../Components/Graphs/Monitoring/Instrument/GraphHeader';
import { DefaultInput } from '../../Components/Inputs/InputsStyle';
import { RegisterReadingsArea } from '../../Components/Instruments/Readings/DataBaseStyles';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';
import { useForgotPassword } from '../../data/hooks/use-forgot-password';
import { ButtonAreaAdmin } from '../AdminScreen/AdminScreenStyle';

interface ForgotMyPasswordType {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export default function ForgotMyPasswordModal({
  showModal,
  setShowModal
}: ForgotMyPasswordType) {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { forgotPassword } = useForgotPassword();

  const handleForgotPassword = async () => {
    if (!email) {
      setError(true);
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }
    const response = await forgotPassword({
      variables: {
        email: email
      }
    });
    toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    if (response.data) {
      setShowModal(false);
      toastfyDimiss('toastLoading');
      toastfySuccess(t('NewPasswordSentEmail'));
    } else if (response.errors) {
      toastfyDimiss('toastLoading');
      toastfyError(
        response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
      );
    }
  };

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '50%',
          minWidth: 500,
          maxWidth: 550,
          height: 'max-content',
          minHeight: '300px'
        }}
      >
        <GraphHeader
          showModal={showModal}
          setShowModal={setShowModal}
          title={t('ForgotMyPassword')}
        />
        <RegisterReadingsArea>
          <DefaultInput
            onKeyDown={(target) =>
              target.key === 'Enter' ? handleForgotPassword() : null
            }
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder={t('Email')}
            style={{ marginTop: '40px', marginBottom: '50px' }}
            error={error && !email}
          />
        </RegisterReadingsArea>
        <ButtonAreaAdmin>
          <CancelButtonStyled onClick={() => setShowModal(false)}>
            {' '}
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          <AdvanceButtonStyled
            onClick={() => {
              handleForgotPassword();
            }}
          >
            {' '}
            {t('Send').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
