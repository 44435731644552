import { ActionPlansData } from '../../Components/V2/Pages/ActionPlanHistoryPage/ActionPlanHistoryPage.interface';

export const getActionPlanHistoryData = async () => {
  try {
    //TODO: inserir retorno do backend
    const response: ActionPlansData[] = [
      {
        title: 'Plano de Ação 1',
        data: '2024-07-22',
        pictures: [
          {
            url: 'https://picsum.photos/200',
            name: 'Image.png',
            id: '1',
            file: undefined
          },
          {
            url: 'https://picsum.photos/200',
            name: 'Image.png',
            id: '2',
            file: undefined
          },
          {
            url: 'https://picsum.photos/200',
            name: 'Image.png',
            id: '3',
            file: undefined
          },
          {
            url: 'https://picsum.photos/200',
            name: 'Image.png',
            id: '4',
            file: undefined
          }
        ],
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. '
      },
      {
        title: 'Plano de Ação 2',
        data: '2024-08-15',
        pictures: [
          {
            url: 'https://picsum.photos/200',
            name: 'Image.png',
            id: '5',
            file: undefined
          },
          {
            url: 'https://picsum.photos/200',
            name: 'Image.png',
            id: '6',
            file: undefined
          }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        title: 'Plano de Ação 3',
        data: '2024-09-05',
        pictures: [
          {
            url: 'https://picsum.photos/200',
            name: 'Image.png',
            id: '7',
            file: undefined
          }
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      }
    ];

    return response;
  } catch (error) {
    //TODO: tratamento de erro
    throw new Error('error');
  }
};