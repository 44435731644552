/* eslint-disable react/prop-types */
import { ReactNode } from 'react';
import Text from '../../Atoms/Text/Text';
import Button from '../../Atoms/Button/Button';
import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter
} from './ContentActionCardStyles';

type ContentActionCardProps = {
  title: string;
  children: ReactNode;
  action?: () => void;
};

const ContentActionCard: React.FC<ContentActionCardProps> = ({
  children,
  action,
  title
}) => {
  return (
    <Card>
      <CardHeader>
        <Text size="large" weight="bold" type="label" color="black">
          {title}
        </Text>
      </CardHeader>
      <CardContent>{children}</CardContent>
      {action && (
        <CardFooter>
          <Button variant="secondary" text="Ver Tudo" onClick={action}></Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default ContentActionCard;
