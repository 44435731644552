import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListSurfaceMarkersByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListSurfaceMarkersByStructureQuery = { __typename?: 'Query', listSurfaceMarkersByStructure: Array<{ __typename?: 'ListSurfaceMarkerType', activationDate?: Date | null, coordinateE: number, coordinateN: number, deactivationDate?: Date | null, id?: string | null, installLocation?: string | null, installationDate?: Date | null, isUTM: boolean, name: string, operationalStatus: Types.InstrumentStatusEnum, readingType: string, status: boolean, sections: Array<{ __typename?: 'BasicSectionsType', id: string, isUTM: boolean, name: string, status: boolean, type?: string | null }>, surfaceMarker?: { __typename?: 'SurfaceMarkerType', id: string, alertLevels?: Array<{ __typename?: 'ListSurfaceMarkerAlertLevelType', coordinateE?: number | null, coordinateN?: number | null, date?: Date | null, elevation?: number | null, id: string, name?: string | null }> | null, references?: Array<{ __typename?: 'ListSurfaceMarkersReferenceType', coordinateE?: number | null, coordinateN?: number | null, date?: Date | null, elevation?: number | null, id: string }> | null } | null, type?: { __typename?: 'InstrumentType', id: string, instrumentType: string } | null }> };


export const ListSurfaceMarkersByStructureDocument = gql`
    query ListSurfaceMarkersByStructure($structureInfo: StructureInfoInput!) {
  listSurfaceMarkersByStructure(structureInfo: $structureInfo) {
    activationDate
    coordinateE
    coordinateN
    deactivationDate
    id
    installLocation
    installationDate
    isUTM
    name
    operationalStatus
    readingType
    sections {
      id
      isUTM
      name
      status
      type
    }
    status
    surfaceMarker {
      alertLevels {
        coordinateE
        coordinateN
        date
        elevation
        id
        name
      }
      id
      references {
        coordinateE
        coordinateN
        date
        elevation
        id
      }
    }
    type {
      id
      instrumentType
    }
  }
}
    `;
export type ListSurfaceMarkersByStructureQueryResult = Apollo.QueryResult<ListSurfaceMarkersByStructureQuery, ListSurfaceMarkersByStructureQueryVariables>;