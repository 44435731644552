import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TiDelete } from 'react-icons/ti';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  UpdateAtoDocument,
  UpdateAtoMutation,
  UpdateAtoMutationVariables
} from '../../../../data/graphql/generated/addEditAto.mutation';
import {
  CreateAtoDocument,
  CreateAtoMutation,
  CreateAtoMutationVariables
} from '../../../../data/graphql/generated/createAto.mutation';
import {
  DeleteAtoDocsDocument,
  DeleteAtoDocsMutation,
  DeleteAtoDocsMutationVariables
} from '../../../../data/graphql/generated/deleteAtoDocs';
import {
  SaveAtoDocsDocument,
  SaveAtoDocsMutation,
  SaveAtoDocsMutationVariables
} from '../../../../data/graphql/generated/saveAtoDocs';
import { FindAllAtoDocument } from '../../../../data/graphql/query/generated/findAllAto.query';
import {
  FindAtoVerifiersDocument,
  FindAtoVerifiersQuery,
  FindAtoVerifiersQueryVariables
} from '../../../../data/graphql/query/generated/findAtoVerifiers';
import {
  ListCompaniesDocument,
  ListCompaniesQuery,
  ListCompaniesQueryVariables
} from '../../../../data/graphql/query/generated/listCompanies.query';
import { useListCompany } from '../../../../data/hooks/admin-company/use-list-company';
import {
  useCreateAto,
  useDeleteAtoDoc,
  useEditAto,
  useFindAtoApprovers,
  useFindAtoVerifiers
} from '../../../../data/services/ATOService';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import InputText from '../../Molecules/InputText/InputText';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import TableWithFilterAndPaginator from '../TableWithFilterAndPaginator/TableWithFilterAndPaginator';
import { AtoFormProps, Docs } from './AtoForm.interfaces';
import {
  HolderCard,
  HolderFooter,
  HolderFormAto,
  HolderFormAtoLeftContainer,
  HolderFormAtoRightContainer,
  HolderTable
} from './AtoForm.styles';

const formAtoSchema = z.object({
  title: z.string().min(1, 'O campo deve ser preenchido'),
  contract: z.string().min(1, 'O campo deve ser preenchido'),
  startDate: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  endDate: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  responsibleCompany: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  companyClient: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  approvedClient: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable(),
  verifier: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  docs: z.array(z.object({ name: z.string(), id: z.string() })).optional()
});

type formAtoSchemaType = z.infer<typeof formAtoSchema>;

const docSchema = z.object({
  docs: z.string().min(1, { message: 'O campo deve ser preenchido' })
});

type docFormSchemaType = z.infer<typeof docSchema>;

const AtoForm = ({ ato, setShowModal }: AtoFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue
  } = useForm<formAtoSchemaType>({
    resolver: zodResolver(formAtoSchema),
    defaultValues: {
      title: ato?.title || '',
      contract: ato?.contract || '',
      startDate: ato?.startDate ? new Date(ato?.startDate) : new Date(),
      endDate: ato?.finalDate ? new Date(ato?.finalDate) : new Date(),

      responsibleCompany: ato?.responsibleCompany
        ? {
            value: ato?.responsibleCompany.id,
            label: ato?.responsibleCompany.name
          }
        : {
            value: '',
            label: ''
          },
      companyClient: ato?.client
        ? {
            value: ato?.client.id,
            label: ato?.client.name
          }
        : {
            value: '',
            label: ''
          },
      approvedClient: ato?.clientApprover
        ? {
            value: ato?.clientApprover.id,
            label: ato?.clientApprover.name
          }
        : {
            value: '',
            label: ''
          },
      verifier: ato?.verifier
        ? {
            value: ato?.verifier.id,
            label: ato?.verifier.name
          }
        : {
            value: '',
            label: ''
          },
      docs: ato?.docs.map((doc) => ({ name: doc.name, id: doc.id })) || []
    }
  });
  const {
    control: docControl,
    handleSubmit: docHandleSubmit,
    reset: docsReset,
    formState: { errors: docErrors }
  } = useForm<docFormSchemaType>({
    resolver: zodResolver(docSchema)
  });

  const [docInput, setDocInput] = useState<Docs[]>(ato?.docs || []);
  const { t } = useTranslation();
  const [createAto] = useMutation<
    CreateAtoMutation,
    CreateAtoMutationVariables
  >(CreateAtoDocument);
  const [editAto] = useMutation<UpdateAtoMutation, UpdateAtoMutationVariables>(
    UpdateAtoDocument
  );
  const { data: listCompanies } = useQuery<
    ListCompaniesQuery,
    ListCompaniesQueryVariables
  >(ListCompaniesDocument, {
    variables: {
      data: {
        listAllCompanies: true
      }
    }
  });
  // const { data: listCompanies } = useListCompany(true);
  const { structureId } = useParams();
  const [deleteDoc] = useMutation<
    DeleteAtoDocsMutation,
    DeleteAtoDocsMutationVariables
  >(DeleteAtoDocsDocument);
  const [saveDoc] = useMutation<
    SaveAtoDocsMutation,
    SaveAtoDocsMutationVariables
  >(SaveAtoDocsDocument);
  const responsibleCompanyData = useWatch({
    control,
    name: 'responsibleCompany'
  });

  const clientCompanyData = useWatch({
    control,
    name: 'companyClient'
  });
  const { data: listAllVerifiers } = useQuery<
    FindAtoVerifiersQuery,
    FindAtoVerifiersQueryVariables
  >(FindAtoVerifiersDocument, {
    variables: {
      structureId: structureId!,
      responsibleCompanyId: responsibleCompanyData?.value || ''
    }
  });
  const { data: listAllApprovers } = useFindAtoApprovers(
    structureId!,
    clientCompanyData?.value || ''
  );
  const listCompaniesOptions = listCompanies?.listCompanies.map((client) => ({
    value: client.id,
    label: client.name
  }));
  const listApproversOptions = listAllApprovers?.findAtoApprovers.map(
    (client) => ({
      value: client.id,
      label: client.name
    })
  );

  const listVerifiersOptions = listAllVerifiers?.findAtoVerifiers.map(
    (verifier) => ({
      value: verifier.id,
      label: verifier.name
    })
  );

  const handleAddDoc = (data: docFormSchemaType) => {
    const newDoc = { name: data.docs, id: '' };
    const newDocs = [...docInput, newDoc];

    if (!ato?.atoId) {
      setDocInput(newDocs);
      setValue('docs', newDocs);
      docsReset({ docs: '' });
    } else {
      const docsToSave = {
        name: newDoc.name
      };

      saveDoc({
        variables: {
          docs: docsToSave,
          atoId: ato?.atoId || ''
        },
        onCompleted: () => {
          setDocInput(newDocs);
          setValue('docs', newDocs);
          toastfySuccess(t('registeredSuccessfully'));
        },
        onError: (error) => {
          toastfyError(error.message);
        }
      });
    }
  };

  // TODO: adicionar cache
  const handleRemoveDoc = (doc: Docs) => {
    const newDocs = docInput.filter((item) => item.name !== doc.name);
    if (!doc.id) {
      setDocInput(newDocs);
      setValue('docs', newDocs);
    } else {
      deleteDoc({
        variables: {
          deleteAtoDocsId: doc.id
        },
        onCompleted: () => {
          setDocInput(newDocs);
          setValue('docs', newDocs);
          toastfySuccess(t('deletedSuccessfully'));
        },
        onError: (error) => {
          toastfyError(error.message);
        }
      });
    }
  };

  const handleUpdateAto = (data: formAtoSchemaType) => {
    const atoData = {
      id: ato!.atoId,
      title: data.title,
      contract: data.contract,
      finalDate: data.endDate,
      responsibleCompanyId: data.responsibleCompany.value,
      verifierId: data.verifier.value,
      clientApproverId: data.approvedClient?.value || null
    };
    editAto({
      variables: {
        data: atoData
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(t('updatedSuccessfully'));
      },
      refetchQueries: [FindAllAtoDocument],
      onError: (error) => {
        toastfyError(error.message);
      }
    });
  };

  const handleSaveAto = (data: formAtoSchemaType) => {
    const atoData = {
      title: data.title,
      contract: data.contract,
      initialDate: new Date(data.startDate),
      finalDate: new Date(data.endDate),
      responsibleCompanyId: data.responsibleCompany.value,
      verifierId: data.verifier.value,
      clientId: data.companyClient.value,
      clientApproverId: data.approvedClient?.value || null,
      docs: data.docs?.map((doc) => doc.name) || []
    };
    createAto({
      variables: {
        data: atoData,
        structureId: structureId!
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(t('registeredSuccessfully'));
      },
      refetchQueries: [FindAllAtoDocument],
      onError: (error) => {
        toastfyError(error.message);
      }
    });
  };

  return (
    <HolderCard>
      <HolderFormAto>
        <HolderFormAtoLeftContainer>
          <InputText
            width="315px"
            control={control}
            label={t('title')}
            name={'title'}
            type={'text'}
            error={!!errors.title}
            errorMessage={errors?.title?.message}
          />
          <InputText
            width="315px"
            control={control}
            label={t('contractCode')}
            name={'contract'}
            type={'text'}
            error={!!errors.contract}
            errorMessage={errors?.contract?.message}
          />

          <InputSelectSearch
            placeholder={t('selectResponsibleCompany')}
            width="300px"
            control={control}
            label={t('responsibleCompany')}
            options={listCompaniesOptions || []}
            name={'responsibleCompany'}
            error={!!errors.responsibleCompany}
            errorMessage={errors?.responsibleCompany?.message}
          />

          <InputSelectSearch
            placeholder={t('selectVerifier')}
            width="300px"
            control={control}
            label={t('verifier')}
            options={listVerifiersOptions || []}
            name="verifier"
            error={!!errors.verifier}
            errorMessage={errors?.verifier?.message}
          />

          <InputSelectSearch
            placeholder={t('selectCompanyClient')}
            width="300px"
            control={control}
            label={t('clientCompany')}
            options={listCompaniesOptions || []}
            name="companyClient"
            error={!!errors.companyClient}
            errorMessage={errors?.companyClient?.message}
            disabled={!!ato}
          />

          <InputSelectSearch
            placeholder={t('selectApprovingClient')}
            width="300px"
            control={control}
            label={t('approvingClient')}
            options={listApproversOptions || []}
            name="approvedClient"
            error={!!errors.approvedClient}
            errorMessage={errors?.approvedClient?.message}
          />
        </HolderFormAtoLeftContainer>
        <HolderFormAtoRightContainer>
          <DatepickerInput
            label={t('startDate')}
            width="340px"
            control={control}
            name="startDate"
            placeholder={t('startDate')}
            time={false}
            error={!!errors.startDate}
            errorMessage={errors?.startDate?.message}
            disabled={!!ato}
          />
          <DatepickerInput
            label={t('finalDate')}
            width="340px"
            control={control}
            name="endDate"
            placeholder={t('finalDate')}
            time={false}
            error={!!errors.endDate}
            errorMessage={errors?.endDate?.message}
          />
          <InputText
            control={docControl}
            name="docs"
            width="315px"
            label={t('archiveName')}
            type={'text'}
            error={!!docErrors.docs}
            errorMessage={docErrors?.docs?.message}
          />
          <Button
            variant={'secondary'}
            size="medium"
            text={t('adcDocument')}
            onClick={docHandleSubmit(handleAddDoc)}
          />
          {docInput.length > 0 && (
            <HolderTable>
              <TableComponent
                tableData={docInput || []}
                columns={[
                  {
                    key: 'name',
                    label: `${t('archiveName')}`
                  }
                ]}
                actions={[
                  {
                    name: `${t('Delete')}`,
                    icon: <TiDelete />,
                    onClick: (doc) => handleRemoveDoc(doc as any),
                    canShow: 'canDelete'
                  }
                ]}
              />
            </HolderTable>
          )}
        </HolderFormAtoRightContainer>
      </HolderFormAto>
      <HolderFooter>
        <Button
          size="large"
          text={ato ? `${t('editAto')}` : `${t('createAto')}`}
          onClick={
            ato ? handleSubmit(handleUpdateAto) : handleSubmit(handleSaveAto)
          }
          variant={'primary'}
        />
      </HolderFooter>
    </HolderCard>
  );
};

export default AtoForm;
