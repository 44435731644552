import { Dispatch } from 'react';

interface GraphData {
  x: string;
  y: number;
}

interface DataSetType {
  label: string;
  type: string;
  yAxisID: string;
  backgroundColor: 'string';
  borderColor: 'string';
  borderWidth: number;
  data: GraphData[];
}

interface Action {
  type: 'dataSet/addDataSet' | 'dataSet/resetDataSet';
  payload: any;
}

export const initialDataSetState: DataSetType[] = [];

export const selectedDataSetReducer = (
  state = initialDataSetState,
  action: Action
) => {
  switch (action.type) {
    case 'dataSet/addDataSet':
      return [...state, action.payload];
    case 'dataSet/resetDataSet':
      return [];
    default:
      return state;
  }
};

export const AddDataSet = (
  dispatch: Dispatch<Action>,
  newData: DataSetType
) => {
  dispatch({ type: 'dataSet/addDataSet', payload: newData });
};

export const resetDataSet = (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'dataSet/resetDataSet', payload: null });
};
