import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const TextareaContainer = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const DivLabel = styled.div`
  position: relative;
  width: 100%;
  font-size: ${FontSize.Small};
  height: 12px;
  margin-left: 2px;
`;

export const TextareaStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: -13px;
`;

export const ErrorStyled = styled.span`
  height: 12px;
  width: 100%;
  font-size: ${FontSize.Xsmall};
  margin: 0.5rem;
  margin-top: 1rem;
  color: ${Color.Red1};
`;
