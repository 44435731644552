import { useMutation } from '@apollo/client';
import {
  EditPluviometerReadingDocument,
  EditPluviometerReadingMutation,
  EditPluviometerReadingMutationVariables
} from '../../graphql/generated/editPluviometerReading.mutation';
import { ListReadingByPluviometerPagDocument } from '../../graphql/query/generated/listReadingByPluviometerPag.query';

export function useEditPluviometerReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editReading, { data, error, loading }] = useMutation<
    EditPluviometerReadingMutation,
    EditPluviometerReadingMutationVariables
  >(EditPluviometerReadingDocument, {
    refetchQueries: [ListReadingByPluviometerPagDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editReading,
    data,
    error,
    loading
  };
}

// O hook acima edita as infos de leitura de um pluviômetro.
// As variáveis aceitas são:
// date: Scalars['DateTime']['input'];
// id: Scalars['String']['input'];
// observation: Scalars['String']['input'];
// rainfall: Scalars['Float']['input'];
// É possível ver esses valores em EditPluviometerReadingMutationVariables. Todas as variáveis acima são obrigatórias
// Uma única leitura deve ser editada por vez
