import styled from 'styled-components';

export const ViewInstrumetBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ViewInstrumentsModal = styled.div`
  background-color: white;
  width: 90%;
  height: 90%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 5px;
  overflow: hidden; /* Ensure content doesn't overflow the border */
`;

export const HolderHeaderModal = styled.div`
  display: flex;
  width: 100%;
  background-color: green;
  align-items: center;
  justify-content: space-between;
`;

export const TextHeaderModal = styled.div`
  font-size: 22px;
  font-weight: 700;
`;

export const HolderViewInstruments = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
`;

export const HolderOverflowTable = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  max-height: 100%;
  &::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
  }
  &::-webkit-scrollbar-track {
    background: rgba(
      0,
      0,
      0,
      0.1
    ); /* Cor de fundo da trilha da barra de rolagem */
    border-radius: 10px; /* Arredondamento da trilha */
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(150, 116, 66, 1); /* Cor da barra de rolagem */
    border-radius: 10px; /* Arredondamento da barra de rolagem */
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(
      150,
      116,
      66,
      0.8
    ); /* Cor da barra de rolagem ao passar o mouse */
  }
`;

export const DivButton = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: end;
  align-items: start;
  gap: 25px;
  padding: 15px;
`;

export const DivButtonTable = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  overflow: auto;
`;

export const DivAsideMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DivTable = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
