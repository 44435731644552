import { DocumentNode } from 'graphql';
import { DeleteCompanyDocument } from '../../data/graphql/generated/deleteCompany.mutation';
import { DeleteSectionDocument } from '../../data/graphql/generated/deleteSection.mutation';
import { DeleteStructureDocument } from '../../data/graphql/generated/deleteStructure.mutation';
import { DeleteStructureModuleDocument } from '../../data/graphql/generated/deleteStructureModule.delete';
import { DeleteUserDocument } from '../../data/graphql/generated/deleteUser.mutation';
import { DeleteUserPermissionDocument } from '../../data/graphql/generated/deleteUserPermission.mutation';
import { ListCompaniesDocument } from '../../data/graphql/query/generated/listCompanies.query';
import { ListSectionDocument } from '../../data/graphql/query/generated/listSection.query';
import { ListStructureAndModulesDocument } from '../../data/graphql/query/generated/listStructureAndModules.query';
import { ListStructuresDocument } from '../../data/graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../data/graphql/query/generated/listUsers.query';
import { ListUsersPermissionsDocument } from '../../data/graphql/query/generated/listUsersPermissions.query';
import { useDeleteStructure } from '../../data/hooks/admin-structure-structures/use-deletestructures.mutation';
import { useGenericMutation } from '../../data/hooks/use-delete.mutation';

interface DeleteMutationMap {
  [key: string]: DocumentNode;
}

const deleteMutationMap: DeleteMutationMap = {
  Clients: DeleteCompanyDocument,
  Users: DeleteUserDocument,
  Permission: DeleteUserPermissionDocument,
  Modules: DeleteStructureModuleDocument,
  Section: DeleteSectionDocument,
  Structures: DeleteStructureDocument
};

const refetchQueryMap: DeleteMutationMap = {
  Clients: ListCompaniesDocument,
  Users: ListUsersDocument,
  Permission: ListUsersPermissionsDocument,
  Modules: ListStructureAndModulesDocument,
  Section: ListSectionDocument,
  Structures: ListStructuresDocument
};

export function useAdminDeleteEntity(type: string) {
  const {
    mutate: deleteEntity,
    data,
    error,
    loading
  } = useGenericMutation<any, any>(deleteMutationMap[type], {
    refetchQueries: [refetchQueryMap[type]]
  });
  const { deleteStructure } = useDeleteStructure();

  const deleteEntityById = async (id: string) => {
    if (type === 'Modules') {
      const createResponse = await deleteEntity({
        variables: {
          deleteStructureModuleId: id
        }
      });

      return createResponse;
    }
    if (type === 'Section') {
      const createResponse = await deleteEntity({
        variables: {
          sectionId: id
        }
      });

      return createResponse;
    }

    if (type === 'Structures') {
      const createResponse = await deleteStructure({
        variables: {
          structureId: id
        }
      });
      return createResponse;
    }

    const createResponse = await deleteEntity({
      variables: {
        data: {
          id: id
        }
      }
    });

    return createResponse;
  };

  return {
    deleteEntityById,
    data,
    error,
    loading
  };
}
