import React, { Dispatch } from 'react';
import { InstrumentItemType } from '../@Types/Instruments/instruments';
import { AsideInstrumentsMenuProps } from '../@Types/Menus/MenusType';

interface showMenuInterface {
  e: React.MouseEvent<HTMLLIElement> | any;
  show?: boolean;
  setShow?: Dispatch<boolean>;
}

interface checkItemInterface {
  e: React.MouseEvent<HTMLLIElement> | any;
  i: number;
  element: InstrumentItemType;
  state: any;
  setState: Dispatch<any>;
  selectedGraphs: any;
  setSelectedGraphs: Dispatch<any>;
}

export default class AsideMenu {
  static showMenu({ e, show, setShow }: showMenuInterface) {
    e.stopPropagation();
    if (setShow) {
      setShow(!show);
    }
  }

  static checkItem({
    e,
    i,
    element,
    state,
    setState,
    selectedGraphs,
    setSelectedGraphs
  }: checkItemInterface) {
    e.stopPropagation();
    const newState = [...state];
    newState[i].isChecked = !element.isChecked;
    setState(newState);

    if (newState[i].isChecked === true) {
      setSelectedGraphs([...selectedGraphs, newState[i]]);
    } else {
      setSelectedGraphs(
        selectedGraphs.filter((item: any) => item !== newState[i])
      );
    }
  }

  static SelectItem({
    selectedItem,
    setSelectedItem
  }: AsideInstrumentsMenuProps) {
    setSelectedItem(selectedItem);
  }
}
