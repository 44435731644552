import { useMutation } from '@apollo/client';
import {
  AddPiezometerInaAlertLevelsDocument,
  AddPiezometerInaAlertLevelsMutation,
  AddPiezometerInaAlertLevelsMutationVariables
} from '../../graphql/generated/addPiezometerInaAlertLevels.mutation';
import { ListPiezometersByStructureDocument } from '../../graphql/query/generated/listPiezometersByStructure.query';

export function useAddPiezometerControlLevels(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addPiezometerControlLevels, { data, error, loading }] = useMutation<
    AddPiezometerInaAlertLevelsMutation,
    AddPiezometerInaAlertLevelsMutationVariables
  >(AddPiezometerInaAlertLevelsDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListPiezometersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addPiezometerControlLevels,
    data,
    error,
    loading
  };
}
