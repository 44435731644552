import { TemplateAtoAdminProps } from './TemplateAtoAdmin.interfaces';
import {
  DivContainerPage,
  DivHeaderContainer,
  DivPageContent
} from './TemplateAtoAdminStyles';

const TemplateAtoAdmin = ({
  button,
  content,
  title
}: TemplateAtoAdminProps) => {
  return (
    <DivContainerPage>
      <DivHeaderContainer>
        {title}
        {button}
      </DivHeaderContainer>
      <DivPageContent>{content}</DivPageContent>
    </DivContainerPage>
  );
};

export default TemplateAtoAdmin;
