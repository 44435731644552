import { Dispatch, SetStateAction } from 'react';
import FormInspectionsTwoOptions from '../../../Components/Forms/FormInspectionsTwoOptions';
import { ContainerSixthStep } from '../InspectionsStyle';

export function SixthStepInspection({
  stepSixStates,
  setStepSixStates,
  error,
  inspectionId
}: {
  stepSixStates: any;
  setStepSixStates: Dispatch<SetStateAction<any>>;
  error: boolean;
  inspectionId: string;
}) {
  return (
    <ContainerSixthStep>
      <FormInspectionsTwoOptions
        setStepStates={setStepSixStates}
        stepStates={stepSixStates}
        topic={'Alguma irregularidade no bombeamento'}
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        setStepStates={setStepSixStates}
        stepStates={stepSixStates}
        topic={'Alguma irregularidade nas tubulações'}
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        setStepStates={setStepSixStates}
        stepStates={stepSixStates}
        topic={'Outros (identificar)'}
        error={error}
        inspectionId={inspectionId}
      />
    </ContainerSixthStep>
  );
}
