import styled from 'styled-components';
import { Color } from '../../../Styles/Styles';
import { Tooltip } from '../../Instruments/Readings/DataBaseStyles';

export const TableDivStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableComponentStyle = styled.table`
  width: 100%;
  height: 100%;
  text-align: center;
  border-collapse: collapse;
  position: relative;
  thead {
    tr {
      padding: 10px 15px;
      background-color: ${Color.Brown3};
      border-bottom: 1px solid ${Color.Brown1};
      color: ${Color.Brown1};
      th {
        padding: 2px 12px;
        font-size: 12px;
      }
    }
  }
  tbody {
    td {
      padding: 10px 10px;
      min-width: 100px;
      font-size: 12px;
      color: ${Color.Disabled};
    }
  }
`;

export const CellWithTooltip = styled.td`
  position: relative;
  &:hover ${Tooltip} {
    visibility: visible;
  }
`;

export const ActionsRow = styled.span`
  display: flex;
  color: ${Color.Brown1};
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
`;
export const ViewInfosValue = styled.span``;
