import { useLazyQuery, useQuery } from '@apollo/client';
import {
  PageInput,
  PiezometerInaFilterInput,
  SortInput,
  StructureInfoInput
} from '../../graphql/base-schema';
import {
  ListReadingByPiezometersPagDocument,
  ListReadingByPiezometersPagQuery,
  ListReadingByPiezometersPagQueryVariables
} from '../../graphql/query/generated/listReadingByPiezometerPag.query';

export interface ListPiezometerReadingPagVariablesModel {
  structureInfo: StructureInfoInput;
  filters: PiezometerInaFilterInput;
  pageInfo?: PageInput;
  sortInfo?: SortInput[];
}

export function useListReadingByPiezometerPag(
  input: ListPiezometerReadingPagVariablesModel,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { structureInfo, filters, pageInfo, sortInfo } = input;
  const { data, loading, error } = useQuery<
    ListReadingByPiezometersPagQuery,
    ListReadingByPiezometersPagQueryVariables
  >(ListReadingByPiezometersPagDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError,
    variables: {
      structureInfo,
      sortInfo,
      pageInfo
    }
  });

  return {
    data,
    loading,
    error
  };
}

export function useListReadingByPiezometerPagLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listReadingPiezometer, { data, loading, error }] = useLazyQuery<
    ListReadingByPiezometersPagQuery,
    ListReadingByPiezometersPagQueryVariables
  >(ListReadingByPiezometersPagDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listReadingPiezometer,
    data,
    loading,
    error
  };
}

//Esse hook acima lista as leituras do piezometro de modo paginado. Os dados listados são:
// activationDate
// deactivationDate
// name
// readingType
// installationDate
// installLocation
// operationalStatus
// status
// coordinateE
// coordinateN
// instrumentId
// bottomCote
// topCote
// readingId
// date
// cote
// reading
// isDry
//acredito que activationDate e deactivationDate não precisam ser listadas na tabela
