import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FindActionPlanAllInfoDocument,
  FindActionPlanAllInfoQuery,
  FindActionPlanAllInfoVariables
} from '../../../../data/services/ActionPlanService';
import { Color } from '../../../../Styles/Styles';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { OptionType } from '../../Molecules/Paginator/Paginator.interfaces';
import SquareCard from '../../Molecules/SquareCard/SquareCard';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import CreateActionPlan from '../../Organisms/CreateActionPlan/CreateActionPlan';
import CardTableTemplate from '../../Templates/CardTableTemplate/CardTableTemplate';
import {
  DivContainerSquareCardList,
  DivContainerTable,
  HolderCards
} from './ActionPlan.styled';

const ActionPlan = () => {
  const { t: translate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { structureId } = useParams();
  const [pageForPaginator, setPageForPaginator] = useState(1);
  const [limit, setLimit] = useState<OptionType>({ value: 10, label: '10' });
  const [offset, setOffset] = useState(0);
  const columns = [
    { key: 'name', label: translate('name') },
    { key: 'responsible', label: translate('responsible') },
    { key: 'status', label: translate('status') },
    { key: 'timeFrame', label: translate('timeFrame') },
    { key: 'priority', label: translate('priorities') }
  ];
  const { data, loading } = useQuery<
    FindActionPlanAllInfoQuery,
    FindActionPlanAllInfoVariables
  >(FindActionPlanAllInfoDocument, {
    variables: {
      pageInfo: {
        limit: limit.value as number,
        offset: offset,
        page: 0
      },
      structureId: structureId ?? '',
      filters: {
        code: null,
        priority: null,
        responsibleName: null,
        situation: null,
        timeFramePeriod: null,
        type: null
      },
      sortInfo: [
        {
          direction: null,
          field: ''
        }
      ]
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });
  const totalPages = data?.findActionPlanPag.pageInfo.totalPages || 0;

  const handleNavigate = (row: any) => {
    navigate(`/${structureId}/actionplan/${row.id}`);
  };

  const handleNextPage = async () => {
    if (pageForPaginator === totalPages) {
      setOffset(0);
      setPageForPaginator(1);
    } else {
      setOffset((prev) => prev + (limit.value as number));
      setPageForPaginator((prev) => prev + 1);
    }
  };

  const handlePreviousPage = async () => {
    if (pageForPaginator !== 1) {
      setOffset((prev) => prev - (limit.value as number));
      setPageForPaginator((prev) => prev - 1);
    }
  };

  const tableData = data?.findActionPlanPag.nodes.map((item) => ({
    name: translate(item.name),
    responsible: translate(item.responsible),
    status: translate(item.status.toLocaleLowerCase()),
    timeFrame: new Date(item.timeFrame).toLocaleDateString('pt-BR') || '',
    priority: translate(item.priority),
    id: item.id,
    color:
      item.status === 'Concluded'
        ? Color.Green1
        : item.status === 'Late'
          ? Color.Red1
          : item.status === 'Opened'
            ? Color.Golden2
            : ''
  }));

  return (
    <>
      {loading ? (
        <CardTableTemplate
          title={<Skeleton height={35} width={400} />}
          button={<Skeleton height={40} width={100} />}
          content={<Skeleton height={500} width={1000} />}
          cards={
            <HolderCards>
              <Skeleton height={100} width={110} />
              <Skeleton height={100} width={110} />
              <Skeleton height={100} width={110} />
              <Skeleton height={100} width={110} />
              <Skeleton height={100} width={110} />
              <Skeleton height={100} width={110} />
            </HolderCards>
          }
        />
      ) : (
        <CardTableTemplate
          title={<Text type="h1">{translate('Action plan')}</Text>}
          button={
            <Button
              onClick={() => setIsModalOpen(true)}
              text={`${translate('create')} ${translate('Action plan')}`}
              size="large"
              variant="primary"
            />
          }
          cards={
            <DivContainerSquareCardList>
              <SquareCard
                label={translate('total')}
                value={data?.findCalculatedInfos.totalPlans.toString() ?? ''}
              />
              <SquareCard
                label={translate('completed')}
                value={
                  data?.findCalculatedInfos.totalPlansDone.toString() ?? ''
                }
              />
              <SquareCard
                label={translate('highPriority')}
                value={
                  data?.findCalculatedInfos.totalPlansHighPriority.toString() ??
                  ''
                }
              />
              <SquareCard
                label={translate('inProgress')}
                value={
                  data?.findCalculatedInfos.totalPlansInProgress.toString() ??
                  ''
                }
              />
              <SquareCard
                label={translate('overdue')}
                value={
                  data?.findCalculatedInfos.totalPlansLate.toString() ?? ''
                }
              />
              <SquareCard
                label={translate('opened')}
                value={
                  data?.findCalculatedInfos.totalPlansOpened.toString() ?? ''
                }
              />
            </DivContainerSquareCardList>
          }
          content={
            <DivContainerTable>
              <TableComponent
                columns={columns}
                exportTable={() => {}}
                filterComponent={<div />}
                onRow={handleNavigate}
                onSearch={() => {}}
                tableData={tableData ?? []}
                totalPages={totalPages}
                currentPage={pageForPaginator}
                nextPage={handleNextPage}
                previousPage={handlePreviousPage}
                countForPage={limit}
                setCountForPage={setLimit}
              />
            </DivContainerTable>
          }
        />
      )}
      {isModalOpen && (
        <ViewModal
          title={`${translate('create')} ${translate('Action plan')}`}
          onClose={() => setIsModalOpen(false)}
          width={50}
          height={85}
          component={<CreateActionPlan onClose={() => setIsModalOpen(false)} />}
        />
      )}
    </>
  );
};

export default ActionPlan;
