/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { TiPlus } from 'react-icons/ti';
import 'react-toggle/style.css';
import { useEditPluviometer } from '../../../data/hooks/pluviometer/use-edit-pluviometer.mutation';
import { useEditSurfaceMarker } from '../../../data/hooks/surface-marker/use-edit-surface-marker.mutation';
import { useEditWaterLevel } from '../../../data/hooks/water-level/use-edit-water-level.mutation';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import { t } from 'i18next';
import {
  CustomRegisterInputArea,
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../Register/RegisterInstrumentStyle';
import GraphHeader from '../../Graphs/Monitoring/Instrument/GraphHeader';
import { InstrumentsType } from '../../../Models/InstrumentsModel';
import { DefaultInput } from '../../Inputs/InputsStyle';
import {
  EditBasicInstrumentInfo,
  EditInstrumentsModalArea,
  GeralInfo,
  EspecificOption,
  EspecificSelect,
  HolderEspecificInfo,
  HolderInfosEditInstrument,
  TitleBasicInfo,
  EditEspecifcInstrumentInfo,
  CardEditAlertLevel,
  InputEditEspecificInfo,
  HolderCardAlertTitle,
  ButtonsaveEspecificInfo,
  HolderButtonSaveEspecificInfo
} from './styles';
import { DatePickerComponent } from '../../DatePicker';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum
} from '../../../data/graphql/base-schema';
import { useEditSurfaceMarkerReferences } from '../../../data/hooks/surface-marker/edit-surface-marker-instrument-references';
import { useEditSurfaceMarkerAlertLevels } from '../../../data/hooks/surface-marker/edit-surface-marker-instrument-alert-levels';
import { useEditPiezometer } from '../../../data/hooks/piezometer/use-edit-piezometer.mutation';
import { useEditPluviometerAndWaterLevelAlert } from '../../../data/hooks/pluviometer/use-edit-pluviometer-wl-alert';
import { ProjectContext } from '../../../Context/ContextAPI';
import { ModuleData } from '../../../@Types/types';
import { ViewInfosValue } from '../../Tables/TableInstrumentComponent/styles';
import { useEditPiezometerAlert } from '../../../data/hooks/piezometer/use-edit-piezometer-alert';
import { useEditPiezometerControlCote } from '../../../data/hooks/piezometer/use-edit-piezometer-control-cote';
import Toggle from 'react-toggle';
import ModalStep from '../../V2/Molecules/ModalStep/ModalStep';
import { RegisterInstrumentInfos } from '../Register/RegisterInstrumentInfos';
import { useReducerHook } from '../../../Hooks/ReducerHook';
import { useAddPluviometerControlLevels } from '../../../data/hooks/pluviometer/add-pluviometer-controlLevel';
import { useAddWaterLevelControlLevels } from '../../../data/hooks/water-level/add-waterLevel-controlLevel';
import { useAddSurfaceMarkerControlLevels } from '../../../data/hooks/surface-marker/add-surface-marker-controlLevel';
import { useAddPiezometerControlLevels } from '../../../data/hooks/piezometer/add-piezometer-controlLevel';
import { DefaultButton } from '../../Buttons/DefaultButton';
import { FaTrashCan } from 'react-icons/fa6';
import { useDeleteInstrumenAlertLevel } from '../../../data/hooks/use-delete-instrument-alert-level';
import { RegisterReadingsArea } from '../Readings/DataBaseStyles';
import { ButtonAreaAdmin } from '../../../Screens/AdminScreen/AdminScreenStyle';
import { IoSaveSharp } from 'react-icons/io5';
import {
  OptionLevel,
  SelectLevel
} from '../Register/Piezometer/addLevelStyles';
import PiezometersModel from '../../../Models/PiezometerModel';
import { useParams } from 'react-router-dom';
interface IFsData {
  value1: string;
  signal1: string;
  signal2: string;
  value2: string;
}
export function EditInstrumentsModal({
  edit,
  selectedType,
  showModal,
  setShowModal,
  setDataChanged
}: {
  edit: { status: boolean; data: any };
  selectedType: string;
  createdReading: boolean;
  setCreatedReading: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setDataChanged: Dispatch<SetStateAction<boolean>>;
}) {
  const { structureId } = useParams();
  const [fsDataArray, setFsDataArray] = useState<IFsData[]>(
    edit?.data?.alertLevels?.map(() => ({
      value1: '',
      signal1: '',
      signal2: '',
      value2: ''
    })) || []
  );

  const handleOnChange = (index: number, field: string, value: any) => {
    const updatedFsData = [...fsDataArray];
    updatedFsData[index] = {
      ...updatedFsData[index],
      [field]: value
    };
    setFsDataArray(updatedFsData);
  };

  const { editPluviometer: editPluviometer } = useEditPluviometer();
  const { editWaterLevel } = useEditWaterLevel();
  const { editSurfaceMarker } = useEditSurfaceMarker();
  const { editPiezometer } = useEditPiezometer();
  const { editSurfaceMarkerReferences } = useEditSurfaceMarkerReferences();
  const { editSurfaceMarkerAlertLevels } = useEditSurfaceMarkerAlertLevels();
  const { editPluviometerAndWaterLevelAlert } =
    useEditPluviometerAndWaterLevelAlert();
  const { editPiezometerAlert } = useEditPiezometerAlert();
  const { editPiezometerControlCote } = useEditPiezometerControlCote();
  const { addPiezometerControlLevels } = useAddPiezometerControlLevels();
  const { addWaterLevelControlLevels } = useAddWaterLevelControlLevels();
  const { addPluviometerControlLevels } = useAddPluviometerControlLevels();
  const { addSurfaceMarkerControlLevels } = useAddSurfaceMarkerControlLevels();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { deleteInstrumentAlertLevel } = useDeleteInstrumenAlertLevel();
  const [showAddModal, setShowAddModel] = useState<boolean>(false);
  const {
    state: instrumentEspecificData,
    updateValue: setInstrumentEspecificData
  }: {
    state: any;
    updateValue: any;
  } = useReducerHook({});
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };

  const [reference, setReference] = useState(
    edit?.data?.surfaceMarker?.references[0] || {}
  );

  const [addedAlertLevel, setAddedAlertLevel] = useState(null);

  const [alertLevels, setAlertLevels] = useState(
    edit?.data?.surfaceMarker?.alertLevels || []
  );

  const [alertLevelsPluviometer, setAlertLevelsPluviometer] = useState(
    edit?.data?.alertLevels || []
  );

  const [alertLevelsWaterLevel, setAlertLevelsWaterLevel] = useState(
    edit?.data?.alertLevels || []
  );

  const [alertLevelsPiezometer, setAlertLevelsPiezometer] = useState(
    edit?.data?.alertLevels || []
  );

  const [controlCotesPiezometer, setControlCotesPiezometer] = useState(
    edit?.data?.controlCotes || []
  );
  const [specificInfosError, setSpecificInfosError] = useState<boolean>(false);
  const [newActivationDate, setNewActivationDate] = useState<Date>(new Date());
  const [newCoordE, setNewCoordE] = useState<string>('');
  const [newCoordN, setNewCoordN] = useState<string>('');
  const [newDeactivationDate, setNewDeactivationDate] = useState<Date>(
    new Date()
  );
  const [newInstallLocation, setNewInstallLocation] = useState<string>('');
  const [newInstallationDate, setNewInstallationDate] = useState<Date>(
    new Date()
  );
  const [newName, setNewName] = useState<string>('');
  const [newOperationalStatus, setNewOperationalStatus] = useState<string>('');
  const [newIsUTM, setNewIsUtm] = useState<boolean>(edit.data.isUTM);
  const [newReadingType, setNewReadingType] =
    useState<InstrumentReadingTypeEnum>(InstrumentReadingTypeEnum.Automatic);
  const [newPluviometerType, setNewPluviometerType] = useState<string>('');

  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('Monitoring');

  const [selectedAlert, setSelectedAlert] = useState({});
  async function handleFetchPiezometers() {
    setNewActivationDate(new Date(edit.data.activationDate));
    setNewCoordE(edit.data.coordinateE);
    setNewCoordN(edit.data.coordinateN);
    setNewDeactivationDate(new Date(edit.data.deactivationDate));
    setNewInstallLocation(edit.data.installLocation);
    setNewInstallationDate(new Date(edit.data.installationDate));
    setNewName(edit.data.name);
    setNewOperationalStatus(edit.data.operationalStatus);
    setNewIsUtm(edit.data.isUTM);
    setNewReadingType(edit.data.readingType);
  }

  async function handleFetchPluviometers() {
    setNewActivationDate(new Date(edit.data.activationDate));
    setNewCoordE(edit.data.coordinateE);
    setNewCoordN(edit.data.coordinateN);
    setNewDeactivationDate(new Date(edit.data.deactivationDate));
    setNewInstallLocation(edit.data.installLocation);
    setNewInstallationDate(new Date(edit.data.installationDate));
    setNewName(edit.data.name);
    setNewOperationalStatus(edit.data.operationalStatus);
    setNewReadingType(edit.data.readingType);
    setNewIsUtm(edit.data.isUTM);
    setNewPluviometerType(edit.data.pluviometerType);
  }

  async function handleFetchWaterLevel() {
    setNewActivationDate(new Date(edit.data.activationDate));
    setNewCoordE(edit.data.coordinateE);
    setNewCoordN(edit.data.coordinateN);
    setNewDeactivationDate(new Date(edit.data.deactivationDate));
    setNewInstallLocation(edit.data.installLocation);
    setNewInstallationDate(new Date(edit.data.installationDate));
    setNewName(edit.data.name);
    setNewOperationalStatus(edit.data.operationalStatus);
    setNewIsUtm(edit.data.isUTM);
    setNewReadingType(edit.data.readingType);
  }

  async function handleFetchSurfaceMarkers() {
    setNewActivationDate(new Date(edit.data.activationDate));
    setNewCoordE(edit.data.coordinateE);
    setNewCoordN(edit.data.coordinateN);
    setNewDeactivationDate(new Date(edit.data.deactivationDate));
    setNewInstallLocation(edit.data.installLocation);
    setNewInstallationDate(new Date(edit.data.installationDate));
    setNewName(edit.data.name);
    setNewOperationalStatus(edit.data.operationalStatus);
    setNewIsUtm(edit.data.isUTM);
    setNewReadingType(edit.data.readingType);
  }

  const handleEditPiezometer = async () => {
    if (newName !== '') {
      const response = await editPiezometer({
        variables: {
          structureInfo: structureInfo,
          data: {
            generalInfo: {
              // TODO:Get the real section array
              sectionsId: [],
              instrumentId: edit.data.id,
              operationalStatus: newOperationalStatus as InstrumentStatusEnum,
              readingType: newReadingType,
              activationDate: newActivationDate,
              deactivationDate: newDeactivationDate,
              installationDate: newInstallationDate,
              installLocation: newInstallLocation,
              name: newName,
              coordinateE: Number(newCoordE),
              coordinateN: Number(newCoordN),
              isUTM: newIsUTM
            }
          }
        }
      });
      toastifyLoading(t('updatingInstrument'));
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('successEditInstrument'));
        setShowModal(false);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  const handleEditPluviometer = async () => {
    if (newName !== '') {
      const response = await editPluviometer({
        variables: {
          structureInfo: structureInfo,
          data: {
            generalInfo: {
              instrumentId: edit.data.id,
              operationalStatus: newOperationalStatus as InstrumentStatusEnum,
              readingType: newReadingType,
              activationDate: newActivationDate,
              deactivationDate: newDeactivationDate,
              installationDate: newInstallationDate,
              installLocation: newInstallLocation,
              name: newName,
              coordinateE: Number(newCoordE),
              coordinateN: Number(newCoordN),
              isUTM: newIsUTM
            },
            specificInfo: {
              pluviometerType: newPluviometerType
            }
          }
        }
      });
      toastifyLoading(t('updatingInstrument'));
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('successEditInstrument'));
        setShowModal(false);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  const handleEditWaterLevel = async () => {
    if (newName !== '') {
      const response = await editWaterLevel({
        variables: {
          structureInfo: structureInfo,
          data: {
            generalInfo: {
              instrumentId: edit.data.id,
              operationalStatus: newOperationalStatus as InstrumentStatusEnum,
              readingType: newReadingType,
              activationDate: newActivationDate,
              deactivationDate: newDeactivationDate,
              installationDate: newInstallationDate,
              installLocation: newInstallLocation,
              name: newName,
              coordinateE: Number(newCoordE),
              coordinateN: Number(newCoordN),
              isUTM: newIsUTM
            }
          }
        }
      });
      toastifyLoading(t('updatingInstrument'));
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('successEditInstrument'));
        setShowModal(false);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  const handleEditSurfaceMarker = async () => {
    if (newName !== '') {
      const response = await editSurfaceMarker({
        variables: {
          structureInfo: structureInfo,
          data: {
            generalInfo: {
              // TODO:Get the real section array
              sectionsId: [],
              instrumentId: edit.data.id,
              operationalStatus: newOperationalStatus as InstrumentStatusEnum,
              readingType: newReadingType,
              activationDate: newActivationDate,
              deactivationDate: newDeactivationDate,
              installationDate: newInstallationDate,
              installLocation: newInstallLocation,
              name: newName,
              coordinateE: Number(newCoordE),
              coordinateN: Number(newCoordN),
              isUTM: newIsUTM
            }
          }
        }
      });
      toastifyLoading(t('updatingInstrument'));
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('successEditInstrument'));
        setShowModal(false);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  const handleUpdateReferences = async () => {
    const { id, __typename, ...cleanReference } = reference;

    const response = await editSurfaceMarkerReferences({
      variables: {
        structureInfo: structureInfo,
        data: {
          referenceId: edit.data.surfaceMarker.references[0].id,
          instrumentId: edit.data.id,
          ...cleanReference
        }
      }
    });

    if (response.data) {
      toastfySuccess(t('referenceUpdates'));
    } else {
      toastfyError(t('referenceUpdateError'));
    }
  };

  const handleUpdateAlertLevels = async () => {
    for (const alertLevel of alertLevels) {
      const { id: alertId, __typename, ...cleanAlertLevel } = alertLevel;
      const response = await editSurfaceMarkerAlertLevels({
        variables: {
          structureInfo: structureInfo,
          data: {
            alertId: alertId,
            instrumentId: edit.data.id,
            ...cleanAlertLevel
          }
        }
      });

      if (!response.data) {
        toastfyError(t('alertLevelsUpdateError'));
        return;
      }
    }

    toastfySuccess(t('updateAlertLevels'));
  };

  const handleUpdateAlertLevelsPluviometer = async () => {
    for (const alertLevel of alertLevelsPluviometer) {
      const {
        id: alertId,
        __typename,
        status,
        ...cleanAlertLevel
      } = alertLevel;

      const response = await editPluviometerAndWaterLevelAlert({
        variables: {
          structureId: structureInfo.structureId,
          data: {
            alertId: alertId,
            instrumentId: edit.data.id,
            ...cleanAlertLevel
          }
        }
      });
      if (!response.data) {
        toastfyError(t('alertLevelsUpdateError'));
        return;
      }
    }

    toastfySuccess(t('updateAlertLevels'));
  };

  const handleUpdateAlertLevelsWaterLevel = async () => {
    for (const alertLevel of alertLevelsWaterLevel) {
      const {
        id: alertId,
        __typename,
        status,
        ...cleanAlertLevel
      } = alertLevel;

      const response = await editPluviometerAndWaterLevelAlert({
        variables: {
          structureId: structureInfo.structureId,
          data: {
            alertId: alertId,
            instrumentId: edit.data.id,
            ...cleanAlertLevel
          }
        }
      });
      if (!response.data) {
        toastfyError(t('alertLevelsUpdateError'));
        return;
      }
    }

    toastfySuccess(t('updateAlertLevels'));
  };

  const handleUpdateAlertLevelsPiezometer = async () => {
    for (const [index, alertLevelsPz] of alertLevelsPiezometer.entries()) {
      const hasValidData =
        fsDataArray[index].value1 &&
        fsDataArray[index].signal1 &&
        fsDataArray[index].value2 &&
        fsDataArray[index].signal2;

      if (!hasValidData) {
        continue;
      }

      if (
        (fsDataArray[index].value1 && !fsDataArray[index].signal1) ||
        (fsDataArray[index].value2 && !fsDataArray[index].signal2)
      ) {
        toastfyError(t('alertLevelsPiezometerCondition'));
        return;
      }

      if (
        (!fsDataArray[index].value1 && fsDataArray[index].signal1) ||
        (!fsDataArray[index].value2 && fsDataArray[index].signal2)
      ) {
        toastfyError(t('alertLevelsPiezometerParameter'));
        return;
      }

      const { id: alertId, __typename, ...cleanAlertLevel } = alertLevelsPz;
      const signal1 =
        fsDataArray[index].signal1 === '' ? '' : fsDataArray[index].signal1;
      const signal2 =
        fsDataArray[index].signal2 === '' ? '' : fsDataArray[index].signal2;
      const levelValue = `${fsDataArray[index].value1} ${signal1} FS ${signal2}
         ${fsDataArray[index].value2}`
        .replace(/\s+/g, ' ')
        .trim();

      let updateData: any = {
        alertId: alertId,
        instrumentId: edit.data.id,
        ...cleanAlertLevel
      };

      if (alertLevelsPz.drained !== undefined) {
        updateData = {
          ...updateData,
          drained: levelValue,
          undrained: null
        };
      } else if (alertLevelsPz.undrained !== undefined) {
        updateData = {
          ...updateData,
          undrained: levelValue,
          drained: null
        };
      }

      const response = await editPiezometerAlert({
        variables: {
          structureInfo: structureInfo,
          data: updateData
        }
      });

      if (!response.data) {
        toastfyError(t('alertLevelsUpdateError'));
        return;
      }
    }

    toastfySuccess(t('updateAlertLevels'));
  };

  const handleUpdateControlCotePiezometer = async () => {
    for (const controlCote of controlCotesPiezometer) {
      const { id: alertId, __typename, ...cleanAlertLevel } = controlCote;
      const response = await editPiezometerControlCote({
        variables: {
          structureInfo: structureInfo,
          data: {
            controlId: alertId,
            instrumentId: edit.data.id,
            ...cleanAlertLevel
          }
        }
      });
      if (!response.data) {
        toastfyError(t('controlCoteUpdateError'));
        return;
      }
    }
    toastfySuccess(t('editedSuccessfully'));
  };

  const handleSubmitNewControlLevel = async () => {
    const alertLevels = instrumentEspecificData?.alertLevels;
    const alertLevelsSurface = instrumentEspecificData?.alertLevelsSurface;
    const alertLevelsPv = instrumentEspecificData?.alertLevelsPv;
    const specificInfos = instrumentEspecificData?.specificInfos;
    switch (edit.data.type.instrumentType) {
      case 'Piezometer':
        try {
          const alertLevelsWithInstrumentId = alertLevels.map((level: any) => ({
            ...level,
            instrumentId: edit.data.id
          }));
          const response = await addPiezometerControlLevels({
            variables: {
              structureId: structureId!,
              data: alertLevelsWithInstrumentId
            }
          });
          if (response.data) {
            setAddedAlertLevel(alertLevelsWithInstrumentId);
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successCreateControlLevel)
            );
            return true;
          }
          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          setSpecificInfosError(true);
          return false;
        }

      case 'Ina':
        try {
          if (!specificInfos.topCote || !specificInfos.bottomCote) {
            toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
            setSpecificInfosError(true);
            return false;
          }
          // Add mutation function
          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          setSpecificInfosError(true);
          return false;
        }

      case 'Pluviometer':
        if (!alertLevelsPv) {
          toastfyError(t('addLeastOneAlertLevel'));
          return false;
        }

        try {
          const alertLevelsWithInstrumentId = alertLevelsPv.map(
            (level: any) => ({
              ...level,
              instrumentId: edit.data.id
            })
          );
          const response = await addPluviometerControlLevels({
            variables: {
              structureId: structureInfo.structureId,
              data: alertLevelsWithInstrumentId
            }
          });
          if (response.data) {
            setAddedAlertLevel(alertLevelsWithInstrumentId);
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successCreateControlLevel)
            );
            return true;
          }
          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          return false;
        }

      case 'WaterLevel':
        if (!alertLevels) {
          toastfyError(t('addLeastOneAlertLevel'));
          return false;
        }

        try {
          const alertLevelsWithInstrumentId = alertLevels.map((level: any) => ({
            ...level,
            instrumentId: edit.data.id
          }));

          const response = await addWaterLevelControlLevels({
            variables: {
              structureId: structureInfo.structureId,
              data: alertLevelsWithInstrumentId
            }
          });
          if (response.data) {
            setAddedAlertLevel(alertLevelsWithInstrumentId);
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successCreateControlLevel)
            );
            return true;
          }
          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          return false;
        }

      case 'SurfaceMarker':
        if (!alertLevelsSurface) {
          toastfyError(t('addLeastOneAlertLevel'));
          setSpecificInfosError(true);
          return false;
        }

        const formattedAlertLevels = alertLevelsSurface.map((alert: any) => ({
          elevation: alert.levelElevation,
          coordinateE: alert.levelCoordinateE,
          coordinateN: alert.levelCoordinateN,
          date: alert.levelDate,
          name: alert.levelName
        }));
        try {
          const alertLevelsWithInstrumentId = formattedAlertLevels.map(
            (level: any) => ({
              ...level,
              instrumentId: edit.data.id
            })
          );
          const response = await addSurfaceMarkerControlLevels({
            variables: {
              structureId: structureInfo.structureId,
              data: alertLevelsWithInstrumentId
            }
          });
          if (response.data) {
            setAddedAlertLevel(alertLevelsWithInstrumentId);
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successCreateControlLevel)
            );
            return true;
          }
          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          return false;
        }
      default:
        return false;
    }
  };

  const handleDeleteAlertLevel = async (alerta: any) => {
    try {
      const response = await deleteInstrumentAlertLevel({
        variables: {
          structureId: structureInfo.structureId,
          data: {
            alertId: alerta.id,
            instrumentId: edit.data.id
          }
        }
      });

      if (response.data) {
        toastfySuccess(` ${alerta.name}${t('deletedSuccessfully')}`);
        setAlertLevelsPluviometer((prevLevels: any) =>
          prevLevels.filter((alert: any) => alert.id !== alerta.id)
        );
        setShowDeleteModal(false);
      } else {
        toastfyError(`${t('errorDelete')} ${alerta.name}!`);
      }
    } catch (error) {
      toastfyError(`${t('errorDelete')} ${alerta.name}!`);
    }
  };

  const openDeleteModal = (alert: any) => {
    setSelectedAlert(alert);
    setShowDeleteModal(true);
  };

  const handleFetchSelectedInstrumentList = (type: string) => {
    const strategy = {
      piezometer: () => handleFetchPiezometers(),
      ina: () => handleFetchPiezometers(),
      pluviometer: () => handleFetchPluviometers(),
      waterlevel: () => handleFetchWaterLevel(),
      surfacemarker: () => handleFetchSurfaceMarkers()
    };
    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  const DeleteAlertLevelInstrument = ({ alerta }: any) => {
    return (
      <RegisterInstrumentBackground>
        <RegisterInstrumentModal
          style={{
            width: '50%',
            minWidth: 600,
            maxWidth: 650,
            height: 'max-content',
            minHeight: '100px',
            borderRadius: '5px'
          }}
        >
          <GraphHeader
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            title={t('deleteAlertLevel')}
            subtitle={selectedType}
          />
          <RegisterReadingsArea
            style={{
              width: '100%',
              textAlign: 'justify',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              columnGap: '5px'
            }}
          >
            {t('deleteAlertLevel')}
            <strong>{alerta.name}</strong>?
          </RegisterReadingsArea>
          <ButtonAreaAdmin
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              width: '100%',
              padding: '35px 0px 10px 0px'
            }}
          >
            <div style={{ paddingRight: '15px' }}>
              <DefaultButton
                title={t('Confirm').toLocaleUpperCase()}
                action={() => handleDeleteAlertLevel(alerta)}
              />
            </div>
          </ButtonAreaAdmin>
        </RegisterInstrumentModal>
      </RegisterInstrumentBackground>
    );
  };

  const InstrumentStatusSelect = () => {
    return (
      <HolderEspecificInfo style={{ marginTop: '20px' }}>
        <GeralInfo>{t('OperationalStatus')}:</GeralInfo>
        {userRoleInModule === 'viewer' ? (
          <ViewInfosValue>
            {t(newOperationalStatus.toLocaleLowerCase())}
          </ViewInfosValue>
        ) : (
          <EspecificSelect
            value={newOperationalStatus}
            onChange={(e) => setNewOperationalStatus(e.target.value)}
            style={{ width: '172px' }}
          >
            <EspecificOption value="Active">{t('active')}</EspecificOption>
            <EspecificOption value="Inactive">
              {t('Desativado')}
            </EspecificOption>
          </EspecificSelect>
        )}
      </HolderEspecificInfo>
    );
  };

  const InstrumentLocalInstall = () => {
    return (
      <HolderEspecificInfo style={{ marginTop: '20px' }}>
        <GeralInfo>{t('InstallationLocation')}:</GeralInfo>

        {userRoleInModule === 'viewer' ? (
          <ViewInfosValue>{newInstallLocation}</ViewInfosValue>
        ) : (
          <EspecificSelect
            style={{ width: '172px' }}
            value={newInstallLocation}
            onChange={(e) => setNewInstallLocation(e.target.value)}
          >
            <EspecificOption value="Foundation">
              {t('Foundation')}
            </EspecificOption>
            <EspecificOption value="Massive">{t('Massive')}</EspecificOption>
            <EspecificOption value="Shoulder">{t('Shoulder')}</EspecificOption>
            <EspecificOption value="DrainingMat">
              {t('DrainingMat')}
            </EspecificOption>
            <EspecificOption value="VerticalFilter">
              {t('VerticalFilter')}
            </EspecificOption>
            <EspecificOption value="Other">{t('Other')}</EspecificOption>
          </EspecificSelect>
        )}
      </HolderEspecificInfo>
    );
  };

  const InstrumentTypeOfReading = () => {
    return (
      <HolderEspecificInfo style={{ marginTop: '20px' }}>
        <GeralInfo>{t('typeOfReading')}:</GeralInfo>
        {userRoleInModule === 'viewer' ? (
          <ViewInfosValue>{newReadingType}</ViewInfosValue>
        ) : (
          <EspecificSelect
            value={newReadingType}
            onChange={(e) =>
              setNewReadingType(e.target.value as InstrumentReadingTypeEnum)
            }
            style={{ width: '172px' }}
          >
            {Object.entries(InstrumentReadingTypeEnum).map(([key, value]) => (
              <EspecificOption key={key} value={value}>
                {t(value)}
              </EspecificOption>
            ))}
          </EspecificSelect>
        )}
      </HolderEspecificInfo>
    );
  };

  useEffect(() => {
    handleFetchSelectedInstrumentList(selectedType);
    if (selectedType === 'SurfaceMarker' && edit?.data?.surfaceMarker) {
      setReference(edit.data.surfaceMarker.references?.[0] || {});
      setAlertLevels(edit.data.surfaceMarker.alertLevels || []);
      // TODO: Faltando chave status no alertLevels do Marco Superficial
      // setAlertLevels(
      //   edit.data.surfaceMarker.alertLevels?.filter(
      //     (alert: any) => alert.status
      //   ) || []
      // );
    } else {
      setReference({});
      setAlertLevels([]);
    }

    if (selectedType === 'Pluviometer' && edit?.data?.alertLevels) {
      setAlertLevelsPluviometer(
        edit.data.alertLevels?.filter((alert: any) => alert.status) || []
      );
    } else {
      setAlertLevelsPluviometer([]);
    }

    if (selectedType === 'WaterLevel' && edit?.data?.alertLevels) {
      setAlertLevelsWaterLevel(
        edit.data.alertLevels?.filter((alert: any) => alert.status) || []
      );
    } else {
      setAlertLevelsWaterLevel([]);
    }

    if (edit && edit.data) {
      if (edit.data.installLocation) {
        setNewInstallLocation(edit.data.installLocation);
      }

      if (edit.data.readingType) {
        setNewReadingType(edit.data.readingType);
      }

      if (edit.data.operationalStatus) {
        setNewOperationalStatus(edit.data.operationalStatus);
      }

      if (edit.data.isUTM) {
        setNewIsUtm(edit.data.isUTM);
      }
    }

    if (edit && edit.data && edit.data.alertLevels) {
      setFsDataArray(
        edit.data.alertLevels.map(() => ({
          value1: '',
          signal1: '',
          signal2: '',
          value2: ''
        }))
      );
    }

    if (addedAlertLevel) {
      switch (selectedType) {
        case 'Pluviometer':
          setAlertLevelsPluviometer((prevLevels: any) => [
            ...prevLevels,
            ...addedAlertLevel
          ]);
          break;
        case 'WaterLevel':
          setAlertLevelsWaterLevel((prevLevels: any) => [
            ...prevLevels,
            ...addedAlertLevel
          ]);
          break;
        default:
          break;
      }
      setAddedAlertLevel(null);
    }
  }, [edit, selectedType, addedAlertLevel]);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          justifyContent: 'space-between',
          minHeight: '680px',
          width: '80%',
          // top: "45%",
          borderRadius: '5px'
        }}
      >
        <GraphHeader
          showModal={showModal}
          setShowModal={(show) => {
            setShowModal(show);
            if (!show) {
              setDataChanged(true);
            }
          }}
          title={t('Editar Instrumento')}
          subtitle={selectedType}
        />
        <EditInstrumentsModalArea>
          {(() => {
            switch (selectedType as InstrumentsType) {
              case 'Piezometer':
                return (
                  <HolderInfosEditInstrument>
                    <EditBasicInstrumentInfo>
                      <TitleBasicInfo> {t('generalData')}</TitleBasicInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('name')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue style={{}}>{newName}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewName(e.target.value)}
                            type="text"
                            placeholder={t('name')}
                            value={newName}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo> {t('coordinateE')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordE}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordE(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordE}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateN')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordN}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordN(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordN}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateUTM')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>
                            {newIsUTM === true ? 'Sim' : 'Não'}
                          </ViewInfosValue>
                        ) : (
                          <Toggle
                            className="custom-toggle"
                            checked={newIsUTM}
                            onChange={(e) => setNewIsUtm(e.target.checked)}
                            icons={false}
                          />
                        )}
                      </HolderEspecificInfo>
                      <InstrumentLocalInstall />
                      <InstrumentTypeOfReading />
                      <>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('deactivationDate')}:</GeralInfo>

                          {edit.data.operationalStatus === 'Active' ? (
                            <span>{t('activeInstrument')}:</span>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newDeactivationDate}
                              setStartDate={setNewDeactivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('InstallationDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newInstallationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newInstallationDate}
                              setStartDate={setNewInstallationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('activactionDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newActivationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newActivationDate}
                              setStartDate={setNewActivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                      </>
                      <InstrumentStatusSelect />
                      {userRoleInModule === 'viewer' ? (
                        <></>
                      ) : (
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <ButtonsaveEspecificInfo
                            onClick={() => handleEditPiezometer()}
                          >
                            {t('saveGeneralData')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        </HolderEspecificInfo>
                      )}
                    </EditBasicInstrumentInfo>
                    <EditEspecifcInstrumentInfo>
                      <TitleBasicInfo>{t('controlCotes')}:</TitleBasicInfo>
                      {controlCotesPiezometer.map(
                        (control: any, index: any) => (
                          <CardEditAlertLevel key={control.topCote || index}>
                            <HolderEspecificInfo style={{ marginTop: '10px' }}>
                              <GeralInfo style={{ fontSize: '12px' }}>
                                {t('topCote')}:
                              </GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue style={{ fontSize: '12px' }}>
                                  {control.topCote}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  style={{ fontSize: '12px' }}
                                  type="number"
                                  value={control.topCote || ''}
                                  onChange={(e) => {
                                    const updatedControlCotesPz =
                                      controlCotesPiezometer.map(
                                        (al: any, idx: any) => {
                                          if (idx === index) {
                                            return {
                                              ...al,
                                              topCote:
                                                parseFloat(e.target.value) || 0
                                            };
                                          }
                                          return al;
                                        }
                                      );
                                    setControlCotesPiezometer(
                                      updatedControlCotesPz
                                    );
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                            <HolderEspecificInfo style={{ marginTop: '15px' }}>
                              <GeralInfo style={{ fontSize: '12px' }}>
                                {t('bottomCote')}:
                              </GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue style={{ fontSize: '12px' }}>
                                  {control.bottomCote}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  style={{ fontSize: '12px' }}
                                  type="number"
                                  value={control.bottomCote || ''}
                                  onChange={(e) => {
                                    const updatedControlCotesPz =
                                      controlCotesPiezometer.map(
                                        (al: any, idx: any) => {
                                          if (idx === index) {
                                            return {
                                              ...al,
                                              bottomCote:
                                                parseFloat(e.target.value) || 0
                                            };
                                          }
                                          return al;
                                        }
                                      );
                                    setControlCotesPiezometer(
                                      updatedControlCotesPz
                                    );
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                          </CardEditAlertLevel>
                        )
                      )}
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={handleUpdateControlCotePiezometer}
                          >
                            {t('saveCote')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      <TitleBasicInfo>{t('AlertLevel')}:</TitleBasicInfo>
                      {alertLevelsPiezometer.map((alert: any, index: any) => (
                        <CardEditAlertLevel key={alert.id || index}>
                          {/* <HolderCardAlertTitle>
                            <GeralInfo
                              style={{
                                fontWeight: "600",
                                fontSize: "12px",
                                marginTop: "5px",
                              }}
                            >
                              {alert.name}
                            </GeralInfo>
                          </HolderCardAlertTitle> */}
                          <HolderEspecificInfo style={{ marginTop: '10px' }}>
                            <GeralInfo style={{ fontSize: '12px' }}>
                              {t('name')}:
                            </GeralInfo>
                            {userRoleInModule === 'viewer' ? (
                              <ViewInfosValue style={{ fontSize: '12px' }}>
                                {alert.name}
                              </ViewInfosValue>
                            ) : (
                              <InputEditEspecificInfo
                                type="text"
                                style={{ fontSize: '12px' }}
                                value={alert.name || ''}
                                onChange={(e) => {
                                  const updateAlertsPiezometer =
                                    alertLevelsPiezometer.map(
                                      (al: any, idx: any) => {
                                        if (idx === index) {
                                          return {
                                            ...al,
                                            name: e.target.value
                                          };
                                        }
                                        return al;
                                      }
                                    );
                                  setAlertLevelsPiezometer(
                                    updateAlertsPiezometer
                                  );
                                }}
                              ></InputEditEspecificInfo>
                            )}
                          </HolderEspecificInfo>

                          <HolderEspecificInfo style={{ marginTop: '15px' }}>
                            <GeralInfo style={{ fontSize: '12px' }}>
                              {t('coteValue')}:
                            </GeralInfo>
                            {userRoleInModule === 'viewer' ? (
                              <ViewInfosValue style={{ fontSize: '12px' }}>
                                {alert.coteValue}
                              </ViewInfosValue>
                            ) : (
                              <InputEditEspecificInfo
                                style={{ fontSize: '12px' }}
                                type="number"
                                value={alert.coteValue || ''}
                                onChange={(e) => {
                                  const updateAlertsPiezometer =
                                    alertLevelsPiezometer.map(
                                      (al: any, idx: any) => {
                                        if (idx === index) {
                                          return {
                                            ...al,
                                            coteValue:
                                              parseFloat(e.target.value) || 0
                                          };
                                        }
                                        return al;
                                      }
                                    );
                                  setAlertLevelsPiezometer(
                                    updateAlertsPiezometer
                                  );
                                }}
                              />
                            )}
                          </HolderEspecificInfo>

                          <HolderEspecificInfo
                            style={{
                              marginTop: '15px',
                              justifyContent: 'center',
                              columnGap: '4px'
                            }}
                          >
                            <GeralInfo style={{ fontSize: '12px' }}>
                              {alert.drained === null || undefined
                                ? 'FS Não Drenado:'
                                : 'FS Drenado:'}
                            </GeralInfo>
                            <ViewInfosValue
                              style={{ fontSize: '12px', fontWeight: '600' }}
                            >
                              {alert.drained === null || undefined
                                ? alert.undrained
                                : alert.drained}
                            </ViewInfosValue>
                          </HolderEspecificInfo>
                          <CustomRegisterInputArea
                            style={{ width: '100%', padding: '0px' }}
                          >
                            <DefaultInput
                              style={{ fontSize: '12px' }}
                              type="number"
                              placeholder={`${t('Value')}`}
                              value={fsDataArray[index].value1}
                              onChange={(e) =>
                                handleOnChange(index, 'value1', e.target.value)
                              }
                            />
                            <SelectLevel
                              style={{ fontSize: '12px' }}
                              value={fsDataArray[index].signal1}
                              onChange={(e) =>
                                handleOnChange(index, 'signal1', e.target.value)
                              }
                            >
                              {PiezometersModel().signals.map((signal) => (
                                <OptionLevel
                                  key={signal.id}
                                  value={signal.value}
                                >
                                  {signal.label}
                                </OptionLevel>
                              ))}
                            </SelectLevel>
                            <SelectLevel
                              style={{ fontSize: '12px' }}
                              value={''}
                              onChange={(e) => {}}
                            >
                              {alert.drained === null || undefined ? (
                                <OptionLevel value="undrained">
                                  FS:{t('NotDrained')}
                                </OptionLevel>
                              ) : (
                                <OptionLevel value="drained">
                                  FS:{t('Drained')}
                                </OptionLevel>
                              )}
                            </SelectLevel>
                            <SelectLevel
                              style={{ fontSize: '12px' }}
                              value={fsDataArray[index].signal2}
                              onChange={(e) =>
                                handleOnChange(index, 'signal2', e.target.value)
                              }
                            >
                              {PiezometersModel().signals.map((signal) => (
                                <OptionLevel
                                  key={signal.id}
                                  value={signal.value}
                                >
                                  {signal.label}
                                </OptionLevel>
                              ))}
                            </SelectLevel>
                            <DefaultInput
                              style={{ fontSize: '12px' }}
                              placeholder={`${t('Value')}`}
                              value={fsDataArray[index].value2}
                              onChange={(e) =>
                                handleOnChange(index, 'value2', e.target.value)
                              }
                            />
                          </CustomRegisterInputArea>
                        </CardEditAlertLevel>
                      ))}
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={handleUpdateAlertLevelsPiezometer}
                          >
                            {t('saveAlertLevel')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={() => setShowAddModel(true)}
                          >
                            {t('AddLevel')}: <TiPlus />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      {showAddModal && (
                        <ModalStep
                          onClose={() => setShowAddModel(false)}
                          // translate
                          title={t('AddLevel')}
                          steps={[
                            {
                              name: 'InstrumentInformation',
                              component: (
                                <RegisterInstrumentInfos
                                  isEditing={true}
                                  instrumentEspecificData={
                                    instrumentEspecificData
                                  }
                                  setInstrumentEspecificData={
                                    setInstrumentEspecificData
                                  }
                                  basicInfos={''}
                                  instrumentSelected={
                                    edit.data.type.instrumentType
                                  }
                                  specificInfosError={specificInfosError}
                                />
                              ),
                              onNext: () => handleSubmitNewControlLevel()
                            }
                          ]}
                        />
                      )}
                    </EditEspecifcInstrumentInfo>
                  </HolderInfosEditInstrument>
                );
              case 'INA':
                return (
                  <HolderInfosEditInstrument>
                    <EditBasicInstrumentInfo>
                      <TitleBasicInfo>{t('saveGeneralData')}: </TitleBasicInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('name')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue style={{}}>{newName}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewName(e.target.value)}
                            type="text"
                            placeholder={t('name')}
                            value={newName}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateE')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordE}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordE(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordE}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateN')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordN}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordN(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordN}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateUTM')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>
                            {newIsUTM === true ? 'Sim' : 'Não'}
                          </ViewInfosValue>
                        ) : (
                          <Toggle
                            className="custom-toggle"
                            checked={newIsUTM}
                            onChange={(e) => setNewIsUtm(e.target.checked)}
                            icons={false}
                          />
                        )}
                      </HolderEspecificInfo>
                      <InstrumentLocalInstall />
                      <InstrumentTypeOfReading />
                      <>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('deactivationDate')}:</GeralInfo>

                          {edit.data.operationalStatus === 'Active' ? (
                            <span>{t('activeInstrument')}:</span>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newDeactivationDate}
                              setStartDate={setNewDeactivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('InstallationDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newInstallationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newInstallationDate}
                              setStartDate={setNewInstallationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('activactionDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newActivationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newActivationDate}
                              setStartDate={setNewActivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                      </>
                      <InstrumentStatusSelect />
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <ButtonsaveEspecificInfo
                          onClick={() => handleEditPiezometer()}
                        >
                          {t('saveGeneralData')}: <IoSaveSharp />
                        </ButtonsaveEspecificInfo>
                      </HolderEspecificInfo>
                    </EditBasicInstrumentInfo>
                    <EditEspecifcInstrumentInfo>
                      <TitleBasicInfo>{t('controlCotes')}:</TitleBasicInfo>
                      {controlCotesPiezometer.map(
                        (control: any, index: any) => (
                          <CardEditAlertLevel key={control.topCote || index}>
                            <HolderEspecificInfo style={{ marginTop: '10px' }}>
                              <GeralInfo style={{ fontSize: '12px' }}>
                                {t('topCote')}:
                              </GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue style={{ fontSize: '12px' }}>
                                  {control.topCote}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  style={{ fontSize: '12px' }}
                                  type="number"
                                  value={control.topCote || ''}
                                  onChange={(e) => {
                                    const updatedControlCotesPz =
                                      controlCotesPiezometer.map(
                                        (al: any, idx: any) => {
                                          if (idx === index) {
                                            return {
                                              ...al,
                                              topCote:
                                                parseFloat(e.target.value) || 0
                                            };
                                          }
                                          return al;
                                        }
                                      );
                                    setControlCotesPiezometer(
                                      updatedControlCotesPz
                                    );
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                            <HolderEspecificInfo style={{ marginTop: '15px' }}>
                              <GeralInfo style={{ fontSize: '12px' }}>
                                {t('bottomCote')}:
                              </GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue style={{ fontSize: '12px' }}>
                                  {control.bottomCote}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  style={{ fontSize: '12px' }}
                                  type="number"
                                  value={control.bottomCote || ''}
                                  onChange={(e) => {
                                    const updatedControlCotesPz =
                                      controlCotesPiezometer.map(
                                        (al: any, idx: any) => {
                                          if (idx === index) {
                                            return {
                                              ...al,
                                              bottomCote:
                                                parseFloat(e.target.value) || 0
                                            };
                                          }
                                          return al;
                                        }
                                      );
                                    setControlCotesPiezometer(
                                      updatedControlCotesPz
                                    );
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                          </CardEditAlertLevel>
                        )
                      )}
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={handleUpdateControlCotePiezometer}
                          >
                            {t('saveCote')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      <TitleBasicInfo>{t('AlertLevel')}:</TitleBasicInfo>
                      {alertLevelsPiezometer.map((alert: any, index: any) => (
                        <CardEditAlertLevel key={alert.id || index}>
                          <HolderCardAlertTitle>
                            <GeralInfo
                              style={{
                                fontWeight: '600',
                                fontSize: '12px',
                                marginTop: '5px'
                              }}
                            >
                              {alert.name}
                            </GeralInfo>
                          </HolderCardAlertTitle>
                          <HolderEspecificInfo style={{ marginTop: '10px' }}>
                            <GeralInfo style={{ fontSize: '12px' }}>
                              {t('name')}:
                            </GeralInfo>
                            {userRoleInModule === 'viewer' ? (
                              <ViewInfosValue style={{ fontSize: '12px' }}>
                                {alert.name}
                              </ViewInfosValue>
                            ) : (
                              <InputEditEspecificInfo
                                type="text"
                                style={{ fontSize: '12px' }}
                                value={alert.name || ''}
                                onChange={(e) => {
                                  const updateAlertsPiezometer =
                                    alertLevelsPiezometer.map(
                                      (al: any, idx: any) => {
                                        if (idx === index) {
                                          return {
                                            ...al,
                                            name: e.target.value
                                          };
                                        }
                                        return al;
                                      }
                                    );
                                  setAlertLevelsPiezometer(
                                    updateAlertsPiezometer
                                  );
                                }}
                              ></InputEditEspecificInfo>
                            )}
                          </HolderEspecificInfo>

                          <HolderEspecificInfo style={{ marginTop: '15px' }}>
                            <GeralInfo style={{ fontSize: '12px' }}>
                              {t('coteValue')}:
                            </GeralInfo>
                            {userRoleInModule === 'viewer' ? (
                              <ViewInfosValue style={{ fontSize: '12px' }}>
                                {alert.coteValue}
                              </ViewInfosValue>
                            ) : (
                              <InputEditEspecificInfo
                                style={{ fontSize: '12px' }}
                                type="number"
                                value={alert.coteValue || ''}
                                onChange={(e) => {
                                  const updateAlertsPiezometer =
                                    alertLevelsPiezometer.map(
                                      (al: any, idx: any) => {
                                        if (idx === index) {
                                          return {
                                            ...al,
                                            coteValue:
                                              parseFloat(e.target.value) || 0
                                          };
                                        }
                                        return al;
                                      }
                                    );
                                  setAlertLevelsPiezometer(
                                    updateAlertsPiezometer
                                  );
                                }}
                              />
                            )}
                          </HolderEspecificInfo>
                        </CardEditAlertLevel>
                      ))}
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={handleUpdateAlertLevelsPiezometer}
                          >
                            {t('saveAlertLevel')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={() => setShowAddModel(true)}
                          >
                            {t('AddLevel')}: <TiPlus />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      {showAddModal && (
                        <ModalStep
                          onClose={() => setShowAddModel(false)}
                          // translate
                          title={t('AddLevel')}
                          steps={[
                            {
                              name: 'InstrumentInformation',
                              component: (
                                <RegisterInstrumentInfos
                                  isEditing={true}
                                  instrumentEspecificData={
                                    instrumentEspecificData
                                  }
                                  setInstrumentEspecificData={
                                    setInstrumentEspecificData
                                  }
                                  basicInfos={''}
                                  instrumentSelected={
                                    edit.data.type.instrumentType
                                  }
                                  specificInfosError={specificInfosError}
                                />
                              ),
                              onNext: () => handleSubmitNewControlLevel()
                            }
                          ]}
                        />
                      )}
                    </EditEspecifcInstrumentInfo>
                  </HolderInfosEditInstrument>
                );
              case 'Pluviometer':
                return (
                  <HolderInfosEditInstrument>
                    <EditBasicInstrumentInfo>
                      <TitleBasicInfo>{t('saveGeneralData')}: </TitleBasicInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('name')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue style={{}}>{newName}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewName(e.target.value)}
                            type="text"
                            placeholder={t('name')}
                            value={newName}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo>
                        <GeralInfo>{t('pluviometerType')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue style={{}}>
                            {newPluviometerType}
                          </ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) =>
                              setNewPluviometerType(e.target.value)
                            }
                            type="text"
                            placeholder={t('pluviometerType')}
                            value={newPluviometerType}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateE')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordE}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordE(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordE}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateN')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordN}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordN(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordN}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateUTM')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>
                            {newIsUTM === true ? 'Sim' : 'Não'}
                          </ViewInfosValue>
                        ) : (
                          <Toggle
                            className="custom-toggle"
                            checked={newIsUTM}
                            onChange={(e) => setNewIsUtm(e.target.checked)}
                            icons={false}
                          />
                        )}
                      </HolderEspecificInfo>
                      <InstrumentLocalInstall />
                      <InstrumentTypeOfReading />
                      <>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('deactivationDate')}:</GeralInfo>

                          {edit.data.operationalStatus === 'Active' ? (
                            <span>{t('activeInstrument')}:</span>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newDeactivationDate}
                              setStartDate={setNewDeactivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('InstallationDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newInstallationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newInstallationDate}
                              setStartDate={setNewInstallationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('activactionDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newActivationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newActivationDate}
                              setStartDate={setNewActivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                      </>
                      <InstrumentStatusSelect />
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={() => handleEditPluviometer()}
                          >
                            {t('saveGeneralData')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderEspecificInfo>
                    </EditBasicInstrumentInfo>
                    <EditEspecifcInstrumentInfo>
                      <>
                        <TitleBasicInfo>{t('AlertLevel')}</TitleBasicInfo>
                        {alertLevelsPluviometer.map(
                          (alert: any, index: any) => (
                            <CardEditAlertLevel key={alert.id || index}>
                              <HolderCardAlertTitle>
                                <GeralInfo
                                  style={{
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    marginTop: '5px'
                                  }}
                                >
                                  {alert.name}
                                </GeralInfo>
                              </HolderCardAlertTitle>
                              <HolderEspecificInfo
                                style={{ marginTop: '10px' }}
                              >
                                <GeralInfo style={{ fontSize: '12px' }}>
                                  {t('name')}:
                                </GeralInfo>
                                {userRoleInModule === 'viewer' ? (
                                  <ViewInfosValue style={{ fontSize: '12px' }}>
                                    {alert.name}
                                  </ViewInfosValue>
                                ) : (
                                  <InputEditEspecificInfo
                                    type="text"
                                    style={{ fontSize: '12px' }}
                                    value={alert.name || ''}
                                    onChange={(e) => {
                                      const updatedAlertsPluviometers =
                                        alertLevelsPluviometer.map(
                                          (al: any, idx: any) => {
                                            if (idx === index) {
                                              return {
                                                ...al,
                                                name: e.target.value
                                              };
                                            }
                                            return al;
                                          }
                                        );
                                      setAlertLevelsPluviometer(
                                        updatedAlertsPluviometers
                                      );
                                    }}
                                  />
                                )}
                              </HolderEspecificInfo>
                              <HolderEspecificInfo
                                style={{ marginTop: '15px' }}
                              >
                                <GeralInfo style={{ fontSize: '12px' }}>
                                  Valor:
                                </GeralInfo>
                                {userRoleInModule === 'viewer' ? (
                                  <ViewInfosValue style={{ fontSize: '12px' }}>
                                    {alert.value}
                                  </ViewInfosValue>
                                ) : (
                                  <InputEditEspecificInfo
                                    style={{ fontSize: '12px' }}
                                    type="number"
                                    value={alert.value || ''}
                                    onChange={(e) => {
                                      const updatedAlertsPluviometers =
                                        alertLevelsPluviometer.map(
                                          (al: any, idx: any) => {
                                            if (idx === index) {
                                              return {
                                                ...al,
                                                value:
                                                  parseFloat(e.target.value) ||
                                                  0
                                              };
                                            }
                                            return al;
                                          }
                                        );
                                      setAlertLevelsPluviometer(
                                        updatedAlertsPluviometers
                                      );
                                    }}
                                  />
                                )}
                              </HolderEspecificInfo>
                              <HolderEspecificInfo
                                style={{
                                  marginTop: '15px',
                                  alignItems: 'flex-end',
                                  justifyContent: 'flex-end'
                                }}
                              >
                                {userRoleInModule === 'viewer' ? (
                                  <></>
                                ) : (
                                  <ButtonsaveEspecificInfo
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columnGap: '5px'
                                    }}
                                    onClick={() => openDeleteModal(alert)}
                                  >
                                    Deletar Nível <FaTrashCan size={10} />
                                  </ButtonsaveEspecificInfo>
                                )}
                              </HolderEspecificInfo>
                            </CardEditAlertLevel>
                          )
                        )}
                        {showDeleteModal && (
                          <DeleteAlertLevelInstrument alerta={selectedAlert} />
                        )}
                      </>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={handleUpdateAlertLevelsPluviometer}
                          >
                            {t('saveAlertLevel')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={() => setShowAddModel(true)}
                          >
                            {t('AddLevel')}: <TiPlus />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      {showAddModal && (
                        <ModalStep
                          onClose={() => setShowAddModel(false)}
                          // translate
                          title={t('AddLevel')}
                          steps={[
                            {
                              name: 'InstrumentInformation',
                              component: (
                                <RegisterInstrumentInfos
                                  isEditing={false}
                                  instrumentEspecificData={
                                    instrumentEspecificData
                                  }
                                  setInstrumentEspecificData={
                                    setInstrumentEspecificData
                                  }
                                  basicInfos={''}
                                  instrumentSelected={
                                    edit.data.type.instrumentType
                                  }
                                  specificInfosError={specificInfosError}
                                />
                              ),
                              onNext: () => handleSubmitNewControlLevel()
                            }
                          ]}
                        />
                      )}
                    </EditEspecifcInstrumentInfo>
                  </HolderInfosEditInstrument>
                );
              case 'WaterLevel':
                return (
                  <HolderInfosEditInstrument>
                    <EditBasicInstrumentInfo>
                      <TitleBasicInfo>{t('saveGeneralData')}: </TitleBasicInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('name')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue style={{}}>{newName}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewName(e.target.value)}
                            type="text"
                            placeholder={t('name')}
                            value={newName}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateE')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordE}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordE(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordE}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateN')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordN}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordN(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordN}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateUTM')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>
                            {newIsUTM === true ? 'Sim' : 'Não'}
                          </ViewInfosValue>
                        ) : (
                          <Toggle
                            className="custom-toggle"
                            checked={newIsUTM}
                            onChange={(e) => setNewIsUtm(e.target.checked)}
                            icons={false}
                          />
                        )}
                      </HolderEspecificInfo>
                      <InstrumentLocalInstall />
                      <InstrumentTypeOfReading />
                      <>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('deactivationDate')}:</GeralInfo>

                          {edit.data.operationalStatus === 'Active' ? (
                            <span>{t('activeInstrument')}:</span>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newDeactivationDate}
                              setStartDate={setNewDeactivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('InstallationDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newInstallationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newInstallationDate}
                              setStartDate={setNewInstallationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('activactionDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newActivationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newActivationDate}
                              setStartDate={setNewActivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                      </>
                      <InstrumentStatusSelect />
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={() => handleEditWaterLevel()}
                          >
                            {t('saveGeneralData')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderEspecificInfo>
                    </EditBasicInstrumentInfo>
                    <EditEspecifcInstrumentInfo>
                      <>
                        <TitleBasicInfo>{t('AlertLevel')}:</TitleBasicInfo>
                        {alertLevelsWaterLevel.map((alert: any, index: any) => (
                          <CardEditAlertLevel key={alert.id || index}>
                            <HolderCardAlertTitle>
                              <GeralInfo
                                style={{
                                  fontWeight: '600',
                                  fontSize: '12px',
                                  marginTop: '5px'
                                }}
                              >
                                {alert.name}
                              </GeralInfo>
                            </HolderCardAlertTitle>
                            <HolderEspecificInfo style={{ marginTop: '10px' }}>
                              <GeralInfo style={{ fontSize: '12px' }}>
                                {t('name')}:
                              </GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue style={{ fontSize: '12px' }}>
                                  {alert.name}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  type="text"
                                  style={{ fontSize: '12px' }}
                                  value={alert.name || ''}
                                  onChange={(e) => {
                                    const updatedAlertsWaterLevel =
                                      alertLevelsWaterLevel.map(
                                        (al: any, idx: any) => {
                                          if (idx === index) {
                                            return {
                                              ...al,
                                              name: e.target.value
                                            };
                                          }
                                          return al;
                                        }
                                      );
                                    setAlertLevelsWaterLevel(
                                      updatedAlertsWaterLevel
                                    );
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                            <HolderEspecificInfo style={{ marginTop: '15px' }}>
                              <GeralInfo style={{ fontSize: '12px' }}>
                                Valor:
                              </GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue style={{ fontSize: '12px' }}>
                                  {alert.value}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  style={{ fontSize: '12px' }}
                                  type="number"
                                  value={alert.value || ''}
                                  onChange={(e) => {
                                    const updatedAlertsWaterLevel =
                                      alertLevelsWaterLevel.map(
                                        (al: any, idx: any) => {
                                          if (idx === index) {
                                            return {
                                              ...al,
                                              value:
                                                parseFloat(e.target.value) || 0
                                            };
                                          }
                                          return al;
                                        }
                                      );
                                    setAlertLevelsWaterLevel(
                                      updatedAlertsWaterLevel
                                    );
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>

                            <HolderEspecificInfo
                              style={{
                                marginTop: '15px',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-end'
                              }}
                            >
                              {userRoleInModule === 'viewer' ? (
                                <></>
                              ) : (
                                <ButtonsaveEspecificInfo
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    columnGap: '5px'
                                  }}
                                  onClick={() => openDeleteModal(alert)}
                                >
                                  Deletar Nível <FaTrashCan size={10} />
                                </ButtonsaveEspecificInfo>
                              )}
                            </HolderEspecificInfo>
                          </CardEditAlertLevel>
                        ))}
                        {showDeleteModal && (
                          <DeleteAlertLevelInstrument alerta={selectedAlert} />
                        )}
                      </>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={handleUpdateAlertLevelsWaterLevel}
                          >
                            {t('saveAlertLevel')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={() => setShowAddModel(true)}
                          >
                            {t('AddLevel')}: <TiPlus />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      {showAddModal && (
                        <ModalStep
                          onClose={() => setShowAddModel(false)}
                          // translate
                          title={t('AddLevel')}
                          steps={[
                            {
                              name: 'InstrumentInformation',
                              component: (
                                <RegisterInstrumentInfos
                                  isEditing={false}
                                  instrumentEspecificData={
                                    instrumentEspecificData
                                  }
                                  setInstrumentEspecificData={
                                    setInstrumentEspecificData
                                  }
                                  basicInfos={''}
                                  instrumentSelected={
                                    edit.data.type.instrumentType
                                  }
                                  specificInfosError={specificInfosError}
                                />
                              ),
                              onNext: () => handleSubmitNewControlLevel()
                            }
                          ]}
                        />
                      )}
                    </EditEspecifcInstrumentInfo>
                  </HolderInfosEditInstrument>
                );
              case 'SurfaceMarker':
                return (
                  <HolderInfosEditInstrument>
                    <EditBasicInstrumentInfo>
                      <TitleBasicInfo>{t('saveGeneralData')}: </TitleBasicInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('name')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue style={{}}>{newName}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewName(e.target.value)}
                            type="text"
                            placeholder={t('name')}
                            value={newName}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateE')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordE}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordE(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordE}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateN')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>{newCoordN}</ViewInfosValue>
                        ) : (
                          <DefaultInput
                            style={{ width: '150px', fontSize: '12px' }}
                            onChange={(e) => setNewCoordN(e.target.value)}
                            type="number"
                            placeholder={t('CoordN')}
                            value={newCoordN}
                          />
                        )}
                      </HolderEspecificInfo>
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        <GeralInfo>{t('coordinateUTM')}:</GeralInfo>
                        {userRoleInModule === 'viewer' ? (
                          <ViewInfosValue>
                            {newIsUTM === true ? 'Sim' : 'Não'}
                          </ViewInfosValue>
                        ) : (
                          <Toggle
                            className="custom-toggle"
                            checked={newIsUTM}
                            onChange={(e) => setNewIsUtm(e.target.checked)}
                            icons={false}
                          />
                        )}
                      </HolderEspecificInfo>
                      <InstrumentLocalInstall />
                      <InstrumentTypeOfReading />
                      <>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('deactivationDate')}:</GeralInfo>

                          {edit.data.operationalStatus === 'Active' ? (
                            <span>{t('activeInstrument')}:</span>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newDeactivationDate}
                              setStartDate={setNewDeactivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('InstallationDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newInstallationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newInstallationDate}
                              setStartDate={setNewInstallationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo style={{ marginTop: '20px' }}>
                          <GeralInfo>{t('activactionDate')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {newActivationDate.toLocaleDateString()}
                            </ViewInfosValue>
                          ) : (
                            <DatePickerComponent
                              showTimeSelect={false}
                              startDate={newActivationDate}
                              setStartDate={setNewActivationDate}
                            />
                          )}
                        </HolderEspecificInfo>
                      </>
                      <InstrumentStatusSelect />
                      <HolderEspecificInfo style={{ marginTop: '20px' }}>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={() => handleEditSurfaceMarker()}
                          >
                            {t('saveGeneralData')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderEspecificInfo>
                    </EditBasicInstrumentInfo>

                    <EditEspecifcInstrumentInfo>
                      <TitleBasicInfo>{t('reference')}</TitleBasicInfo>
                      <CardEditAlertLevel>
                        <HolderEspecificInfo>
                          <GeralInfo>{t('elevation')}</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {reference.elevation}
                            </ViewInfosValue>
                          ) : (
                            <InputEditEspecificInfo
                              type="text"
                              placeholder={t('Elevation')}
                              value={reference.elevation || ''}
                              onChange={(e) =>
                                setReference((prev: any) => ({
                                  ...prev,
                                  elevation: parseFloat(e.target.value) || 0
                                }))
                              }
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo>
                          <GeralInfo>{t('coordinateE')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {reference.coordinateE}
                            </ViewInfosValue>
                          ) : (
                            <InputEditEspecificInfo
                              type="text"
                              placeholder={t('coordE')}
                              value={reference.coordinateE || 0}
                              onChange={(e) =>
                                setReference((prev: any) => ({
                                  ...prev,
                                  coordinateE: parseFloat(e.target.value)
                                }))
                              }
                            />
                          )}
                        </HolderEspecificInfo>
                        <HolderEspecificInfo>
                          <GeralInfo>{t('coordinateN')}:</GeralInfo>
                          {userRoleInModule === 'viewer' ? (
                            <ViewInfosValue>
                              {reference.coordinateN}
                            </ViewInfosValue>
                          ) : (
                            <InputEditEspecificInfo
                              placeholder={t('coordN')}
                              type="text"
                              value={reference.coordinateN || 0}
                              onChange={(e) =>
                                setReference((prev: any) => ({
                                  ...prev,
                                  coordinateN: parseFloat(e.target.value)
                                }))
                              }
                            />
                          )}
                        </HolderEspecificInfo>
                      </CardEditAlertLevel>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={handleUpdateReferences}
                          >
                            {t('saveReference')} <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      <>
                        <TitleBasicInfo
                          style={{ fontSize: '15px', marginTop: '10px' }}
                        >
                          {t('AlertLevel')}:
                        </TitleBasicInfo>
                        {alertLevels.map((alert: any, index: any) => (
                          <CardEditAlertLevel key={alert.id || index}>
                            <HolderCardAlertTitle>
                              <GeralInfo style={{ fontWeight: '600' }}>
                                {alert.name}
                              </GeralInfo>
                            </HolderCardAlertTitle>
                            <HolderEspecificInfo>
                              <GeralInfo>{t('name')}:</GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue>{alert.name}</ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  type="text"
                                  value={alert.name || ''}
                                  onChange={(e) => {
                                    const updatedAlerts = alertLevels.map(
                                      (al: any, idx: any) => {
                                        if (idx === index) {
                                          return {
                                            ...al,
                                            name: e.target.value
                                          };
                                        }
                                        return al;
                                      }
                                    );
                                    setAlertLevels(updatedAlerts);
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                            <HolderEspecificInfo>
                              <GeralInfo>{t('elevation')}</GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue>
                                  {alert.elevation}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  type="text"
                                  value={alert.elevation || ''}
                                  onChange={(e) => {
                                    const updatedAlerts = alertLevels.map(
                                      (al: any, idx: any) => {
                                        if (idx === index) {
                                          return {
                                            ...al,
                                            elevation:
                                              parseFloat(e.target.value) || 0
                                          };
                                        }
                                        return al;
                                      }
                                    );
                                    setAlertLevels(updatedAlerts);
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                            <HolderEspecificInfo style={{}}>
                              <GeralInfo>{t('coordinateE')}:</GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue>
                                  {alert.coordinateE}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  type="text"
                                  value={alert.coordinateE || ''}
                                  onChange={(e) => {
                                    const updatedAlerts = alertLevels.map(
                                      (al: any, idx: any) => {
                                        if (idx === index) {
                                          return {
                                            ...al,
                                            coordinateE:
                                              parseFloat(e.target.value) || 0
                                          };
                                        }
                                        return al;
                                      }
                                    );
                                    setAlertLevels(updatedAlerts);
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                            <HolderEspecificInfo style={{}}>
                              <GeralInfo>{t('coordinateN')}:</GeralInfo>
                              {userRoleInModule === 'viewer' ? (
                                <ViewInfosValue>
                                  {alert.coordinateN}
                                </ViewInfosValue>
                              ) : (
                                <InputEditEspecificInfo
                                  type="text"
                                  value={alert.coordinateN || ''}
                                  onChange={(e) => {
                                    const updatedAlerts = alertLevels.map(
                                      (al: any, idx: any) => {
                                        if (idx === index) {
                                          return {
                                            ...al,
                                            coordinateN:
                                              parseFloat(e.target.value) || 0
                                          };
                                        }
                                        return al;
                                      }
                                    );
                                    setAlertLevels(updatedAlerts);
                                  }}
                                />
                              )}
                            </HolderEspecificInfo>
                            <HolderEspecificInfo
                              style={{
                                marginTop: '15px',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-end'
                              }}
                            >
                              {userRoleInModule === 'viewer' ? (
                                <></>
                              ) : (
                                <ButtonsaveEspecificInfo
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    columnGap: '5px'
                                  }}
                                  onClick={() => openDeleteModal(alert)}
                                >
                                  Deletar Nível <FaTrashCan size={10} />
                                </ButtonsaveEspecificInfo>
                              )}
                            </HolderEspecificInfo>
                          </CardEditAlertLevel>
                        ))}
                        {showDeleteModal && (
                          <DeleteAlertLevelInstrument alerta={selectedAlert} />
                        )}
                      </>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={handleUpdateAlertLevels}
                          >
                            {t('saveAlertLevel')}: <IoSaveSharp />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      <HolderButtonSaveEspecificInfo>
                        {userRoleInModule === 'viewer' ? (
                          <></>
                        ) : (
                          <ButtonsaveEspecificInfo
                            onClick={() => setShowAddModel(true)}
                          >
                            {t('AddLevel')}: <TiPlus />
                          </ButtonsaveEspecificInfo>
                        )}
                      </HolderButtonSaveEspecificInfo>
                      {showAddModal && (
                        <ModalStep
                          onClose={() => setShowAddModel(false)}
                          // translate
                          title={t('AddLevel')}
                          steps={[
                            {
                              name: 'InstrumentInformation',
                              component: (
                                <RegisterInstrumentInfos
                                  isEditing={true}
                                  instrumentEspecificData={
                                    instrumentEspecificData
                                  }
                                  setInstrumentEspecificData={
                                    setInstrumentEspecificData
                                  }
                                  basicInfos={''}
                                  instrumentSelected={
                                    edit.data.type.instrumentType
                                  }
                                  specificInfosError={specificInfosError}
                                />
                              ),
                              onNext: () => handleSubmitNewControlLevel()
                            }
                          ]}
                        />
                      )}
                    </EditEspecifcInstrumentInfo>
                  </HolderInfosEditInstrument>
                );

              default:
                return null;
            }
          })()}
        </EditInstrumentsModalArea>
        {/* <ButtonAreaAdmin>
          <CancelButtonStyled onClick={() => setShowModal(false)}>
            {t("Cancel").toLocaleUpperCase()}
          </CancelButtonStyled>
          <AdvanceButtonStyled
            onClick={() => {
              handleEditInstrument(selectedType);
            }}
          >
            {t("Save").toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </ButtonAreaAdmin> */}
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
