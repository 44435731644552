import styled from 'styled-components';
import backgroundImage from '../../Images/imagemFundoGS3.png';
import {
  Color,
  FontSize,
  MaxWindowHeight,
  MaxWindowWidth
} from '../../Styles/Styles';

const LoginAreaBackground = 'rgba(150, 116, 66, 0.53)';
const LoginLogoBackground = 'rgba(0, 0, 0, 0.65)';

export const LoginLogo = styled.div`
  flex: 2 1;
  background-color: ${LoginLogoBackground};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  img {
    width: 50%;
  }
`;

export const LoginButton = styled.button`
  width: 109px;
  height: 40px;
  border-radius: 5px;
  background-color: ${Color.Gray1};
  border: none;
  cursor: pointer;
  position: relative;
  top: 40px;
`;

export const LoginContainer = styled.div`
  width: ${MaxWindowWidth};
  height: ${MaxWindowHeight};
  position: absolute;
  display: flex;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

export const LoginArea = styled.div`
  flex: 1;
  background-color: ${LoginAreaBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 350px;
`;

export const H1 = styled.h1`
  position: relative;
  top: -50px;
  color: ${Color.White};
  font-size: ${FontSize.XLarge};
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 70%;
  margin-top: 50px;
  position: relative;
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const LoginInput = styled.input`
  border: none;
  border-bottom: 1px solid ${Color.White};
  background-color: transparent;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  line-height: normal;
  font-size: ${FontSize.Small};
  font-style: normal;
  font-weight: 400;
  color: ${Color.White};
  box-shadow: 0 0 0 1000px #534127 inset !important;
  -webkit-text-fill-color: white !important;

  &:focus {
    color: ${Color.White};
    outline: none;
  }
`;

export const ForgotPassword = styled.span`
  position: absolute;
  bottom: 60px;
  right: 0;
  cursor: pointer;
  font-size: ${FontSize.Xsmall};
  color: ${Color.White};
`;
export const LoginError = styled.p`
  position: absolute;
  font-size: ${FontSize.Xsmall};
  right: 0;
  top: 25px;
  color: ${Color.White};
`;

export const AllRightsReserved = styled.p`
  font-size: ${FontSize.Xsmall};
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
  color: ${Color.White};
`;

export const LoginAreaResetPassword = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  background-color: ${LoginAreaBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 400px;
`;

export const LoginInstructionsContainer = styled.div`
  max-width: 383px;
`;

export const LoginInstructionsPContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 16px;
    width: 10%;
    color: ${Color.Gray3};
  }
`;

export const LoginInstructionsP = styled.p`
  color: ${Color.Gray3};
  font-size: 12px;
  font-weight: 400;
  width: 90%;
`;

export const ResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 70%;
  margin-top: 50px;
  position: relative;
`;

export const PasswordError = styled.p`
  position: absolute;
  font-size: ${FontSize.Xsmall};
  right: 0;
  top: 25px;
  color: ${Color.White};
`;

export const LoginLogoReset = styled.div`
  flex: 2 1;
  background-color: ${LoginLogoBackground};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  img {
    padding: 0 25% 0 0;
    width: 50%;
  }
`;
