import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillDelete } from 'react-icons/ai';
import { MdFileDownload } from 'react-icons/md';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import TableWithFilterAndPaginator from '../TableWithFilterAndPaginator/TableWithFilterAndPaginator';
import { DataHeaderItem } from '../TableWithFilterAndPaginator/tableWithFilterAndPaginatorTypes';
import { DataType, GISTMModalFilesProps } from './GISTMListFilesInterface';
import {
  CommentContainer,
  DivComment,
  DivContainerListFiles,
  DivFilesRightSideContainer,
  DivListFiles
} from './GISTMListFilesStyle';
import {
  downloadGISTMFile,
  useFindGistComments,
  useSaveGistmComments,
  useFindFilesPagGISTM,
  useDeleteGISTMFile
} from '../../../../data/services/GISTMService';
import Comment from '../Comment/Comment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import { useParams } from 'react-router-dom';

const commentSchema = z.object({
  comment: z.string().min(1, { message: 'Preencha o campo de comentário!' })
});

type schemaType = z.infer<typeof commentSchema>;

const GISTMListFiles = ({ gistmId }: GISTMModalFilesProps) => {
  const { t } = useTranslation();
  const { structureId } = useParams();
  const { data: allGistmComments } = useFindGistComments(
    gistmId,
    structureId || ''
  );
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState<OptionType>({
    value: 10,
    label: '10'
  });
  const { data: allGistmFiles } = useFindFilesPagGISTM(
    gistmId,
    structureId || '',
    { limit: limit.value as number, offset: offset, page: 0 }
  );
  const { deleteGistmFile } = useDeleteGISTMFile();
  const [deleting, setDeleting] = useState(false);
  const totalPages = allGistmFiles?.findGistmFilesPag.pageInfo.totalPages;
  const [pageForPaginator, setPageForPaginator] = useState(1);
  const { saveGistmComments } = useSaveGistmComments();
  const [item, setItem] = useState<DataType>({
    id: '',
    file: '',
    date: '',
    url: ''
  });
  const columns: DataHeaderItem[] = [
    { name: 'File', filter: '' },
    { name: 'date', filter: '' }
  ];

  const dataTable = allGistmFiles?.findGistmFilesPag.nodes.map((x) => {
    return {
      id: x.id,
      File: x.fileName,
      date: new Date(x.createdAt).toLocaleDateString() || '',
      url: x.file
    };
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors }
  } = useForm<schemaType>({
    resolver: zodResolver(commentSchema)
  });
  const HandleSubmitComment = async (data: schemaType) => {
    try {
      await saveGistmComments({
        variables: {
          structureId: structureId!,
          comments: {
            gistmId: gistmId,
            comment: data.comment
          }
        }
      });
      toastfySuccess('Comentário enviado com sucesso!');
      reset();
    } catch (error) {
      toastfyError('Erro ao enviar comentário. Tente novamente.');
    }
  };

  const handleNextPage = async () => {
    if (pageForPaginator === totalPages) {
      setOffset(0);
      setPageForPaginator(1);
    } else {
      setOffset((prev) => prev + (limit.value as number));
      setPageForPaginator((prev) => prev + 1);
    }
  };

  const handlePreviousPage = async () => {
    if (pageForPaginator !== 1) {
      setOffset((prev) => prev - (limit.value as number));
      setPageForPaginator((prev) => prev - 1);
    }
  };

  const handleDeleteClick = async (row: any) => {
    setDeleting(true);
    setItem(row);
  };

  const actions = [
    { icon: MdFileDownload, onClick: downloadGISTMFile },
    { icon: AiFillDelete, onClick: handleDeleteClick }
  ];

  return (
    <DivContainerListFiles>
      {dataTable?.length === 0 ? (
        <Text type="span"> {t('ThereAreNoRegisteredFiles')}</Text>
      ) : (
        <DivListFiles>
          <DivFilesRightSideContainer>
            <TableWithFilterAndPaginator
              dataHeader={columns}
              data={dataTable || []}
              actions={actions}
              totalPages={totalPages}
              paginator={true}
              currentPage={pageForPaginator}
              nextPage={handleNextPage}
              previousPage={handlePreviousPage}
              countForPage={limit}
              setCountForPage={setLimit}
            />
          </DivFilesRightSideContainer>
          <DivFilesRightSideContainer>
            <DivComment>
              <InputTextarea
                name="comment"
                label="Comentário"
                width="100%"
                register={register}
                error={!!errors.comment}
                errorMessage={errors.comment?.message}
              />
              <Button
                variant={'primary'}
                text="Send"
                size="small"
                onClick={handleSubmit(HandleSubmitComment)}
              />
            </DivComment>
            <CommentContainer>
              {allGistmComments?.findGistmComments.map((comment) => (
                <Comment
                  key={comment.id}
                  user={comment.userName}
                  acronym={comment.userName}
                  date={new Date(comment?.createdAt).toLocaleDateString() || ''}
                  comment={comment.comment}
                />
              ))}
            </CommentContainer>
          </DivFilesRightSideContainer>
        </DivListFiles>
      )}
      {deleting && (
        <ModalConfirm
          onClose={() => setDeleting(false)}
          onConfirm={() => {
            deleteGistmFile({ variables: { fileId: item.id } });
            setDeleting(false);
          }}
          title={t('Delete')}
          text={t('ConfirmDeleteFile')}
        />
      )}
    </DivContainerListFiles>
  );
};

export default GISTMListFiles;
