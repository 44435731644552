import { useContext } from 'react';
import { NotificationListType } from '../../@Types/types';
import { NotificationContext } from '../UserBadge';
import NotificationItem from './NotificationItem';
import { DivNotificationListContainer } from './NotificationStyle';

export default function NotificationList() {
  const { lastNotificationItemsList } = useContext(NotificationContext) as {
    lastNotificationItemsList: NotificationListType[];
  };

  return (
    <DivNotificationListContainer>
      {lastNotificationItemsList.slice(-5).map((e, index) => (
        <NotificationItem
          key={`${e.id}${index}`}
          title={e.alertLevel}
          date={e.sentAt}
          userName={e.structureName}
        />
      ))}
    </DivNotificationListContainer>
  );
}
