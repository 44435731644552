import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { IoIosClose } from 'react-icons/io';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { PiAsteriskBold } from 'react-icons/pi';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../Components/Buttons/ButtonsStyle';
import { CloseIconStyle } from '../../../Components/Graphs/Monitoring/Instrument/StyleInstrumentGraph';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { checkBoxStyle } from '../../../Components/Map/MapStyle';
import { regexMail } from '../../../Components/RegexValidator';
import { StyleInstrumentSelect } from '../../../Components/Selects/InstrumentSelect';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  AdminInput,
  ButtonAreaAdmin,
  CheckboxAdmin,
  IconRequired,
  InputArea,
  InputMaskStyle,
  RequiredInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../Styles/Styles';
import { UserRole } from '../../../data/graphql/base-schema';
import { useCreateUser } from '../../../data/hooks/admin-users/use-create-users';
import { useEditUser } from '../../../data/hooks/admin-users/use-edit-user';
import {
  Mandatory,
  UsersData,
  __initialMandatory,
  __initialUserData
} from './UsersInterface';

export function UsersModal({
  setShowModal,
  edit,
  setEdit,
  userData,
  setUserData,
  companies,
  listUsers
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  edit: { status: boolean; data: any };
  setEdit: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  userData: UsersData;
  setUserData: Dispatch<SetStateAction<UsersData>>;
  companies: { id: string; label: string; value: string }[];
  listUsers: any;
}) {
  const { t } = useTranslation();
  const { createUser } = useCreateUser();
  const { editUser } = useEditUser();
  const [isOnwer, setIsOnwer] = useState<boolean>(false);
  const [role, setRole] = useState<UserRole>(UserRole.Viewer);
  const [mandatorys, setMandatorys] = useState<Mandatory>(__initialMandatory);

  const handleIsOwner = () => {
    if (isOnwer) {
      setRole(UserRole.Viewer);
    } else {
      setRole(UserRole.Owner);
    }
  };

  const mandatoryInputs = () => {
    const checkEmptyFields = (data: UsersData): string[] => {
      const emptyFields: string[] = [];

      if (!data.companyId) emptyFields.push('companyId');
      if (!data.email) emptyFields.push('email');
      if (!data.name) emptyFields.push('name');
      if (!data.phoneNumber) emptyFields.push('phoneNumber');
      if (!data.position) emptyFields.push('position');
      if (!data.role) emptyFields.push('role');

      return emptyFields;
    };
    const emptyFields = checkEmptyFields(userData);

    for (const item of emptyFields) {
      setMandatorys((prev) => ({
        ...prev,
        [item]: false
      }));
    }
  };

  const handleUserOperation = async (operation: string) => {
    if (
      userData.name.length >= 3 &&
      regexMail.test(userData.email) &&
      userData.position &&
      userData.companyId
    ) {
      if (isOnwer) {
        setRole(UserRole.Owner);
      } else {
        setRole(UserRole.Viewer);
      }

      const requestEditData = {
        variables: {
          data: {
            id: userData.id || '',
            companyId: userData.companyId,
            email: userData.email,
            name: userData.name,
            phoneNumber: userData.phoneNumber,
            position: userData.position,
            role: { role: role }
          }
        }
      };

      const requestData = {
        variables: {
          data: {
            companyId: userData.companyId,
            email: userData.email,
            name: userData.name,
            phoneNumber: userData.phoneNumber,
            position: userData.position,
            role: { role: role }
          }
        }
      };

      const response = await (operation === 'edit'
        ? editUser(requestEditData)
        : createUser(requestData));

      toastifyLoading(
        `${operation === 'edit' ? 'Editing' : 'Registering'} ${t('User')}...`
      );
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(
          `${t('User')} ${operation === 'edit' ? t('editedSuccessfully') : t('registeredSuccessfully')}!`
        );
        setShowModal(false);
        setUserData(__initialUserData);
        setEdit({ status: false, data: {} });
        window.location.reload();
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      mandatoryInputs();
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  useEffect(() => {
    if (edit.status && edit.data) {
      if (edit.data.role === 'Owner') {
        setIsOnwer(true);
        setRole(UserRole.Owner);
        setUserData((prevData) => ({
          ...prevData,
          role: 'Owner'
        }));
      } else {
        setIsOnwer(false);
        setRole(UserRole.Viewer);
        setUserData((prevData) => ({
          ...prevData,
          role: 'Viewer'
        }));
      }
      setUserData((prevData) => ({
        ...prevData,
        id: edit.data.id,
        name: edit.data.name,
        companyId: listUsers.listUsers.find(
          (user: { name: string }) => user.name === edit.data.name
        ).company.id,
        email: edit.data.email,
        phoneNumber: edit.data.phoneNumber,
        position: edit.data.position
      }));
      setShowModal(true);
    }
  }, []);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '0'
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>
            {edit.status
              ? `${t('Edit')} ${t('Users')}`
              : `${t('Register')} ${t('Users')}`}
          </RegisterInstrumentTitle>
          <IoIosClose
            size={35}
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setEdit({ status: false, data: {} });
            }}
            style={CloseIconStyle}
          />
        </RegisterInstrumentHeader>
        <InputArea>
          <RequiredInput>
            <AdminInput
              value={userData.name}
              onChange={(e) => {
                setUserData((prevData) => ({
                  ...prevData,
                  name: e.target.value
                }));
                toastfyDimiss('toastError');
              }}
              style={{ borderColor: mandatorys.name ? '' : 'red' }}
              placeholder={t('name')}
            />
            {userData.name.length <= 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              value={userData.email}
              onChange={(e) => {
                setUserData((prevData) => ({
                  ...prevData,
                  email: e.target.value
                }));
                toastfyDimiss('toastError');
              }}
              style={{ borderColor: mandatorys.email ? '' : 'red' }}
              placeholder="Email"
            />
            {userData.email.includes('@') ? (
              !regexMail.test(userData.email) ? (
                <PiAsteriskBold style={IconRequired} />
              ) : (
                <BsCheckLg style={IconRequired} />
              )
            ) : (
              <PiAsteriskBold style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <InputMask
              value={userData.phoneNumber}
              onChange={(e) =>
                setUserData((prevData) => ({
                  ...prevData,
                  phoneNumber: e.target.value
                }))
              }
              style={{
                ...InputMaskStyle,
                borderColor: mandatorys.phoneNumber ? '' : 'red'
              }}
              mask="(99)99999-9999"
              placeholder={t('Telephone')}
            />
            {userData.phoneNumber.length < 9 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>

          <RequiredInput>
            <AdminInput
              value={userData.position}
              onChange={(e) => {
                setUserData((prevData) => ({
                  ...prevData,
                  position: e.target.value
                }));
                toastfyDimiss('toastError');
              }}
              style={{ borderColor: mandatorys.position ? '' : 'red' }}
              placeholder={t('position')}
            />
            {userData.position.length < 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput style={{ position: 'relative', width: '50%' }}>
            <Select
              styles={{
                ...StyleInstrumentSelect,
                control: (provided) => ({
                  ...provided,
                  border: 'none',
                  borderBottom: mandatorys.companyId
                    ? `1px solid ${Color.Brown1}`
                    : '1px solid red',
                  borderRadius: 'none',
                  boxSizing: 'border-box',
                  boxShadow: 'none',
                  padding: 'none',
                  fontSize: '11pt',
                  cursor: 'pointer',
                  marginBottom: '13px',
                  userSelect: 'none',
                  background: 'none',
                  ':hover': {
                    borderBottom: mandatorys.companyId
                      ? `1px solid ${Color.Brown1}`
                      : '1px solid red'
                  }
                })
              }}
              isSearchable={false}
              placeholder={t('Company')}
              hideSelectedOptions
              options={companies}
              onChange={(e: any) => {
                setUserData((prevData) => ({
                  ...prevData,
                  companyId: e.id
                }));
                toastfyDimiss('toastError');
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
              value={companies.find(
                (company: any) => company.id === userData.companyId
              )}
            />
          </RequiredInput>
          <div style={{ display: 'flex', width: '50%', color: Color.Brown1 }}>
            <CheckboxAdmin
              id="owner"
              onClick={() => {
                setIsOnwer(!isOnwer);
                handleIsOwner();
              }}
            >
              {t('Owner')}{' '}
              {isOnwer ? (
                <MdCheckBox style={checkBoxStyle} />
              ) : (
                <MdCheckBoxOutlineBlank style={checkBoxStyle} />
              )}
            </CheckboxAdmin>
          </div>
        </InputArea>
        <ButtonAreaAdmin style={{ justifyContent: 'flex-end' }}>
          <CancelButtonStyled
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setEdit({ status: false, data: {} });
              setUserData(__initialUserData);
            }}
          >
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          {!edit.status && (
            <AdvanceButtonStyled
              onClick={async () => await handleUserOperation('register')}
            >
              {' '}
              {t('Save').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
          {edit.status && (
            <AdvanceButtonStyled
              onClick={async () => await handleUserOperation('edit')}
            >
              {' '}
              {t('Edit').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
