import { useMutation } from '@apollo/client';
import {
  SetInstrumentNotificationUnreadStatusDocument,
  SetInstrumentNotificationUnreadStatusMutation,
  SetInstrumentNotificationUnreadStatusMutationVariables
} from '../graphql/generated/setInstrumentNotificationUnreadStatus';

export function useSetInstrumentNotificationUnreadStatus(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [setInstrumentNotificationUnreadStatus, { data, error, loading }] =
    useMutation<
      SetInstrumentNotificationUnreadStatusMutation,
      SetInstrumentNotificationUnreadStatusMutationVariables
    >(SetInstrumentNotificationUnreadStatusDocument, {
      errorPolicy: 'all',
      onCompleted: onCompleted,
      onError: onError
    });

  return {
    setInstrumentNotificationUnreadStatus,
    data,
    error,
    loading
  };
}
