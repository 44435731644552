import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillDelete } from 'react-icons/ai';
import { MdFileDownload } from 'react-icons/md';
import Text from '../../Atoms/Text/Text';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableWithFilterAndPaginator from '../TableWithFilterAndPaginator/TableWithFilterAndPaginator';
import { DataHeaderItem } from '../TableWithFilterAndPaginator/tableWithFilterAndPaginatorTypes';
import { DataType, PSBModalFilesProps } from './PSBListFiles.interface';
import { DivContainerPSBListFiles } from './PSBListFiles.styles';
import {
  downloadPSBFile,
  useDeletePsbFile,
  useFindFilesPag
} from '../../../../data/services/PSBService';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import { useParams } from 'react-router-dom';

export function PSBListFiles({ psbId }: PSBModalFilesProps) {
  const { t } = useTranslation();
  const { structureId } = useParams();
  const [deleting, setDeleting] = useState(false);
  const [pageForPaginator, setPageForPaginator] = useState(1);
  const [limit, setLimit] = useState<OptionType>({
    value: 10,
    label: '10'
  });
  const [offset, setOffset] = useState(0);
  const [item, setItem] = useState<DataType>({
    id: '',
    file: '',
    date: '',
    url: ''
  });
  const { data } = useFindFilesPag(psbId, structureId!, {
    limit: limit.value as number,
    offset: offset,
    page: 0
  });
  const { deletePsbFile } = useDeletePsbFile();
  const totalPages = data?.findFilesPag.pageInfo.totalPages || 0;

  const columns: DataHeaderItem[] = [
    { name: 'File', filter: '' },
    { name: 'date', filter: '' }
  ];

  const dataTable =
    data?.findFilesPag.nodes.map((x) => {
      return {
        id: x.id,
        File: x.name,
        date: new Date(x.date).toLocaleDateString() || '',
        url: x.file
      };
    }) || [];

  const handleNextPage = async () => {
    if (pageForPaginator === totalPages) {
      setOffset(0);
      setPageForPaginator(1);
    } else {
      setOffset((prev) => prev + (limit.value as number));
      setPageForPaginator((prev) => prev + 1);
    }
  };

  const handlePreviousPage = async () => {
    if (pageForPaginator !== 1) {
      setOffset((prev) => prev - (limit.value as number));
      setPageForPaginator((prev) => prev - 1);
    }
  };

  const handleDeleteClick = async (row: any) => {
    setDeleting(true);
    setItem(row);
  };

  const actions = [
    { icon: MdFileDownload, onClick: downloadPSBFile },
    { icon: AiFillDelete, onClick: handleDeleteClick }
  ];

  const handleConfirmDelete = async () => {
    await deletePsbFile({ variables: { fileId: item.id } });
    setDeleting(false);
  };

  return (
    <DivContainerPSBListFiles>
      {!data?.findFilesPag.nodes.length ? (
        <Text type={'span'}> {t('ThereAreNoRegisteredFiles')}</Text>
      ) : (
        <TableWithFilterAndPaginator
          dataHeader={columns}
          data={dataTable}
          actions={actions}
          totalPages={totalPages}
          paginator={true}
          currentPage={pageForPaginator}
          nextPage={handleNextPage}
          previousPage={handlePreviousPage}
          countForPage={limit}
          setCountForPage={setLimit}
        />
      )}
      {deleting && (
        <ModalConfirm
          onClose={() => setDeleting(false)}
          onConfirm={handleConfirmDelete}
          title={t('Delete')}
          text={t('ConfirmDeleteFile')}
        />
      )}
    </DivContainerPSBListFiles>
  );
}
