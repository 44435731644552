import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ModuleData } from '../../../../@Types/types';
import { ProjectContext } from '../../../../Context/ContextAPI';
import {
  FindAllAtoDocument,
  FindAllAtoQuery,
  FindAllAtoQueryVariables
} from '../../../../data/graphql/query/generated/findAllAto.query';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import {
  DivContainer,
  DivHolderButtons,
  DivHolderChild
} from './AtoNotFoundStyles';

const AtoNotFound = () => {
  const navigate = useNavigate();
  const { structureId } = useParams();

  const { data: allAtos } = useQuery<FindAllAtoQuery, FindAllAtoQueryVariables>(
    FindAllAtoDocument,
    {
      variables: {
        structureId: structureId || ''
      }
    }
  );

  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('ATO');


  const navigateToAtoAdmin = () => {
    navigate(`/${structureId}/ato/admin`);
  };

  const navigateToHomePage = () => {
    navigate(`/${structureId}`);
  };

  useEffect(() => {
    if (allAtos && allAtos.findAllAto && allAtos.findAllAto.length > 0) {
      navigate(`/${structureId}/ato/${allAtos.findAllAto[0].id}`);
    }
  }, [allAtos]);

  return (
    <DivContainer>
      <DivHolderChild>
        <Text color="black" type="h3">
          {t('NoAtosRegistered')}
        </Text>
      </DivHolderChild>
      <DivHolderButtons>
        {userRoleInModule !== 'viewer' ? (
          <>
            <Text color="black" weight="bold" size="large" type="span">
              {t('GoTo')}
            </Text>
            <Button
              onClick={navigateToAtoAdmin}
              variant={'primary'}
              text={`${t('RegisterAto')}`}
            />
            <Button
              onClick={navigateToHomePage}
              variant={'secondary'}
              text={`${t('BackToHome')}`}
            />
          </>
        ) : (
          <Button
            onClick={navigateToHomePage}
            variant={'secondary'}
            text={`${t('BackToHome')}`}
          />
        )}
      </DivHolderButtons>
    </DivContainer>
  );
};

export default AtoNotFound;
