import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import { t } from 'i18next';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';
import { API_URL } from '../../utils/const';
import { AtoFilterInput, PageInput, SortInput } from '../graphql/base-schema';
import {
  UpdateAtoDocument,
  UpdateAtoMutation,
  UpdateAtoMutationVariables
} from '../graphql/generated/addEditAto.mutation';
import {
  CreateAtoDocument,
  CreateAtoMutation,
  CreateAtoMutationVariables
} from '../graphql/generated/createAto.mutation';
import {
  RecordApprovalFlowDocument,
  RecordApprovalFlowMutation,
  RecordApprovalFlowMutationVariables
} from '../graphql/generated/recordApprovalFlow';
import {
  FindAllRecordsPagDocument,
  FindAllRecordsPagQuery,
  FindAllRecordsPagQueryVariables
} from '../graphql/query/generated/findAllAtoRecordsPag';
import {
  FindAtoApproversDocument,
  FindAtoApproversQuery,
  FindAtoApproversQueryVariables
} from '../graphql/query/generated/findAtoApprovers';
import {
  FindAtoVerifiersDocument,
  FindAtoVerifiersQuery,
  FindAtoVerifiersQueryVariables
} from '../graphql/query/generated/findAtoVerifiers';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery,
  FindDataByRecordQueryVariables
} from '../graphql/query/generated/findDataByRecord';
import {
  FindGeneralInfoDocument,
  FindGeneralInfoQuery,
  FindGeneralInfoQueryVariables
} from '../graphql/query/generated/findGeneralInfo';

import {
  SetAtoCanceledDocument,
  SetAtoCanceledMutation,
  SetAtoCanceledMutationVariables
} from '../graphql/generated/canceledAto';
import {
  DeleteAtoDocsDocument,
  DeleteAtoDocsMutation,
  DeleteAtoDocsMutationVariables
} from '../graphql/generated/deleteAtoDocs';
import {
  SetAtoDoneDocument,
  SetAtoDoneMutation,
  SetAtoDoneMutationVariables
} from '../graphql/generated/doneAto';
import {
  ReactivateAtoDocument,
  ReactivateAtoMutation,
  ReactivateAtoMutationVariables
} from '../graphql/generated/reactivateAto';
import { FindAllAtoDocument } from '../graphql/query/generated/findAllAto.query';

export const useGetAllAtoRecords = (
  atoId: string,
  pageInfo?: PageInput,
  filters?: AtoFilterInput,
  sortInfo?: [SortInput],
  onCompleted?: () => void,
  onError?: () => void
) => {
  const { data, loading, error, refetch } = useQuery<
    FindAllRecordsPagQuery,
    FindAllRecordsPagQueryVariables
  >(FindAllRecordsPagDocument, {
    variables: {
      atoId,
      pageInfo: pageInfo || {},
      filters,
      sortInfo
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error,
    refetch
  };
};

export const useAtoCanceled = (
  atoId: string,
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [cancelAto, { data, error, loading }] = useMutation<
    SetAtoCanceledMutation,
    SetAtoCanceledMutationVariables
  >(SetAtoCanceledDocument, {
    refetchQueries: [FindAllAtoDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    cancelAto,
    data,
    error,
    loading
  };
};

export const useAtoDone = (
  atoId: string,
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [atoDone, { data, error, loading }] = useMutation<
    SetAtoDoneMutation,
    SetAtoDoneMutationVariables
  >(SetAtoDoneDocument, {
    refetchQueries: [FindAllAtoDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    atoDone,
    data,
    error,
    loading
  };
};

export const useAtoReactivate = (
  atoId: string,
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [atoReactivate, { data, error, loading }] = useMutation<
    ReactivateAtoMutation,
    ReactivateAtoMutationVariables
  >(ReactivateAtoDocument, {
    refetchQueries: [FindAllAtoDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    atoReactivate,
    data,
    error,
    loading
  };
};

export const useFindAtoVerifiers = (
  structureId: string,
  responsibleCompanyId: string,
  onCompleted?: () => void,
  onError?: () => void
) => {
  const { data, loading, error, refetch } = useQuery<
    FindAtoVerifiersQuery,
    FindAtoVerifiersQueryVariables
  >(FindAtoVerifiersDocument, {
    variables: {
      structureId,
      responsibleCompanyId
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error,
    refetch
  };
};

export const useFindAtoApprovers = (
  structureId: string,
  clientId: string,
  onCompleted?: () => void,
  onError?: () => void
) => {
  const { data, loading, error, refetch } = useQuery<
    FindAtoApproversQuery,
    FindAtoApproversQueryVariables
  >(FindAtoApproversDocument, {
    variables: {
      structureId,
      clientId
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error,
    refetch
  };
};

export const useCreateAto = (
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [createAto, { data, error, loading }] = useMutation<
    CreateAtoMutation,
    CreateAtoMutationVariables
  >(CreateAtoDocument, {
    refetchQueries: [FindAllAtoDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createAto,
    data,
    error,
    loading
  };
};

export const useEditAto = (onCompleted?: () => void, onError?: () => void) => {
  const [editAto, { data, error, loading }] = useMutation<
    UpdateAtoMutation,
    UpdateAtoMutationVariables
  >(UpdateAtoDocument, {
    refetchQueries: [FindAllAtoDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editAto,
    data,
    error,
    loading
  };
};

export const useDeleteAtoDoc = (
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [deleteDoc, { data, error, loading }] = useMutation<
    DeleteAtoDocsMutation,
    DeleteAtoDocsMutationVariables
  >(DeleteAtoDocsDocument, {
    refetchQueries: [FindAllAtoDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteDoc,
    data,
    error,
    loading
  };
};

export const listATO = async (atoId: string) => {
  try {
    const params = {
      atoId: atoId
    };
    // Const response = await axios.get(`${API_URL}/findAllForms`, { params })
    // return response.data;
    return [
      {
        id: '1',
        type: 'Ficha 001',
        quantity: 5
      },
      {
        id: '2',
        type: 'Ficha 002',
        quantity: 6
      },
      {
        id: '3',
        type: 'Ficha 003',
        quantity: 2
      }
    ];
    // Retorno: forms: []
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const listATOdashboard = async (atoId: string) => {
  try {
    const params = {
      atoId: atoId
    };
    // Const response = await axios.get(`${API_URL}/findGeneralInfo`, { params })
    // return response.data;
    return [
      {
        title: 'ATO 001',
        situation: 'Em andamento',
        contract: 'Contrato 001',
        recentPhotos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentVideos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentForms: [
          { id: '1', type: 'Ficha 001', quantity: 5 },
          { id: '2', type: 'Ficha 002', quantity: 6 },
          { id: '3', type: 'Ficha 003', quantity: 2 }
        ],
        formsCount: 15,
        occurrencesCount: 6,
        commentsCount: 4,
        imagesCount: 12,
        activitiesCount: 5,
        videosCount: 6
      },
      {
        title: 'ATO 002',
        situation: 'Em andamento',
        contract: 'Contrato 002',
        recentPhotos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentVideos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentForms: [
          { id: '1', type: 'Ficha 001', quantity: 6 },
          { id: '2', type: 'Ficha 002', quantity: 8 },
          { id: '3', type: 'Ficha 003', quantity: 4 }
        ],
        formsCount: 6,
        occurrencesCount: 9,
        commentsCount: 6,
        imagesCount: 11,
        activitiesCount: 6,
        videosCount: 8
      },
      {
        title: 'ATO 003',
        situation: 'Em andamento',
        contract: 'Contrato 003',
        recentPhotos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentVideos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentForms: [
          {
            id: '1',
            type: 'Ficha 001',
            quantity: 8
          },
          {
            id: '2',
            type: 'Ficha 002',
            quantity: 7
          },
          {
            id: '3',
            type: 'Ficha 003',
            quantity: 3
          }
        ],
        formsCount: 15,
        occurrencesCount: 6,
        commentsCount: 16,
        imagesCount: 13,
        activitiesCount: 11,
        videosCount: 6
      },
      {
        title: 'ATO 004',
        situation: 'Em andamento',
        contract: 'Contrato 004',
        recentPhotos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentVideos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentForms: [
          {
            id: '1',
            type: 'Ficha 001',
            quantity: 9
          },
          {
            id: '2',
            type: 'Ficha 002',
            quantity: 4
          },
          {
            id: '3',
            type: 'Ficha 003',
            quantity: 1
          }
        ],
        formsCount: 19,
        occurrencesCount: 15,
        commentsCount: 12,
        imagesCount: 10,
        activitiesCount: 13,
        videosCount: 12
      },
      {
        title: 'ATO 005',
        situation: 'Em andamento',
        contract: 'Contrato 005',
        recentPhotos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentVideos: [
          'https://www.google.com.br/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
        ],
        recentForms: [
          {
            id: '1',
            type: 'Ficha 001',
            quantity: 3
          },
          {
            id: '2',
            type: 'Ficha 002',
            quantity: 7
          },
          {
            id: '3',
            type: 'Ficha 003',
            quantity: 5
          }
        ],
        formsCount: 10,
        occurrencesCount: 6,
        commentsCount: 4,
        imagesCount: 7,
        activitiesCount: 8,
        videosCount: 6
      }
    ];
    // Retorno:
    // title: (string) // titulo do ato,
    // situation: (string) // situação da obra
    // contract: (string) // contrato da obra
    // recentPhotos: (string[]) // 6 fotos mais recentes
    // recentVideos: // 3 vídeos mais recentes
    // recentForms:  ultimas X fichas com a quantidade de fotos ou vídeos,
    // formsCount: (number) quantidade de formulários até a data atual,
    // occurrencesCount: (number) quantidade de ocorrências,
    // commentsCount: (number) quantidade de comentários,
    // imagesCount: (number) quantidade de fotos,
    // activitiesCount: (number) quantidade de atividades,
    // videosCount: (number) quantidade de videos
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const exportATOImage = async (formData: FormData) => {
  // Objetivo: salvar fotos de uma atividade da ficha
  try {
    const response = await axios.post(
      `${API_URL}/saveFormActivitiesImages`,
      formData
    );
    toastfySuccess('Imagem exportada com sucesso');
    return response.data;
    // Retorno: um array de ids das imagens
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const permissionATO = async () => {
  // Objetivo: listar todos os usuários com permissão de "edição" e todos os usuarios com permissão de "visualização" no modulo de ato;
  try {
    // Const response = await axios.get(`${API_URL}/findAtoVerifiersAndApprovers`)
    // return response.data;
    return {
      verifiers: [
        { id: '1', name: 'João', email: 'joao123@gmail.com' },
        { id: '2', name: 'Maria', email: 'maria123@gmail.com' },
        { id: '3', name: 'Pedro', email: 'pedro123@gmail.com' }
      ],
      approversClient: [
        { id: '4', name: 'Ana', email: 'ana123@gmail.com' },
        { id: '5', name: 'Carlos', email: 'carlos123@gmail.com' },
        { id: '6', name: 'Marta', email: 'marta123@gmail.com' }
      ]
    };
    // Retorno:
    // {
    // 	verifiers: [
    //     {
    //  id: (uuid) // id do usuário,
    //  name: (string) // nome do usuário,
    //  email: (string) // email do usuario
    //     }
    //   ],
    // approversClient: [
    //         {
    //     id: (uuid) // id do usuário,
    //     name: (string) // nome do usuário,
    //     email: (string) // email do usuario
    //         }
    //   ],
    // }
  } catch (error) {
    console.error(error);

    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const createActivity = async (data: {
  formId: string;
  description: string;
}) => {
  // Objetivo: salvar atividades da ficha
  try {
    const params = {
      formId: data.formId,
      description: data.description
    };
    // Const response = await axios.post(`${API_URL}/saveFormActivities`, params)
    // toastfySuccess(t("registeredSuccessfully"));
    // return response.data;
    console.log(params);
    // Retorno: id: (uuid) // id da atividade criada
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const createDocReference = async (data: {
  formId: string;
  name: string;
}) => {
  // Objetivo: salvar documentos de referencia
  try {
    const params = {
      formId: data.formId,
      name: data.name
    };
    // Const response = await axios.post(`${API_URL}/saveFormDocs`, params)
    // toastfySuccess(t("registeredSuccessfully"));
    // return response.data;
    console.log(params);
    // Retorno: id: (uuid); // id do documento
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const createEquipmentAndLabor = async (data: {
  formId: string;
  type: string;
  description: string;
  quantity: number;
}) => {
  // Objetivo: salvar dados de equipamentos ou mão de obra;
  try {
    const params = {
      formId: data.formId,
      type: data.type,
      description: data.description,
      quantity: data.quantity
    };
    // Const response = await axios.post(`${API_URL}/saveFormEquipamentAndLabor`, params)
    // toastfySuccess(t("registeredSuccessfully"));
    // return response.data;
    console.log(params);
    // Retorno: id: (uuid); // id do equipamento ou mão de obra
  } catch (error) {
    console.error(error);

    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const ImageObservation = async (data: {
  imageId: string;
  observation: string;
}) => {
  // Objetivo: salvar observações de uma imagem da ficha
  try {
    const params = {
      imageId: data.imageId,
      observation: data.observation
    };
    // Const response = await axios.post(`${API_URL}/saveFormImageObservation`, params)
    // toastfySuccess(t("registeredSuccessfully"));
    // return response.data;
    console.log(params);
    // Retorno: uma mensagem de sucesso;
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const createRedAlert = async (data: {
  formId: string;
  start: Date;
  end: Date;
  shif: string;
}) => {
  // Objetivo: salvar alerta vermelho
  try {
    const params = {
      formId: data.formId,
      start: data.start,
      end: data.end,
      shif: data.shif
    };
    // Const response = await axios.post(`${API_URL}/saveFormRedAlertPeriod`, params)
    // toastfySuccess(t("registeredSuccessfully"));
    // return response.data;
    console.log(params);
    // Retorno: id: (uuid); // id do alerta vermelho
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const createWorkShift = async (data: {
  formId: string;
  entry: Date;
  exit: Date;
  entry_interval: Date;
  exit_interval: Date;
  shif: string;
}) => {
  // Objetivo: salvar turno de trabalho
  try {
    const params = {
      formId: data.formId,
      entry: data.entry,
      exit: data.exit,
      entry_interval: data.entry_interval,
      exit_interval: data.exit_interval,
      shif: data.shif
    };
    // Const response = await axios.post(`${API_URL}/saveFormWorkShift`, params)
    // toastfySuccess(t("registeredSuccessfully"));
    // return response.data;
    console.log(params);
    // Retorno: id: (uuid); // id do turno de trabalho
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const saveImage = async (key: string, file: File) => {
  // Objetivo: salvar imagem (1 foto por vez)
  try {
    const formData = new FormData();
    formData.append(key, file);
    // Const response = await axios.post(`${API_URL}/saveFormImage`, formData)
    // toastfySuccess(t("registeredSuccessfully"));
    // return response.data;
    console.log(formData);
    // Retorno: id: (uuid); // id da imagem
  } catch (error) {
    console.error(error);
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

// Export const saveVideo = async (key: string, file: File) => {
//   // Objetivo: salvar video (1 video por vez)
//   try {
//     const formData = new FormData();
//     formData.append(key, file);
//     const response = await axios.post(`${API_URL}/saveFormVideo`, formData)
//     toastfySuccess('Video salvo com sucesso');
//     return response.data;
//     // retorno: id: (uuid); // id do video
//   }
//   catch (error) {
//     console.error(error);
//      toastfyError(t(ToastifyModel().toastifyMessage.error));
//   }
// }

// export const createObservation = async (data: {
//   formId: string;
//   type: string;
//   description: string;
// }) => {
//   // Objetivo: salvar observação da ficha
//   try {
//     const params = {
//       formId: data.formId,
//       type: data.type,
//       description: data.description
//     };
//     // const response = await axios.post(`${API_URL}/saveFormObservation`, params)
//     // toastfySuccess(t("registeredSuccessfully"));
//     // return response.data;
//     console.log(params);
//     // retorno: id: (uuid); // id da observação
//   } catch (error) {
//     console.error(error);
//     toastfyError(t(ToastifyModel().toastifyMessage.error));
//   }
// };

export const sendActivityFile = async (
  formData: FormData,
  url: string,
  recordId: string,
  token: string
) => {
  try {
    if (!formData) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }

    toastifyLoading(`${t('registering')}...`);
    const response = await fetch(`${API_URL}${url}${recordId}`, {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: token
      }
    });
    if (response.status === 200) {
      toastfyDimiss('toastLoading');
      toastfySuccess(`${t('registeredSuccessfully')}!`);
    }

    return response.status;
  } catch (error) {
    console.error(error);
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const sendPhotoFile = async (
  formData: FormData,
  url: string,
  recordId: string,
  token: string
) => {
  try {
    if (!formData) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }

    toastifyLoading(`${t('registering')}...`);
    const response = await axios.post(`${API_URL}${url}${recordId}`, formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: token
      }
    });
    if (response.status === 200) {
      toastfyDimiss('toastLoading');
      toastfySuccess(`${t('registeredSuccessfully')}!`);
    }

    return response;
  } catch (error) {
    console.error(error);
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const sendVideoFile = async (
  formData: FormData,
  url: string,
  recordId: string,
  token: string
) => {
  try {
    if (!formData) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }

    toastifyLoading(`${t('registering')}...`);
    const response = await fetch(`${API_URL}${url}${recordId}`, {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: token
      }
    });
    if (response.status === 201) {
      toastfyDimiss('toastLoading');
      toastfySuccess(`${t('registeredSuccessfully')}!`);
    }

    return response.status;
  } catch (error) {
    console.error(error);
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export function useFindGeneralInfo(
  atoId: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    FindGeneralInfoQuery,
    FindGeneralInfoQueryVariables
  >(FindGeneralInfoDocument, {
    variables: {
      atoId: atoId
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export const useRecordApprovalFlow = (
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [recordApprovalFlow, { data, error, loading }] = useMutation<
    RecordApprovalFlowMutation,
    RecordApprovalFlowMutationVariables
  >(RecordApprovalFlowDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    recordApprovalFlow,
    data,
    error,
    loading
  };
};

export const createWaetherCondition = async (data: {
  formId: string;
  weather: string;
  start: Date;
  end: string;
}) => {
  // Objetivo: salvar condição climática
  try {
    const params = {
      formId: data.formId,
      weather: data.weather,
      start: data.start,
      end: data.end
    };
    // Const response = await axios.post(`${API_URL}/saveFormWeatherCondition`, params)
    // toastfySuccess(t("registeredSuccessfully"));
    // return response.data;
    console.log(params);
    // Retorno: id: (uuid); // id da condição climática
  } catch (error) {
    console.error(error);

    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};
