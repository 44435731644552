import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type PageInfoQueryVariables = Types.Exact<{
  gistmId: Types.Scalars['String']['input'];
  structureId: Types.Scalars['String']['input'];
  pageInfo?: Types.InputMaybe<Types.PageInput>;
}>;


export type PageInfoQuery = { __typename?: 'Query', findGistmFilesPag: { __typename?: 'PaginatedGistmFilesType', count: number, pageInfo: { __typename?: 'PageInfo', totalPages: number, page: number, offset: number, limit: number, hasPreviousPage: boolean, hasNextPage: boolean }, nodes: Array<{ __typename?: 'GistmFileType', id: string, fileName: string, file: string, createdAt: Date }> } };


export const PageInfoDocument = gql`
    query PageInfo($gistmId: String!, $structureId: String!, $pageInfo: PageInput) {
  findGistmFilesPag(
    gistmId: $gistmId
    structureId: $structureId
    pageInfo: $pageInfo
  ) {
    pageInfo {
      totalPages
      page
      offset
      limit
      hasPreviousPage
      hasNextPage
    }
    nodes {
      id
      fileName
      file
      createdAt
    }
    count
  }
}
    `;
export type PageInfoQueryResult = Apollo.QueryResult<PageInfoQuery, PageInfoQueryVariables>;