import { useTranslation } from 'react-i18next';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import AsideMenu from '../../Controllers/AsideMenuController';
import Toggles from '../Buttons/Toggles/Toggles';
import {
  DefaultAsideMenu,
  DefaultMenuItem,
  DivToggle,
  HolderTitle
} from './MenuStyle';

export const AsideInstrumentsMenu = ({
  selectedItem,
  setSelectedItem,
  title,
  items,
  setStatus,
  status
}: any) => {
  const { t } = useTranslation();

  return (
    <DefaultAsideMenu>
      <HolderTitle>
        <span>{t(title)}</span>
      </HolderTitle>
      {items?.map((v: any, i: number) => (
        <DefaultMenuItem
          key={i}
          onClick={(e) => {
            AsideMenu.SelectItem({
              selectedItem: v.id,
              setSelectedItem,
              item: selectedItem
            });
          }}
        >
          {selectedItem === v.id ? (
            <MdRadioButtonChecked />
          ) : (
            <MdRadioButtonUnchecked />
          )}{' '}
          <span>{v.label}</span>{' '}
        </DefaultMenuItem>
      ))}
      {setStatus && (
        <DivToggle>
          {Toggles.ToggleStatus({
            check: status,
            setCheck: setStatus,
            key: 'status',
            text: t('active'),
            updateValue: () => {}
          })}
        </DivToggle>
      )}
    </DefaultAsideMenu>
  );
};
