import { Dispatch } from 'react';

interface axisType {
  type: string;
  position: string;
  display: boolean;
  title: {
    display: boolean;
    text: string;
    position: string;
    align: string;
  };
}

interface initialSelectedAxisType {
  [key: string]: axisType;
}

interface Action {
  type: 'axis/addAxis' | 'axis/removeAxis';
  payload: any;
}

export const initialSelectedAxis: initialSelectedAxisType = {};

export const selectedAxisReducer = (
  state = initialSelectedAxis,
  action: Action
) => {
  switch (action.type) {
    case 'axis/addAxis':
      return {
        ...state,
        ...action.payload
      };
    case 'axis/removeAxis':
      // eslint-disable-next-line no-case-declarations
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    default:
      return state;
  }
};

export const addAxis = (
  dispatch: Dispatch<Action>,
  newAxis: initialSelectedAxisType
) => {
  dispatch({ type: 'axis/addAxis', payload: newAxis });
};

export const removeAxis = (
  dispatch: Dispatch<Action>,
  axisToRemove: string
) => {
  dispatch({ type: 'axis/removeAxis', payload: axisToRemove });
};
