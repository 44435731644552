import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { AtoObservationTypeEnum } from '../../../../data/graphql/base-schema';
import {
  DeleteRecordObservationDocument,
  DeleteRecordObservationMutation,
  DeleteRecordObservationMutationVariables
} from '../../../../data/graphql/generated/deleteRecordObservation';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AtoObservationForm from '../AtoObservationForm/AtoObservationForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  InspectionObservationsItemType,
  InspectionObservationsProps,
  InspectionObservationsType,
  RowToEditType
} from './CardAtoInfoFiscalization.interface';

export const CardAtoInfoFiscalization = ({
  inspectionObservations
}: InspectionObservationsProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [deleteRecordObservation] = useMutation<
    DeleteRecordObservationMutation,
    DeleteRecordObservationMutationVariables
  >(DeleteRecordObservationDocument);
  const [rowToEdit, setRowToEdit] = useState<RowToEditType>();
  const [idForDelete, setIdForDelete] = useState<string>('');

  const columnsData = [
    {
      key: 'indexId',
      label: 'id'
    },
    {
      key: 'observation',
      label: t('observation')
    }
  ];

  const tableData = inspectionObservations.map(
    (item: InspectionObservationsType, index) => ({
      observation: item.observation,
      id: item.id,
      indexId: index
    })
  );

  const actions = [
    {
      name: t('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => handleEditRow(row),
      canShow: 'Edit'
    },
    {
      name: t('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'Delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRecordObservation({
      variables: {
        data: {
          type: 'InspectionObservation' as AtoObservationTypeEnum,
          id: id
        }
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
        setUpdateDelete(!updateDelete);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessage);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            inspectionObservations:
              existingData.findDataByRecord.inspectionObservations.filter(
                (item: InspectionObservationsItemType) => item.id !== id
              )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setRowToEdit(row);
    setShowEditModal(true);
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  return (
    <>
      <CardAtoInfo
        title={t('fiscalizationObservations')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={columnsData}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      {showModal && (
        <ViewModal
          width={40}
          height={50}
          title={t('addInspectionObservation')}
          onClose={() => setShowModal(false)}
          component={
            <AtoObservationForm
              setShowModal={setShowModal}
              recordId={recordId?.toString() || ''}
              type={'InspectionObservation' as AtoObservationTypeEnum}
            />
          }
        />
      )}
      {showEditModal && (
        <ViewModal
          width={40}
          height={50}
          title={t('editInspectionObservation')}
          onClose={() => setShowEditModal(false)}
          component={
            <AtoObservationForm
              setShowModal={setShowEditModal}
              recordId={recordId?.toString() || ''}
              type={'InspectionObservation' as AtoObservationTypeEnum}
              editObservation={{
                id: rowToEdit?.id || '',
                observation: rowToEdit?.observation || '',
                type: 'InspectionObservation'
              }}
            />
          }
        />
      )}
      {idForDelete && (
        <ModalConfirm
          title={`${t('Delete')} ${t('Activity')}`}
          text={t('ConfirmDelete')}
          subtitle={t('ActionCannotBeUndone')}
          onClose={() => {
            setIdForDelete('');
          }}
          onConfirm={() => {
            handleDeleteRow(idForDelete);
            setIdForDelete('');
          }}
        />
      )}
    </>
  );
};
