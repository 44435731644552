import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const Container = styled.div`
  background-color: ${Color.White};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  overflow-x: hidden;
`;

export const DivContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DivTitle = styled.div`
  display: flex;
  justify-content: start;
`;

export const DivHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 90%;
`;
