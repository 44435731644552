import styled from 'styled-components';
import { Color } from '../../Styles/Styles';
export const HolderButtonsMonitoringScreen = styled.div`
  background-color: transparent;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 220px;
  padding-bottom: 5px;
  padding-right: 3px;
`;

export const MonitoringScreenButtonsStyled = styled.div`
  background-color: ${Color.Brown1};
  z-index: 1;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  width: 90%;
  margin-top: 5px;
  border-radius: 5px;
`;

export const AdvanceButtonStyled = styled.button`
  width: 150px;
  height: 50px;
  border: none;
  background-color: ${Color.Brown1};
  color: ${Color.White};
  cursor: pointer;
`;

export const CancelButtonStyled = styled.button`
  width: 150px;
  height: 50px;
  background-color: ${Color.White};
  border: none;
  cursor: pointer;
  color: ${Color.Brown2};
`;

export const TextButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${Color.Brown1};
  width: max-content;
  position: absolute;
  bottom: -10px;
  right: 40px;
`;

export const DefaultButtonStyle = styled.button`
  background-color: ${Color.Brown1};
  border: none;
  cursor: pointer;
  color: ${Color.White};
  padding: 8px 15px 8px 15px;
  border-radius: 5px;
  user-select: none;
`;
