import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import {
  DeleteRecordWeatherConditionsDocument,
  DeleteRecordWeatherConditionsMutation,
  DeleteRecordWeatherConditionsMutationVariables
} from '../../../../data/graphql/generated/deleteRecordWeatherConditions';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import CardAtoInfo from '../CardInfo/CardInfo';
import WeatherConditionForm from '../WeatherConditionForm/WeatherConditionForm';
import {
  CardAtoInfoWeatherProps,
  RowToEditType,
  WeatherConditionItemType,
  WeatherConditionsType
} from './CardAtoInfoWeather.interface';

export const CardAtoInfoWeather = ({ weather }: CardAtoInfoWeatherProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [deleteRecordWeatherConditions] = useMutation<
    DeleteRecordWeatherConditionsMutation,
    DeleteRecordWeatherConditionsMutationVariables
  >(DeleteRecordWeatherConditionsDocument);
  const [rowToEdit, setRowToEdit] = useState<RowToEditType>();
  const [idForDelete, setIdForDelete] = useState('');

  const tableColumns = [
    {
      key: 'indexId',
      label: 'id'
    },
    {
      key: 'condition',
      label: t('condition')
    },
    {
      key: 'start',
      label: t('start')
    },
    {
      key: 'End',
      label: t('End')
    }
  ];

  const tableData = weather.map((item: WeatherConditionsType, index) => ({
    End: item.end,
    start: item.start,
    condition: item.weather,
    id: item.id,
    indexId: index
  }));

  const actions = [
    {
      name: t('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => {
        handleEditRow(row);
      },
      canShow: 'edit'
    },
    {
      name: t('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRecordWeatherConditions({
      variables: {
        deleteRecordWeatherConditionsId: id
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
        setUpdateDelete(!updateDelete);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessage);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            weatherConditions:
              existingData.findDataByRecord.weatherConditions.filter(
                (item: WeatherConditionItemType) => item.id !== id
              )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setRowToEdit(row);
    setShowEditModal(true);
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  return (
    <>
      <CardAtoInfo
        title={t('weatherCondition')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={tableColumns}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      {showModal && (
        <ViewModal
          width={40}
          height={50}
          title={t('addWeatherCondition')}
          onClose={() => setShowModal(false)}
          component={
            <WeatherConditionForm
              setShowModal={setShowModal}
              recordId={recordId?.toString() || ''}
            />
          }
        />
      )}
      {showEditModal && (
        <ViewModal
          width={40}
          height={50}
          title={t('editWeatherCondition')}
          onClose={() => setShowEditModal(false)}
          component={
            <WeatherConditionForm
              setShowModal={setShowEditModal}
              recordId={recordId?.toString() || ''}
              idWeatherCondition={rowToEdit?.id}
            />
          }
        />
      )}
      {idForDelete && (
        <ModalConfirm
          title={`${t('Delete')} ${t('Activity')}`}
          text={t('ConfirmDelete')}
          subtitle={t('ActionCannotBeUndone')}
          onClose={() => {
            setIdForDelete('');
          }}
          onConfirm={() => {
            handleDeleteRow(idForDelete);
            setIdForDelete('');
          }}
        />
      )}
    </>
  );
};
