import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { ActionsRow } from '../../../Components/Tables/TableInstrumentComponent/styles';
import { TableComponentStyle } from '../../../Components/Tables/TablesStyle';
import { toastfyDimiss, toastifyLoading } from '../../../Components/Toastify';
import { useDeleteParticipant } from '../../../data/hooks/use-delete-participant';
import { Participant } from './AddParticipantComponent';

interface Props {
  subParticipants: Participant[];
  setSubParticipants: Dispatch<SetStateAction<any[]>>;
  editing: boolean;
}

export const AttributionInspectionTable = ({
  subParticipants,
  setSubParticipants,
  editing
}: Props) => {
  const { t } = useTranslation();
  const { deleteParticipant } = useDeleteParticipant();

  const handleDelete = async (participant: { name: string; id: string }) => {
    const response = subParticipants.filter((e) => e !== participant);
    setSubParticipants(response);

    if (editing && participant.id !== undefined) {
      const response = await deleteParticipant({
        variables: {
          participantId: participant.id
        }
      });
      toastifyLoading('Deletando participante...');
      if (response.data) {
        toastfyDimiss('toastLoading');
      }
    }
  };

  return (
    <>
      {subParticipants.length === 0 && (
        <div style={{ height: 'auto', overflowY: 'auto', width: '100%' }}>
          <TableComponentStyle>
            <thead style={{ position: 'sticky', top: 0 }}>
              <tr>
                <th>{t('Participants')}</th>
              </tr>
            </thead>
            <tbody>
              {subParticipants.map((participant, index) => (
                <tr key={index}>
                  <td>{participant.name}</td>
                </tr>
              ))}
            </tbody>
          </TableComponentStyle>
        </div>
      )}
      {subParticipants.length !== 0 && (
        <div style={{ height: '120px', overflowY: 'auto', width: '100%' }}>
          <TableComponentStyle>
            <thead style={{ position: 'sticky', top: 0 }}>
              <tr>
                <th>{t('Participants')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {subParticipants.map((participant, index) => (
                <tr key={index}>
                  <td>{participant.name}</td>
                  <td>
                    <ActionsRow>
                      <MdDelete
                        onClick={() => {
                          handleDelete(participant);
                        }}
                      />
                    </ActionsRow>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableComponentStyle>
        </div>
      )}
    </>
  );
};
