import React from 'react';
import { useTranslation } from 'react-i18next';
import { InstrumentButtonInterface } from '../../../@Types/Instruments/instruments';
import ReadingsController from '../../../Controllers/Instruments/ReadingsController';
import { MonitoringScreenButtonsStyled } from '../ButtonsStyle';

export const ReadingsButton = ({
  showModal,
  setShowModal
}: InstrumentButtonInterface) => {
  const { t } = useTranslation();

  return (
    <MonitoringScreenButtonsStyled
      onClick={() => ReadingsController.ShowModal({ showModal, setShowModal })}
    >
      {`${t('Reading')}s`}
    </MonitoringScreenButtonsStyled>
  );
};
