import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { getActionPlanHistoryData } from '../../../../data/services/ActionPlanHistoryService';
import Icon from '../../Atoms/Icon/Icon';
import Image from '../../Atoms/Image/Image';
import Text from '../../Atoms/Text/Text';
import FileViewer from '../../Molecules/FileViewer/FileViewer';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputText from '../../Molecules/InputText/InputText';
import { SeeMoreTemplate } from '../../Templates/SeeMoreTemplate/SeeMoreTemplate';
import { ActionPlansData } from './ActionPlanHistoryPage.interface';
import {
  DivContainerDescription,
  DivContainerFilterHistory,
  DivContentDescription,
  DivContentDescriptionTitle,
  DivImages
} from './ActionPlanHistoryPage.styles';
const ActionPlanHistoryPage = () => {
  const { t } = useTranslation();
  const [historyData, setHistoryData] = useState<ActionPlansData[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileType | null>(null);
  const [files, setFiles] = useState<FileType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInfos();
    // TODO: remover const timer e inserir response
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const fetchInfos = async () => {
    const res = await getActionPlanHistoryData();
    setHistoryData(res);
  };

  const handleNavigateBack = () => {
    navigate('/actionplan/1');
  };

  return (
    <>
      {isLoading ? (
        <SeeMoreTemplate
          icon={<Skeleton height={35} width={400} />}
          title={''}
          header={<Skeleton height={25} width={1289} />}
          content={<Skeleton height={500} width={1289} />}
        />
      ) : (
        <SeeMoreTemplate
          icon={
            <Icon Icon={IoIosArrowBack} onClick={() => handleNavigateBack()} />
          }
          title={t('history')}
          header={
            //TODO: pegar filtos da url
            <DivContainerFilterHistory>
              <InputText
                label={t('name')}
                name="name"
                type="text"
                width="300px"
              />
              <InputText
                label={t('responsible')}
                name="responsible"
                type="text"
                width="300px"
              />
            </DivContainerFilterHistory>
          }
          content={historyData.map((plan, index) => {
            return (
              <DivContainerDescription key={index}>
                <DivContentDescriptionTitle>
                  <Text type="h5" size="medium" weight="bold" color="brown">
                    {plan.title}
                  </Text>
                  <Text type="span" size="small">
                    {new Date(plan.data).toLocaleDateString('pt-br')}
                  </Text>
                </DivContentDescriptionTitle>
                <DivContentDescription>
                  <DivImages>
                    {plan.pictures.map((picture, index) => (
                      <Image
                        alt={picture.url}
                        src={picture.url}
                        onClick={() => {
                          setSelectedFile(picture);
                          setFiles(plan.pictures);
                        }}
                        key={index}
                        width="150px"
                      />
                    ))}
                  </DivImages>
                  <Text type="span" size="medium" color="black">
                    {plan.description}
                  </Text>
                </DivContentDescription>
              </DivContainerDescription>
            );
          })}
        />
      )}
      {selectedFile && (
        <FileViewer
          files={files}
          setSelectedFile={setSelectedFile}
          onClose={() => setSelectedFile(null)}
          selectedFile={selectedFile}
        />
      )}
    </>
  );
};

export default ActionPlanHistoryPage;
