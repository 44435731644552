import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { FaFilter } from 'react-icons/fa';
// import { FaFilterCircleXmark } from 'react-icons/fa6';
import Icon from '../../Atoms/Icon/Icon';
import Paginator from '../../Molecules/Paginator/Paginator';
// import FilterTable from './FilterTable';
import {
  DivContainer,
  DivTh,
  // PopupFilterContainer,
  TableBody,
  TableContainer,
  TableHead,
  TableStyle,
  TableTd,
  TableTh,
  TableTr
} from './TableWithFilterAndPaginatorStyled';
import {
  DataHeaderItem,
  // FiltersState,
  orderByType,
  TableWithFilterAndPaginatorProps
} from './tableWithFilterAndPaginatorTypes';
import Text from '../../Atoms/Text/Text';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

// TODO: Verificar se irá implementar outra forma de filtro

const TableWithFilterAndPaginator = ({
  data,
  dataHeader,
  totalPages,
  nextPage,
  previousPage,
  currentPage,
  countForPage,
  setCountForPage,
  onChangeFilters,
  actions,
  paginator,
  optionsPaginator,
  onClickRow,
  onChangeOrderBy
}: TableWithFilterAndPaginatorProps) => {
  // const [showSearch, setShowSearch] = useState<boolean>(false);
  // const [activeFilter, setActiveFilter] = useState<DataHeaderItem | null>(null);
  // const [popupPosition, setPopupPosition] = useState<{
  //   top: number;
  //   left: number;
  // }>({ top: 0, left: 0 });
  const [columnOrder, setColumnOrder] = useState<orderByType[]>(
    dataHeader.map((item) => ({
      name: item.name,
      order: 'asc'
    }))
  );
  // const [filters, setFilters] = useState<FiltersState>({});
  const { t } = useTranslation();
  const divContainerRef = useRef<HTMLDivElement>(null);
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];

  // const handleFilter = (item: DataHeaderItem, event: React.MouseEvent) => {
  //   event.stopPropagation();
  //   setActiveFilter(item);
  //   setShowSearch(true);
  //   const rect = event.currentTarget.getBoundingClientRect();
  //   const containerRect = divContainerRef.current?.getBoundingClientRect();
  //   if (containerRect) {
  //     setPopupPosition({ top: containerRect.y + 50, left: rect.x - 100 });
  //   }
  // };

  // const applyFilter = (
  //   filterValue: string | string[] | { startDate: string; endDate: string }
  // ) => {
  //   if (!activeFilter || !onChangeFilters) return;
  //   const newFilters = { ...filters, [activeFilter.name]: filterValue };
  //   setFilters(newFilters);
  //   onChangeFilters(newFilters);
  //   setShowSearch(false);
  // };

  // const removeFilter = (filterKey: string) => {
  //   if (!filters || !onChangeFilters) return;
  //   const { [filterKey]: _, ...newFilters } = filters;
  //   setFilters(newFilters);
  //   onChangeFilters(newFilters);
  //   setActiveFilter(null);
  // };

  const handleOrder = (item: DataHeaderItem) => {
    const newOrder: orderByType[] = columnOrder.map((order) => {
      if (order.name === item.name) {
        return {
          name: order.name,
          order: order.order === 'asc' ? 'desc' : 'asc'
        };
      }

      return order;
    });
    setColumnOrder(newOrder);
    if (onChangeOrderBy) onChangeOrderBy(newOrder);
  };

  return (
    <>
      <DivContainer>
        <TableContainer
          ref={divContainerRef}
          // onClick={() => setShowSearch(false)}
        >
          <TableStyle>
            <TableHead>
              <TableTr>
                {dataHeader.map((item, index) => (
                  <TableTh
                    key={index}
                    onClick={() => handleOrder(item)}
                    orderBy={!!onChangeOrderBy}
                  >
                    <DivTh>
                      {t(item.name)}
                      {onChangeOrderBy &&
                        (columnOrder.find((order) => order.name === item.name)
                          ?.order === 'asc' ? (
                          <Icon Icon={IoIosArrowUp} variant="small" />
                        ) : (
                          <Icon Icon={IoIosArrowDown} variant="small" />
                        ))}
                    </DivTh>

                    {/* {onChangeFilters &&
                      (filters[item.name] ? (
                        <Icon
                          Icon={FaFilterCircleXmark}
                          variant="small"
                          onClick={(event) => handleFilter(item, event)}
                        />
                      ) : (
                        item.filter && (
                          <Icon
                            Icon={FaFilter}
                            variant="small"
                            onClick={(event) => handleFilter(item, event)}
                          />
                        )
                      ))} */}
                  </TableTh>
                ))}
                {actions && <TableTh>{t('actions')}</TableTh>}
              </TableTr>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableTr key={index}>
                  {dataHeader.map((header, headerIndex) => (
                    <TableTd
                      key={headerIndex}
                      onClick={() => {
                        onClickRow && onClickRow(item);
                      }}
                      clickRow={!!onClickRow}
                    >
                      {item[header.name] || '-'}
                    </TableTd>
                  ))}
                  {actions && (
                    <TableTd className="action">
                      {actions.map((action, index) => (
                        <Icon
                          key={index}
                          Icon={action.icon}
                          variant="small"
                          onClick={() => action.onClick(item)}
                        />
                      ))}
                    </TableTd>
                  )}
                </TableTr>
              ))}
            </TableBody>
          </TableStyle>
        </TableContainer>
        {paginator && (
          <Paginator
            totalPages={totalPages}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            countForPage={countForPage}
            setCountForPage={setCountForPage}
            options={optionsPaginator || optionsPaginatorDefault}
          />
        )}
      </DivContainer>
      {/* {onChangeFilters && showSearch && activeFilter && (
        <PopupFilterContainer
          style={{ top: popupPosition.top, left: popupPosition.left }}
        >
          <FilterTable
            filter={activeFilter.filter}
            data={data}
            applyFilter={applyFilter}
            activeFilter={activeFilter}
            filters={filters}
            removeFilter={removeFilter}
          />
        </PopupFilterContainer>
      )} */}
    </>
  );
};

export default TableWithFilterAndPaginator;
