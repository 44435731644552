import { CardTableTemplateProps } from './CardTableTemplate.interface';
import {
  Container,
  DivContent,
  DivHeader,
  DivTitle
} from './CardTableTemplate.styles';

export function CardTableTemplate({
  title,
  button,
  cards,
  content
}: CardTableTemplateProps) {
  return (
    <Container>
      <DivHeader>
        <DivTitle>{title}</DivTitle>
        <div>{button}</div>
      </DivHeader>
      <DivContent>{cards}</DivContent>
      <DivContent>{content}</DivContent>
    </Container>
  );
}

export default CardTableTemplate;
