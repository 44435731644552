import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InstrumentSelectInterface } from '../../../../@Types/Instruments/instruments';
import { StructureSelectType } from '../../../../@Types/types';
import { useReducerHook } from '../../../../Hooks/ReducerHook';
import { InstrumentsType } from '../../../../Models/InstrumentsModel';
import { ReadingsModel } from '../../../../Models/Readings/ReadingsModel';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { useCreatePiezometerReading } from '../../../../data/hooks/piezometer/use-create-piezometer-reading.mutation';
import { useListPiezometersByStructureLazy } from '../../../../data/hooks/piezometer/use-list-piezometers-by-structure.query';
import { useCreatePluviometerReading } from '../../../../data/hooks/pluviometer/use-create-pluviometer-reading.mutation';
import { useListPluviometersByStructureLazy } from '../../../../data/hooks/pluviometer/use-list-pluviometers-by-structure';
import { useCreateSurfaceMarkerReading } from '../../../../data/hooks/surface-marker/use-create-surface-marker-reading.mutation';
import { useListSurfaceMarkersByStructureLazy } from '../../../../data/hooks/surface-marker/use-list-surface-markers-by-structure.query';
import { useCreateWaterLevelReading } from '../../../../data/hooks/water-level/use-create-water-level-reading.mutation';
import { useListWaterLevelByStructureLazy } from '../../../../data/hooks/water-level/use-list-water-level-by-structure';
import { AdvanceButtonStyled } from '../../../Buttons/ButtonsStyle';
import { CancelButtonComponent } from '../../../Buttons/Instruments/CancelButton';
import Toggles from '../../../Buttons/Toggles/Toggles';
import { DatePickerComponent } from '../../../DatePicker';
import GraphHeader from '../../../Graphs/Monitoring/Instrument/GraphHeader';
import { DefaultInput } from '../../../Inputs/InputsStyle';
import InstrumentSelects from '../../../Selects/Instruments/InstrumentsSelects';
import { TextAreaComponent } from '../../../TextAreaComponent';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../../Register/RegisterInstrumentStyle';
import { DataBaseButtonArea, RegisterReadingsArea } from '../DataBaseStyles';

export const RegisterReadingsModal = ({
  type,
  showModal,
  setShowModal,
  createdReading,
  setCreatedReading,
  setCountPie,
  setCountPlu,
  setCountWL,
  setCountSM
}: {
  type: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  createdReading: boolean;
  setCreatedReading: Dispatch<SetStateAction<boolean>>;
  setCountPie: Dispatch<SetStateAction<number>>;
  setCountPlu: Dispatch<SetStateAction<number>>;
  setCountWL: Dispatch<SetStateAction<number>>;
  setCountSM: Dispatch<SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const { structureId } = useParams();
  const [instrumentSelected, setInstrumentSelected] =
    useState<InstrumentSelectInterface | null>(null);
  const [isDry, setIsDry] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const [observations, setObservations] = useState<string>('');
  const { state, updateValue } = useReducerHook({});
  const [instrumentList, setInstrumentList] = useState<any[]>([]);
  const { createReading: createReadingPiezometer } =
    useCreatePiezometerReading();
  const { createReading: createReadingPluviometer } =
    useCreatePluviometerReading();
  const { createReading: createReadingWaterLevel } =
    useCreateWaterLevelReading();
  const { createReading: createReadingSurfaceMarker } =
    useCreateSurfaceMarkerReading();
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const { listPiezometers } = useListPiezometersByStructureLazy();
  const { listPluviometers } = useListPluviometersByStructureLazy();
  const { listWaterLevels } = useListWaterLevelByStructureLazy();
  const { listSurfaceMarkers } = useListSurfaceMarkersByStructureLazy();

  const isReadingValid = state.Reading !== undefined && state.Reading !== '';
  const isQuotaValid = state.Quota !== undefined && state.Quota !== '';

  async function handleFetchPiezometers() {
    const response = await listPiezometers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listPiezometersByStructure);
    }
  }

  async function handleFetchPluviometers() {
    const response = await listPluviometers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listPluviometersByStructure);
    }
  }

  async function handleFetchWaterLevels() {
    const response = await listWaterLevels({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listWaterLevelByStructure);
    }
  }

  async function handleFetchSurfaceMarkers() {
    const response = await listSurfaceMarkers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listSurfaceMarkersByStructure);
    }
  }

  const handleFetchSelectedInstrumentList = (type: string) => {
    const strategy = {
      piezometer: () => handleFetchPiezometers(),
      ina: () => handleFetchPiezometers(),
      pluviometer: () => handleFetchPluviometers(),
      waterlevel: () => handleFetchWaterLevels(),
      surfacemarker: () => handleFetchSurfaceMarkers()
    };

    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  async function handleSavePiezometer() {
    if (
      instrumentSelected?.label &&
      (state.Quota || state.Reading) &&
      (state.Quota !== '' || state.Reading !== '')
    ) {
      const response = await createReadingPiezometer({
        variables: {
          structureInfo: structureInfo,
          data: {
            cote:
              state.Quota && state.Quota !== '' ? Number(state.Quota) : null,
            date: state.date,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            isDry: state.isDry,
            observation: state.observations,
            reading:
              state.Reading && state.Reading !== ''
                ? Number(state.Reading)
                : null
          }
        }
      });
      toastifyLoading('Cadastrando Leitura...');
      if (response.data?.createPiezometerReading[0].error) {
        toastfyDimiss('toastLoading');
        const errormessage = response.data.createPiezometerReading[0].error;
        toastfyError(errormessage || t(ToastifyModel().toastifyMessage.error));
        return;
      }

      if (response.data) {
        toastfySuccess('Leitura Cadastrada Com Sucesso');
        setCountPie((prev) => prev + 1);
        setShowModal(false);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  }

  async function handleSavePluviometer() {
    if (instrumentSelected?.label && state.Reading) {
      const response = await createReadingPluviometer({
        variables: {
          structureInfo: structureInfo,
          data: {
            date: state.date,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: state.observations,
            rainfall: Number(state.Reading)
          }
        }
      });
      toastifyLoading('Cadastrando Leitura...');

      if (response.data?.createPluviometerReading[0].error) {
        toastfyDimiss('toastLoading');
        const errormessage = response.data.createPluviometerReading[0].error;
        toastfyError(errormessage || t(ToastifyModel().toastifyMessage.error));
        return;
      }

      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Leitura Cadastrada Com Sucesso');
        setCountPlu((prev) => prev + 1);
        setShowModal(false);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  }

  async function handleSaveWaterLevel() {
    if (instrumentSelected?.label) {
      const response = await createReadingWaterLevel({
        variables: {
          structureInfo: structureInfo,
          data: {
            date: state.date,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: state.observations,
            value: Number(state.Reading)
          }
        }
      });
      toastifyLoading('Cadastrando Leitura...');

      if (response.data?.createWaterLevelReading[0].error) {
        toastfyDimiss('toastLoading');
        const errormessage = response.data.createWaterLevelReading[0].error;
        toastfyError(errormessage || t(ToastifyModel().toastifyMessage.error));
        return;
      }

      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Leitura Cadastrada Com Sucesso');
        setShowModal(false);
        setCountWL((prev) => prev + 1);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  }

  async function handleSaveSurfaceMarker() {
    if (instrumentSelected?.label) {
      const response = await createReadingSurfaceMarker({
        variables: {
          structureInfo: structureInfo,
          data: {
            date: state.date,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: state.observations,
            coordinateE: Number(state.N),
            coordinateN: Number(state.E),
            elevation: Number(state.Elevation)
          }
        }
      });
      toastifyLoading('Cadastrando Leitura...');

      if (response.data?.createSurfaceMarkersReading[0].error) {
        toastfyDimiss('toastLoading');
        const errormessage = response.data.createSurfaceMarkersReading[0].error;
        toastfyError(errormessage || t(ToastifyModel().toastifyMessage.error));
        return;
      }

      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Leitura Cadastrada Com Sucesso');
        setShowModal(false);
        setCountSM((prev) => prev + 1);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  }

  const handleSaveReading = (type: string) => {
    const strategy = {
      piezometer: () => handleSavePiezometer(),
      ina: () => handleSavePiezometer(),
      pluviometer: () => handleSavePluviometer(),
      waterlevel: () => handleSaveWaterLevel(),
      surfacemarker: () => handleSaveSurfaceMarker()
    };

    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  useEffect(() => {
    handleFetchSelectedInstrumentList(type);
  }, []);

  useMemo(() => {
    if (
      (type as InstrumentsType) === 'Piezometer' ||
      (type as InstrumentsType) === 'INA'
    ) {
      updateValue('isDry', isDry);
      updateValue('observations', observations);
    }
  }, [isDry, observations]);

  useMemo(() => {
    updateValue('date', date);
  }, [date]);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '50%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '300px'
        }}
      >
        <GraphHeader
          showModal={showModal}
          setShowModal={setShowModal}
          title="RegisterReadings"
          subtitle={type}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RegisterReadingsArea>
            {InstrumentSelects.InstrumentsName({
              width: 79,
              placeholder: t('Instruments'),
              options: instrumentList.map((e) => ({
                label: t(`${e.name}`),
                value: e.id,
                id: e.id,
                instrumentType: e.type.instrumentType
              })),
              setSelected: setInstrumentSelected,
              selected: instrumentSelected
            })}
            {(() => {
              switch (type as InstrumentsType) {
                case 'Piezometer':
                  return (
                    <>
                      {new ReadingsModel().piezometer.inputs.map((input, i) => {
                        if (input === 'Reading') {
                          return (
                            <DefaultInput
                              onChange={(e) =>
                                updateValue(input, e.target.value)
                              }
                              key={i}
                              type="number"
                              placeholder={t(input)}
                              disabled={isQuotaValid}
                            />
                          );
                        }

                        if (input === 'Quota') {
                          return (
                            <DefaultInput
                              onChange={(e) =>
                                updateValue(input, e.target.value)
                              }
                              key={i}
                              type="number"
                              placeholder={t(input)}
                              disabled={isReadingValid}
                            />
                          );
                        }
                      })}
                      <div
                        style={{ width: '76%', marginTop: 0, marginBottom: 13 }}
                      >
                        {Toggles.ToggleStatus({
                          check: isDry,
                          setCheck: setIsDry,
                          key: 'isDry',
                          updateValue: updateValue,
                          text: 'Dry'
                        })}
                      </div>
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {t('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{t('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'INA':
                  return (
                    <>
                      {new ReadingsModel().piezometer.inputs.map((input, i) => {
                        if (input === 'Reading') {
                          return (
                            <DefaultInput
                              onChange={(e) =>
                                updateValue(input, e.target.value)
                              }
                              key={i}
                              type="number"
                              placeholder={t(input)}
                              disabled={isQuotaValid}
                            />
                          );
                        }

                        if (input === 'Quota') {
                          return (
                            <DefaultInput
                              onChange={(e) =>
                                updateValue(input, e.target.value)
                              }
                              key={i}
                              type="number"
                              placeholder={t(input)}
                              disabled={isReadingValid}
                            />
                          );
                        }
                      })}
                      <div
                        style={{ width: '76%', marginTop: 0, marginBottom: 13 }}
                      >
                        {Toggles.ToggleStatus({
                          check: isDry,
                          setCheck: setIsDry,
                          key: 'isDry',
                          updateValue: updateValue,
                          text: 'Dry'
                        })}
                      </div>
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {t('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{t('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'Pluviometer':
                  return (
                    <>
                      {' '}
                      {new ReadingsModel().pluviometer.inputs.map(
                        (input, i) => (
                          <DefaultInput
                            onChange={(e) => updateValue(input, e.target.value)}
                            key={i}
                            type="number"
                            placeholder={t(input)}
                          />
                        )
                      )}
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          height: '100px',
                          marginTop: 0,
                          marginBottom: 70
                        }}
                      >
                        {t('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{t('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'WaterLevel':
                  return (
                    <>
                      {' '}
                      {new ReadingsModel().reservoirWaterLevel.inputs.map(
                        (input, i) => (
                          <DefaultInput
                            onChange={(e) => updateValue(input, e.target.value)}
                            key={i}
                            type="number"
                            placeholder={t(input)}
                          />
                        )
                      )}
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginTop: 0,
                          marginBottom: 135
                        }}
                      >
                        {t('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{t('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'SurfaceMarker':
                  return (
                    <>
                      {' '}
                      {new ReadingsModel().surfaceLandmark.inputs.map(
                        (input, i) => (
                          <DefaultInput
                            onChange={(e) => updateValue(input, e.target.value)}
                            key={i}
                            type="number"
                            placeholder={t(input)}
                          />
                        )
                      )}
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          height: '120px',
                          marginTop: 0,
                          marginBottom: 50
                        }}
                      >
                        {t('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{t('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </RegisterReadingsArea>
        </div>
        <DataBaseButtonArea>
          <CancelButtonComponent
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <AdvanceButtonStyled
            onClick={() => {
              handleSaveReading(type);
            }}
          >
            {' '}
            {t('Save').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </DataBaseButtonArea>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
};
