import { useMutation } from '@apollo/client';
import { EditWaterLevelDocument } from '../../graphql/generated/editWaterLevel.mutation';
import {
  EditSurfaceMarkerDocument,
  EditSurfaceMarkerMutation,
  EditSurfaceMarkerMutationVariables
} from '../../graphql/generated/editSurfaceMarker.mutation';
import { ListSurfaceMarkersByStructureDocument } from '../../graphql/query/generated/listSurfaceMarkersByStructure.query';

export function useEditSurfaceMarker(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editSurfaceMarker, { data, error, loading }] = useMutation<
    EditSurfaceMarkerMutation,
    EditSurfaceMarkerMutationVariables
  >(EditSurfaceMarkerDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListSurfaceMarkersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editSurfaceMarker,
    data,
    error,
    loading
  };
}
// O hook acima edita as infos gerais do instrumento marco superficial, tais quais:
// activationDate?:
// coordinateE
// coordinateN
// deactivationDate?
// installLocation?
// installationDate?
// instrumentId
// name
// operationalStatus:
// readingType
// sectionsId
// tudo que está com (?) é opcional na chamada, é possível ver essas variáveis ao clicar em EditSurfaceMarkerMutationVariables. O objeto deve ser passado como:
// {
//   "data": {
//     "generalInfo": {
//       "sectionsId": ,
//       "readingType": ,
//       "operationalStatus": ,
//       "name": ,
//       "installLocation": ,
//       "installationDate": ,
//       "instrumentId": ,
//       "deactivationDate": ,
//       "coordinateN": ,
//       "coordinateE": ,
//       "activationDate":
//     }
//   }
// }
// marco superficial tem seção. Importante fazer a chamada de seções antes para poder por os sectionIds
