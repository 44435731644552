import { useTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Text from '../../Atoms/Text/Text';
import InputSelectSearch from '../InputSelectSearch/InputSelectSearch';
import { DivContainer, DivContent } from './Paginator.styles';
import Icon from '../../Atoms/Icon/Icon';
import { PaginatorProps } from './Paginator.interfaces';

const Paginator = ({
  totalPages,
  currentPage,
  nextPage,
  previousPage,
  countForPage,
  options,
  setCountForPage
}: PaginatorProps) => {
  const { t } = useTranslation();

  const handleNextPage = async () => {
    if (currentPage === totalPages) return;

    nextPage();
  };

  const handlePreviousPage = async () => {
    if (currentPage === 1) return;

    previousPage();
  };

  return (
    <DivContainer>
      <DivContent>
        <Icon
          Icon={IoIosArrowBack}
          onClick={handlePreviousPage}
          variant="small"
        />
        <Text type="span">
          {' '}
          {currentPage} {t('of')} {totalPages}{' '}
        </Text>
        <Icon
          Icon={IoIosArrowForward}
          onClick={handleNextPage}
          variant="small"
        />
        <Text type="span"> {`${t('linesPerPage')}:`} </Text>
        <InputSelectSearch
          label=""
          name="countForPage"
          options={options}
          hideSelectedOptions
          width="40px"
          value={countForPage}
          onChange={(value) => setCountForPage(value)}
        />
      </DivContent>
    </DivContainer>
  );
};

export default Paginator;
