import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { ButtonAreaDelete } from '../../../../Screens/AdminScreen/AdminScreenStyle';
import { useDeleteInstrumentReading } from '../../../../data/hooks/use-delete-instrument-reading';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../Buttons/ButtonsStyle';
import GraphHeader from '../../../Graphs/Monitoring/Instrument/GraphHeader';
import { DefaultInput } from '../../../Inputs/InputsStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../../Register/RegisterInstrumentStyle';
import { RegisterReadingsArea } from '../DataBaseStyles';
import { useParams } from 'react-router-dom';

export function DeleteReadingsModal({
  showModal,
  setShowModal,
  selectedType,
  deleting,
  setCreatedReading,
  createdReading,
  setData,
  data,
  setCountPie,
  setCountPlu,
  setCountWL,
  setCountSM
}: {
  createdReading: boolean;
  setCreatedReading: Dispatch<SetStateAction<boolean>>;
  deleting: { status: boolean; data: any };
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedType: string;
  setData: Dispatch<SetStateAction<any>>;
  data: any;
  setCountPie: Dispatch<SetStateAction<number>>;
  setCountPlu: Dispatch<SetStateAction<number>>;
  setCountWL: Dispatch<SetStateAction<number>>;
  setCountSM: Dispatch<SetStateAction<number>>;
}) {
  const { t } = useTranslation();
  const { structureId } = useParams();
  const { deleteInstrument } = useDeleteInstrumentReading();
  const [date, setDate] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [readingId, setReadingId] = useState<string>('');
  const [instrumentId, setInstrumentId] = useState<string>('');

  const handleDeleteReading = async () => {
    const res = data.filter((e: any) => e !== deleting.data);
    setData(res);
    const response = await deleteInstrument({
      variables: {
        structureId: structureId!,
        data: {
          readingId: readingId,
          instrumentId: instrumentId
        }
      }
    });
    toastifyLoading('Deletando Leitura...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      toastfySuccess('Leitura Deletada Com Sucesso');
      setShowModal(false);
      setCreatedReading(!createdReading);

      if (selectedType.toLocaleLowerCase() === 'surfacemarker') {
        setCountSM((prev) => prev - 1);
      }

      if (
        selectedType.toLocaleLowerCase() === 'piezometer' ||
        selectedType.toLocaleLowerCase() === 'ina'
      ) {
        setCountPie((prev) => prev - 1);
      }

      if (selectedType.toLocaleLowerCase() === 'pluviometer') {
        setCountPlu((prev) => prev - 1);
      }

      if (selectedType.toLocaleLowerCase() === 'waterlevel') {
        setCountWL((prev) => prev - 1);
      }

      toastfyDimiss('toastfySuccess');
    } else if (response.errors) {
      toastfyDimiss('toastLoading');
      toastfyError(
        response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
      );
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (selectedType === 'SurfaceMarker') {
      setName(deleting.data.name);
      setDate(deleting.data.reading.date.toString());
      setReadingId(deleting.data.reading.id);
      setInstrumentId(deleting.data.instrumentId);
    } else {
      setName(deleting.data.name);
      setDate(deleting.data.date.toString());
      setReadingId(deleting.data.readingId);
      setInstrumentId(deleting.data.instrumentId);
    }
  }, []);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '50%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '300px'
        }}
      >
        <GraphHeader
          showModal={showModal}
          setShowModal={setShowModal}
          title={t('DeleteReadings')}
          subtitle={selectedType}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RegisterReadingsArea>
            <DefaultInput type="text" value={name} readOnly />
            <DefaultInput type="text" value={date} readOnly />
          </RegisterReadingsArea>
        </div>
        <ButtonAreaDelete>
          <CancelButtonStyled onClick={() => setShowModal(false)}>
            {' '}
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          <AdvanceButtonStyled
            onClick={() => {
              handleDeleteReading();
            }}
          >
            {' '}
            {t('Delete').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </ButtonAreaDelete>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
