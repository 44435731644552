import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivVolumeContainer = styled.div`
  color: ${Color.Text1};
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleVolume = styled.span`
  padding: 15px 0;
  font-weight: bold;
`;

export const DivContainerVolumeList = styled.div`
  width: 100%;
`;

export const DivVolumeList = styled.div`
  display: grid;
  grid-template-columns: 90% 5%;
  align-items: center;
  gap: 25px;
  padding-bottom: 15px;

  svg {
    font-size: 20px;
    cursor: pointer;
    color: ${Color.Brown1} !important;
  }
`;

export const DivBodyModal = styled.div`
  height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DivContainerTableModalFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
`;

export const DivContainerListFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const DivListFiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
`;

export const DivFilesRightSideContainer = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;
  box-sizing: border-box;
  gap: 20px;
  height: 100%;
  max-height: 100%;
  overflow: auto;
`;

export const DivFilesLeftSideContainer = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DivComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CommentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow-y: auto;
`;
