import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ChangeLocale from '../../Components/ChangeLocale';
import { regexMail } from '../../Components/RegexValidator';
import { ProjectContext } from '../../Context/ContextAPI';
import logoGS3 from '../../Images/logoGS3.svg';
import { LanguageSelect } from '../../Styles/LanguageSelect';
import { useLogin } from '../../data/hooks/use-login.mutation';
import ForgotMyPasswordModal from './ForgotMyPasswordModal';
import {
  AllRightsReserved,
  ForgotPassword,
  H1,
  DivInput,
  LoginArea,
  LoginButton,
  LoginContainer,
  LoginError,
  LoginForm,
  LoginInput,
  LoginLogo
} from './LoginScreenStyle';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { toastfyError } from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';

function LoginScreen() {
  document.title = 'Login';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loginValidate, setLoginValidate] = useState<boolean>(true);
  const [forgotPasswordModal, setForgotPasswordModal] =
    useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const acurrentYear = new Date().getFullYear();

  const { login } = useLogin(
    () => {
      return;
    },
    () => {
      setLoginValidate(false);
    }
  );

  const { dispatch } = useContext(ProjectContext) as {
    dispatch: any;
  };

  class LoginFunctions {
    Signin = async (e: { key?: string; preventDefault: () => void }) => {
      e.preventDefault();
      if (!email || !password)
        return toastfyError(
          t(ToastifyModel().toastifyMessage.fillRequiredFields)
        );

      const regexValidate = regexMail.test(email);
      setLoginValidate(regexValidate);
      if (regexValidate) {
        const loginResponse = await login({
          variables: { data: { email, password } }
        });
        if (loginResponse.data) {
          dispatch({
            type: 'SET_USER_DATA',
            payload: {
              name: loginResponse.data.login.user.name,
              id: loginResponse.data.login.user.id,
              token: loginResponse.data.login.token,
              role: loginResponse.data.login.user.role.role,
              isLoggedWithTemporaryPassword:
                loginResponse.data.login.isLoggedWithTemporaryPassword
            }
          });
          localStorage.setItem(
            'user',
            JSON.stringify({
              name: loginResponse.data.login.user.name,
              id: loginResponse.data.login.user.id,
              token: loginResponse.data.login.token,
              role: loginResponse.data.login.user.role.role,
              isLoggedWithTemporaryPassword:
                loginResponse.data.login.isLoggedWithTemporaryPassword
            })
          );

          if (
            loginResponse.data.login.isLoggedWithTemporaryPassword === false
          ) {
            const urlPath = localStorage.getItem('urlPath');
            if (urlPath) {
              navigate(urlPath);
              localStorage.removeItem('urlPath');
            } else {
              navigate('/');
            }
          } else {
            navigate('/resetPassword');
          }
        }

        if (loginResponse.errors) {
          toastfyError(t('LoginValidate'));
        }
      }
    };
  }
  useEffect(() => {
    localStorage.removeItem('user');
    dispatch({ type: 'CLEAR_DATA' });
  }, []);
  const loginFunctions = new LoginFunctions();
  return (
    <>
      <LoginContainer>
        <LoginArea>
          <H1>{t('LoginTitle')}</H1>
          <LoginForm>
            <DivInput>
              <LoginInput
                onKeyDown={(target) =>
                  target.key === 'Enter' ? loginFunctions.Signin : null
                }
                value={email}
                onChange={(e) =>
                  setEmail(e.target.value.length <= 50 ? e.target.value : email)
                }
                autoComplete="off"
                placeholder={t('User')}
                type="text"
                name="email"
              />
            </DivInput>
            {!loginValidate ? (
              <LoginError>{t('LoginValidate')}</LoginError>
            ) : null}
            <DivInput>
              <LoginInput
                onKeyDown={(target) =>
                  target.key === 'Enter' ? loginFunctions.Signin : null
                }
                value={password}
                onChange={(e) =>
                  setPassword(
                    e.target.value.length <= 50 ? e.target.value : password
                  )
                }
                autoComplete="off"
                placeholder={t('Password')}
                type={showPassword ? 'text' : 'password'}
                name="password"
              />
              {showPassword ? (
                <IoMdEyeOff
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: '10px'
                  }}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <IoMdEye
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: '10px'
                  }}
                  onClick={() => setShowPassword(true)}
                />
              )}
            </DivInput>
            <ForgotPassword onClick={() => setForgotPasswordModal(true)}>
              {t('ForgotMyPassword')}
            </ForgotPassword>
            <LoginButton onClick={loginFunctions.Signin} type="submit">
              {t('Login')}
            </LoginButton>
          </LoginForm>
        </LoginArea>
        <LoginLogo>
          <img draggable={false} src={logoGS3} alt="Logo"></img>
          <AllRightsReserved>
            © {t('AllRightsReserved')} - {acurrentYear}
          </AllRightsReserved>
        </LoginLogo>
        <LanguageSelect>
          <ChangeLocale setShowCard={() => null} hasMargin={true} />
        </LanguageSelect>
      </LoginContainer>
      {forgotPasswordModal && (
        <ForgotMyPasswordModal
          setShowModal={setForgotPasswordModal}
          showModal={forgotPasswordModal}
        />
      )}
    </>
  );
}

export default LoginScreen;
