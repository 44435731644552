import { useMutation } from '@apollo/client';
import {
  DeleteInstrumentReadingDocument,
  DeleteInstrumentReadingMutation,
  DeleteInstrumentReadingMutationVariables
} from '../graphql/generated/deleteInstrumentReading';
import { ListReadingByPiezometersPagDocument } from '../graphql/query/generated/listReadingByPiezometerPag.query';
import { ListReadingByPluviometerPagDocument } from '../graphql/query/generated/listReadingByPluviometerPag.query';
import { ListReadingBySurfaceMarkersPagDocument } from '../graphql/query/generated/listReadingBySurfaceMarkersPag.query';
import { ListReadingByWaterLevelPagDocument } from '../graphql/query/generated/listReadingByWaterLevelPag.query';

export function useDeleteInstrumentReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteInstrument, { data, error, loading }] = useMutation<
    DeleteInstrumentReadingMutation,
    DeleteInstrumentReadingMutationVariables
  >(DeleteInstrumentReadingDocument, {
    refetchQueries: [
      ListReadingByWaterLevelPagDocument,
      ListReadingBySurfaceMarkersPagDocument,
      ListReadingByPluviometerPagDocument,
      ListReadingByPiezometersPagDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteInstrument,
    data,
    error,
    loading
  };
}
