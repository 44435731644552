import styled from 'styled-components';
import { Color } from '../../Styles/Styles';

export const DefaultAsideMenu = styled.div`
  height: 100%;
  background-color: ${Color.Brown3};
  display: flex;
  flex-direction: column;
  padding-left: 1%;
  padding-right: 1%;
  row-gap: 12px;
`;

export const HolderTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  span {
    font-size: 20px;
    font-weight: 700;
    color: ${Color.Brown1};
    margin-top: 28px;
  }
`;

export const DefaultMenuItem = styled.div`
  cursor: pointer;
  color: ${Color.Brown1};
  display: flex;
  align-items: center;
  width: max-content;
  column-gap: 5px;
  margin: 0 8px 0 8px;
  span {
  }
`;

export const DivToggle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: ${Color.Brown1};
`;
