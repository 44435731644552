import styled from 'styled-components';

export const DivContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: start;
  min-height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
`;

export const DivContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
