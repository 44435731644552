import styled from 'styled-components';

export const DivContainerComment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 90%;
`;

export const DivContainerSeeComments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 200px;
  overflow-y: auto;
`;

export const DivContainerAllComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  width: 90%;
`;
