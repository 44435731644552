import { useLazyQuery, useQuery } from '@apollo/client';
import {
  PageInput,
  PiezometerInaFilterInput,
  SortInput,
  StructureInfoInput
} from '../../graphql/base-schema';
import {
  ListReadingByWaterLevelPagDocument,
  ListReadingByWaterLevelPagQuery,
  ListReadingByWaterLevelPagQueryVariables
} from '../../graphql/query/generated/listReadingByWaterLevelPag.query';

export interface ListReadingByWaterLevelPagVariablesModel {
  structureInfo: StructureInfoInput;
  filters: PiezometerInaFilterInput;
  pageInfo?: PageInput;
  sortInfo?: SortInput[];
}

export function useListReadingByWaterLevelPag(
  input: ListReadingByWaterLevelPagVariablesModel,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { structureInfo, filters, pageInfo, sortInfo } = input;

  const { data, loading, error } = useQuery<
    ListReadingByWaterLevelPagQuery,
    ListReadingByWaterLevelPagQueryVariables
  >(ListReadingByWaterLevelPagDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError,
    variables: {
      structureInfo,
      sortInfo,
      filters,
      pageInfo
    }
  });

  return {
    data,
    loading,
    error
  };
}

export function useListReadingByWaterLevelPagLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listReadingWaterLevel, { data, loading, error }] = useLazyQuery<
    ListReadingByWaterLevelPagQuery,
    ListReadingByWaterLevelPagQueryVariables
  >(ListReadingByWaterLevelPagDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listReadingWaterLevel,
    data,
    loading,
    error
  };
}
