import { useMutation } from '@apollo/client';
import {
  AddSurfaceMarkerAlertLevelsDocument,
  AddSurfaceMarkerAlertLevelsMutation,
  AddSurfaceMarkerAlertLevelsMutationVariables
} from '../../graphql/generated/addSurfaceMarkerAlertLevels.mutation';
import { ListSurfaceMarkersByStructureDocument } from '../../graphql/query/generated/listSurfaceMarkersByStructure.query';

export function useAddSurfaceMarkerControlLevels(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addSurfaceMarkerControlLevels, { data, error, loading }] = useMutation<
    AddSurfaceMarkerAlertLevelsMutation,
    AddSurfaceMarkerAlertLevelsMutationVariables
  >(AddSurfaceMarkerAlertLevelsDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListSurfaceMarkersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addSurfaceMarkerControlLevels,
    data,
    error,
    loading
  };
}
