import { IoNotifications } from 'react-icons/io5';
import { formatDate } from '../../utils/formatDate';
import {
  DivNotificationDateUserName,
  DivNotificationItemContainer,
  SpanNotificationTitle
} from './NotificationStyle';

//TODO olhar se precisa ajustar a largura dos campos

export default function NotificationItem({
  title,
  date,
  userName
}: {
  title: string;
  date: string;
  userName: string;
}) {
  return (
    <DivNotificationItemContainer>
      <IoNotifications style={{ fontSize: '35px' }} />
      <div>
        <SpanNotificationTitle>
          {title.length > 24 ? `${title.slice(0, 24)}...` : title}
        </SpanNotificationTitle>
        <DivNotificationDateUserName>
          <span>{formatDate(new Date(date))}</span>
          <span> - </span>
          <span>
            {userName.length > 19 ? `${userName.slice(0, 19)}...` : userName}
          </span>
        </DivNotificationDateUserName>
      </div>
    </DivNotificationItemContainer>
  );
}
