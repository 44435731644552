import { useLazyQuery, useQuery } from '@apollo/client';
import { StructureInfoInput } from '../../graphql/base-schema';
import {
  ListSurfaceMarkersByStructureDocument,
  ListSurfaceMarkersByStructureQuery,
  ListSurfaceMarkersByStructureQueryVariables
} from '../../graphql/query/generated/listSurfaceMarkersByStructure.query';

export function useListSurfaceMarkersByStructure(
  structureInfo: StructureInfoInput,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListSurfaceMarkersByStructureQuery,
    ListSurfaceMarkersByStructureQueryVariables
  >(ListSurfaceMarkersByStructureDocument, {
    variables: {
      structureInfo
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export function useListSurfaceMarkersByStructureLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listSurfaceMarkers, { data, loading, error }] = useLazyQuery<
    ListSurfaceMarkersByStructureQuery,
    ListSurfaceMarkersByStructureQueryVariables
  >(ListSurfaceMarkersByStructureDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listSurfaceMarkers,
    data,
    loading,
    error
  };
}
