import { useMutation } from '@apollo/client';
import {
  EditWaterLevelReadingDocument,
  EditWaterLevelReadingMutation,
  EditWaterLevelReadingMutationVariables
} from '../../graphql/generated/editWaterLevelReading.mutation';
import { ListReadingByWaterLevelPagDocument } from '../../graphql/query/generated/listReadingByWaterLevelPag.query';

export function useEditWaterLevelReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editWaterLevelReading, { data, error, loading }] = useMutation<
    EditWaterLevelReadingMutation,
    EditWaterLevelReadingMutationVariables
  >(EditWaterLevelReadingDocument, {
    refetchQueries: [ListReadingByWaterLevelPagDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editWaterLevelReading,
    data,
    error,
    loading
  };
}

// O hook acima edita as infos de leitura de um medidor de nível dágua.
// As variáveis aceitas são:
// date: Scalars['DateTime']['input'];
// id: Scalars['String']['input']; <-- esse é o id da leitura
// observation?: InputMaybe<Scalars['String']['input']>;
// value: Scalars['Float']['input'];
// É possível ver esses valores em EditWaterLevelReadingMutationVariables. Todas as variáveis acima são obrigatórias
// Uma única leitura deve ser editada por vez
