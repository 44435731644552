import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListWaterLevelByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListWaterLevelByStructureQuery = { __typename?: 'Query', listWaterLevelByStructure: Array<{ __typename?: 'WaterLevelListType', activationDate?: Date | null, coordinateE: number, coordinateN: number, deactivationDate?: Date | null, id?: string | null, installLocation?: string | null, installationDate?: Date | null, isUTM: boolean, name: string, operationalStatus: Types.InstrumentStatusEnum, readingType: string, status: boolean, alertLevels: Array<{ __typename?: 'PluviometerWaterLevelAlertLevelsType', id: string, name: string, status: boolean, value: number }>, type?: { __typename?: 'InstrumentType', id: string, instrumentType: string } | null }> };


export const ListWaterLevelByStructureDocument = gql`
    query ListWaterLevelByStructure($structureInfo: StructureInfoInput!) {
  listWaterLevelByStructure(structureInfo: $structureInfo) {
    activationDate
    alertLevels {
      id
      name
      status
      value
    }
    coordinateE
    coordinateN
    deactivationDate
    id
    installLocation
    installationDate
    isUTM
    name
    operationalStatus
    readingType
    status
    type {
      id
      instrumentType
    }
  }
}
    `;
export type ListWaterLevelByStructureQueryResult = Apollo.QueryResult<ListWaterLevelByStructureQuery, ListWaterLevelByStructureQueryVariables>;