import React, { Dispatch, SetStateAction } from 'react';
import FormInspectionsThreeOptions from '../../../Components/Forms/FormInspectionsThreeOptions';
import { SaveGenericDataInput } from '../../../data/graphql/base-schema';
import { ContainerFirstStep } from '../InspectionsStyle';

export function FirstStepInspection({
  setStepOneStates,
  stepOneStates,
  error,
  inspectionId
}: {
  setStepOneStates: Dispatch<SetStateAction<any>>;
  stepOneStates: SaveGenericDataInput[];
  error: boolean;
  inspectionId: string;
}) {
  return (
    <ContainerFirstStep>
      <FormInspectionsThreeOptions
        topic="Conservação Geral"
        setStepStates={setStepOneStates}
        stepStates={stepOneStates}
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsThreeOptions
        topic="Taludes"
        setStepStates={setStepOneStates}
        stepStates={stepOneStates}
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsThreeOptions
        topic="Revestimento do Piso"
        setStepStates={setStepOneStates}
        stepStates={stepOneStates}
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsThreeOptions
        topic="Dispositivos de drenagem"
        setStepStates={setStepOneStates}
        stepStates={stepOneStates}
        error={error}
        inspectionId={inspectionId}
      />
    </ContainerFirstStep>
  );
}
