import React from 'react';
import { PopupPositionType } from '../../../@Types/types';
import { PopUpContainerStyle } from '../InspectionsStyle';

export default function PopUp({
  popupPosition,
  showPopUpText
}: {
  popupPosition: PopupPositionType;
  showPopUpText: string;
}) {
  return (
    <PopUpContainerStyle
      style={{ left: popupPosition.x - 280, top: popupPosition.y - 215 }}
    >
      {showPopUpText}
    </PopUpContainerStyle>
  );
}
