import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';
import { useStructureSummary } from '../../data/hooks/use-structure-summary.query';
import { CarrouselFunction } from './Carroulsel';
import {
  Carrousel,
  CarrouselArea,
  CarrouselButton,
  ClientLogo,
  CompanyData,
  Container,
  DataArea,
  GeneralDataContainer,
  GeneralStructureData,
  GeotecnicalContainer,
  GeotecnicalHeader,
  GeotecnicalInfos,
  LineDivision,
  LogoImage,
  MapArea,
  SubTitle,
  Text,
  Title
} from './GeneralStructureScreen';
import { BUCKET_URL } from '../../utils/const';
import { useParams } from 'react-router-dom';
import MapGoogle from '../../Components/Map/MapGoogle';

export const GeneralStructureScreen = () => {
  const { t } = useTranslation();
  const { structureId } = useParams();

  const {
    data: listStructureSummary,
    loading,
    error
  } = useStructureSummary('', structureId || '');
  const { currentIndex, handlePrev, handleNext } = CarrouselFunction(
    listStructureSummary?.getStructureSummary.damRaisingHistory || []
  );
  const {
    currentIndex: currentIndexTailing,
    handlePrev: handlePrevtailing,
    handleNext: handleNexttailing
  } = CarrouselFunction(
    listStructureSummary?.getStructureSummary.reservoirHandling
      ?.tailingVolumeHistory || []
  );

  useEffect(() => {
    if (error) {
      toastfyError(error.message || 'Ocorreu um erro');
      toastfyDimiss('toastLoading');
    } else if (loading) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (listStructureSummary) {
      toastfyDimiss('toastLoading');
    }
  }, [listStructureSummary]);

  return (
    <Container>
      <GeneralDataContainer>
        <CompanyData>
          <div>
            <Title style={{ paddingLeft: '5px' }}>{t('CompanyData')}</Title>
            <DataArea>
              <SubTitle>{t('CompanyName')}</SubTitle>
              <Text>
                {listStructureSummary?.getStructureSummary.company.name || '-'}
              </Text>
            </DataArea>
            <DataArea>
              <SubTitle>{t('Local')}</SubTitle>
              <Text>{`${listStructureSummary?.getStructureSummary.company.address?.country} - ${listStructureSummary?.getStructureSummary.company.address?.state} - ${listStructureSummary?.getStructureSummary.company.address?.city}`}</Text>
            </DataArea>
          </div>
          <ClientLogo>
            {listStructureSummary?.getStructureSummary.company.logo ? (
              <LogoImage
                src={`${BUCKET_URL}/${listStructureSummary?.getStructureSummary.company.logo}`}
                alt="logo"
              />
            ) : null}
          </ClientLogo>
        </CompanyData>
        <GeneralStructureData>
          <Title style={{ paddingLeft: '5px' }}>{t('GeneralData')}</Title>
          <DataArea style={{ width: '45%' }}>
            <SubTitle>{t('Coordinates')}</SubTitle>
            <Text>
              {t('CoordinateSystem')}{' '}
              {listStructureSummary?.getStructureSummary.summary?.datumName ??
                '-'}
            </Text>
            <Text>
              {t('Zone')}{' '}
              {listStructureSummary?.getStructureSummary.summary?.utmZone ??
                '-'}
            </Text>
            <Text>
              N:{' '}
              {listStructureSummary?.getStructureSummary.summary?.coordinateN ??
                '-'}
            </Text>
            <Text>
              E:{' '}
              {listStructureSummary?.getStructureSummary.summary?.coordinateE ??
                '-'}
            </Text>
          </DataArea>
          <LineDivision />
          <DataArea style={{ flexDirection: 'row' }}>
            <DataArea style={{ marginRight: '50px', padding: '0' }}>
              <SubTitle>{t('OperationalStatus')}</SubTitle>
              <Text>
                {listStructureSummary?.getStructureSummary.summary
                  ?.operationalStatus ?? '-'}
              </Text>
              <br />
              <SubTitle>{t('Operation')}</SubTitle>
              <Text>
                {t('Beginning')} -{' '}
                {listStructureSummary?.getStructureSummary.summary
                  ?.operationStart
                  ? new Date(
                    listStructureSummary?.getStructureSummary.summary?.operationStart
                  ).toLocaleDateString('pt-br')
                  : '-'}
              </Text>
              <Text>
                {t('End')} -{' '}
                {listStructureSummary?.getStructureSummary.summary?.operationEnd
                  ? new Date(
                    listStructureSummary?.getStructureSummary.summary?.operationEnd
                  ).toLocaleDateString('pt-br')
                  : '-'}
              </Text>
            </DataArea>
            <DataArea>
              <SubTitle>{t('Responsible')}</SubTitle>
              <Text>
                {listStructureSummary?.getStructureSummary.responsible.name ??
                  '-'}
              </Text>
              <br />
            </DataArea>
          </DataArea>
          <LineDivision />
          <DataArea style={{ overflow: 'hidden', position: 'relative' }}>
            <SubTitle>{t('ChangeHistory')}</SubTitle>
            <CarrouselArea>
              <CarrouselButton onClick={handlePrev}>&#9664;</CarrouselButton>
              <Carrousel>
                {listStructureSummary?.getStructureSummary.damRaisingHistory?.map(
                  (text, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        transform: `translateX(${-currentIndex * 100}%)`,
                        transition: 'transform 0.5s ease'
                      }}
                    >
                      <Text>
                        {text.stage} - {text.elevation} - {text.method} -{' '}
                        {text.date
                          ? new Date(text.date).toLocaleDateString('pt-br')
                          : '-'}
                      </Text>
                    </div>
                  )
                )}
              </Carrousel>
              <CarrouselButton onClick={handleNext}>&#9654;</CarrouselButton>
            </CarrouselArea>
          </DataArea>
          <DataArea>
            <SubTitle>{t('RiskMapping')}</SubTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '15px'
              }}
            >
              <Text>
                <span style={{ fontWeight: '700' }}>{t('TypeofWaste')}: </span>
                <span style={{ float: 'right' }}>
                  {listStructureSummary?.getStructureSummary?.riskAssessment
                    ?.wasteType || '-'}
                </span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('HumanOccupationDownstreamDam')}:{' '}
                </span>
                <span style={{ float: 'right' }}>
                  {listStructureSummary?.getStructureSummary?.riskAssessment
                    ?.downstreamHumanOccupation || '-'}
                </span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('EnvironmentalInterestAreaDownstreamDam')}:{' '}
                </span>
                <span style={{ float: 'right' }}>
                  {listStructureSummary?.getStructureSummary?.riskAssessment
                    ?.downstreamEnvironmentalInterest || '-'}
                </span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('FacilityDownstreamAreaDam')}:{' '}
                </span>
                <span style={{ float: 'right' }}>
                  {listStructureSummary?.getStructureSummary?.riskAssessment
                    ?.downstreamInstallations || '-'}
                </span>
              </Text>
            </div>
          </DataArea>
        </GeneralStructureData>
      </GeneralDataContainer>
      <GeotecnicalContainer>
        <GeotecnicalHeader>
          <DataArea>
            <SubTitle>{t('AssociatedPotentialHarm')}</SubTitle>
            <Text>
              {listStructureSummary?.getStructureSummary.summary
                ?.potentialDamage === 'NA'
                ? 'N/A'
                : listStructureSummary?.getStructureSummary.summary
                  ?.potentialDamage ?? '-'}
            </Text>
          </DataArea>
          <DataArea>
            <SubTitle>{t('RiskRating')}</SubTitle>
            <Text>
              {listStructureSummary?.getStructureSummary.summary
                ?.riskClassification === 'NA'
                ? 'N/A'
                : listStructureSummary?.getStructureSummary.summary
                  ?.riskClassification ?? '-'}
            </Text>
          </DataArea>
          <DataArea>
            <SubTitle>{t('CrestLength')}</SubTitle>
            <Text>
              {listStructureSummary?.getStructureSummary.geometry
                ?.crestLength ?? '-'}
            </Text>
          </DataArea>
          <DataArea>
            <SubTitle>{t('MaximumHeight')}</SubTitle>
            <Text>
              {listStructureSummary?.getStructureSummary.summary?.maxHeight ??
                '-'}
            </Text>
          </DataArea>
          <DataArea style={{ marginRight: '10px' }}>
            <SubTitle>{t('ANMClassification')}</SubTitle>
            <Text>
              {listStructureSummary?.getStructureSummary.summary
                ?.ANMClassification === 'NA'
                ? 'N/A'
                : listStructureSummary?.getStructureSummary.summary
                  ?.ANMClassification ?? '-'}
            </Text>
          </DataArea>
        </GeotecnicalHeader>
        <MapArea>
          <MapGoogle />
        </MapArea>
        <GeotecnicalInfos>
          <DataArea>
            <SubTitle>{t('Hydrology')}</SubTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '15px',
                width: '300px'
              }}
            >
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('TotalVolumeAtCrestElevation')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.volumeTotalElevationCrest?.toFixed(3) || '-'} m²`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('OverflowThreshold')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.spillwaySill || '-'} m`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('N.A.MaximusMaximorum')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.NAMaximoMaximorum || '-'} m`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>{t('ProjectRain')}: </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.projectRainfall || '-'} mm`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>{t('ReturnTime')}: </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.returnPeriod || '-'} ${t('years')}`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('CriticalDuration')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.criticalDuration || '-'} h`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>{t('AffluentFlow')}: </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.inflowRate || '-'} m³/s`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>{t('EffluentFlow')}: </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.outflowRate || '-'} m³/s`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('TypeOfOverflow')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.spillwayType || '-'}`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('ContribuitionBasin')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.hydrologyInfos?.reservoirContributionArea || '-'}`}</span>
              </Text>
            </div>
          </DataArea>
          <DataArea>
            <SubTitle>{t('ReservoirManagement')}</SubTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '15px',
                width: '300px'
              }}
            >
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('TotalReservoirVolume')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.reservoirHandling?.reservoirTotalVolume || '-'} m³ `}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('AreaOccupiedReservoir')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.reservoirHandling?.reservoirOccupiedArea || '-'} m²`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('reservoirOccupiedVolume')}:{' '}
                </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.reservoirHandling?.reservoirTotalVolume || '-'} m³`}</span>
              </Text>
            </div>
            <DataArea style={{ overflow: 'hidden', position: 'relative' }}>
              <SubTitle>{t('tailingVolumeHistory')}</SubTitle>
              <CarrouselArea style={{ width: '250px' }}>
                <CarrouselButton onClick={handlePrevtailing}>
                  &#9664;
                </CarrouselButton>
                <Carrousel>
                  {listStructureSummary?.getStructureSummary.reservoirHandling?.tailingVolumeHistory.map(
                    (text, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'inline-block',
                          width: '100%',
                          transform: `translateX(${-currentIndexTailing * 100}%)`,
                          transition: 'transform 0.5s ease'
                        }}
                      >
                        <Text>
                          {text.volume} -{' '}
                          {text.date
                            ? new Date(text.date).toLocaleDateString('pt-br')
                            : '-'}
                        </Text>
                      </div>
                    )
                  )}
                </Carrousel>
                <CarrouselButton onClick={handleNexttailing}>
                  &#9654;
                </CarrouselButton>
              </CarrouselArea>
            </DataArea>
          </DataArea>
          <DataArea style={{ overflow: 'hidden', position: 'relative' }}>
            <SubTitle>{t('MassiveGeometry')}</SubTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '15px',
                width: '300px'
              }}
            >
              <Text>
                <span style={{ fontWeight: '700' }}>{t('CrestWidth')}: </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.geometry?.crestWidth?.toFixed(3) || '-'} m`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>{t('CrestLength')}: </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.geometry?.crestLength?.toFixed(3) || '-'} m`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>{t('BermWidth')}: </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.geometry?.bermLength?.toFixed(3) || '-'} m`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>{t('CrestRise')}: </span>
                <span
                  style={{ float: 'right' }}
                >{`${listStructureSummary?.getStructureSummary.geometry?.crestElevation || '-'} m`}</span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('GlobalDownstreamSlope')}:{' '}
                </span>
                <span style={{ float: 'right' }}>
                  {listStructureSummary?.getStructureSummary.geometry
                    ?.globalSlopeDownstream || '-'}
                </span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('SlopeBetweenBerms')}:{' '}
                </span>
                <span style={{ float: 'right' }}>
                  {listStructureSummary?.getStructureSummary.geometry
                    ?.slopeBetweenBerms || '-'}
                </span>
              </Text>
              <Text>
                <span style={{ fontWeight: '700' }}>
                  {t('UpstreamSlope')}:{' '}
                </span>
                <span style={{ float: 'right' }}>
                  {listStructureSummary?.getStructureSummary.geometry
                    ?.upstreamSlopeInclination || '-'}
                </span>
              </Text>
            </div>
          </DataArea>
        </GeotecnicalInfos>
      </GeotecnicalContainer>
    </Container>
  );
};
