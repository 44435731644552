import { PropsWithChildren } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { TableHeaderCellProps } from './TableHeaderCell.interface';
import { TableHeaderCellContent } from './TableHeaderCell.styles';

const TableHeaderCell = ({
  children,
  onClick,
  isSorted = false,
  isSortedDesc = false
}: PropsWithChildren<TableHeaderCellProps>) => {
  const handleSortClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <TableHeaderCellContent>
      <div>
        {children}
        {isSorted ? (
          isSortedDesc ? (
            <FaArrowDown onClick={handleSortClick} />
          ) : (
            <FaArrowUp onClick={handleSortClick} />
          )
        ) : (
          <FaArrowDown onClick={handleSortClick} />
        )}
      </div>
    </TableHeaderCellContent>
  );
};

export default TableHeaderCell;
