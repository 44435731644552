import styled from 'styled-components';

export const DivContainerComment = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 10% 80%;
  align-items: center;
  padding: 25px 0;

  p {
    margin: 0;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;

  p {
    margin: 0;
  }
`;

export const DivContainerTiltle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DivComment = styled.div`
  display: flex;
  flex-direction: column;
`;
