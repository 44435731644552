import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';
import { ViewModalStyleProps } from './ViewModal.interface';

export const DivModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const DivViewModal = styled.div<ViewModalStyleProps>`
  position: fixed;
  background-color: ${Color.White};
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width || 80}%;
  height: ${(props) => props.height || 80}%;
  max-width: 90%;
  max-height: 90%;
  z-index: 100;
`;

export const DivViewModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const DivViewModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${Color.Brown3};

  /* TODO: mudar quando inserir variante no botão */
  span {
    font-weight: bold;
  }

  button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: ${Color.Brown1};
  }
`;

export const DivVolumeContainer = styled.div`
  color: ${Color.Text1};
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleVolume = styled.span`
  padding: 15px 0;
  font-weight: bold;
`;

export const DivContainerViewModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  height: 100%;
  overflow-y: auto;
`;
