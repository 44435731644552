import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { AtoEquipmentLaborTypeEnum } from '../../../../data/graphql/base-schema';
import {
  DeleteRecordEquipmentAndLaborDocument,
  DeleteRecordEquipmentAndLaborMutation,
  DeleteRecordEquipmentAndLaborMutationVariables
} from '../../../../data/graphql/generated/deleteRecordEquipmentAndLabor';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AtoQuantityDescriptionForm from '../AtoQuantityDescriptionForm/AtoQuantityDescriptionForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoLaborProps,
  LaborDataType,
  LaborItemType,
  RowToEditType
} from './CardAtoInfoLabor.interface';

export const CardAtoInfoLabor = ({ laborData }: CardAtoInfoLaborProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [deleteRecordEquipmentAndLabor] = useMutation<
    DeleteRecordEquipmentAndLaborMutation,
    DeleteRecordEquipmentAndLaborMutationVariables
  >(DeleteRecordEquipmentAndLaborDocument);
  const [rowToEdit, setRowToEdit] = useState<RowToEditType>();
  const [idForDelete, setIdForDelete] = useState<string>('');

  const tableColumns = [
    {
      key: 'indexId',
      label: 'id'
    },
    {
      key: 'quantity',
      label: t('quantity')
    },
    {
      key: 'description',
      label: t('description')
    }
  ];

  const tableData = laborData.map((item: LaborDataType, index) => ({
    description: item.description,
    quantity: item.quantity,
    id: item.id,
    indexId: index
  }));

  const actions = [
    {
      name: t('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => {
        handleEditRow(row);
      },
      canShow: 'edit'
    },
    {
      name: t('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRecordEquipmentAndLabor({
      variables: {
        data: {
          type: 'Labor' as AtoEquipmentLaborTypeEnum,
          id: id
        }
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
        setUpdateDelete(!updateDelete);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessage);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            labor: existingData.findDataByRecord.labor.filter(
              (item: LaborItemType) => item.id !== id
            )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setRowToEdit(row);
    setShowEditModal(true);
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  return (
    <>
      <CardAtoInfo
        title={t('labor')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={tableColumns}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      {showModal && (
        <ViewModal
          width={40}
          height={50}
          title={t('addLabor')}
          onClose={() => setShowModal(false)}
          component={
            <AtoQuantityDescriptionForm
              setShowModal={setShowModal}
              recordId={recordId?.toString() || ''}
              type={'Labor' as AtoEquipmentLaborTypeEnum}
            />
          }
        />
      )}
      {showEditModal && (
        <ViewModal
          width={40}
          height={50}
          title={t('editLabor')}
          onClose={() => setShowEditModal(false)}
          component={
            <AtoQuantityDescriptionForm
              setShowModal={setShowEditModal}
              recordId={recordId?.toString() || ''}
              type={'Labor' as AtoEquipmentLaborTypeEnum}
              editQuantityDescription={{
                id: rowToEdit?.id || '',
                quantity: rowToEdit?.quantity || 0,
                description: rowToEdit?.description || ''
              }}
            />
          }
        />
      )}
      {idForDelete && (
        <ModalConfirm
          title={`${t('Delete')} ${t('Activity')}`}
          text={t('ConfirmDelete')}
          subtitle={t('ActionCannotBeUndone')}
          onClose={() => {
            setIdForDelete('');
          }}
          onConfirm={() => {
            handleDeleteRow(idForDelete);
            setIdForDelete('');
          }}
        />
      )}
    </>
  );
};
