import styled from 'styled-components';

export const HolderCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const HolderFormAto = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  row-gap: 10px;
`;

export const HolderFormAtoLeftContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
`;

export const HolderFormAtoRightContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
`;

export const HolderTable = styled.div`
  width: 330px;
  overflow: auto;
  max-height: 170px;
`;

export const HolderFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding-bottom: 10px;
`;
