import { StylesConfig } from 'react-select';
import styled from 'styled-components';
import { Color } from './Styles';

export const LanguageSelect = styled.div`
  position: absolute;
  bottom: 10px;
  left: 20px;
`;

export const FlagImage = styled.img`
  width: 20px;
  position: relative;
  bottom: -30px;
  left: -25px;
`;

export const StyleLanguageSelect: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 'none',
    boxSizing: 'border-box',
    boxShadow: 'none',
    padding: 'none',
    width: '160px',
    marginBottom: state.menuIsOpen ? '50px' : '10px',
    fontSize: '11pt',
    ':hover': { border: 'none', borderBottom: `1px solid ${Color.White}` }
  }),

  indicatorSeparator: () => ({}), // disable indicator

  placeholder: (provided) => ({
    ...provided,
    color: Color.White
  }),

  input: (provider) => ({
    ...provider,
    color: Color.Golden2,
    backgroundColor: 'transparent',
    textShadow: 'none',
    height: '20px',

    ':hover': {
      border: '1px solid transparent'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: Color.White,
    ':hover': {
      color: Color.White
    }
  }),

  option: (provided) => ({
    ...provided,
    color: Color.White,
    fontSize: '10pt',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    marginTop: '-5px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#cdcdcd13'
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: Color.White
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    boxShadow: 'none'
  })
};

export const UserBadgeLanguageSelect: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 'none',
    boxSizing: 'border-box',
    boxShadow: 'none',
    padding: 'none',
    width: '140px',
    marginBottom: '0px',
    fontSize: '8pt',
    cursor: 'pointer'
  }),

  indicatorSeparator: () => ({}), // disable indicator

  placeholder: (provided) => ({
    ...provided,
    color: Color.White
  }),

  input: (provider) => ({
    ...provider,
    color: Color.Golden2,
    backgroundColor: 'transparent',
    textShadow: 'none',
    height: '20px',

    ':hover': {
      border: '1px solid transparent'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: Color.White,
    ':hover': {
      color: Color.White
    }
  }),

  option: (provided) => ({
    ...provided,
    color: Color.White,
    fontSize: '8pt',
    backgroundColor: Color.Gray2,
    borderRadius: '2px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Color.Gray2
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: Color.White
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: '-5px',
    marginLeft: '2px',
    width: '120px'
  })
};
