import { useMutation } from '@apollo/client';
import {
  EditPiezometerReadingDocument,
  EditPiezometerReadingMutation,
  EditPiezometerReadingMutationVariables
} from '../../graphql/generated/editPiezometerReading.mutation';
import { ListReadingByPiezometersPagDocument } from '../../graphql/query/generated/listReadingByPiezometerPag.query';

export function useEditPiezometerReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editPiezometerReading, { data, error, loading }] = useMutation<
    EditPiezometerReadingMutation,
    EditPiezometerReadingMutationVariables
  >(EditPiezometerReadingDocument, {
    refetchQueries: [ListReadingByPiezometersPagDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editPiezometerReading,
    data,
    error,
    loading
  };
}
