import React, { Dispatch, SetStateAction } from 'react';
import { ContainerSecondStep } from '../InspectionsStyle';
import { Coating } from './DamMassif/Coating';
import { CracksIdentifies } from './DamMassif/CracksIdentifies';
import { IdentifiedWaterEmergency } from './DamMassif/IdentifiedWaterEmergency';
import { InternalDrainage } from './DamMassif/InternalDrainage';
import { PresenceOfTreesAnimalsInsects } from './DamMassif/PresenceOfTrees';
import { RepressionsIdentifies } from './DamMassif/RepressionsIdentifies';
import { SurfaceDrainage } from './DamMassif/SurfaceDrainage';
import { SurfaceErosions } from './DamMassif/SurfaceErosions';

interface secondStepInspectionProps {
  isSelectedInternalDrainage: boolean;
  setIsSelectedInternalDrainage: Dispatch<SetStateAction<boolean>>;
  isSelectedSurfaceDrainage: boolean;
  setIsSelectedSurfaceDrainage: Dispatch<SetStateAction<boolean>>;
  setStepTwoStates: any;
  stepTwoStates: any;
  error: boolean;
  inspectionId: string;
  mssStructure: any;
  setUpdateStepTwo: any;
}

export function SecondStepInspection({
  isSelectedInternalDrainage,
  setIsSelectedInternalDrainage,
  isSelectedSurfaceDrainage,
  setIsSelectedSurfaceDrainage,
  setStepTwoStates,
  stepTwoStates,
  error,
  inspectionId,
  mssStructure,
  setUpdateStepTwo
}: secondStepInspectionProps) {
  return (
    <ContainerSecondStep>
      <CracksIdentifies
        setStepTwoStates={setStepTwoStates}
        stepTwoStates={stepTwoStates}
        inspectionId={inspectionId}
        mssStructure={mssStructure.identifiedCracks}
        setUpdateStepTwo={setUpdateStepTwo}
      />
      <RepressionsIdentifies
        setStepTwoStates={setStepTwoStates}
        stepTwoStates={stepTwoStates}
        inspectionId={inspectionId}
        mssStructure={mssStructure.identifiedDeformations}
        setUpdateStepTwo={setUpdateStepTwo}
      />
      <IdentifiedWaterEmergency
        setStepTwoStates={setStepTwoStates}
        stepTwoStates={stepTwoStates}
        inspectionId={inspectionId}
        mssStructure={mssStructure.identifiedWaterLeaks}
        setUpdateStepTwo={setUpdateStepTwo}
      />
      <SurfaceErosions
        topic="SurfaceErosions"
        setStepStates={setStepTwoStates}
        stepStates={stepTwoStates}
        error={error}
        inspectionId={inspectionId}
      />
      <SurfaceDrainage
        setStepStates={setStepTwoStates}
        stepStates={stepTwoStates}
        isSelectedSurfaceDrainage={isSelectedSurfaceDrainage}
        setIsSelectedSurfaceDrainage={setIsSelectedSurfaceDrainage}
        error={error}
        inspectionId={inspectionId}
      />
      <Coating
        topic="Coating"
        setStepStates={setStepTwoStates}
        stepStates={stepTwoStates}
        error={error}
        inspectionId={inspectionId}
      />
      <PresenceOfTreesAnimalsInsects
        setStepStates={setStepTwoStates}
        stepStates={stepTwoStates}
        error={error}
        inspectionId={inspectionId}
      />
      <InternalDrainage
        setStepStates={setStepTwoStates}
        stepStates={stepTwoStates}
        setIsSelectedInternalDrainage={setIsSelectedInternalDrainage}
        isSelectedInternalDrainage={isSelectedInternalDrainage}
        error={error}
        inspectionId={inspectionId}
      />
    </ContainerSecondStep>
  );
}
