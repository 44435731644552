import { ReactNode } from 'react';
import {
  DivContainerImages,
  DivContainerLeftSide,
  DivContainerRightSide,
  DivContainterInfos,
  HolderCards,
  HolderContentPage,
  HolderHeader,
  HolderVideos,
  MainScreenAto
} from './TemplateAtoStyles';

interface TemplateAtoProps {
  header: ReactNode;
  infosContent: ReactNode;
  imagesContent: ReactNode;
  cardContent: ReactNode;
  videosContent: ReactNode;
  tableContent: ReactNode;
}

export const TemplateAto = ({
  header,
  infosContent,
  imagesContent,
  cardContent,
  videosContent,
  tableContent
}: TemplateAtoProps) => {
  return (
    <MainScreenAto>
      <HolderHeader>{header}</HolderHeader>
      <HolderContentPage>
        <DivContainerLeftSide>
          <DivContainterInfos>{infosContent}</DivContainterInfos>
          <DivContainerImages>{imagesContent}</DivContainerImages>
        </DivContainerLeftSide>
        <DivContainerRightSide>
          <HolderCards>{cardContent}</HolderCards>
          <HolderVideos>{tableContent}</HolderVideos>
          <HolderVideos>{videosContent}</HolderVideos>
        </DivContainerRightSide>
      </HolderContentPage>
    </MainScreenAto>
  );
};
