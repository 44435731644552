import styled from 'styled-components';
import { Color, FontSize } from '../../../Styles/Styles';


export const MapPopUpHeaderStyle = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
font-size: ${FontSize.Small};
color: ${Color.Brown2};
border-bottom: 1px solid ${Color.Brown2};
max-height: 40px;
& h3 {
  color: ${Color.Brown1};
}

& span {
  position: absolute;
  top: 0px;
  right: 25px;
}
`;

export const MapPopUpContentStyle = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
font-size: ${FontSize.Small};
color: ${Color.Gray4};

& > div {
    border-bottom: 1px solid ${Color.Brown2};
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 35px;
    width: 100%;
  }

  & > div:last-child {
    border-bottom: none;
  }
}
`;

export const SpanInstrumentPopUpStyle = styled.span`
  text-align: left;
  min-width: 70px;
}
`;

export const MapPopUpTableContentStyle = styled.div`
display: flex;
justify-content: center;
margin-top: 5px;

& > table {
  text-align: justify;
  }
`;

export const TextInstumentsStyle = styled.div`
  color: black;
  background: white;
  min-width: 40px;
  width: auto;
`;
