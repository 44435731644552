import { useMutation } from '@apollo/client';
import {
  EditSurfaceMarkerAlertLevelDocument,
  EditSurfaceMarkerAlertLevelMutation,
  EditSurfaceMarkerAlertLevelMutationVariables
} from '../../graphql/generated/editSurfaceMarkerAlertLevel';
import { ListSurfaceMarkersByStructureDocument } from '../../graphql/query/generated/listSurfaceMarkersByStructure.query';

export function useEditSurfaceMarkerAlertLevels(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editSurfaceMarkerAlertLevels, { data, error, loading }] = useMutation<
    EditSurfaceMarkerAlertLevelMutation,
    EditSurfaceMarkerAlertLevelMutationVariables
  >(EditSurfaceMarkerAlertLevelDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListSurfaceMarkersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editSurfaceMarkerAlertLevels,
    data,
    error,
    loading
  };
}
