import { useMutation } from '@apollo/client';
import {
  EditPiezometerAlertDocument,
  EditPiezometerAlertMutation,
  EditPiezometerAlertMutationVariables
} from '../../graphql/generated/editPiezometerAlert.mutation';

export function useEditPiezometerAlert(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editPiezometerAlert, { data, error, loading }] = useMutation<
    EditPiezometerAlertMutation,
    EditPiezometerAlertMutationVariables
  >(EditPiezometerAlertDocument, {
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editPiezometerAlert,
    data,
    error,
    loading
  };
}
