import { useMutation } from '@apollo/client';
import {
  CreateSurfaceMarkersReadingDocument,
  CreateSurfaceMarkersReadingMutation,
  CreateSurfaceMarkersReadingMutationVariables
} from '../../graphql/generated/createSurfaceMarkerReading.mutation';
import { ListReadingBySurfaceMarkersPagDocument } from '../../graphql/query/generated/listReadingBySurfaceMarkersPag.query';

export function useCreateSurfaceMarkerReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createReading, { data, error, loading }] = useMutation<
    CreateSurfaceMarkersReadingMutation,
    CreateSurfaceMarkersReadingMutationVariables
  >(CreateSurfaceMarkersReadingDocument, {
    refetchQueries: [ListReadingBySurfaceMarkersPagDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createReading,
    data,
    error,
    loading
  };
}
