import { useReducer, useState } from 'react';
import { InstrumentItemType } from '../../../../@Types/Instruments/instruments';
import {
  initialDataSetState,
  selectedDataSetReducer
} from '../../../../Controllers/Graphs/SelectedGraphsDataSetController';
import {
  initialSelectedAxis,
  selectedAxisReducer
} from '../../../../Controllers/Graphs/SlectedGraphsAxisController';
import { RegisterInstrumentModal } from '../../../Instruments/Register/RegisterInstrumentStyle';
import { InstrumentsChart } from './Charts/InstrumentsChart';
import GraphHeader from './GraphHeader';
import GraphMenu from './GraphMenu';
import { InstrumentGraphBackground } from './StyleInstrumentGraph';

export const InstrumentGraph = ({ showModal, setShowModal }: any) => {
  interface InstrumentCount {
    rainGauge: number;
    piezometer: number;
    shallowLandmark: number;
    reservoirWaterLevel: number;
    ina: number;
  }
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 5000);

  const [selectedGraphs, setSelectedGraphs] = useState<InstrumentItemType[]>(
    []
  );
  const [stateSelectedAxis, dispatchAxis] = useReducer(
    selectedAxisReducer,
    initialSelectedAxis
  );
  const [stateSelectedDataSet, dispatchDataSet] = useReducer(
    selectedDataSetReducer,
    initialDataSetState
  );
  const [instrumentsCount, setInstrumentsCount] = useState<InstrumentCount>({
    rainGauge: 0,
    piezometer: 0,
    shallowLandmark: 0,
    reservoirWaterLevel: 0,
    ina: 0
  });

  const [startDate, setStartDate] = useState<Date>(thirtyDaysAgo);
  const [endDate, setEndDate] = useState<Date>(today);
  const [rainGauge, setRainGauge] = useState<any[]>([]);
  const [piezometer, setPiezometer] = useState<any[]>([]);
  const [shallowLandmark, setShallowLandmark] = useState<any[]>([]);
  const [reservoirWaterLevel, setReservoirWaterLevel] = useState<any[]>([]);
  const [ina, setIna] = useState<any>([]);

  return (
    <InstrumentGraphBackground>
      <RegisterInstrumentModal>
        <GraphHeader
          title={'GenerateGraph'}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <GraphMenu
          selectedGraphs={selectedGraphs}
          setSelectedGraphs={setSelectedGraphs}
          rainGauge={rainGauge}
          piezometer={piezometer}
          shallowLandmark={shallowLandmark}
          reservoirWaterLevel={reservoirWaterLevel}
          setRainGauge={setRainGauge}
          setPiezometer={setPiezometer}
          setShallowLandmark={setShallowLandmark}
          setReservoirWaterLevel={setReservoirWaterLevel}
          ina={ina}
          setIna={setIna}
          startDate={startDate}
          endDate={endDate}
        />
        <InstrumentsChart
          selectedGraphs={selectedGraphs}
          stateSelectedAxis={stateSelectedAxis}
          dispatchAxis={dispatchAxis}
          stateSelectedDataSet={stateSelectedDataSet}
          dispatchDataSet={dispatchDataSet}
          instrumentsCount={instrumentsCount}
          setInstrumentsCount={setInstrumentsCount}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </RegisterInstrumentModal>
    </InstrumentGraphBackground>
  );
};
