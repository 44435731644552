import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ViewInstrumentsButtonInterface } from '../../../@Types/Instruments/instruments';
import { ModuleData } from '../../../@Types/types';
import { ProjectContext } from '../../../Context/ContextAPI';
import ExportData from '../../../Controllers/Export/ExportData';
import InstrumentsModel from '../../../Models/InstrumentsModel';
import { useListPiezometersByStructureLazy } from '../../../data/hooks/piezometer/use-list-piezometers-by-structure.query';
import { useListPluviometersByStructureLazy } from '../../../data/hooks/pluviometer/use-list-pluviometers-by-structure';
import { useListSurfaceMarkersByStructureLazy } from '../../../data/hooks/surface-marker/use-list-surface-markers-by-structure.query';
import { useListWaterLevelByStructureLazy } from '../../../data/hooks/water-level/use-list-water-level-by-structure';
import { DefaultButton } from '../../Buttons/DefaultButton';
import GraphHeader from '../../Graphs/Monitoring/Instrument/GraphHeader';
import { AsideInstrumentsMenu } from '../../Menus/AsideInstrumentsMenu';
import { TableInstrumentComponent } from '../../Tables/TableInstrumentComponent/TableInstrumentComponent';
import { toastfyDimiss, toastifyLoading } from '../../Toastify';
import { EditInstrumentsModal } from '../EditInstrument';
import { RegisterInstrument } from '../Register/RegisterInstrument';
import {
  DivAsideMenu,
  DivButton,
  DivButtonTable,
  DivTable,
  HolderOverflowTable,
  HolderViewInstruments,
  ViewInstrumentsModal,
  ViewInstrumetBackground
} from './styles';

export default function ViewInstrumentsPage(
  props: ViewInstrumentsButtonInterface
) {
  const { structureId } = useParams();
  const { showModal, setShowModal } = props;
  const defaultInstrumentValue = 'Piezometer';
  const [selectedType, setSelectedType] = useState<string>(
    defaultInstrumentValue
  );
  const [dataChanged, setDataChanged] = useState(false);
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const loading = toastifyLoading(t('loading'));
  const dismissLoading = toastfyDimiss('toastLoading');
  const [data, setData] = useState<any>([]);
  const { listPiezometers } = useListPiezometersByStructureLazy();
  const { listWaterLevels } = useListWaterLevelByStructureLazy();
  const { listSurfaceMarkers } = useListSurfaceMarkersByStructureLazy();
  const { listPluviometers } = useListPluviometersByStructureLazy();
  const [createdReading, setCreatedReading] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [showRegisterInstrumentModal, setShowRegisterInstrumentModal] =
    useState<boolean>(false);
    const { getUserRoleByModule } = useContext(ProjectContext) as {
      getUserRoleByModule: (moduleName: string) => string;
    };
    const userRoleInModule = getUserRoleByModule('Monitoring');
  const [status, setStatus] = useState<boolean>(true);

  async function handleFetchPiezometers(selectedType: string) {
    const response = await listPiezometers({
      variables: {
        structureInfo
      }
    });
    if (response.data) {
      const filteredData = response.data.listPiezometersByStructure.filter(
        (item) =>
          item.type.instrumentType.toLocaleUpperCase() ===
            selectedType.toLocaleUpperCase() && item.status !== false
      );
      if (status) {
        setData(
          filteredData
            .filter((e: any) => e.operationalStatus === 'Active')
            .map((e: any) => {
              return {
                ...e,
                readingType: t(e.readingType)
              };
            })
        );
      } else {
        setData(
          filteredData.map((e: any) => {
            return {
              ...e,
              readingType: t(e.readingType)
            };
          })
        );
      }

      dismissLoading;
    }
  }

  async function handleFetchWaterLevels() {
    const response = await listWaterLevels({
      variables: {
        structureInfo
      }
    });
    loading;
    if (response.data) {
      dismissLoading;
      const filteredData = response.data.listWaterLevelByStructure.filter(
        (item) => item.status !== false
      );
      if (status) {
        setData(
          filteredData.filter((e: any) => e.operationalStatus === 'Active')
        );
      } else {
        setData(filteredData);
      }
    }
  }

  async function handleFetchSurfaceMarkers() {
    const response = await listSurfaceMarkers({
      variables: {
        structureInfo
      }
    });
    loading;
    if (response.data) {
      dismissLoading;
      const filteredData = response.data.listSurfaceMarkersByStructure.filter(
        (item) => item.status !== false
      );
      if (status) {
        setData(
          filteredData.filter((e: any) => e.operationalStatus === 'Active')
        );
      } else {
        setData(filteredData);
      }
    }
  }

  async function handleFetchPluviometers() {
    const response = await listPluviometers({
      variables: {
        structureInfo
      }
    });
    loading;
    if (response.data) {
      dismissLoading;
      const filteredData = response.data.listPluviometersByStructure.filter(
        (item) => item.status !== false
      );
      if (status) {
        setData(
          filteredData.filter((e: any) => e.operationalStatus === 'Active')
        );
      } else {
        setData(filteredData);
      }
    }
  }

  const fetchData = async () => {
    switch (selectedType) {
      case 'Piezometer':
      case 'INA':
        handleFetchPiezometers(selectedType);
        break;
      case 'WaterLevel':
        handleFetchWaterLevels();
        break;
      case 'SurfaceMarker':
        handleFetchSurfaceMarkers();
        break;
      case 'Pluviometer':
        handleFetchPluviometers();
        break;
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedType, dataChanged, status]);

  return (
    <ViewInstrumetBackground>
      <ViewInstrumentsModal>
        <GraphHeader
          showModal={showModal}
          setShowModal={setShowModal}
          title="Plano de Instrumentos"
        />
        <HolderViewInstruments>
          <DivAsideMenu>
            <AsideInstrumentsMenu
              selectedItem={selectedType}
              setSelectedItem={setSelectedType}
              title="Instruments"
              items={InstrumentsModel().types}
              setStatus={setStatus}
              status={status}
            />
          </DivAsideMenu>
          <DivTable>
            <DivButtonTable>
              <HolderOverflowTable>
                <TableInstrumentComponent
                  dados={data}
                  setEdit={setEdit}
                  setShowEditModal={setShowEditModal}
                  setData={setData}
                />
              </HolderOverflowTable>
            </DivButtonTable>
            <DivButton>
              <DefaultButton
                title="ExportTable"
                action={() => {
                  ExportData.exportInstrumentList(data, selectedType);
                }}
              />
              {userRoleInModule !== 'viewer' && (
                <DefaultButton
                  title="AddInstrument"
                  action={() =>
                    setShowRegisterInstrumentModal(!showRegisterInstrumentModal)
                  }
                />
              )}
            </DivButton>
          </DivTable>
        </HolderViewInstruments>
        {showEditModal && (
          <EditInstrumentsModal
            edit={edit}
            selectedType={selectedType}
            createdReading={createdReading}
            setCreatedReading={setCreatedReading}
            setShowModal={(show) => {
              setShowEditModal(show);
              if (!show) {
                setDataChanged(true);
              }
            }}
            showModal={showEditModal}
            setDataChanged={setDataChanged}
          />
        )}
        {showRegisterInstrumentModal && (
          <RegisterInstrument
            setDataChanged={setDataChanged}
            showModal={showRegisterInstrumentModal}
            setShowModal={setShowRegisterInstrumentModal}
            selectedType={selectedType}
          />
        )}
      </ViewInstrumentsModal>
    </ViewInstrumetBackground>
  );
}
