import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivButtonComments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const DivContainer = styled.div`
  padding: 10px 25px;
  border: 1px solid ${Color.Brown1};
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  flex-direction: column;
  width: 100%;
`;

export const DivFileInputs = styled.div`
  display: flex;
  gap: 10px;

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    flex-direction: column;
  }
`;

export const DivImageFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DivMarginTop = styled.div`
  margin-top: 20px;
`;

export const DivButtons = styled.div`
  display: flex;
`;