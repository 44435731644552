import styled, { CSSProperties, createGlobalStyle } from 'styled-components';

export const Color = {
  Blue: '#0079d5',
  Brown1: 'rgba(150, 116, 66, 1)',
  Brown2: ' #BC9A68',
  Brown3: ' #DBC8AD',
  Title2: 'rgba(150, 116, 66, 1)',
  Golden2: ' #FFD285',
  Gray1: 'rgba(217, 217, 217, 1)',
  Gray2: ' #3B3838',
  Gray3: ' #ACACAC',
  backgroundColor: '#292929',
  Text1: 'rgba(41, 41, 41, 1)',
  Red1: ' #9B0000',
  Green1: ' #218000',
  Yellow1: ' #FFD600',
  White: '#FFFFFF',
  Disabled: '#828282',
  Gray4: '#000'
};

export const FontSize = {
  XLarge: '36px',
  Large: '30px',
  XMedium: '22px',
  Medium: '18px',
  Small: '14px',
  Xsmall: '12px'
};

export const DefaultLogo = styled.img.attrs(() => ({ draggable: 'false' }))`
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
`;

export const IconStyle = {
  fontSize: '20pt',
  color: Color.White,
  cursor: 'pointer',
  padding: '5px',
  borderRadius: '5px'
};

export const RegisterInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${Color.Title2};
  padding: 7px 5px 7px 5px;
  color: ${Color.White};
  width: 200px;
  margin: 15px;
`;

export const InputPlaceholder = styled.span`
  position: absolute;
  font-size: 8pt;
  bottom: 3px;
  right: 20px;
`;

export const NumbericFormatStyle: CSSProperties = {
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: `1px solid ${Color.Title2}`,
  padding: '7px 5px 7px 5px',
  color: `${Color.White}`,
  width: '200px',
  margin: ' 20px'
};

export const InputCheckBox = styled.input`
  display: none;
`;

export const CheckText = styled.span`
  font-size: ${FontSize.Small};
  user-select: none;
  position: absolute;
  width: max-content;
  left: 23px;
`;

export const CheckBox = styled.label`
  position: relative;
  box-shadow: ${Color.White} 0px 0px 0px 2px;
  /* background-color: rgba(16, 16, 16, 0.5); */
  height: 15px;
  width: 15px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 0.26em;
    top: 0.1em;
    width: 0.25em;
    height: 0.5em;
    border: solid green;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
    transition: all 500ms ease-in-out;
  }
`;

export const RadioCheboxArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Adiciona espaçamento entre os itens */
  margin: 0 10px 0 10px;
`;

export const RadioCheckbox = styled.label`
  display: block;
  cursor: pointer;
  width: 7px;
  height: 7px;
  border: 3px solid rgba(255, 255, 255, 0);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 0px 2px ${Color.Title2};
  div {
    width: 60px;
    height: 60px;
    background-color: ${Color.Title2};
    top: -52px;
    left: -55px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 0;
  }

  input[type='radio']:checked + div {
    left: -10px;
    top: -10px;
  }
  input[type='radio'] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }
  .transition {
    transition: 300ms ease;
  }
`;

export const RadioCheck = styled.label`
  user-select: none;
  cursor: pointer;
  margin-left: 10px;
  font-size: ${FontSize.Small};
`;

export const MaxWindowWidth = '100vw';

export const MaxWindowHeight = '100vh';

const GlobalStyle = createGlobalStyle`

 .custom-datepicker {
background-color: transparent;
border: none;
color: ${Color.Text1};
cursor: pointer;
width: 120px;
}

.react-datepicker{
display: flex;
background-color: ${Color.White};
border: 1px solid ${Color.Gray1};
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__header {
  background-color: ${Color.Brown3};
    color: white;
    height: 40px;
    border-bottom: 1px solid transparent;
}

.react-datepicker__month {
  background-color: ${Color.White};
  margin: 0;
}

.react-datepicker__day {
  color: ${Color.Brown1};
}

.react-datepicker__time-list-item:hover {
  background-color: ${Color.Brown3};
}

.react-datepicker__navigation-icon::before{
border-color: ${Color.Brown1};
}

.react-datepicker__input-container .react-datepicker__calendar-icon{
  fill: ${Color.Title2};
  padding: 0.3rem;
right: 0px;
}
.react-datepicker__close-icon::after{
  background-color: #ff000070;
  width: 13px;
  height:5px ;
}
.react-datepicker__time-container {
  position: relative;
  top: 0;
  border: none;
}
.react-datepicker__time-container .react-datepicker__time{
  background-color: ${Color.Brown1};
  color: #fff;
}

.react-datepicker__navigation {
  overflow: visible;
}
.react-datepicker__navigation--next--with-time {
  right: 0;
}

.react-datepicker__day:hover, .react-datepicker__time-list-item:hover{
  color: #000;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: ${Color.Brown1};
  color: #fff;
}

.datapicker-separator{
  color: ${Color.Gray4};
  font-size: 20px;
  margin-left:20px;
}

body{
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif, Bahnschrift SemiBold;
    background-color:${Color.backgroundColor};
    color:${Color.White};

 input::placeholder {
    color: ${Color.Brown1};
 }
 input:focus {
    outline: none
  }
  input:checked ~ .checkmark {
  box-shadow: #fff;
  background-color: #fff;
  height: 15px;
  width: 15px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
}
input:checked ~ .checkmark:after {
  display: block;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]{
    background-color: transparent;
    color: ${Color.White};
    border: none;
    margin: 0;
    padding: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(1) brightness(100%) sepia(0%) saturate(100%) hue-rotate(-55deg);
}
}

@supports (scrollbar-color: auto) {
    body {
        scrollbar-color: ${Color.Brown1} ${Color.Brown3};
    }
}

/* Estilos para browsers que suportam ::-webkit-scrollbar */
@supports selector(::-webkit-scrollbar) {
    body {
        scrollbar-width: thin; /* Para Firefox */
    }
    body::-webkit-scrollbar {
        background: ${Color.Brown1};
    }
    body::-webkit-scrollbar-thumb {
        background: ${Color.Brown3};
    }
}
`;
export default GlobalStyle;
