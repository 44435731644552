import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Text from '../../Atoms/Text/Text';
import { InputSelectSearchProps } from './InputSelectSearch.interfaces';
import {
  ErrorStyled,
  InputContainer,
  InputStyled,
  StyleSelect
} from './InputSelectSearch.styles';

const InputSelectSearch = ({
  label,
  options,
  error,
  errorMessage,
  name,
  control,
  hideSelectedOptions,
  width,
  placeholder,
  value,
  onChange,
  disabled,
  required
}: InputSelectSearchProps) => {
  const { t } = useTranslation();

  const renderSelect = (field: any) => (
    <StyleSelect
      {...field}
      id={name}
      width={width}
      error={error}
      placeholder={placeholder}
      options={options}
      hideSelectedOptions={hideSelectedOptions}
      isDisabled={disabled}
      value={
        options.find((option) => option.value === field?.value?.value) || null
      }
      onChange={(option) => field.onChange(option)}
    />
  );

  return (
    <InputContainer>
      <Text
        type="label"
        color={disabled ? 'disabled' : 'brown'}
        htmlFor={name}
        error={error}
      >
        {label} {required && '*'}
      </Text>
      <InputStyled>
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({ field }) => renderSelect(field)}
          />
        ) : (
          renderSelect({ value, onChange })
        )}
      </InputStyled>
      {error ? <ErrorStyled>{t(errorMessage)}</ErrorStyled> : <ErrorStyled />}
    </InputContainer>
  );
};

export default InputSelectSearch;
