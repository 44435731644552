import {
  DivContainerGroupText,
  DivContainerSpanCard
} from './GroupText.styles';
import Text from '../../Atoms/Text/Text';
import { GroupTextProps } from './GroupText.interfaces';
// TODO: Este component pode não ser necessário, pois pode ser substituído por um componente de texto comum

export const GroupText = ({ title, value }: GroupTextProps) => {
  return (
    <DivContainerGroupText>
      <Text type="label">{title}</Text>
      <DivContainerSpanCard>
        <Text type="span" color="black">
          {value}
        </Text>
      </DivContainerSpanCard>
    </DivContainerGroupText>
  );
};
