import { useMutation } from '@apollo/client';
import {
  EditWaterLevelDocument,
  EditWaterLevelMutation,
  EditWaterLevelMutationVariables
} from '../../graphql/generated/editWaterLevel.mutation';
import { ListWaterLevelByStructureDocument } from '../../graphql/query/generated/listWaterLevelByStructure.query';

export function useEditWaterLevel(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editWaterLevel, { data, error, loading }] = useMutation<
    EditWaterLevelMutation,
    EditWaterLevelMutationVariables
  >(EditWaterLevelDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListWaterLevelByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editWaterLevel,
    data,
    error,
    loading
  };
}
// O hook acima edita as infos gerais do instrumento nível dágua, tais quais:
// activationDate?:
// coordinateE
// coordinateN
// deactivationDate?
// installLocation?
// installationDate?
// instrumentId
// name
// operationalStatus:
// readingType
// sectionsId
// tudo que está com (?) é opcional na chamada, é possível ver essas variáveis ao clicar em EditWaterLevelMutationVariables
