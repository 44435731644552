import { useLazyQuery, useQuery } from '@apollo/client';
import { StructureInfoInput } from '../../graphql/base-schema';
import {
  ListWaterLevelByStructureDocument,
  ListWaterLevelByStructureQuery,
  ListWaterLevelByStructureQueryVariables
} from '../../graphql/query/generated/listWaterLevelByStructure.query';

export function useListWaterLevelByStructure(
  structureInfo: StructureInfoInput,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListWaterLevelByStructureQuery,
    ListWaterLevelByStructureQueryVariables
  >(ListWaterLevelByStructureDocument, {
    variables: {
      structureInfo
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

//Esse hook acima lista tos os pluviômetros de uma estrutura o estrutura associada.
//É importante realizar essa listagem para que seja possível ver os pluviômetros existentes antes de editar algum deles
//Na tela no figma a listagem dos instrumentos não está separada por instrumento, então terá que fazer uma lógica
//para que quando a pessoa clique em algum tipo de instrumento realize a chamada específica
//para mais infos consultar tabela do figma de listagem de instrumentos

//Caso seja útil é possível também fazer com que a listagem seja realizada a partir de alguma ação, como por exemplo
// algum onClick, ref: https://www.apollographql.com/docs/react/api/react/hooks/#uselazyquery

export function useListWaterLevelByStructureLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listWaterLevels, { data, loading, error }] = useLazyQuery<
    ListWaterLevelByStructureQuery,
    ListWaterLevelByStructureQueryVariables
  >(ListWaterLevelByStructureDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listWaterLevels,
    data,
    loading,
    error
  };
}
