import { useMutation } from '@apollo/client';
import {
  CreatePiezometerReadingDocument,
  CreatePiezometerReadingMutation,
  CreatePiezometerReadingMutationVariables
} from '../../graphql/generated/createPiezometerReading.mutation';
import { ListReadingByPiezometersPagDocument } from '../../graphql/query/generated/listReadingByPiezometerPag.query';

export function useCreatePiezometerReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createReading, { data, error, loading }] = useMutation<
    CreatePiezometerReadingMutation,
    CreatePiezometerReadingMutationVariables
  >(CreatePiezometerReadingDocument, {
    refetchQueries: [ListReadingByPiezometersPagDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createReading,
    data,
    error,
    loading
  };
}

// O hook acima cria as infos de leitura de um piezômetro ou ina, tais quais:
// cote?
// date
// instrumentId?
// instrumentName
// isDry?
// observation?
// reading?
// tudo que está com (?) é opcional na chamada, é possível ver essas variáveis ao clicar em CreatePiezometerReadingMutationVariables,
// essa mesma chamada pode ser usada para salvar leituras em lote, por isso o fato da variável data ser um array
// Ao tentar cadastrar a leitura de um instrumento essa chamada te retorna um array contendo a linha, o instrumento e o erro que deu
// caso alguma das leituras que tentou cadastrar tenha dado erro. Nesse caso nenhuma das leituras são cadastradas.
// Caso o retorno seja um array vazio é porque foi salvo com sucesso
