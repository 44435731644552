import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const CarouselContainer = styled.div`
  width: 100%;
  .carousel .control-dots {
    margin: -2px 0;
  }

  .carousel .slide {
    background: none;
    height: 550px;
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
  }
  .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
  }
  .carousel .thumbs-wrapper {
    margin: 20px 0;
    overflow: auto;
  }
  .carousel .thumbs {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .carousel .thumb {
    border: 2px solid ${Color.Brown3};
    margin: 0 2px;
    transition: border-color 0.3s ease;
  }
  .carousel .thumb:hover {
    border-color: #967442;
  }
  .carousel .thumb.selected {
    transform: scale(1.1);
  }
  .carousel .control-prev.control-arrow,
  .carousel .control-next.control-arrow {
    background: ${Color.Brown1};
    border: ${Color.Brown1};
    color: #967442;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: auto;
  }

  @media (max-width: 992px) {
    .carousel .slide {
      height: 400px;
    }
  }

  @media (max-width: 768px) {
    .carousel .slide {
      height: 300px;
    }
  }

  @media (max-width: 576px) {
    .carousel .slide {
      height: 200px;
    }
  }
`;

export const DivPDFThumb = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${Color.White};
`;
