import { useMutation } from '@apollo/client';
import {
  AddWaterLevelAlertLevelsDocument,
  AddWaterLevelAlertLevelsMutation,
  AddWaterLevelAlertLevelsMutationVariables
} from '../../graphql/generated/addWaterLevelAlertLevels.mutation';
import { ListWaterLevelByStructureDocument } from '../../graphql/query/generated/listWaterLevelByStructure.query';

export function useAddWaterLevelControlLevels(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addWaterLevelControlLevels, { data, error, loading }] = useMutation<
    AddWaterLevelAlertLevelsMutation,
    AddWaterLevelAlertLevelsMutationVariables
  >(AddWaterLevelAlertLevelsDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListWaterLevelByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addWaterLevelControlLevels,
    data,
    error,
    loading
  };
}
