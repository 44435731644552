import { FaRegFolderOpen } from 'react-icons/fa6';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import { VolumeProps } from './Volume.interface';
import {
  DivContainerVolumeList,
  DivPadding,
  DivVolumeContainer,
  DivVolumeList
} from './Volume.styles';

function renderNestedVolumes(data: any, setId: (id: string) => void) {
  return data.map((item: { id: string; name: string; nested: any[] }) => (
    <DivPadding key={item.id}>
      <DivVolumeList>
        <Text type={'span'}>{item.name}</Text>
        {(!item.nested || item.nested.length === 0) && (
          <Icon
            Icon={FaRegFolderOpen}
            variant="small"
            onClick={() => setId(item.id)}
          />
        )}
      </DivVolumeList>
      {item.nested &&
        item.nested.length > 0 &&
        renderNestedVolumes(item.nested, setId)}
    </DivPadding>
  ));
}

export function Volume({ data, setId, title }: VolumeProps) {
  return (
    <DivVolumeContainer>
      {title && <Text type={'h4'}>{title}</Text>}
      <DivContainerVolumeList>
        {renderNestedVolumes(data, setId)}
      </DivContainerVolumeList>
    </DivVolumeContainer>
  );
}
