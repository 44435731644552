import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindFilesPagQueryVariables = Types.Exact<{
  psbId: Types.Scalars['String']['input'];
  structureId: Types.Scalars['String']['input'];
  pageInfo?: Types.InputMaybe<Types.PageInput>;
}>;


export type FindFilesPagQuery = { __typename?: 'Query', findFilesPag: { __typename?: 'PaginatedPsbFileType', count: number, nodes: Array<{ __typename?: 'FileType', id: string, file: string, name: string, date: Date }>, pageInfo: { __typename?: 'PageInfo', page: number, offset: number, limit: number, totalPages: number, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const FindFilesPagDocument = gql`
    query FindFilesPag($psbId: String!, $structureId: String!, $pageInfo: PageInput) {
  findFilesPag(psbId: $psbId, structureId: $structureId, pageInfo: $pageInfo) {
    count
    nodes {
      id
      file
      name
      date
    }
    pageInfo {
      page
      offset
      limit
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type FindFilesPagQueryResult = Apollo.QueryResult<FindFilesPagQuery, FindFilesPagQueryVariables>;