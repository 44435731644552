import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ActionPlanApprovalFlowDocument,
  ActionPlanApprovalFlowMutation,
  ActionPlanApprovalFlowMutationVariables
} from '../../../../data/graphql/generated/actionPlanApprovalFlow.mutation';
import {
  SetActionPlanProgressDocument,
  SetActionPlanProgressMutation,
  SetActionPlanProgressMutationVariables
} from '../../../../data/graphql/generated/setActionPlanProgress.mutation';
import { FindActionPlanGeneratedDocument } from '../../../../data/services/ActionPlanGeneratedService';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import { ActionPlanApprovalFlowProps } from './ActionPlanApprovalFlow.interface';

const ActionPlanApprovalFlow = ({
  setIsApproval,
  situation,
  timeFrame,
  responsibleId,
  priority
}: ActionPlanApprovalFlowProps) => {
  const { t } = useTranslation();
  const { actionPlanId } = useParams();

  const [actionPlanApprovalFlow] = useMutation<
    ActionPlanApprovalFlowMutation,
    ActionPlanApprovalFlowMutationVariables
  >(ActionPlanApprovalFlowDocument);

  const [setActionPlanProgress] = useMutation<
    SetActionPlanProgressMutation,
    SetActionPlanProgressMutationVariables
  >(SetActionPlanProgressDocument);

  const handleApproveFlow = () => {
    if (situation !== 'Opened') {
      actionPlanApprovalFlow({
        variables: {
          actionPlanId: actionPlanId ?? ''
        },
        onCompleted: () => {
          toastfySuccess(t('actionPlanApproved'));
          setIsApproval(false);
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
          setIsApproval(false);
        },
        refetchQueries: [FindActionPlanGeneratedDocument]
      });
    } else {
      setActionPlanProgress({
        variables: {
          data: {
            timeFrame: timeFrame,
            responsibleId: responsibleId,
            priority: priority,
            actionPlanId: actionPlanId ?? ''
          }
        },
        onCompleted: () => {
          toastfySuccess(t('actionPlanApproved'));
          setIsApproval(false);
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
          setIsApproval(false);
        },
        refetchQueries: [FindActionPlanGeneratedDocument]
      });
    }
  };

  return (
    <ModalConfirm
      onClose={() => setIsApproval(false)}
      onConfirm={() => handleApproveFlow()}
      title={t('requestApproval')}
      text={t('requestApprovalText')}
    />
  );
};

export default ActionPlanApprovalFlow;
