import {
  InstrumentButtonInterface,
  ViewInstrumentsButtonInterface
} from '../../@Types/Instruments/instruments';

class ViewInstrument {
  static ShowModal({
    showModal,
    setShowModal
  }: ViewInstrumentsButtonInterface) {
    if (setShowModal) !showModal ? setShowModal(true) : setShowModal(false);
  }
}

export default ViewInstrument;
