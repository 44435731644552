import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from './Button.interfaces';
import { ButtonPrimaryStyled, ButtonSecondaryStyled } from './Button.styles';

const setVariant = {
  primary: ButtonPrimaryStyled,
  secondary: ButtonSecondaryStyled
};

const Button: React.FC<ButtonProps> = ({
  text,
  variant,
  onClick,
  disabled,
  size = 'medium',
  error,
  ...props
}) => {
  const { t } = useTranslation();
  const ButtonComponent = setVariant[variant];
  return (
    <ButtonComponent
      error={error}
      onClick={onClick}
      disabled={disabled}
      size={size}
      {...props}
    >
      {t(text).charAt(0).toLocaleUpperCase() +
        t(text).slice(1).toLocaleLowerCase()}
    </ButtonComponent>
  );
};

export default Button;
