import { useTranslation } from 'react-i18next';
import { InstrumentButtonInterface } from '../../../@Types/Instruments/instruments';
import { CancelButtonStyled } from '../ButtonsStyle';

export const CancelButtonComponent = ({
  setShowModal
}: InstrumentButtonInterface) => {
  const { t } = useTranslation();

  return (
    <CancelButtonStyled
      onClick={() => {
        if (setShowModal) {
          setShowModal(false);
        }
      }}
    >
      {t('Cancel').toLocaleUpperCase()}
    </CancelButtonStyled>
  );
};
