import { useLazyQuery, useQuery } from '@apollo/client';
import {
  PageInput,
  PiezometerInaFilterInput,
  SortInput,
  StructureInfoInput
} from '../../graphql/base-schema';
import {
  ListReadingBySurfaceMarkersPagDocument,
  ListReadingBySurfaceMarkersPagQuery,
  ListReadingBySurfaceMarkersPagQueryVariables
} from '../../graphql/query/generated/listReadingBySurfaceMarkersPag.query';

export interface ListSurfaceMarkersReadingPagVariablesModel {
  structureInfo: StructureInfoInput;
  filters: PiezometerInaFilterInput;
  pageInfo?: PageInput;
  sortInfo?: SortInput[];
}

export function useListReadingBySurfaceMarkersPag(
  input: ListSurfaceMarkersReadingPagVariablesModel,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { structureInfo, filters, pageInfo, sortInfo } = input;
  const { data, loading, error } = useQuery<
    ListReadingBySurfaceMarkersPagQuery,
    ListReadingBySurfaceMarkersPagQueryVariables
  >(ListReadingBySurfaceMarkersPagDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError,
    variables: {
      structureInfo,
      sortInfo,
      filters,
      pageInfo
    }
  });

  return {
    data,
    loading,
    error
  };
}

export function useListReadingBySurfaceMarkersPagLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listReadingSurfaceMarker, { data, loading, error }] = useLazyQuery<
    ListReadingBySurfaceMarkersPagQuery,
    ListReadingBySurfaceMarkersPagQueryVariables
  >(ListReadingBySurfaceMarkersPagDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listReadingSurfaceMarker,
    data,
    loading,
    error
  };
}
