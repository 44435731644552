import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListUserNotificationQueryVariables = Types.Exact<{
  filters: Types.UserNotificationFilters;
}>;


export type ListUserNotificationQuery = { __typename?: 'Query', listUserNotification: Array<{ __typename?: 'UserNotificationType', alertLevel: string, alertLevelValue: number, id: string, installLocation?: string | null, name: string, reading: number, readingDate: Date, sentAt: Date, structureName: string, unread: boolean }> };


export const ListUserNotificationDocument = gql`
    query ListUserNotification($filters: UserNotificationFilters!) {
  listUserNotification(filters: $filters) {
    alertLevel
    alertLevelValue
    id
    installLocation
    name
    reading
    readingDate
    sentAt
    structureName
    unread
  }
}
    `;
export type ListUserNotificationQueryResult = Apollo.QueryResult<ListUserNotificationQuery, ListUserNotificationQueryVariables>;