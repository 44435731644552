import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import {
  QueryDocument,
  QueryQuery,
  QueryQueryVariables
} from '../../../../data/graphql/query/generated/findAllActionPlanImages.query';
import { BUCKET_URL } from '../../../../utils/const';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError } from '../../../Toastify';
import Icon from '../../Atoms/Icon/Icon';
import Image from '../../Atoms/Image/Image';
import Text from '../../Atoms/Text/Text';
import FileViewer from '../../Molecules/FileViewer/FileViewer';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import {
  DivContainerImages,
  DivImages,
  DivTitle
} from './ActionPlanImagesPage.styles';

const ActionPlanImagesPage = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { structureId, actionPlanId } = useParams();
  const [selectImage, setSelectImage] = useState<FileType | null>(null);

  const { data: actionPlanImagesData, loading: actionPlanImagesLoading } =
    useQuery<QueryQuery, QueryQueryVariables>(QueryDocument, {
      variables: {
        actionPlanId: actionPlanId!
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });

  const handleNavigateBack = () => {
    navigate(`/${structureId}/actionplan/${actionPlanId}`);
  };

  const urlsPicturesData = actionPlanImagesData?.findAllActionPlanImages.map(
    (url) => ({
      url: `${BUCKET_URL}/${url}`,
      name: url,
      id: url,
      file: undefined
    })
  );

  return actionPlanImagesLoading ? (
    <SimpleContentTemplate
      title={<Skeleton height={35} width={400} />}
      button={undefined}
      content={
        <DivContainerImages>
          <DivImages>
            <Skeleton height={200} width={200} />
            <Skeleton height={200} width={200} />
            <Skeleton height={200} width={200} />
            <Skeleton height={200} width={200} />
            <Skeleton height={200} width={200} />
            <Skeleton height={200} width={200} />
          </DivImages>
        </DivContainerImages>
      }
    />
  ) : (
    <SimpleContentTemplate
      title={
        <DivTitle>
          <Icon Icon={IoIosArrowBack} onClick={() => handleNavigateBack()} />
          <Text weight="bold" type="h2">
            {translate('picturesOfAnomaly')}
          </Text>
        </DivTitle>
      }
      button={undefined}
      content={
        <DivContainerImages>
          <DivImages>
            {urlsPicturesData?.map((picture, index) => (
              <Image
                key={index}
                alt={picture.url}
                src={picture.url}
                width="50%"
                onClick={() => setSelectImage(picture)}
              />
            ))}
            {selectImage && urlsPicturesData && (
              <FileViewer
                files={urlsPicturesData}
                selectedFile={selectImage}
                setSelectedFile={setSelectImage}
                onClose={() => setSelectImage(null)}
              />
            )}
          </DivImages>
        </DivContainerImages>
      }
    />
  );
};

export default ActionPlanImagesPage;
