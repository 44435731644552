import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { ImagesType, UserData } from '../../../@Types/types';
import { DatePickerComponent } from '../../../Components/DatePicker';
import { InputFile } from '../../../Components/Forms/FormInspectionsStyle';
import { DefaultInput } from '../../../Components/Inputs/InputsStyle';
import { RegisterInstrumentBackground } from '../../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../Components/Toastify';
import { ProjectContext } from '../../../Context/ContextAPI';
import ReadingsController from '../../../Controllers/Instruments/ReadingsController';
import ToastifyModel from '../../../Models/ToastifyModel';
import { useCreateInspection } from '../../../data/hooks/use-create-inspection.mutation';
import { useUpdateDate } from '../../../data/hooks/use-update-date';
import { RegisterArea } from '../InspectionsStyle';
import { StepsRegisterInspections } from '../Register/StepsRegisterInspections';
import { AddParticipantComponent } from './AddParticipantComponent';
import { API_URL } from '../../../utils/const';
import ModalStep from '../../../Components/V2/Molecules/ModalStep/ModalStep';
import FileList from '../../../Components/V2/Molecules/FileList/FileList';
import { FileType } from '../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';
import { useParams } from 'react-router-dom';

export function AttributionInspection({
  showModal,
  setShowModal,
  editing,
  inspectionId,
  setInspectionId,
  selectedInspection
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  editing: boolean;
  inspectionId: string;
  setInspectionId: Dispatch<SetStateAction<string>>;
  selectedInspection: any;
}) {
  const { t }: any = useTranslation();
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const { structureId } = useParams();
  const [participants, setParticipants] = useState<any[]>([
    { name: userData.name }
  ]);
  const [subParticipants, setSubParticipants] = useState<any[]>([]);
  const [showStepsInspectionsModal, setShowStepsInspectionsModal] =
    useState<boolean>(false);
  const { createInspection } = useCreateInspection();
  const [date, setDate] = useState<Date>(new Date());
  const [imageFileInspection, setImageFileInspection] = useState<ImagesType[]>([
    {
      fileSent: false,
      file: false,
      id: null,
      endpoint: 'mss=genericData',
      topic: '',
      title: ''
    }
  ]);
  const [requiredFieldImage, setRequiredFieldImage] = useState<boolean>(false);
  const { updateDate } = useUpdateDate();
  const [pdf, setPdf] = useState<FileType[]>([]);

  let newArray: any[];

  async function postSituationAccess(formData: FormData, id: string) {
    const response = await fetch(`${API_URL}/inspection/${id}/pdf`, {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: userData.token
      }
    });

    if (response.status !== 200) {
      throw new Error('Erro');
    }
  }

  async function SendAtribuitionInspection() {
    if (inspectionId !== '') {
      setRequiredFieldImage(false);
      setShowStepsInspectionsModal(!showStepsInspectionsModal);
      return;
    } else {
      if (
        imageFileInspection[0].fileSent === false ||
        imageFileInspection[0].fileSent === null
      ) {
        setRequiredFieldImage(true);
        toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
        return;
      }

      if (subParticipants.length > 0) {
        const res = subParticipants.map((obj) => {
          const { id, ...rest } = obj;
          return rest;
        });
        newArray = participants.concat(res);
      } else {
        newArray = participants;
      }

      const createResponse = await createInspection({
        variables: {
          data: {
            structureId: structureId?.toString() || '',
            participants: newArray,
            date: date
          }
        }
      });
      toastifyLoading('Cadastrando atribuição...');

      if (createResponse.data) {
        setRequiredFieldImage(false);
        setInspectionId(createResponse.data.createInspection);
        toastfyDimiss('toastLoading');
        ReadingsController.ShowModal({
          showModal: showStepsInspectionsModal,
          setShowModal: setShowStepsInspectionsModal
        });
      } else if (createResponse.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          createResponse.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
      }

      const updatedImageFileInspection = [...imageFileInspection];

      if (createResponse.data !== null && createResponse.data !== undefined) {
        updatedImageFileInspection[0].id = createResponse.data.createInspection;
        setImageFileInspection(updatedImageFileInspection);

        if (updatedImageFileInspection[0].file !== null) {
          const formData = new FormData();
          formData.append(`pdf`, updatedImageFileInspection[0].file[0]);
          postSituationAccess(formData, createResponse.data.createInspection);
        }
      }
    }
  }

  function handleChangeImage(e: ChangeEvent<HTMLInputElement>) {
    const filesSelected = e.target.files && e.target.files.length > 0;

    setImageFileInspection((state) => [
      {
        ...state[0],
        fileSent: filesSelected,
        file: e.target.files,
        id: null,
        endpoint: 'mss=genericData',
        topic: '',
        title: e.target.dataset.title
      }
    ]);
    setPdf(
      Array.from(e.target.files).map((file: any) => {
        return {
          id: null,
          name: file.name,
          url: URL.createObjectURL(file)
        };
      })
    );
  }

  async function updateDateFunction(e: any) {
    setDate(e);
    if (editing) {
      const response = await updateDate({
        variables: {
          inspectionId: inspectionId,
          newDate: date
        }
      });
      toastifyLoading('Atualizando data...');
      if (response.data) {
        toastfyDimiss('toastLoading');
      }
    }
  }

  const handleDeleteFile = (pdf: FileType) => {
    if (pdf.id) {
      toastfyError('Não é possível deletar o arquivo');
      return false;
    } else {
      const updatedImageFileInspection = [...imageFileInspection];
      updatedImageFileInspection[0].file = null;
      setImageFileInspection(updatedImageFileInspection);
      setPdf([]);
      return true;
    }
  };

  const hanldePdf = async () => {
    const response = await fetch(`${API_URL}/${selectedInspection.userPdf}`);
    const id = selectedInspection.userPdf.replace('.pdf', '');
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    setPdf([
      {
        id: id,
        name: selectedInspection.id,
        url: url
      }
    ]);
  };

  useEffect(() => {
    if (selectedInspection.userPdf) {
      hanldePdf();
    }
  }, []);

  return (
    <>
      <RegisterInstrumentBackground>
        <ModalStep
          width={30}
          height={70}
          onClose={() => setShowModal(false)}
          title={`${editing ? `${t('Edit')} ${t('Inspection')}` : t('RegisterNewInspection')}`}
          saveButton="Advance"
          steps={[
            {
              name: '',
              component: (
                <RegisterArea>
                  <DefaultInput
                    disabled
                    style={{ width: '293px' }}
                    placeholder={`${t('InspectedFor')} (${t('name')})`}
                    value={userData.name}
                  />
                  <AddParticipantComponent
                    setSubParticipants={setSubParticipants}
                    subParticipants={subParticipants}
                    editing={editing}
                    inspectionId={inspectionId}
                    selectedInspection={selectedInspection}
                  />
                  <div>
                    {t('DateOfInspection')}:
                    <DatePickerComponent
                      startDate={date}
                      setStartDate={setDate}
                      showTimeSelect={false}
                      onChange={(e: any) => updateDateFunction(e)}
                    />
                  </div>
                  <InputFile>
                    <input
                      type="file"
                      name="image"
                      id="File_crack"
                      accept=".pdf"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeImage(e)
                      }
                      style={{
                        outline: requiredFieldImage ? '1px solid red' : 'none'
                      }}
                    />
                  </InputFile>
                  {pdf.length > 0 && (
                    <FileList
                      files={pdf}
                      onDelete={(pdf: FileType) => {
                        handleDeleteFile(pdf);
                      }}
                    />
                  )}
                </RegisterArea>
              ),
              onNext: () => SendAtribuitionInspection()
            }
          ]}
        />
      </RegisterInstrumentBackground>
      {showStepsInspectionsModal && (
        <StepsRegisterInspections
          inspectionId={inspectionId}
          showModal={showStepsInspectionsModal}
          setShowStepsInspectionsModal={setShowStepsInspectionsModal}
          setShowModal={setShowModal}
          editing={editing}
        />
      )}
    </>
  );
}
