import styled from 'styled-components';

export const DivButtonHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DivContainerCreateActionPlan = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
  width: 90%;
`;

export const DivContainerInputFile = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 15%;

  div:nth-child(2) {
    height: 50%;
  }
`;

export const DivContainerInputs = styled.div`
  display: flex;
  width: 90%;
  justify-items: center;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
`;

export const DivSaveButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const DivContainerCreateButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 50px 0;
`;

export const DivButtonApproveFlow = styled.div`
  display: flex;
  align-items: center;
`;

export const DivButtonSeeAll = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const DivButtonsPictures = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;
