import React, { useContext, useEffect } from 'react';
import { MdPhotoCamera } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useFindGeneralInfo } from '../../../../data/services/ATOService';
import { BUCKET_URL } from '../../../../utils/const';
import SkeletonAtoDashboardPage from '../../../Skeleton/AtoDashboardPage/SkeletonAtoDashboardPage';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { GroupText } from '../../Molecules/GroupText/GroupText';
import { PictureGallery } from '../../Molecules/PictureGallery/PictureGallery';
import SquareCard from '../../Molecules/SquareCard/SquareCard';
import { VideosGallery } from '../../Molecules/VideosGallery/VideosGallery';
import ContentActionCard from '../../Organisms/ContentActionCard/ContentActionCard';
import { TemplateAto } from '../../Templates/TemplateAto/TemplateAto';
import { HolderPictures } from './AtoDashboardStyles';

import { useQuery } from '@apollo/client';
import { FaCirclePlay } from 'react-icons/fa6';
import { ModuleData } from '../../../../@Types/types';
import { ProjectContext } from '../../../../Context/ContextAPI';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import { HolderIconAndValueTable } from '../AtoAdminPage/AtoAdminPageStyles';
import {
  CardDataInterface,
  contractInfosInterface
} from './AtoDashboard.interfaces';
import {
  FindAllAtoQuery,
  FindAllAtoQueryVariables,
  FindAllAtoDocument
} from '../../../../data/graphql/query/generated/findAllAto.query';
import { t } from 'i18next';

export interface listRecordsTableDataProps {
  date: string;
  number: string;
  situation: string;
  imagesCount: JSX.Element;
  videosCount: JSX.Element;
  id: string;
}

const CardList: React.FC<{ data: CardDataInterface[] }> = ({ data }) => (
  <>
    {data.map((item, index) => (
      <SquareCard key={index} label={item.title} value={item.value} />
    ))}
  </>
);

const GroupInfos: React.FC<{ data: contractInfosInterface[] }> = ({ data }) => (
  <>
    {data.map((item, index) => (
      <GroupText key={index} title={item.title} value={item.value} />
    ))}
  </>
);

const AtoDashboardPage = () => {
  const { atoId, structureId } = useParams<{
    atoId: string;
    structureId: string;
  }>();
  const navigate = useNavigate();

  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => ModuleData;
  };
  const userRoleInModule = getUserRoleByModule('ATO');

  const { data: allAtos } = useQuery<FindAllAtoQuery, FindAllAtoQueryVariables>(
    FindAllAtoDocument,
    {
      variables: {
        structureId: structureId || ''
      }
    }
  );

  const { data: allAtoSpecificInfos, loading } = useFindGeneralInfo(
    atoId ? atoId : ''
  );
  const atoTitle = `${allAtoSpecificInfos?.findGeneralInfo.title} -
   ${allAtoSpecificInfos?.findGeneralInfo.contract}`;
  const recentPictures = allAtoSpecificInfos?.findGeneralInfo.recentPhotos.map(
    (image) => ({
      name: image,
      url: `${BUCKET_URL}/${image}`,
      id: image
    })
  );
  const recentVideos = allAtoSpecificInfos?.findGeneralInfo.recentVideos.map(
    (video) => ({
      name: video,
      url: `${BUCKET_URL}/${video}`,
      id: video
    })
  );

  const contractInfos: contractInfosInterface[] = [
    {
      title: `${t('contractualPeriod')}:`,
      value: allAtoSpecificInfos?.findGeneralInfo.contractualPeriod as string
    },
    {
      title: `${t('dueDate')}:`,
      value: allAtoSpecificInfos?.findGeneralInfo.remainingTerm as string
    },
    {
      title: `${t('estimatedEndDate')}:`,
      value: allAtoSpecificInfos?.findGeneralInfo.finalDate
        ? new Date(
            allAtoSpecificInfos.findGeneralInfo.finalDate
          ).toLocaleDateString()
        : ''
    },
    {
      title: `${t('startDate')}:`,
      value: allAtoSpecificInfos?.findGeneralInfo.initialDate
        ? new Date(
            allAtoSpecificInfos.findGeneralInfo.initialDate
          ).toLocaleDateString()
        : ''
    }
  ];

  const dataTable = [
    {
      key: 'date',
      label: `${t('Date')}`
    },
    {
      key: 'number',
      label: `${t('Number')}`
    },
    {
      key: 'situation',
      label: 'Status'
    },
    {
      key: 'imagesCount',
      label: `${t('Photos')}`
    },
    {
      key: 'videosCount',
      label: 'Vídeos'
    }
  ];

  const cardInfos: CardDataInterface[] = [
    {
      title: `${t('reports')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.recordsCount}`
    },
    {
      title: `${t('occurrences')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.occurrencesCount}`
    },
    {
      title: `${t('observations')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.observationsCount}`
    },
    {
      title: `${t('Photos')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.imagesCount}`
    },
    {
      title: `${t('activities')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.activitiesCount}`
    },
    {
      title: `${t('video')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.videosCount}`
    }
  ];

  const listRecordsTableData =
    allAtoSpecificInfos?.findGeneralInfo.recentRecords.map((record) => ({
      date: new Date(record.date).toLocaleDateString(),
      number: record.recordNumber,
      imagesCount: (
        <HolderIconAndValueTable>
          <MdPhotoCamera /> {record.imagesCount}
        </HolderIconAndValueTable>
      ),
      videosCount: (
        <HolderIconAndValueTable>
          <FaCirclePlay /> {record.videosCount}
        </HolderIconAndValueTable>
      ),
      situation: record.situation,
      id: record.id
    }));

  const navigateToPhotos = () => {
    navigate('fotos');
  };

  const navigateToVideos = () => {
    navigate('videos');
  };

  const navigateToRecords = () => {
    navigate('records');
  };

  const navigateToRecord = (record: listRecordsTableDataProps) => {
    navigate(`/${structureId}/ato/${atoId}/view/${record.id}`);
  };

  const navigateToAtoAdmin = () => {
    navigate(`/${structureId}/ato/admin`);
  };

  useEffect(() => {
    if (allAtos && allAtos.findAllAto.length === 0) {
      navigate(`/${structureId}/ato`);
    }
  }, [allAtos, structureId, navigate]);

  return (
    <>
      {loading ? (
        <SkeletonAtoDashboardPage />
      ) : (
        <TemplateAto
          header={
            <>
              <Text type="h2">{atoTitle}</Text>
              {userRoleInModule?.role !== 'viewer' ? (
                <Button
                  onClick={navigateToAtoAdmin}
                  variant="primary"
                  text={`${t('atoDashboard')}`}
                />
              ) : (
                <></>
              )}
            </>
          }
          cardContent={<CardList data={cardInfos} />}
          infosContent={
            <ContentActionCard title={`${t('deadlineInfo')}`}>
              <HolderPictures>
                <GroupInfos data={contractInfos} />
              </HolderPictures>
            </ContentActionCard>
          }
          imagesContent={
            <ContentActionCard
              action={() => navigateToPhotos()}
              title={`${t('recentPhotos')}`}
            >
              <HolderPictures>
                <PictureGallery imageWidth="80%" files={recentPictures ?? []} />
              </HolderPictures>
            </ContentActionCard>
          }
          tableContent={
            <ContentActionCard
              action={() => navigateToRecords()}
              title={`${t('recentReports')}`}
            >
              <TableComponent
                tableData={listRecordsTableData || []}
                columns={dataTable}
                onRow={(row: listRecordsTableDataProps | any) =>
                  navigateToRecord(row)
                }
              />
            </ContentActionCard>
          }
          videosContent={
            <ContentActionCard
              action={() => navigateToVideos()}
              title={`${t('recentVideos')}`}
            >
              <VideosGallery videosToShow={3} videos={recentVideos ?? []} />
            </ContentActionCard>
          }
        />
      )}
    </>
  );
};

export default AtoDashboardPage;
