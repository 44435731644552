import { ReactNode } from 'react';
import {
  HolderContent,
  HolderHeader,
  HolderTitle,
  PageContainer
} from './SeeMoreTemplateStyles';
import Text from '../../Atoms/Text/Text';
interface TemplateAtoProps {
  title: string;
  header: ReactNode;
  content: ReactNode;
  icon?: ReactNode;
}

export const SeeMoreTemplate = ({
  header,
  title,
  content,
  icon
}: TemplateAtoProps) => {
  return (
    <PageContainer>
      <HolderTitle>
        {icon}
        <Text weight="bold" type="h2">
          {title}
        </Text>
      </HolderTitle>
      <HolderHeader>{header}</HolderHeader>
      <HolderContent>{content}</HolderContent>
    </PageContainer>
  );
};
