import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { RegisterInstrumentModalInterface } from '../../../@Types/Instruments/instruments';
import { ProjectContext } from '../../../Context/ContextAPI';
import ExportData from '../../../Controllers/Export/ExportData';
import ReadingsController from '../../../Controllers/Instruments/ReadingsController';
import InstrumentsModel from '../../../Models/InstrumentsModel';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum
} from '../../../data/graphql/base-schema';
import { useListPiezometersByStructure } from '../../../data/hooks/piezometer/use-list-piezometers-by-structure.query';
import { useListReadingByPiezometerPagLazy } from '../../../data/hooks/piezometer/use-list-reading-by-piezometer-pag';
import { useListPluviometersByStructure } from '../../../data/hooks/pluviometer/use-list-pluviometers-by-structure';
import { useListReadingByPluviometerPagLazy } from '../../../data/hooks/pluviometer/use-list-reading-by-pluviometer-pag';
import { useListReadingBySurfaceMarkersPagLazy } from '../../../data/hooks/surface-marker/use-list-reading-by-surface-markers-pag';
import { useListSurfaceMarkersByStructure } from '../../../data/hooks/surface-marker/use-list-surface-markers-by-structure.query';
import { useListReadingByWaterLevelPagLazy } from '../../../data/hooks/water-level/use-list-reading-by-water-level-pag';
import { useListWaterLevelByStructure } from '../../../data/hooks/water-level/use-list-water-level-by-structure';
import { DefaultButtonStyle } from '../../Buttons/ButtonsStyle';
import { DefaultButton } from '../../Buttons/DefaultButton';
import { DatePickerComponent } from '../../DatePicker';
import GraphHeader from '../../Graphs/Monitoring/Instrument/GraphHeader';
import { AsideInstrumentsMenu } from '../../Menus/AsideInstrumentsMenu';
import { TableReadingComponent } from '../../Tables/TableReadingComponent';
import { toastfyDimiss, toastifyLoading } from '../../Toastify';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../Register/RegisterInstrumentStyle';
import {
  DataBaseButtonArea,
  DateFilterArea,
  FilterBotton,
  HolderFilterAndTable,
  HolderOverflowTable,
  HolderPage,
  PagesButtonArea
} from './DataBaseStyles';
import { DeleteReadingsModal } from './Delete/DeleteReadingsModal';
import { EditReadingsModal } from './Edit/EditReadingsModal';
import { RegisterBatchReadingsModal } from './Register/RegisterBatchReadingsModal';
import { RegisterReadingsModal } from './Register/RegisterReadingsModal';

export const ReadingsComponent = (props: RegisterInstrumentModalInterface) => {
  const { structureId } = useParams();
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });

  const { t } = useTranslation();
  const { showModal, setShowModal } = props;
  const [selectedType, setSelectedType] = useState<string>(
    InstrumentsModel().types[0].id
  );

  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [data, setData] = useState<any>([]);
  const [instruments, setInstruments] = useState<any>([]);
  const [createdReading, setCreatedReading] = useState<boolean>(false);

  const numberOfItensPerPage =
    window.innerHeight <= 730
      ? Math.floor(window.innerHeight / 110)
      : Math.floor(window.innerHeight / 80);

  const [countPie, setCountPie] = useState<number>(0);
  const [countPlu, setCountPlu] = useState<number>(0);
  const [countWL, setCountWL] = useState<number>(0);
  const [countSM, setCountSM] = useState<number>(0);

  const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
  const [showBatchRegisterModal, setShowBatchRegisterModal] =
    useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<string | null>(null);
  const [filterReading, setFilterReading] =
    useState<InstrumentReadingTypeEnum | null>(null);
  const [filterStatus, setFilterStatus] = useState<InstrumentStatusEnum | null>(
    null
  );
  const { listReadingPiezometer } = useListReadingByPiezometerPagLazy();
  const { listReadingPluviometer } = useListReadingByPluviometerPagLazy();
  const { listReadingWaterLevel } = useListReadingByWaterLevelPagLazy();
  const { listReadingSurfaceMarker } = useListReadingBySurfaceMarkersPagLazy();
  const [allData, setAllData] = useState<boolean>(false);

  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('Monitoring');
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };

  const { data: listAllPiezometers } =
    useListPiezometersByStructure(structureInfo);
  const { data: listAllPluviometers } =
    useListPluviometersByStructure(structureInfo);
  const { data: listAllWaterLevel } =
    useListWaterLevelByStructure(structureInfo);
  const { data: listAllSurfaceMarker } =
    useListSurfaceMarkersByStructure(structureInfo);

  useEffect(() => {
    handleFetchTableData(selectedType);
  }, [
    selectedType,
    currentPage,
    filterStatus,
    filterName,
    filterReading,
    startDate,
    endDate,
    instruments
  ]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filterStatus, filterName, filterReading, startDate, endDate]);

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedType]);

  useEffect(() => {
    handleFetchTableData(selectedType);
  }, [createdReading, allData]);

  function handleDateFilter() {
    setStartDate(null);
    setEndDate(null);
  }

  async function handlePiezometer() {
    if (allData) {
      const response = await listReadingPiezometer({
        variables: {
          structureInfo,
          pageInfo: {}
        }
      });
      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        ExportData.exportReadingsList(
          response.data.listReadingByPiezometersPag.nodes,
          selectedType
        );
        setAllData(false);
      }
    } else {
      const response = await listReadingPiezometer({
        variables: {
          structureInfo,
          pageInfo: {
            limit: numberOfItensPerPage,
            page: currentPage,
            offset: null
          },
          filters: {
            endDate: endDate,
            startDate: startDate,
            status: filterStatus,
            readingType: filterReading ?? null,
            instrumentsId: filterName ? [filterName] : null
          }
        }
      });
      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        setInstruments(listAllPiezometers?.listPiezometersByStructure ?? []);
        setData(response.data.listReadingByPiezometersPag.nodes);
        if (countPie === 0) {
          setTotalPages(
            response.data.listReadingByPiezometersPag.pageInfo.totalPages
          );
          setCountPie(response.data.listReadingByPiezometersPag.count);
        } else {
          setTotalPages(
            Math.ceil(
              response.data.listReadingByPiezometersPag.count /
                numberOfItensPerPage
            )
          );
        }
      }
    }
  }

  async function handlePluviometer() {
    if (allData) {
      const response = await listReadingPluviometer({
        variables: {
          structureInfo
        }
      });
      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        ExportData.exportReadingsList(
          response.data.listReadingByPluviometerPag.nodes,
          selectedType
        );
        setAllData(false);
      }
    } else {
      const response = await listReadingPluviometer({
        variables: {
          structureInfo,
          pageInfo: {
            limit: numberOfItensPerPage,
            page: currentPage,
            offset: null
          },
          filters: {
            endDate: endDate,
            startDate: startDate,
            status: filterStatus,
            readingType: filterReading,
            instrumentsId: filterName ? [filterName] : null
          }
        }
      });
      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        setInstruments(listAllPluviometers?.listPluviometersByStructure ?? []);
        setData(response.data.listReadingByPluviometerPag.nodes);
        if (countPlu === 0) {
          setTotalPages(
            response.data.listReadingByPluviometerPag.pageInfo.totalPages
          );
          setCountPlu(response.data.listReadingByPluviometerPag.count);
        } else {
          setTotalPages(
            Math.ceil(
              response.data.listReadingByPluviometerPag.count /
                numberOfItensPerPage
            )
          );
        }
      }
    }
  }

  async function handleWaterLevel() {
    if (allData) {
      const response = await listReadingWaterLevel({
        variables: {
          structureInfo
        }
      });
      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        ExportData.exportReadingsList(
          response.data.listReadingByWaterLevelPag.nodes,
          selectedType
        );
        setAllData(false);
      }
    } else {
      const response = await listReadingWaterLevel({
        variables: {
          structureInfo,
          pageInfo: {
            limit: numberOfItensPerPage,
            page: currentPage,
            offset: null
          },
          filters: {
            endDate: endDate,
            startDate: startDate,
            status: filterStatus,
            readingType: filterReading,
            instrumentsId: filterName ? [filterName] : null
          }
        }
      });
      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        setInstruments(listAllWaterLevel?.listWaterLevelByStructure ?? []);
        setData(response.data.listReadingByWaterLevelPag.nodes);
        if (countWL === 0) {
          setTotalPages(
            response.data.listReadingByWaterLevelPag.pageInfo.totalPages
          );
          setCountWL(response.data.listReadingByWaterLevelPag.count);
        } else {
          setTotalPages(
            Math.ceil(
              response.data.listReadingByWaterLevelPag.count /
                numberOfItensPerPage
            )
          );
        }
      }
    }
  }

  async function handleSurfaceMarker() {
    if (allData) {
      const response = await listReadingSurfaceMarker({
        variables: {
          structureInfo
        }
      });
      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        ExportData.exportReadingsList(
          response.data.listReadingBySurfaceMarkersPag.nodes,
          selectedType
        );
        setAllData(false);
      }
    } else {
      const response = await listReadingSurfaceMarker({
        variables: {
          structureInfo,
          pageInfo: {
            limit: numberOfItensPerPage,
            page: currentPage,
            offset: null
          },
          filters: {
            endDate: endDate,
            startDate: startDate,
            status: filterStatus,
            readingType: filterReading,
            instrumentsId: filterName ? [filterName] : null
          }
        }
      });
      toastifyLoading('Carregando Leituras...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        setInstruments(
          listAllSurfaceMarker?.listSurfaceMarkersByStructure ?? []
        );
        setData(response.data.listReadingBySurfaceMarkersPag.nodes);
        if (countSM === 0) {
          setTotalPages(
            response.data.listReadingBySurfaceMarkersPag.pageInfo.totalPages
          );
          setCountSM(response.data.listReadingBySurfaceMarkersPag.count);
        } else {
          setTotalPages(
            Math.ceil(
              response.data.listReadingBySurfaceMarkersPag.count /
                numberOfItensPerPage
            )
          );
        }
      }
    }
  }

  function handleFetchTableData(key: any) {
    const strategy = {
      piezometer: () => handlePiezometer(),
      pluviometer: () => handlePluviometer(),
      ina: () => handlePiezometer(),
      waterlevel: () => handleWaterLevel(),
      surfacemarker: () => handleSurfaceMarker()
    };

    strategy[key.toLowerCase() as keyof typeof strategy]();
  }

  function handlePreviousPage() {
    if (currentPage !== 0) {
      setCurrentPage((state) => state - 1);
    }
  }

  function handleNextPage() {
    if (currentPage <= totalPages - 2) {
      setCurrentPage((state) => state + 1);
    }
  }

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal style={{ width: '95%', height: '95%' }}>
        <GraphHeader
          showModal={showModal}
          setShowModal={setShowModal}
          title="Reading"
        />
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: '78px',
            bottom: 0,
            width: '100%',
            maxWidth: '100%'
          }}
        >
          <AsideInstrumentsMenu
            selectedItem={selectedType}
            setSelectedItem={setSelectedType}
            title="Instruments"
            items={InstrumentsModel().types}
          />
          <HolderPage>
            <HolderFilterAndTable>
              <HolderOverflowTable>
                <TableReadingComponent
                  dados={data}
                  setEdit={setEdit}
                  setShowEditModal={setShowEditModal}
                  setShowDeleteModal={setShowDeleteModal}
                  setDeleting={setDeleting}
                  filterName={filterName}
                  filterReading={filterReading}
                  filterStatus={filterStatus}
                  setFilterName={setFilterName}
                  setFilterReading={setFilterReading}
                  setFilterStatus={setFilterStatus}
                  instruments={instruments}
                  selectedType={selectedType}
                />
              </HolderOverflowTable>
              <FilterBotton>
                <DateFilterArea>
                  <DefaultButtonStyle
                    style={{ width: '100px' }}
                    onClick={() => {
                      handleDateFilter();
                    }}
                  >
                    {t('clean')}
                  </DefaultButtonStyle>
                  <DatePickerComponent
                    setStartDate={setStartDate}
                    startDate={startDate}
                    hasInterval
                    setEndDate={setEndDate}
                    endDate={endDate}
                    dateText={t('InitialDate')}
                  />
                </DateFilterArea>
                <PagesButtonArea>
                  <MdKeyboardArrowLeft onClick={handlePreviousPage} />
                  <span>
                    {currentPage + 1} de {totalPages}
                  </span>
                  <MdKeyboardArrowRight onClick={handleNextPage} />
                </PagesButtonArea>
              </FilterBotton>
            </HolderFilterAndTable>
          </HolderPage>
        </div>
        <DataBaseButtonArea>
          {userRoleInModule !== 'viewer' && (
            <DefaultButton
              title="RegisterReadings"
              action={() =>
                ReadingsController.ShowModal({
                  showModal: showRegisterModal,
                  setShowModal: setShowRegisterModal
                })
              }
            />
          )}
          {userRoleInModule !== 'viewer' && (
            <DefaultButton
              title="RegisterReadingsFile"
              action={() => {
                ReadingsController.ShowModal({
                  showModal: showBatchRegisterModal,
                  setShowModal: setShowBatchRegisterModal
                });
              }}
            />
          )}
          <DefaultButton
            title="ExportTable"
            action={() => {
              setAllData(true);
              handleFetchTableData(selectedType);
            }}
          />
        </DataBaseButtonArea>

        {showRegisterModal ? (
          <RegisterReadingsModal
            type={selectedType}
            showModal={showRegisterModal}
            setShowModal={setShowRegisterModal}
            createdReading={createdReading}
            setCreatedReading={setCreatedReading}
            setCountPie={setCountPie}
            setCountPlu={setCountPlu}
            setCountWL={setCountWL}
            setCountSM={setCountSM}
          />
        ) : null}

        {showBatchRegisterModal ? (
          <RegisterBatchReadingsModal
            type={selectedType}
            showModal={showBatchRegisterModal}
            setShowModal={setShowBatchRegisterModal}
            createdReading={createdReading}
            setCreatedReading={setCreatedReading}
            setCountPie={setCountPie}
            setCountPlu={setCountPlu}
            setCountWL={setCountWL}
            setCountSM={setCountSM}
          />
        ) : null}

        {showEditModal && (
          <EditReadingsModal
            edit={edit}
            selectedType={selectedType}
            createdReading={createdReading}
            setCreatedReading={setCreatedReading}
            setShowModal={setShowEditModal}
            showModal={showEditModal}
          />
        )}

        {showDeleteModal && (
          <DeleteReadingsModal
            createdReading={createdReading}
            setCreatedReading={setCreatedReading}
            deleting={deleting}
            selectedType={selectedType}
            setShowModal={setShowDeleteModal}
            showModal={showDeleteModal}
            setData={setData}
            data={data}
            setCountPie={setCountPie}
            setCountPlu={setCountPlu}
            setCountWL={setCountWL}
            setCountSM={setCountSM}
          />
        )}
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
};
