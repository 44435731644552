import styled from 'styled-components';

export const MainScreenAto = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 5px 30px 30px 30px;
  box-sizing: border-box;
  overflow: auto;
`;

export const HolderHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const HolderContentPage = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
`;

export const DivContainerLeftSide = styled.div`
  width: 44%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
`;

export const DivContainterInfos = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  text-align: flex-start;
  justify-content: space-between;
  flex-direction: row;
`;

export const DivContainerImages = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const DivContainerRightSide = styled.div`
  width: 52%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
`;

export const HolderCards = styled.div`
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
  flex-direction: row;
`;

export const HolderTable = styled.div`
  width: 100%;
`;

export const HolderVideos = styled.div`
  width: 100%;
`;
