import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { checkBoxStyle } from '../../../../Components/Map/MapStyle';
import { IdentifiesContainer, IdentifiesHeader } from './DamMassifStyles';
import { SurfaceDrainageTable } from './SurfaceDrainageTable';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';

export function SurfaceDrainage({
  setIsSelectedSurfaceDrainage,
  isSelectedSurfaceDrainage,
  setStepStates,
  stepStates,
  error,
  inspectionId
}: {
  setIsSelectedSurfaceDrainage: Dispatch<SetStateAction<boolean>>;
  isSelectedSurfaceDrainage: boolean;
  setStepStates: Dispatch<SetStateAction<any>>;
  stepStates: any;
  error: boolean;
  inspectionId: string;
}) {
  const { t }: any = useTranslation();

  const topics = ['Estado de limpeza', 'Condições estruturais'];

  return (
    <IdentifiesContainer>
      <IdentifiesHeader>
        <span>{t('SurfaceDrainage')}</span>
        <span
          id="repressions"
          onClick={() =>
            setIsSelectedSurfaceDrainage(!isSelectedSurfaceDrainage)
          }
        >
          {t('DoesNotApply/NonExistent')}{' '}
          {isSelectedSurfaceDrainage ? (
            <MdCheckBox style={checkBoxStyle} />
          ) : (
            <MdCheckBoxOutlineBlank style={checkBoxStyle} />
          )}
        </span>
      </IdentifiesHeader>
      {!isSelectedSurfaceDrainage && (
        <TableComponentStyle>
          <thead>
            <tr>
              <th>{''}</th>
              <th>{t('Value')}</th>
              <th>{t('Observation')}</th>
              <th>{t('Image')}</th>
            </tr>
          </thead>
          <tbody>
            {topics.map((topic: string, index: number) => (
              <SurfaceDrainageTable
                setState={setStepStates}
                state={stepStates}
                topic={topic}
                error={error}
                key={index}
                inspectionId={inspectionId}
              />
            ))}
          </tbody>
        </TableComponentStyle>
      )}
    </IdentifiesContainer>
  );
}
