import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonNotification,
  DivNotificationCardStyle,
  DivNotificationViewAll
} from '../../Styles/UserBadgeStyle';
import NotificationList from './NotificationList';

interface NotificationCardTypes {
  setShowNotificationsCard: Dispatch<boolean>;
  setNotificationModal: Dispatch<boolean>;
  fetchAllNotificationItens: () => void;
}

export default function NotificationCard(props: NotificationCardTypes) {
  const { t }: any = useTranslation();
  return (
    <DivNotificationCardStyle
      onMouseLeave={() => props.setShowNotificationsCard(false)}
    >
      <NotificationList />
      <DivNotificationViewAll>
        <ButtonNotification
          onClick={() => {
            props.setNotificationModal(true);
            props.fetchAllNotificationItens();
          }}
        >
          {t('ViewAllNotifications')}
        </ButtonNotification>
      </DivNotificationViewAll>
    </DivNotificationCardStyle>
  );
}
