import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';
import { IdentifiesContainer, IdentifiesHeader } from './DamMassifStyles';
import { YesNoBodyTable } from './YesNoBodyTable';

export function SurfaceErosions({
  topic,
  setStepStates,
  stepStates,
  error,
  inspectionId
}: {
  topic: string;
  setStepStates: Dispatch<SetStateAction<any>>;
  stepStates: any;
  error: boolean;
  inspectionId: string;
}) {
  const { t }: any = useTranslation();

  return (
    <IdentifiesContainer>
      <IdentifiesHeader>
        <span>{t('SurfaceErosions')}</span>
      </IdentifiesHeader>
      <TableComponentStyle>
        <thead>
          <tr>
            <th></th>
            <th>{t('Value')}</th>
            <th>{t('Observation')}</th>
            <th>{t('Image')}</th>
          </tr>
        </thead>
        <tbody>
          <YesNoBodyTable
            topic={topic}
            setState={setStepStates}
            state={stepStates}
            error={error}
            inspectionId={inspectionId}
          />
        </tbody>
      </TableComponentStyle>
    </IdentifiesContainer>
  );
}
