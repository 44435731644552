import styled from 'styled-components';

export const MainScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 20px 30px 0px 30px;
  box-sizing: border-box;
`;

export const TitleSkeleton = styled.div`
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
`;

export const HolderContentPage = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
`;

export const DivContainerLeftSide = styled.div`
  width: 44%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 40px;
`;

export const DivContainterInfos = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  text-align: flex-start;
  justify-content: space-between;
  flex-direction: row;
`;
export const ContainterInfos = styled.div`
  row-gap: 10px;
  display: flex;
  align-items: flex-start;
  text-align: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

export const DivContainerImages = styled.div`
  width: 100%;
  row-gap: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const DivContainerPictures = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;
  gap: 15px;
`;

export const DivContainerRightSide = styled.div`
  width: 52%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 40px;
`;

export const HolderCards = styled.div`
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
  flex-direction: row;
`;

export const HolderTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
