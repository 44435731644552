import { gql } from '@apollo/client';
import {
  ActionPlanPriorityEnum,
  ActionPlanSituationEnum
} from '../graphql/base-schema';

export interface FindActionPlanGeneratedQueryVariables {
  structureId: string;
  actionPlanId: string;
}

export interface FindActionPlanGeneratedQuery {
  findActionPlanResponsible: {
    email: string;
    id: string;
    name: string;
  }[];
  findActionPlanData: {
    code: string;
    situation: ActionPlanSituationEnum;
    images: string[];
    description: string;
    responsible: {
      id: string;
      name: string;
    };
    priority: ActionPlanPriorityEnum;
    timeFrame: Date;
    comments: {
      id: string;
      comment: string;
      userName: string;
      createdAt: Date;
    }[];
    teamMembers: {
      id: string;
      name: string;
      email: string;
    }[];
    imagesCount: number;
  };
}

export const FindActionPlanGeneratedDocument = gql`
  query FindActionPlan($structureId: String!, $actionPlanId: String!) {
    findActionPlanResponsible(structureId: $structureId) {
      email
      id
      name
    }
    findActionPlanData(actionPlanId: $actionPlanId) {
      code
      situation
      images
      description
      responsible {
        id
        name
      }
      priority
      timeFrame
      comments {
        id
        comment
        userName
        createdAt
      }
      teamMembers {
        id
        name
        email
      }
      imagesCount
    }
  }
`;
