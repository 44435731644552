import { ReactNode } from 'react';
import {
  HolderHeader,
  HolderInfosContent,
  HolderPageContent,
  HolderSignatureContent,
  MainScreenNewAto,
  PageContainer
} from './TemplateNewAtoStyles';

interface TemplateNewAtoProps {
  header: ReactNode;
  infosContent: ReactNode;
  pageContent: ReactNode;
  signaturesContent: ReactNode;
  icon?: ReactNode;
}

export const TemplateNewAto = ({
  header,
  infosContent,
  pageContent,
  signaturesContent,
  icon
}: TemplateNewAtoProps) => {
  return (
    <MainScreenNewAto>
      <HolderHeader>
        {icon}
        {header}
      </HolderHeader>
      <PageContainer>
        <HolderInfosContent>{infosContent}</HolderInfosContent>
        <HolderPageContent>{pageContent}</HolderPageContent>
        <HolderSignatureContent>{signaturesContent}</HolderSignatureContent>
      </PageContainer>
    </MainScreenNewAto>
  );
};
