import { isValid, parse } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InstrumentSelectInterface } from '../../../../@Types/Instruments/instruments';
import { InstrumentsType } from '../../../../Models/InstrumentsModel';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { useCreatePiezometerReading } from '../../../../data/hooks/piezometer/use-create-piezometer-reading.mutation';
import { useListPiezometersByStructureLazy } from '../../../../data/hooks/piezometer/use-list-piezometers-by-structure.query';
import { useCreatePluviometerReading } from '../../../../data/hooks/pluviometer/use-create-pluviometer-reading.mutation';
import { useListPluviometersByStructureLazy } from '../../../../data/hooks/pluviometer/use-list-pluviometers-by-structure';
import { useCreateSurfaceMarkerReading } from '../../../../data/hooks/surface-marker/use-create-surface-marker-reading.mutation';
import { useListSurfaceMarkersByStructureLazy } from '../../../../data/hooks/surface-marker/use-list-surface-markers-by-structure.query';
import { useCreateWaterLevelReading } from '../../../../data/hooks/water-level/use-create-water-level-reading.mutation';
import { useListWaterLevelByStructureLazy } from '../../../../data/hooks/water-level/use-list-water-level-by-structure';
import {
  AdvanceButtonStyled,
  DefaultButtonStyle
} from '../../../Buttons/ButtonsStyle';
import { CancelButtonComponent } from '../../../Buttons/Instruments/CancelButton';
import { InputFile } from '../../../Forms/FormInspectionsStyle';
import GraphHeader from '../../../Graphs/Monitoring/Instrument/GraphHeader';
import InstrumentSelects from '../../../Selects/Instruments/InstrumentsSelects';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../../Register/RegisterInstrumentStyle';
import { DataBaseButtonArea, RegisterReadingsArea } from '../DataBaseStyles';

export const RegisterBatchReadingsModal = ({
  type,
  showModal,
  setShowModal,
  createdReading,
  setCreatedReading,
  setCountPie,
  setCountPlu,
  setCountWL,
  setCountSM
}: {
  type: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  createdReading: boolean;
  setCreatedReading: Dispatch<SetStateAction<boolean>>;
  setCountPie: Dispatch<SetStateAction<number>>;
  setCountPlu: Dispatch<SetStateAction<number>>;
  setCountWL: Dispatch<SetStateAction<number>>;
  setCountSM: Dispatch<SetStateAction<number>>;
}) => {
  const { structureId } = useParams();
  const { t } = useTranslation();
  const [instrumentSelected, setInstrumentSelected] =
    useState<InstrumentSelectInterface | null>(null);
  const [instrumentList, setInstrumentList] = useState<any[]>([]);
  const { createReading: createReadingPiezometer } =
    useCreatePiezometerReading();
  const { createReading: createReadingPluviometer } =
    useCreatePluviometerReading();
  const { createReading: createReadingWaterLevel } =
    useCreateWaterLevelReading();
  const { createReading: createReadingSurfaceMarker } =
    useCreateSurfaceMarkerReading();
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const [data, setData] = useState<any | null>(null);
  const [definedHeaders, setDefinedHeaders] = useState<any[]>([]);
  const { listPiezometers } = useListPiezometersByStructureLazy();
  const { listPluviometers } = useListPluviometersByStructureLazy();
  const { listWaterLevels } = useListWaterLevelByStructureLazy();
  const { listSurfaceMarkers } = useListSurfaceMarkersByStructureLazy();

  async function handleFetchPiezometers() {
    setDefinedHeaders(['date', 'isdry', 'cote', 'reading', 'observation']);
    const response = await listPiezometers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listPiezometersByStructure);
    }
  }

  async function handleFetchPluviometers() {
    setDefinedHeaders(['date', 'observation', 'rainfall']);
    const response = await listPluviometers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listPluviometersByStructure);
    }
  }

  async function handleFetchWaterLevels() {
    setDefinedHeaders(['date', 'observation', 'value']);
    const response = await listWaterLevels({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listWaterLevelByStructure);
    }
  }

  async function handleFetchSurfaceMarkers() {
    setDefinedHeaders([
      'date',
      'coordinatee',
      'coordinaten',
      'elevation',
      'observation'
    ]);
    const response = await listSurfaceMarkers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listSurfaceMarkersByStructure);
    }
  }

  const handleFetchSelectedInstrumentList = (type: string) => {
    const strategy = {
      piezometer: () => handleFetchPiezometers(),
      ina: () => handleFetchPiezometers(),
      pluviometer: () => handleFetchPluviometers(),
      waterlevel: () => handleFetchWaterLevels(),
      surfacemarker: () => handleFetchSurfaceMarkers()
    };

    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  async function handleSavePiezometer() {
    toastifyLoading('Cadastrando Leitura...');

    try {
      //TODO checar se o formato de data atende
      if (instrumentSelected?.label) {
        const filteredData = data.filter((e) =>
          Object.values(e).some((a) => a.length)
        );

        const preparedData = filteredData.map((item: any) => {
          let dateStr = item.date;

          if (dateStr && dateStr.length === 29) {
            dateStr = dateStr.substring(0, 19);
          }

          return {
            ...item,
            date: dateStr
          };
        });

        const readingsData = preparedData.map((item: any) => {
          const dateFormats = [
            'dd/MM/yyyy HH:mm',
            'dd/MM/yyyy',
            'yyyy-MM-dd HH:mm:ss'
          ];
          let parsedDate = null;

          for (const format of dateFormats) {
            const date = parse(item.date, format, new Date(), { locale: enUS });

            if (isValid(date)) {
              parsedDate = date;
              break;
            }
          }

          if (!parsedDate) {
            parsedDate = null;
          }

          return {
            date: parsedDate,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: item.observation,
            cote: item.cote && item.cote !== '' ? Number(item.cote) : null,
            isDry: item.isDry === 'false' ? false : true,
            reading:
              item.reading && item.reading !== '' ? Number(item.reading) : null
          };
        });

        const response = await createReadingPiezometer({
          variables: {
            structureInfo: structureInfo,
            data: readingsData
          }
        });

        if (response.data) {
          toastfySuccess('Leitura Cadastrada Com Sucesso');
          setCountPie((prev) => prev + readingsData.length);
          setShowModal(false);
          setCreatedReading(!createdReading);
          toastfyDimiss('toastfySuccess');
        } else if (response.errors) {
          toastfyDimiss('toastLoading');
          toastfyError(
            response.errors[0].message ||
              t(ToastifyModel().toastifyMessage.error)
          );
        }
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      }
    } catch (error) {
      console.error(error);
      toastfyError('Ocorreu um erro ao salvar a leitura.');
    } finally {
      toastfyDimiss('toastLoading');
    }
  }

  async function handleSavePluviometer() {
    toastifyLoading('Cadastrando Leitura...');
    try {
      if (instrumentSelected?.label) {
        const filteredData = data.filter((e) =>
          Object.values(e).some((a) => a.length)
        );

        const preparedData = filteredData.map((item: any) => {
          let dateStr = item.date;

          if (dateStr && dateStr.length === 29) {
            dateStr = dateStr.substring(0, 19);
          }

          return {
            ...item,
            date: dateStr
          };
        });

        const readingsData = preparedData.map((item: any) => {
          const dateFormats = [
            'dd/MM/yyyy HH:mm',
            'dd/MM/yyyy',
            'yyyy-MM-dd HH:mm:ss'
          ];
          let parsedDate = null;

          for (const format of dateFormats) {
            const date = parse(item.date, format, new Date(), { locale: enUS });
            if (isValid(date)) {
              parsedDate = date;
              break;
            }
          }

          if (!parsedDate) {
            parsedDate = null;
          }

          return {
            date: parsedDate,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: item.observation,
            rainfall:
              item.rainfall === null || isNaN(item.rainfall)
                ? 0
                : parseFloat(item.rainfall)
          };
        });

        const response = await createReadingPluviometer({
          variables: {
            structureInfo: structureInfo,
            data: readingsData
          }
        });
        toastifyLoading('Cadastrando Leitura...');
        if (response.data) {
          toastfyDimiss('toastLoading');
          toastfySuccess('Leitura Cadastrada Com Sucesso');
          setCountPlu((prev) => prev + readingsData.length);
          setShowModal(false);
          setCreatedReading(!createdReading);
          toastfyDimiss('toastfySuccess');
        } else if (response.errors) {
          toastfyDimiss('toastLoading');
          toastfyError(
            response.errors[0].message ||
              t(ToastifyModel().toastifyMessage.error)
          );
        }
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      }
    } catch (error) {
      console.error(error);
      toastfyError('Ocorreu um erro ao salvar a leitura.');
    } finally {
      toastfyDimiss('toastLoading');
    }
  }

  async function handleSaveWaterLevel() {
    toastifyLoading('Cadastrando Leitura...');
    try {
      if (instrumentSelected?.label) {
        const filteredData = data.filter((e) =>
          Object.values(e).some((a) => a.length)
        );

        const preparedData = filteredData.map((item: any) => {
          let dateStr = item.date;

          if (dateStr && dateStr.length === 29) {
            dateStr = dateStr.substring(0, 19);
          }

          return {
            ...item,
            date: dateStr
          };
        });

        const readingsData = preparedData.map((item: any) => {
          const dateFormats = [
            'dd/MM/yyyy HH:mm',
            'dd/MM/yyyy',
            'yyyy-MM-dd HH:mm:ss'
          ];
          let parsedDate = null;

          for (const format of dateFormats) {
            const date = parse(item.date, format, new Date(), { locale: enUS });
            if (isValid(date)) {
              parsedDate = date;
              break;
            }
          }

          if (!parsedDate) {
            parsedDate = null;
          }

          return {
            date: parsedDate,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: item.observation,
            value:
              item.value === null || isNaN(item.value)
                ? 0
                : parseFloat(item.value)
          };
        });

        const response = await createReadingWaterLevel({
          variables: {
            structureInfo: structureInfo,
            data: readingsData
          }
        });
        toastifyLoading('Cadastrando Leitura...');
        if (response.data) {
          toastfyDimiss('toastLoading');
          toastfySuccess('Leitura Cadastrada Com Sucesso');
          setCountWL((prev) => prev + readingsData.length);
          setShowModal(false);
          setCreatedReading(!createdReading);
          toastfyDimiss('toastfySuccess');
        } else if (response.errors) {
          toastfyDimiss('toastLoading');
          toastfyError(
            response.errors[0].message ||
              t(ToastifyModel().toastifyMessage.error)
          );
        }
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      }
    } catch (error) {
      console.error(error);
      toastfyError('Ocorreu um erro ao salvar a leitura.');
    } finally {
      toastfyDimiss('toastLoading');
    }
  }

  async function handleSaveSurfaceMarker() {
    if (instrumentSelected?.label) {
      const readingsData = data.map((item: any) => ({
        date: new Date(item.date),
        instrumentId: instrumentSelected?.id,
        instrumentName: instrumentSelected?.label,
        observation: item.observation,
        coordinateE: Number(item.coordinateE),
        coordinateN: Number(item.coordinateN),
        elevation: Number(item.elevation)
      }));

      const response = await createReadingSurfaceMarker({
        variables: {
          structureInfo: structureInfo,
          data: readingsData
        }
      });
      toastifyLoading('Cadastrando Leitura...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Leitura Cadastrada Com Sucesso');
        setCountSM((prev) => prev + readingsData.length);
        setShowModal(false);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  }

  const handleSaveReading = (type: string) => {
    const strategy = {
      piezometer: () => handleSavePiezometer(),
      ina: () => handleSavePiezometer(),
      pluviometer: () => handleSavePluviometer(),
      waterlevel: () => handleSaveWaterLevel(),
      surfacemarker: () => handleSaveSurfaceMarker()
    };

    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  const handleDownload = async (file: string) => {
    try {
      const fileUrl = `/Excel/${file}.csv`;

      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const fileName = `${file}.csv`;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleFileChange = (files: any) => {
    const file = files[0];

    if (file && file.type === 'text/csv') {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const csvContent = event.target.result;

        const rows = csvContent
          .split('\n')
          .filter((row: string) => row.trim() !== '');

        const headers = rows[0]
          .split(';')
          .map((header) => header.trim().toLowerCase());

        const requiredHeaders = definedHeaders;

        const isValidHeader = requiredHeaders.every((header) =>
          headers.includes(header)
        );

        if (!isValidHeader) {
          console.error('O arquivo CSV não contém os cabeçalhos esperados.');
          return;
        }

        const data = rows
          .slice(1)
          .map((row: string) => {
            const values = row.split(';');
            const obj: any = {};
            headers.forEach((header: string, index: number) => {
              if (requiredHeaders.includes(header.trim())) {
                obj[header.trim()] = values[index].trim();
              }
            });
            return obj;
          })
          .filter((item: any) => Object.keys(item).length > 0);

        setData(data);
      };

      reader.onerror = (error) => {
        console.error('Erro ao ler o arquivo:', error);
      };

      reader.readAsText(file);
    } else {
      console.error('Por favor, selecione um arquivo CSV válido.');
    }
  };

  useEffect(() => {
    handleFetchSelectedInstrumentList(type);
  }, []);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '50%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '300px'
        }}
      >
        <GraphHeader
          showModal={showModal}
          setShowModal={setShowModal}
          title="RegisterReadings"
          subtitle={type}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RegisterReadingsArea>
            {InstrumentSelects.InstrumentsName({
              width: 79,
              placeholder: t('Instruments'),
              options: instrumentList.map((e) => ({
                label: t(`${e.name}`),
                value: e.id,
                id: e.id,
                instrumentType: e.type.instrumentType
              })),
              setSelected: setInstrumentSelected,
              selected: instrumentSelected
            })}
            {(() => {
              switch (type as InstrumentsType) {
                case 'Piezometer':
                  return (
                    <>
                      <DefaultButtonStyle
                        onClick={() =>
                          handleDownload('registerPiezometerReadings')
                        }
                      >
                        {t('DownloadTemplate')}
                      </DefaultButtonStyle>
                      <InputFile>
                        <input
                          type="file"
                          name={'File'}
                          multiple
                          accept=".csv"
                          id={'File_$'}
                          onChange={(e) => {
                            handleFileChange(e.target.files);
                          }}
                        />
                      </InputFile>
                    </>
                  );
                case 'INA':
                  return (
                    <>
                      <DefaultButtonStyle
                        onClick={() =>
                          handleDownload('registerPiezometerReadings')
                        }
                      >
                        {t('DownloadTemplate')}
                      </DefaultButtonStyle>
                      <InputFile>
                        <input
                          type="file"
                          name={'File'}
                          multiple
                          accept=".csv"
                          id={'File_$'}
                          onChange={(e) => {
                            handleFileChange(e.target.files);
                          }}
                        />
                      </InputFile>
                    </>
                  );
                case 'Pluviometer':
                  return (
                    <>
                      <DefaultButtonStyle
                        onClick={() =>
                          handleDownload('registerPluviometerReadings')
                        }
                      >
                        {t('DownloadTemplate')}
                      </DefaultButtonStyle>
                      <InputFile>
                        <input
                          type="file"
                          name={'File'}
                          multiple
                          accept=".csv"
                          id={'File_$'}
                          onChange={(e) => {
                            handleFileChange(e.target.files);
                          }}
                        />
                      </InputFile>
                    </>
                  );
                case 'WaterLevel':
                  return (
                    <>
                      <DefaultButtonStyle
                        onClick={() =>
                          handleDownload('registerWaterLevelReadings')
                        }
                      >
                        {t('DownloadTemplate')}
                      </DefaultButtonStyle>
                      <InputFile>
                        <input
                          type="file"
                          name={'File'}
                          multiple
                          accept=".csv"
                          id={'File_$'}
                          onChange={(e) => {
                            handleFileChange(e.target.files);
                          }}
                        />
                      </InputFile>
                    </>
                  );
                case 'SurfaceMarker':
                  return (
                    <>
                      <DefaultButtonStyle
                        onClick={() =>
                          handleDownload('registerSurfaceMarkersReadings')
                        }
                      >
                        {t('DownloadTemplate')}
                      </DefaultButtonStyle>
                      <InputFile>
                        <input
                          type="file"
                          name={'File'}
                          multiple
                          accept=".csv"
                          id={'File_$'}
                          onChange={(e) => {
                            handleFileChange(e.target.files);
                          }}
                        />
                      </InputFile>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </RegisterReadingsArea>
        </div>
        <DataBaseButtonArea>
          <CancelButtonComponent
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <AdvanceButtonStyled
            onClick={() => {
              handleSaveReading(type);
            }}
          >
            {' '}
            {t('Save').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </DataBaseButtonArea>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
};
