import { useMutation } from '@apollo/client';
import {
  LoginDocument,
  LoginMutation,
  LoginMutationVariables
} from '../graphql/generated/login.mutation';

export function useLogin(onCompleted?: () => void, onError?: () => void) {
  const [login, { data, error, loading }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LoginDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    login,
    data,
    error,
    loading
  };
}
