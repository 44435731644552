import { useMutation } from '@apollo/client';
import {
  CreateWaterLevelReadingDocument,
  CreateWaterLevelReadingMutation,
  CreateWaterLevelReadingMutationVariables
} from '../../graphql/generated/createWaterLevelReading.mutation';
import { ListReadingByWaterLevelPagDocument } from '../../graphql/query/generated/listReadingByWaterLevelPag.query';

export function useCreateWaterLevelReading(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createReading, { data, error, loading }] = useMutation<
    CreateWaterLevelReadingMutation,
    CreateWaterLevelReadingMutationVariables
  >(CreateWaterLevelReadingDocument, {
    refetchQueries: [ListReadingByWaterLevelPagDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createReading,
    data,
    error,
    loading
  };
}
