import { Dispatch, SetStateAction } from 'react';
import FormInspectionsTwoOptions from '../../../Components/Forms/FormInspectionsTwoOptions';
import { ContainerThirdStep } from '../InspectionsStyle';

export function FourthStepInspection({
  stepFourStates,
  setStepFourStates,
  error,
  inspectionId
}: {
  stepFourStates: any;
  setStepFourStates: Dispatch<SetStateAction<any>>;
  error: boolean;
  inspectionId: string;
}) {
  return (
    <ContainerThirdStep>
      <FormInspectionsTwoOptions
        setStepStates={setStepFourStates}
        stepStates={stepFourStates}
        centerTitle={true}
        topic={'Obstrução da seção de emboque / stop logs?'}
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        setStepStates={setStepFourStates}
        stepStates={stepFourStates}
        centerTitle={true}
        topic={'Há danos na(s) estrutura(s)'}
        error={error}
        inspectionId={inspectionId}
      />
    </ContainerThirdStep>
  );
}
