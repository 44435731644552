import { t } from 'i18next';
import React, { useState } from 'react';
import { FaCirclePlay } from 'react-icons/fa6';
import { IoIosArrowBack } from 'react-icons/io';
import { MdPhotoCamera } from 'react-icons/md';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetAllAtoRecords } from '../../../../data/services/ATOService';
import Icon from '../../Atoms/Icon/Icon';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import InputText from '../../Molecules/InputText/InputText';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import TableWithFilterAndPaginator from '../../Organisms/TableWithFilterAndPaginator/TableWithFilterAndPaginator';
import { SeeMoreTemplate } from '../../Templates/SeeMoreTemplate/SeeMoreTemplate';
import { HolderIconAndValueTable } from './AtoRelatorioStyles';

export const AtoRelatorios = () => {
  const { atoId, structureId } = useParams<{
    atoId: string;
    structureId: string;
  }>();
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState<OptionType>({
    value: 10,
    label: '10'
  });
  const { data: getAllAtoRecords, loading } = useGetAllAtoRecords(
    atoId ? atoId : '',
    { limit: limit.value as number, offset: offset, page: 0 }
  );
  const totalPages =
    getAllAtoRecords?.findAllRecordsPag.pageInfo.totalPages ?? 0;
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const [pageForPaginator, setPageForPaginator] = useState(1);
  const sortedAtoRecords = getAllAtoRecords?.findAllRecordsPag.nodes.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  const listAllAtoRecords = sortedAtoRecords?.map((record) => ({
    id: record.id,
    date: new Date(record.date).toLocaleDateString(),
    number: record.recordNumber,
    imagesCount: (
      <HolderIconAndValueTable>
        <MdPhotoCamera /> {record.recordImagesCount}
      </HolderIconAndValueTable>
    ),
    videosCount: (
      <HolderIconAndValueTable>
        <FaCirclePlay /> {record.recordVideosCount}
      </HolderIconAndValueTable>
    ),
    situation: record.situation
  }));

  const filteredRecords = listAllAtoRecords?.filter((record) =>
    record.number.includes(searchValue)
  );
  const dataTable = [
    {
      key: 'date',
      label: 'Data'
    },
    {
      key: 'number',
      label: 'Número'
    },
    {
      key: 'situation',
      label: 'Status'
    },
    {
      key: 'imagesCount',
      label: 'Fotos'
    },
    {
      key: 'videosCount',
      label: 'Vídeos'
    }
  ];
  const navigateBack = () => {
    navigate(`/${structureId}/ato/${atoId}`);
  };

  const handlePushAtoIdToUrl = (recordId: string) => {
    navigate(`/${structureId}/ato/${atoId}/view/${recordId}`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ search: event.target.value });
  };

  const handleNextPage = async () => {
    if (pageForPaginator === totalPages) {
      setOffset(0);
      setPageForPaginator(1);
    } else {
      setOffset((prev) => prev + (limit.value as number));
      setPageForPaginator((prev) => prev + 1);
    }
  };

  const handlePreviousPage = async () => {
    if (pageForPaginator !== 1) {
      setOffset((prev) => prev - (limit.value as number));
      setPageForPaginator((prev) => prev - 1);
    }
  };

  return (
    <SeeMoreTemplate
      icon={<Icon Icon={IoIosArrowBack} onClick={() => navigateBack()} />}
      title="Relatorios"
      header={
        <InputText
          label={t('RdoNumber')}
          type="number"
          value={searchValue}
          onChange={handleSearchChange}
          name="search"
        />
      }
      content={
        <>
          <TableComponent
            tableData={filteredRecords || []}
            columns={dataTable}
            onRow={(record) => handlePushAtoIdToUrl(record.id)}
            paginator={true}
            totalPages={totalPages}
            currentPage={pageForPaginator}
            nextPage={handleNextPage}
            previousPage={handlePreviousPage}
            countForPage={limit}
            setCountForPage={setLimit}
          />
        </>
      }
    />
  );
};
