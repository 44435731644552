import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { ProjectContext } from '../../../../Context/ContextAPI';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { ActionPlanPriorityEnum } from '../../../../data/graphql/base-schema';
import {
  CreateActionPlanDocument,
  CreateActionPlanMutation,
  CreateActionPlanMutationVariables
} from '../../../../data/graphql/generated/createActionPlan.mutation';
import {
  FindActionPlanResponsibleDocument,
  FindActionPlanResponsibleQuery,
  FindActionPlanResponsibleQueryVariables
} from '../../../../data/graphql/query/generated/findActionPlanResponsible.query';
import { FindActionPlanAllInfoDocument } from '../../../../data/services/ActionPlanService';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { ModuleData } from '../../../Map/types';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { CreateActionPlanProps } from './CreateActionPlan.interface';
import {
  DivContainerCreateActionPlan,
  DivContainerCreateButton,
  DivSelects
} from './CreateActionPlan.styles';

const oneMegaByte = 1048576;

const CreateActionPlan = ({ onClose }: CreateActionPlanProps) => {
  const { modulesByStructure } = useContext(ProjectContext) as {
    modulesByStructure: ModuleData[];
  };
  const modulePermission = modulesByStructure?.find(
    (module) => module.moduleName === 'ActionPlan'
  )?.role;
  const actionPlanSchema = z.object({
    priorities: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine(
        (value) => {
          if (modulePermission === 'Admin') {
            if (value) {
              return true;
            }

            return false;
          } else {
            return true;
          }
        },
        { message: 'fieldIsRequired' }
      ),
    responsible: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine(
        (value) => {
          if (modulePermission === 'Admin') {
            if (value) {
              return true;
            }

            return false;
          } else {
            return true;
          }
        },
        { message: 'fieldIsRequired' }
      ),
    description: z
      .string()
      .refine((val) => val.length > 0, { message: 'fieldIsRequired' }),
    files: z
      .array(z.instanceof(File), { message: 'fieldIsRequired' })
      .min(1, { message: 'fieldIsRequired' })
      .refine((val) => val.every((file) => file.size <= 5 * oneMegaByte), {
        message: 'veryBigFile'
      }),
    timeFrame: z
      .date()
      .nullable()
      .refine(
        (value) => {
          if (modulePermission === 'Admin') {
            if (value) {
              return true;
            }

            return false;
          } else {
            return true;
          }
        },
        { message: 'fieldIsRequired' }
      )
  });

  type actionPlanSchemaType = z.infer<typeof actionPlanSchema>;
  const { t: translate } = useTranslation();
  const [files, setFiles] = useState<FileType[]>([]);
  const { structureId } = useParams();
  const [createActionPlan] = useMutation<
    CreateActionPlanMutation,
    CreateActionPlanMutationVariables
  >(CreateActionPlanDocument);
  const { data: findActionPlanResponsible, loading } = useQuery<
    FindActionPlanResponsibleQuery,
    FindActionPlanResponsibleQueryVariables
  >(FindActionPlanResponsibleDocument, {
    variables: {
      structureId: structureId ?? ''
    }
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<actionPlanSchemaType>({
    resolver: zodResolver(actionPlanSchema)
  });
  const priorityOptions = [
    {
      label: translate('High'),
      value: 'High'
    },
    {
      label: translate('Medium'),
      value: 'Medium'
    },
    {
      label: translate('Low'),
      value: 'Low'
    }
  ];
  const selectOptionsData =
    findActionPlanResponsible?.findActionPlanResponsible.map((responsible) => ({
      value: responsible.id,
      label: responsible.name
    }));

  const handleFileChange = (selectedFiles: File[]) => {
    const fileSize = selectedFiles[0].size;
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = files
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('files', storedStateFiles);
    if (fileSize <= 5 * oneMegaByte) {
      setFiles((prev) => [...prev, ...newselectedFiles]);
    }
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'files',
      updatedFiles.map((file) => file.file!)
    );
  };

  const handleCreate = (actionPlanData: actionPlanSchemaType) => {
    if (modulePermission === 'Admin') {
      createActionPlan({
        variables: {
          data: {
            description: actionPlanData.description,
            structureId: structureId ?? '',
            responsibleId: actionPlanData.responsible?.value,
            priority: actionPlanData.priorities
              ?.value as ActionPlanPriorityEnum,
            images: actionPlanData.files,
            timeFrame: actionPlanData.timeFrame
          }
        },
        onCompleted: () => {
          onClose();
          toastfySuccess(translate('registeredSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        },
        refetchQueries: [FindActionPlanAllInfoDocument]
      });
    } else {
      createActionPlan({
        variables: {
          data: {
            structureId: structureId ?? '',
            description: actionPlanData.description,
            images: actionPlanData.files
          }
        },
        onCompleted: () => {
          onClose();
          toastfySuccess(translate('registeredSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        },
        refetchQueries: [FindActionPlanAllInfoDocument]
      });
    }
  };

  return (
    <>
      {loading ? (
        <>
          <DivContainerCreateActionPlan>
            <Skeleton height={40} width={400} />
            <Skeleton height={40} width={400} />
            <Skeleton height={100} width={400} />
            <Skeleton height={100} width={400} />
          </DivContainerCreateActionPlan>
          <DivContainerCreateButton>
            <Skeleton height={40} width={100} />
            <Skeleton height={40} width={100} />
          </DivContainerCreateButton>
        </>
      ) : (
        <>
          <DivContainerCreateActionPlan>
            <DivSelects admin={modulePermission === 'Admin'}>
              <InputSelectSearch
                errorMessage={errors.responsible?.message}
                label={translate('responsible')}
                name="responsible"
                options={selectOptionsData ?? []}
                width="300px"
                control={control}
                error={!!errors.responsible}
              />
              <InputSelectSearch
                errorMessage={errors.priorities?.message}
                label={translate('priorities')}
                name="priorities"
                options={priorityOptions}
                width="300px"
                control={control}
                error={!!errors.priorities}
              />
              <DatepickerInput
                placeholder={translate('timeFrame')}
                enableMinMaxDateSelection={true}
                time={true}
                width={'230px'}
                error={!!errors.timeFrame}
                errorMessage={errors.timeFrame?.message}
                control={control}
                label={translate('timeFrame')}
                name="timeFrame"
              />
            </DivSelects>
            <InputFileWithList
              name="files"
              error={!!errors.files}
              accept=".png, .jpg, .jpeg"
              label="picturesOfAnomaly"
              multiple={true}
              files={files}
              onChange={(event) => handleFileChange(event.target.files as any)}
              onDelete={(file: FileType) => handleDeleteImage(file)}
            />
            <InputTextarea
              label={translate('description')}
              name={'description'}
              width="100%"
              errorMessage={errors.description?.message}
              register={register}
              error={!!errors.description}
            />
          </DivContainerCreateActionPlan>
          <DivContainerCreateButton>
            <Button
              variant={'secondary'}
              text="Cancel"
              size="small"
              onClick={() => onClose()}
            />
            <Button
              variant={'primary'}
              text="create"
              size="small"
              onClick={handleSubmit(handleCreate)}
            />
          </DivContainerCreateButton>
        </>
      )}
    </>
  );
};

export default CreateActionPlan;
