import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  ContainterInfos,
  DivContainerImages,
  DivContainerLeftSide,
  DivContainerPictures,
  DivContainerRightSide,
  DivContainterInfos,
  HolderCards,
  HolderContentPage,
  HolderTable,
  MainScreen,
  TitleSkeleton
} from './SkeletonAtoDashboardPageStyles';

const SkeletonAtoDashboardPage: React.FC = () => {
  return (
    <MainScreen>
      <TitleSkeleton>
        <Skeleton height={35} width={400} />
      </TitleSkeleton>
      <HolderContentPage>
        <DivContainerLeftSide>
          <DivContainterInfos>
            <ContainterInfos>
              <Skeleton height={30} width={155} />
              <Skeleton height={20} width={140} />
            </ContainterInfos>

            <ContainterInfos>
              <Skeleton height={30} width={155} />
              <Skeleton height={20} width={140} />
            </ContainterInfos>
            <ContainterInfos>
              <Skeleton height={30} width={155} />
              <Skeleton height={20} width={140} />
            </ContainterInfos>
            <ContainterInfos>
              <Skeleton height={30} width={155} />
              <Skeleton height={20} width={140} />
            </ContainterInfos>
          </DivContainterInfos>
          <DivContainerImages>
            <Skeleton height={30} width={180} />
            <DivContainerPictures>
              <Skeleton height={120} width={200} />
              <Skeleton height={120} width={200} />
              <Skeleton height={120} width={200} />
              <Skeleton height={120} width={200} />
              <Skeleton height={120} width={200} />
              <Skeleton height={120} width={200} />
              <Skeleton height={120} width={200} />
              <Skeleton height={120} width={200} />
              <Skeleton height={120} width={200} />
            </DivContainerPictures>
          </DivContainerImages>
        </DivContainerLeftSide>
        <DivContainerRightSide>
          <HolderCards>
            <Skeleton height={100} width={110} />
            <Skeleton height={100} width={110} />
            <Skeleton height={100} width={110} />
            <Skeleton height={100} width={110} />
            <Skeleton height={100} width={110} />
          </HolderCards>
          <HolderTable>
            <Skeleton height={295} width={740} />
          </HolderTable>
        </DivContainerRightSide>
      </HolderContentPage>
    </MainScreen>
  );
};

export default SkeletonAtoDashboardPage;
