import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import Select from 'react-select';
import { CracksType, UserData } from '../../../../@Types/types';
import {
  DivInputViewImage,
  InputFile,
  InspectionInputsDiv
} from '../../../../Components/Forms/FormInspectionsStyle';
import { DefaultInput } from '../../../../Components/Inputs/InputsStyle';
import {
  DivSelectStyle,
  StyleInstrumentSelect
} from '../../../../Components/Selects/InstrumentSelect';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';
import { toastfyError, toastfySuccess } from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { ActionsRow } from '../../../../Screens/AdminScreen/AdminScreenStyle';
import {
  RadioCheboxArea,
  RadioCheck,
  RadioCheckbox
} from '../../../../Styles/Styles';
import { InspectionTextArea, RegisterArea } from '../../InspectionsStyle';
import { AnswerEnum } from '../../../../data/graphql/base-schema';
import {
  IdentifiesContainer,
  IdentifiesHeader,
  TextButtonAdd
} from './DamMassifStyles';
import ModalStep from '../../../../Components/V2/Molecules/ModalStep/ModalStep';
import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { API_URL, BUCKET_URL } from '../../../../utils/const';
import ModalConfirm from '../../../../Components/V2/Molecules/ModalConfirm/ModalConfirm';
import FileList from '../../../../Components/V2/Molecules/FileList/FileList';
import { FileType } from '../../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';
import FileViewer from '../../../../Components/V2/Molecules/FileViewer/FileViewer';

export function CracksIdentifies({
  setStepTwoStates,
  stepTwoStates,
  inspectionId,
  mssStructure,
  setUpdateStepTwo
}: {
  setStepTwoStates: Dispatch<SetStateAction<any[]>>;
  stepTwoStates: any[];
  inspectionId: string;
  mssStructure: any;
  setUpdateStepTwo?: any;
}) {
  const { t }: any = useTranslation();
  const [cracks, setCracks] = useState<any[]>([]);
  const [cracksForm, setCracksForm] = useState<any>({
    name: '',
    length: '',
    opening: '',
    longitudinalTransverse: '',
    observation: '',
    file: [],
    fileSent: false
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [checkBox, setCheckBox] = useState([
    { checked: false },
    { checked: false }
  ]);
  const [img, setImg] = useState<FileType[]>([]);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [fileShowModal, setFileShowModal] = useState<FileType | null>(null);
  const [error, setError] = useState<boolean>(false);

  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const httpLink = new HttpLink({
    uri: `${API_URL}/graphql`
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: userData.token
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  const types = [
    { label: t('Longitudinal'), id: 'Longitudinal', value: 'Longitudinal' },
    { label: t('Transversal'), id: 'Transversal', value: 'Transversal' }
  ];

  const handleChangeCheckbox = (value: AnswerEnum, topic: string) => {
    const updatedStepStates = stepTwoStates.map((item: any) => {
      if (item.topic === topic) {
        return { ...item, answer: value };
      }
      return item;
    });
    setStepTwoStates(updatedStepStates);
    setCheckBox([
      { checked: value === AnswerEnum.Yes },
      { checked: value === AnswerEnum.No }
    ]);
  };

  const handleDelete = async (crack: CracksType) => {
    if (crack.id) {
      if (showModalConfirm) {
        const deleteResponse = await client.mutate({
          mutation: gql`
            mutation DeleteIdentifiedCrack(
              $inspectionId: String!
              $identifiedCrackId: String!
            ) {
              deleteIdentifiedCrack(
                inspectionId: $inspectionId
                identifiedCrackId: $identifiedCrackId
              )
            }
          `,
          variables: {
            inspectionId: inspectionId,
            identifiedCrackId: crack.id
          }
        });
        if (deleteResponse.data) {
          const filteredCracks = cracks.filter((e) => e.id !== crack.id);
          setCracks(filteredCracks);
          setStepTwoStates((prev) =>
            prev?.map((item) =>
              item.topic === 'Cracks'
                ? { ...item, fields: filteredCracks }
                : item
            )
          );
          toastfySuccess(t('deletedSuccessfully'));
        }
        setShowModalConfirm(false);
      } else {
        setShowModalConfirm(true);
      }
    } else {
      const filteredCracks = cracks.filter((e) => e !== crack);
      setCracks(filteredCracks);
      setStepTwoStates((prev) =>
        prev?.map((item) =>
          item.topic === 'Cracks' ? { ...item, fields: filteredCracks } : item
        )
      );
    }
  };

  const handleEdit = (crack: any) => {
    setCracksForm(crack);
    setShowModal(true);
    if (crack.id) {
      setImg(crack.file);
    } else {
      setImg(
        crack.file.map((file: any) => {
          return {
            id: null,
            name: file.name,
            url: URL.createObjectURL(file),
            file: file
          };
        })
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCracksForm({
      name: '',
      length: '',
      opening: '',
      longitudinalTransverse: '',
      observation: '',
      file: [],
      fileSent: false
    });
    setImg([]);
  };

  async function postSituationAccess(formData: FormData) {
    const response = await fetch(
      `${API_URL}/inspection/${inspectionId}/images`,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `${userData.token}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error('Erro');
    }
  }

  const addCrack = async () => {
    if (
      cracksForm.name &&
      cracksForm.length &&
      cracksForm.opening &&
      cracksForm.longitudinalTransverse &&
      cracksForm.observation &&
      cracksForm.fileSent
    ) {
      if (cracksForm.id) {
        const editResponse = await client.mutate({
          mutation: gql`
            mutation UpdateIdentifiedCrack(
              $inspectionId: String!
              $newData: UpdateMssIdentifiedCrackInput!
            ) {
              updateIdentifiedCrack(
                inspectionId: $inspectionId
                newData: $newData
              )
            }
          `,
          variables: {
            inspectionId: inspectionId,
            newData: {
              id: cracksForm.id,
              name: cracksForm.name,
              length: cracksForm.length,
              opening: cracksForm.opening,
              longitudinalTransverse: cracksForm.longitudinalTransverse,
              observation: cracksForm.observation
            }
          }
        });
        const newImages = cracksForm.file.filter((file: any) => !file.id);
        if (newImages.length > 0) {
          const formData = new FormData();
          newImages.forEach((image: any) => {
            formData.append(`mss=identifiedCracks=${cracksForm.id}`, image);
          });
          await postSituationAccess(formData);
        }
        if (editResponse.data) {
          const updatedCracks = cracks.map((crack) => {
            if (crack.id === cracksForm.id) {
              return {
                ...crack,
                name: cracksForm.name,
                length: cracksForm.length,
                opening: cracksForm.opening,
                longitudinalTransverse: cracksForm.longitudinalTransverse,
                observation: cracksForm.observation,
                file: cracksForm.file
              };
            }
            return crack;
          });
          setCracks(updatedCracks);
          setStepTwoStates((prev) =>
            prev?.map((item) =>
              item.topic === 'Cracks'
                ? { ...item, fields: updatedCracks }
                : item
            )
          );
          setCracksForm({
            name: '',
            length: '',
            opening: '',
            longitudinalTransverse: '',
            observation: '',
            file: [],
            fileSent: false
          });
          setImg([]);
          toastfySuccess(t('editedSuccessfully'));
          setError(false);
          setUpdateStepTwo((prev: boolean) => !prev);
          return true;
        } else {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          setError(true);
          return false;
        }
      } else {
        if (mssStructure) {
          const createCrackResponse = await client.mutate({
            mutation: gql`
              mutation AddIdentifiedCrack(
                $inspectionId: String!
                $newIdentifiedCrack: MssIdentifiedCracksInput!
              ) {
                addIdentifiedCrack(
                  inspectionId: $inspectionId
                  newIdentifiedCrack: $newIdentifiedCrack
                )
              }
            `,
            variables: {
              inspectionId: inspectionId,
              newIdentifiedCrack: {
                name: cracksForm.name,
                length: cracksForm.length,
                opening: cracksForm.opening,
                longitudinalTransverse: cracksForm.longitudinalTransverse,
                observation: cracksForm.observation
              }
            }
          });
          if (createCrackResponse.data) {
            if (cracksForm.file.length > 0) {
              const formData = new FormData();
              cracksForm.file.forEach((image: any) => {
                formData.append(
                  `mss=identifiedCracks=${createCrackResponse.data.addIdentifiedCrack}`,
                  image
                );
              });
              if (formData) await postSituationAccess(formData);
            }
            const newCrack = {
              ...cracksForm,
              id: createCrackResponse.data.addIdentifiedCrack
            };
            setCracks([...cracks, newCrack]);
            setStepTwoStates((prev) =>
              prev?.map((item) =>
                item.topic === 'Cracks'
                  ? { ...item, fields: [...cracks, newCrack] }
                  : item
              )
            );
            setCracksForm({
              name: '',
              length: '',
              opening: '',
              longitudinalTransverse: '',
              observation: '',
              file: [],
              fileSent: false
            });
            setImg([]);
            toastfySuccess(t('registeredSuccessfully'));
            setError(false);
            setUpdateStepTwo((prev: boolean) => !prev);
            return true;
          } else {
            toastfyError(t(ToastifyModel().toastifyMessage.error));
            setError(true);
            return false;
          }
        } else {
          setCracks([...cracks, cracksForm]);
          setStepTwoStates((prev) =>
            prev?.map((item) =>
              item.topic === 'Cracks'
                ? { ...item, fields: [...cracks, cracksForm] }
                : item
            )
          );
          setCracksForm({
            name: '',
            length: '',
            opening: '',
            longitudinalTransverse: '',
            observation: '',
            file: [],
            fileSent: false
          });
          setImg([]);
          setError(false);
          return true;
        }
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setError(true);
      return false;
    }
  };

  const handleChange = (e: any) => {
    setCracksForm((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleChangeImage = (files: any) => {
    const updatedImg = img.concat(
      Array.from(files).map((file: any) => {
        return {
          id: null,
          name: file.name,
          url: URL.createObjectURL(file),
          file: file
        };
      })
    );
    setCracksForm((prevState: any) => ({
      ...prevState,
      fileSent: true,
      file: [...prevState.file, ...files]
    }));
    setImg(updatedImg);
  };

  const handleDeleteImage = (imgSelect: FileType) => {
    if (imgSelect.id) {
      const deleteResponse = client.mutate({
        mutation: gql`
          mutation DeleteInspectionDataImage($image: DeleteInspectionDataImageInput!, $inspectionId: String!) {
            deleteInspectionDataImage(image: $image, inspectionId: $inspectionId)
          }}
          `,
        variables: {
          inspectionId: inspectionId,
          image: {
            id: imgSelect.id,
            dataType: 'identifiedCracks'
          }
        }
      });
      if (deleteResponse.data) {
        const filteredImages = img.filter(
          (image: FileType) => image.id !== imgSelect.id
        );
        setImg(filteredImages);
        setCracksForm((prevState: any) => ({
          ...prevState,
          file: filteredImages
        }));
        const updatedStepStates = stepTwoStates.map((item: any) => {
          if (item.topic === 'Cracks') {
            return { ...item, fields: filteredImages };
          }
          return item;
        });
        setStepTwoStates(updatedStepStates);
        return true;
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.error));
        return false;
      }
    } else {
      const updatedImg = img.filter((item: FileType) => item !== imgSelect);
      setImg(updatedImg);
      const updateCracksForm = cracksForm.file.filter(
        (file: any) => file !== imgSelect.file
      );
      setCracksForm((prevState: any) => ({
        ...prevState,
        file: updateCracksForm
      }));
      const updatedStepStates = stepTwoStates.map((item: any) => {
        const existingFiles = item.file?.filter(
          (file: any) => file !== imgSelect
        );
        if (item.topic === 'Cracks') {
          return { ...item, fields: existingFiles };
        }
        return item;
      });
      setStepTwoStates(updatedStepStates);
      return true;
    }
  };

  useEffect(() => {
    if (stepTwoStates.length > 0) {
      stepTwoStates?.map((item: any) => {
        if (item.topic === 'Cracks') {
          setCheckBox([
            { checked: item.answer === AnswerEnum.Yes },
            { checked: item.answer === AnswerEnum.No }
          ]);
          setCracks(
            item.fields.map((crack: any) => {
              let fileURLs: FileType[] = [];
              if (crack.file?.[0]?.image) {
                fileURLs = crack.file.map((file: any) => {
                  return {
                    id: file.id,
                    name: file.image,
                    url: `${BUCKET_URL}/${file.image}`
                  };
                });
              } else if (crack.fileSent) {
                if (crack.file.length > 0) {
                  fileURLs = crack.file.map((file: any) => {
                    return file;
                  });
                }
              }
              return {
                ...crack,
                file: fileURLs
              };
            }) || []
          );
        }
      });
    }
  }, [stepTwoStates]);

  return (
    <>
      <IdentifiesContainer>
        <IdentifiesHeader>
          <span id="cracks">
            {t('CracksIdentifies')}
            <InspectionInputsDiv
              style={{
                justifyContent: 'flex-start',
                outline:
                  error && !checkBox.some((x) => x.checked)
                    ? '1px solid red'
                    : 'none'
              }}
            >
              <RadioCheboxArea style={{ left: 'unset' }}>
                <RadioCheckbox>
                  <input
                    type="radio"
                    name="CracksIdentifies"
                    id="yes_CracksIdentifies"
                    checked={checkBox[0].checked}
                    onChange={() =>
                      handleChangeCheckbox(AnswerEnum.Yes, 'Cracks')
                    }
                  />
                  <div className="transition"></div>
                </RadioCheckbox>
                <RadioCheck htmlFor="yes_CracksIdentifies">
                  {t('Yes')}
                </RadioCheck>
              </RadioCheboxArea>
              <RadioCheboxArea style={{ left: 'unset' }}>
                <RadioCheckbox>
                  <input
                    type="radio"
                    name="CracksIdentifies"
                    id="no_CracksIdentifies"
                    checked={checkBox[1].checked}
                    onChange={() =>
                      handleChangeCheckbox(AnswerEnum.No, 'Cracks')
                    }
                  />
                  <div className="transition"></div>
                </RadioCheckbox>
                <RadioCheck htmlFor="no_CracksIdentifies">{t('No')}</RadioCheck>
              </RadioCheboxArea>
            </InspectionInputsDiv>
          </span>
          {checkBox[0].checked && (
            <TextButtonAdd
              onClick={() => {
                setShowModal(!showModal);
                setCracksForm((prev: any) => ({ ...prev }));
              }}
            >
              {t('Add')}
            </TextButtonAdd>
          )}
        </IdentifiesHeader>
        {checkBox[0].checked && (
          <TableComponentStyle
            style={{
              outline:
                error && checkBox[0].checked && cracks.length <= 0
                  ? '1px solid red'
                  : 'none'
            }}
          >
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{`${t('Length')} (m)`}</th>
                <th>{`${t('Opening')} (mm)`}</th>
                <th>{t('Longitudinal/') + t('Transversal')}</th>
                <th>{t('Observation')}</th>
                <th>{t('Image')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {cracks?.map((crack, index) => (
                <tr key={index}>
                  <td>{crack.name}</td>
                  <td>{crack.length}</td>
                  <td>{crack.opening}</td>
                  <td>{crack.longitudinalTransverse}</td>
                  <td>
                    {crack.observation && crack.observation.length > 0
                      ? `${crack.observation.slice(0, 9)}...`
                      : '-'}
                  </td>
                  <td>
                    {crack.file?.map((file: any, index: number) => {
                      return (
                        <img
                          key={index}
                          src={file.url || URL.createObjectURL(file)}
                          alt="crack"
                          style={{
                            width: '50px',
                            height: '50px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setFileShowModal(file);
                          }}
                        />
                      );
                    }) || '-'}
                    {fileShowModal && (
                      <FileViewer
                        files={crack.file}
                        selectedFile={fileShowModal}
                        setSelectedFile={setFileShowModal}
                        onClose={() => {
                          setFileShowModal(null);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <ActionsRow>
                      {crack.id && (
                        <BiSolidPencil onClick={() => handleEdit(crack)} />
                      )}
                      <MdDelete onClick={() => handleDelete(crack)} />
                    </ActionsRow>
                    {showModalConfirm && (
                      <ModalConfirm
                        title={`${t('Delete')} ${t('Crack')}`}
                        text={t('ConfirmDelete')}
                        subtitle={t('ActionCannotBeUndone')}
                        onConfirm={() => handleDelete(crack)}
                        onClose={() => {
                          setShowModalConfirm(false);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </TableComponentStyle>
        )}
        {showModal && (
          <ModalStep
            width={30}
            onClose={handleCloseModal}
            title={cracksForm.id ? `${t('Edit')} ${t('Crack')}` : t('AddCrack')}
            steps={[
              {
                name: '',
                component: (
                  <>
                    <RegisterArea>
                      <DefaultInput
                        style={{ width: '293px' }}
                        placeholder={`${t('name')}`}
                        name="name"
                        value={cracksForm.name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={error && cracksForm.name === ''}
                      />
                      <DefaultInput
                        style={{ width: '293px' }}
                        placeholder={`${t('Length')} (m)`}
                        name="length"
                        value={cracksForm.length}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="number"
                        error={error && cracksForm.length === ''}
                      />
                      <DefaultInput
                        style={{ width: '293px' }}
                        placeholder={`${t('Opening')} (mm)`}
                        name="opening"
                        value={cracksForm.opening}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="number"
                        error={error && cracksForm.opening === ''}
                      />
                      <DivSelectStyle
                        style={{ width: '320px' }}
                        error={error && !cracksForm.longitudinalTransverse}
                      >
                        <Select
                          placeholder={t('Orientation')}
                          options={types}
                          styles={StyleInstrumentSelect}
                          hideSelectedOptions
                          maxMenuHeight={200}
                          name="longitudinalTransverse"
                          value={types.find(
                            (type) =>
                              type.value === cracksForm.longitudinalTransverse
                          )}
                          onChange={(e) => {
                            setCracksForm((prevState: any) => ({
                              ...prevState,
                              longitudinalTransverse: e?.value
                            }));
                          }}
                        />
                      </DivSelectStyle>
                      <InspectionTextArea
                        style={{
                          width: '293px',
                          height: '80px',
                          minHeight: '80px',
                          outline:
                            error && cracksForm.observation === ''
                              ? '1px solid red'
                              : 'none'
                        }}
                        placeholder={t('Observation')}
                        spellCheck={false}
                        name="observation"
                        value={cracksForm.observation}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <DivInputViewImage>
                        <InputFile>
                          <input
                            type="file"
                            name="image"
                            id="File_crack"
                            accept=".png, .jpg, .jpeg"
                            multiple
                            onChange={(e) => {
                              handleChangeImage(e.target.files);
                            }}
                            style={{
                              outline:
                                error && img.length <= 0
                                  ? '1px solid red'
                                  : 'none'
                            }}
                          />
                        </InputFile>
                        {img.length > 0 && (
                          <FileList
                            files={img}
                            onDelete={(img: FileType) => {
                              handleDeleteImage(img);
                            }}
                          />
                        )}
                      </DivInputViewImage>
                    </RegisterArea>
                  </>
                ),
                onNext: () => addCrack()
              }
            ]}
          />
        )}
      </IdentifiesContainer>
    </>
  );
}
