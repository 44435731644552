import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { NotificationListType } from '../../@Types/types';
import { Color } from '../../Styles/Styles';
import { DatePickerComponent } from '../DatePicker';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalHeader
} from '../V2/Molecules/ModalStep/ModalStep.styles';
import { StyleInstrumentSelect } from '../Selects/InstrumentSelect';
import { NotificationContext } from '../UserBadge';
import NotificationListModal from './NotificationListModal';
import { ModalFilterDateContainer } from './NotificationStyle';

interface NotificationsModalProps {
  onClose: () => void;
}

export default function NotificationModal(props: NotificationsModalProps) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [notificationSelect, setNotificationSelect] = useState<{
    label: string;
    id: string;
    value: string;
  } | null>(null);

  const { allNotificationItemsList } = useContext(NotificationContext) as {
    allNotificationItemsList: NotificationListType[];
  };
  const [filteredData, setFilteredData] = useState<NotificationListType[]>(
    allNotificationItemsList
  );

  const notificationView = [
    { label: t('read'), id: 'read', value: 'read' },
    { label: t('unread'), id: 'unread', value: 'unread' },
    { label: t('allMessages'), id: 'allMessages', value: 'allMessages' }
  ];

  const customStyles = {
    ...StyleInstrumentSelect,
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${Color.Brown1}`,
      borderRadius: 'none',
      boxSizing: 'border-box',
      boxShadow: 'none',
      padding: 'none',
      fontSize: '11pt',
      cursor: 'pointer',
      userSelect: 'none',
      width: 200,
      ':hover': {
        borderBottom: `1px solid ${Color.Brown1}`
      }
    })
  };

  useEffect(() => {
    handleFilterDate();
  }, [startDate, endDate]);

  const handleFilterDate = () => {
    const filteredItemsByDate = allNotificationItemsList.filter((e) => {
      const itemDate = new Date(e.sentAt);
      if (startDate !== null && endDate !== null) {
        return itemDate >= startDate && itemDate <= endDate;
      } else if (startDate) {
        return itemDate >= startDate;
      } else if (endDate) {
        return itemDate <= endDate;
      } else {
        return true;
      }
    });

    setFilteredData(filteredItemsByDate);
  };

  const handleFilterMesages = (ele: {
    label: string;
    id: string;
    value: string;
  }) => {
    setNotificationSelect(ele);

    const filteredItemsByStatus = allNotificationItemsList.filter((e) => {
      if (ele !== null && ele.value === 'read') {
        return e.unread === false;
      }

      if (ele !== null && ele.value === 'unread') {
        return e.unread === true;
      }

      if (ele === null || ele.value === 'allMessages') {
        return true;
      }
    });

    setFilteredData(filteredItemsByStatus);
  };

  return (
    <ModalContainer>
      <Modal width={40} height={100}>
        <ModalContent>
          <ModalHeader>
            <ModalFilterDateContainer>
              <DatePickerComponent
                setStartDate={setStartDate}
                startDate={startDate}
                hasInterval
                setEndDate={setEndDate}
                endDate={endDate}
                dateText={t('InitialDate')}
              />
              <Select
                placeholder={t('Type')}
                isSearchable={false}
                options={notificationView}
                styles={customStyles}
                hideSelectedOptions
                maxMenuHeight={200}
                value={notificationSelect}
                onChange={(ele: any) => {
                  handleFilterMesages(ele);
                }}
              />
            </ModalFilterDateContainer>
            <button onClick={props.onClose}>&times;</button>
          </ModalHeader>
          <NotificationListModal filteredData={filteredData} />
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}
