import React from 'react';
import {
  Container,
  DivContent,
  DivButton,
  DivTitle,
  DivHeader
} from './SimpleContentTemplateStyle';

export function PSBScreenTemplate({
  title,
  button,
  content
}: {
  title: React.ReactNode;
  button: React.ReactNode;
  content: React.ReactNode;
}) {
  return (
    <Container>
      <DivHeader>
        <DivTitle>{title}</DivTitle>
        <DivButton>{button}</DivButton>
      </DivHeader>
      <DivContent>{content}</DivContent>
    </Container>
  );
}

export default PSBScreenTemplate;
