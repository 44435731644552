import { ReactElement, useEffect, useState } from 'react';
import {
  AdvancedMarker,
  InfoWindow,
  Pin,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';
import { Color } from '../../../Styles/Styles';
import { WiRaindrops } from 'react-icons/wi';
import { FaArrowUpFromGroundWater } from 'react-icons/fa6';
import { TbArrowsUpRight } from 'react-icons/tb';
import { RiTestTubeFill } from 'react-icons/ri';
import { CustomMarkerStyle } from '../MapStyle';
import { useTranslation } from 'react-i18next';
import { IoIosClose } from 'react-icons/io';
import { CloseIconStyle } from '../../Graphs/Monitoring/Instrument/StyleInstrumentGraph';
import {
  MapPopUpContentStyle,
  MapPopUpHeaderStyle,
  MapPopUpTableContentStyle,
  SpanInstrumentPopUpStyle,
  TextInstumentsStyle
} from './MapGoogle.styles';

interface MarkerWithInfowindowProps {
  markInstument?: {
    id: string;
    name: string;
    lat: number;
    lon: number;
    type: string;
  };
  title?: string;
  shouldRenderIcon?: boolean;
}

export const MarkerWithInfowindow = ({
  markInstument,
  title,
  shouldRenderIcon
}: MarkerWithInfowindowProps) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const { t: translation } = useTranslation();

  const getMarkerIcon = (type: string) => {
    const getIcon: { [key: string]: ReactElement } = {
      Pluviometer: <WiRaindrops style={CustomMarkerStyle} />,
      WaterLevel: <FaArrowUpFromGroundWater style={CustomMarkerStyle} />,
      SurfaceMarker: <TbArrowsUpRight style={CustomMarkerStyle} />,
      Piezometer: <RiTestTubeFill style={CustomMarkerStyle} />,
      Ina: <RiTestTubeFill style={CustomMarkerStyle} />
    };
    return getIcon[type];
  };

  // TODO: Adcionar tipagem para o instrumento.
  const createPopup = (type: string, instrument: any) => {
    const instrumentData = [
      {
        label: 'Type',
        value: type === 'Ina' ? type.toLocaleUpperCase() : type
      },
      { label: 'Status', value: instrument?.operationalStatus },
      { label: 'LastReadingDate', value: instrument?.date },
      {
        label: 'LastReadingValue',
        value: instrument?.rainfall || instrument?.value
      },
      { label: 'OverflowThreshold', value: instrument?.overflowThreshold },
      { label: 'N.A.MaximusMaximorum', value: instrument?.naMaximusMaximorum },
      { label: 'ControlLevel', value: instrument?.alertControl }
    ];

    const tableContent = // TODO: Validar dados com o backend.
      type === 'SurfaceMarker' ? (
        <table style={{ color: 'black' }}>
          <thead>
            <tr>
              <th>{translation('Reading')}</th>
              <th>{translation('Displacement')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>VALOR BANCO</td>
              <td>VALOR BANCO</td>
            </tr>
            <tr>
              <td>VALOR BANCO</td>
              <td>VALOR BANCO</td>
            </tr>
            <tr>
              <td>VALOR BANCO</td>
              <td>VALOR BANCO</td>
            </tr>
          </tbody>
        </table>
      ) : (
        ''
      );

    return (
      <>
        <MapPopUpHeaderStyle>
          <h3>{instrument?.name}</h3>
          <IoIosClose
            size={25}
            onClick={() => setInfowindowOpen(false)}
            style={CloseIconStyle}
          />
        </MapPopUpHeaderStyle>
        <MapPopUpContentStyle>
          {instrumentData.map(({ label, value }) => (
            <div key={label}>
              <SpanInstrumentPopUpStyle>
                <p>{translation(label)}</p>
              </SpanInstrumentPopUpStyle>
              <SpanInstrumentPopUpStyle>
                <p>{translation(value) || '-'}</p>
              </SpanInstrumentPopUpStyle>
            </div>
          ))}
        </MapPopUpContentStyle>
        <MapPopUpTableContentStyle>{tableContent}</MapPopUpTableContentStyle>
      </>
    );
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        key={'AdvancedMarker' + markInstument!.lat}
        onClick={() => {
          setInfowindowOpen(true);
        }}
        position={{ lat: markInstument!.lat, lng: markInstument!.lon }}
        title={title}
      >
        <Pin
          scale={shouldRenderIcon ? 1 : 0}
          background={Color.White}
          borderColor={Color.Brown1}
        >
          {shouldRenderIcon ? (
            getMarkerIcon(markInstument!.type)
          ) : (
            <TextInstumentsStyle>
              {markInstument!.name}
            </TextInstumentsStyle>
          )}
        </Pin>
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          headerDisabled={true}
          disableAutoPan={true}
          minWidth={280}
        >
          {createPopup(markInstument!.type, markInstument)}
        </InfoWindow>
      )}
    </>
  );
};
