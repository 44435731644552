import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery,
  FindDataByRecordQueryVariables
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { Color } from '../../../../Styles/Styles';
import { BUCKET_URL } from '../../../../utils/const';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import SkeletonNewAtoPage from '../../../Skeleton/newAtoPage/SkeletonNewAtoPage';
import { toastfyError } from '../../../Toastify';
import Text from '../../Atoms/Text/Text';
import { AtoSignatureCard } from '../../Organisms/AtoSignatureCard/AtoSignatureCard';
import { CardAtoInfoActivities } from '../../Organisms/CardAtoInfoActivities/CardAtoInfoActivities';
import CardAtoInfoAlertPeriod from '../../Organisms/CardAtoInfoAlertPeriod/CardAtoInfoAlertPeriod';
import { CardAtoInfoCompanyObservation } from '../../Organisms/CardAtoInfoCompanyObservation/CardAtoInfoCompanyObservation';
import { CardAtoInfoEquipments } from '../../Organisms/CardAtoInfoEquipments/CardAtoInfoEquipments';
import { CardAtoInfoFiscalization } from '../../Organisms/CardAtoInfoFiscalization/CardAtoInfoFiscalization';
import { CardAtoInfoLabor } from '../../Organisms/CardAtoInfoLabor/CardAtoInfoLabor';
import { CardAtoInfoPhotos } from '../../Organisms/CardAtoInfoPhotos/CardAtoInfoPhotos';
import { CardAtoInfoVideos } from '../../Organisms/CardAtoInfoVideos/CardAtoInfoVideos';
import { CardAtoInfoWeather } from '../../Organisms/CardAtoInfoWeather/CardAtoInfoWeather';
import { CardAtoInfoWorkSafetyObs } from '../../Organisms/CardAtoInfoWorkSafetyObs/CardAtoInfoWorkSafetyObs';
import { CardAtoInfoWorkSchedule } from '../../Organisms/CardAtoInfoWorkSchedule/CardAtoInfoWorkSchedule';
import ReportCard from '../../Organisms/ReportCard/ReportCard';
import SignatureContainer from '../../Organisms/SignatureContainer/SignatureContainer';
import { TemplateNewAto } from '../../Templates/TemplateNewAto/TemplateNewAto';
import {
  ActivityType,
  EquipmentDataType,
  InspectionObservationsType,
  LaborDataType,
  RecordImagesType,
  RecordSignaturesType,
  RecordVideosType,
  RedAlertPeriodType,
  ResponsibleCompanyObservationType,
  WeatherConditionsType,
  WorkSafetyObservationsType,
  WorkShiftsType
} from './EditAtoPage.interface';

export const EditAtoPage: React.FC = () => {
  const { t } = useTranslation();
  const { recordId, structureId, atoId } = useParams();
  const navigate = useNavigate();
  const { data: rdoFormsData, loading } = useQuery<
    FindDataByRecordQuery,
    FindDataByRecordQueryVariables
  >(FindDataByRecordDocument, {
    variables: {
      recordId: recordId ? recordId : ''
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
      toastfyError(errorMessage);
    }
  });

  const workShifts = useMemo(() => {
    return rdoFormsData?.findDataByRecord.workShifts.map(
      (shift: WorkShiftsType) => ({
        entry: new Date(shift.entry).toLocaleString(),
        exit: new Date(shift.exit).toLocaleString(),
        entryInterval: new Date(shift.entryInterval).toLocaleString(),
        exitInterval: new Date(shift.exitInterval).toLocaleString(),
        id: shift.id,
        observation: shift.observation,
        totalHours: shift.totalHours
      })
    );
  }, [rdoFormsData?.findDataByRecord.workShifts]);

  const weather = useMemo(() => {
    return rdoFormsData?.findDataByRecord.weatherConditions.map(
      (condition: WeatherConditionsType) => ({
        weather: condition.weather,
        start: new Date(condition.start).toLocaleString(),
        end: new Date(condition.end).toLocaleString(),
        id: condition.id
      })
    );
  }, [rdoFormsData?.findDataByRecord.weatherConditions]);

  const redAlertPeriod: RedAlertPeriodType[] = useMemo(() => {
    return (
      rdoFormsData?.findDataByRecord.redAlertPeriods.map((condition) => ({
        start: new Date(condition.start).toLocaleString(),
        end: new Date(condition.end).toLocaleString(),
        id: condition.id
      })) || []
    );
  }, [rdoFormsData?.findDataByRecord.redAlertPeriods]);

  const laborData = useMemo(() => {
    return rdoFormsData?.findDataByRecord.labor.map((data: LaborDataType) => ({
      description: data.description,
      quantity: data.quantity,
      id: data.id
    }));
  }, [rdoFormsData?.findDataByRecord.labor]);

  const equipmentData = useMemo(() => {
    return rdoFormsData?.findDataByRecord.equipments.map(
      (data: EquipmentDataType) => ({
        description: data.description,
        quantity: data.quantity,
        id: data.id
      })
    );
  }, [rdoFormsData?.findDataByRecord.equipments]);

  const activity: ActivityType[] = useMemo(() => {
    return (
      rdoFormsData?.findDataByRecord.activities.map((data) => ({
        id: data.id,
        description: data.description,
        images: data.images.map((image) => ({
          id: image.id,
          image: image.image
        })),
        videos: data.videos.map((video) => ({
          id: video.id,
          video: video.video
        }))
      })) || []
    );
  }, [rdoFormsData?.findDataByRecord.activities]);

  const responsibleCompanyObservation = useMemo(() => {
    return rdoFormsData?.findDataByRecord.responsibleCompanyObservations.map(
      (data: ResponsibleCompanyObservationType) => ({
        id: data.id,
        observation: data.observation
      })
    );
  }, [rdoFormsData?.findDataByRecord.responsibleCompanyObservations]);

  const inspectionObservations = useMemo(() => {
    return rdoFormsData?.findDataByRecord.inspectionObservations.map(
      (data: InspectionObservationsType) => ({
        id: data.id,
        observation: data.observation
      })
    );
  }, [rdoFormsData?.findDataByRecord.inspectionObservations]);

  const workSafetyObservations = useMemo(() => {
    return rdoFormsData?.findDataByRecord.workSafetyObservations.map(
      (data: WorkSafetyObservationsType) => ({
        id: data.id,
        observation: data.observation
      })
    );
  }, [rdoFormsData?.findDataByRecord.workSafetyObservations]);

  const recordImages = useMemo(() => {
    return (
      rdoFormsData?.findDataByRecord.recordImages.map(
        (data: RecordImagesType) => ({
          id: data.id,
          image: data.image
        })
      ) ?? []
    );
  }, [rdoFormsData?.findDataByRecord.recordImages]);

  const recordVideos = useMemo(() => {
    return rdoFormsData?.findDataByRecord.recordVideos.map(
      (data: RecordVideosType) => ({
        id: data.id,
        video: data.video
      })
    );
  }, [rdoFormsData?.findDataByRecord.recordVideos]);

  const navigateBack = () => {
    navigate(`/${structureId}/ato/${atoId}`);
  };

  return (
    <>
      {loading ? (
        <SkeletonNewAtoPage />
      ) : (
        <TemplateNewAto
          icon={
            <IoIosArrowBack
              style={{
                cursor: 'pointer',
                color: `${Color.Brown1}`
              }}
              size={25}
              color="brown"
              onClick={() => navigateBack()}
            />
          }
          header={
            <Text color="brown" type="h2">
              {`RDO - ${new Date(
                rdoFormsData?.findDataByRecord.recordDate || new Date()
              ).toLocaleDateString()}`}
            </Text>
          }
          infosContent={
            <ReportCard
              logoLeft={
                rdoFormsData?.findDataByRecord.clientLogo
                  ? `${BUCKET_URL}/${rdoFormsData?.findDataByRecord.clientLogo}`
                  : ''
              }
              logoRight={
                rdoFormsData?.findDataByRecord.responsibleCompanyLogo
                  ? `${BUCKET_URL}/${
                      rdoFormsData?.findDataByRecord.responsibleCompanyLogo
                    }`
                  : ''
              }
              title={rdoFormsData?.findDataByRecord.title || ''}
              contractNumber={rdoFormsData?.findDataByRecord.contract || ''}
              contractualTerm={
                rdoFormsData?.findDataByRecord.contractualPeriod || ''
              }
              elapsedDuration={rdoFormsData?.findDataByRecord.elapsedTerm || ''}
              remainingDuration={
                rdoFormsData?.findDataByRecord.remainingTerm || ''
              }
              responsiblePerson={
                rdoFormsData?.findDataByRecord.elaborator || ''
              }
              company={rdoFormsData?.findDataByRecord.responsibleCompany || ''}
              rdoNumber={rdoFormsData?.findDataByRecord.recordNumber || ''}
              date={
                new Date(
                  rdoFormsData?.findDataByRecord.recordDate || new Date()
                ).toLocaleDateString() || ''
              }
            />
          }
          pageContent={
            <>
              <CardAtoInfoWorkSchedule
                workShifts={workShifts}
                rdoFormsData={rdoFormsData}
              />
              <CardAtoInfoWeather weather={weather || []} />
              <CardAtoInfoAlertPeriod redAlertPeriod={redAlertPeriod || []} />
              <CardAtoInfoLabor laborData={laborData || []} />
              <CardAtoInfoEquipments equipmentData={equipmentData || []} />
              <CardAtoInfoActivities activity={activity || []} />
              <CardAtoInfoCompanyObservation
                responsibleCompanyObservation={
                  responsibleCompanyObservation || []
                }
              />
              <CardAtoInfoFiscalization
                inspectionObservations={inspectionObservations || []}
              />
              <CardAtoInfoWorkSafetyObs
                workSafetyObservations={workSafetyObservations || []}
              />
              <CardAtoInfoPhotos recordImages={recordImages || []} />
              <CardAtoInfoVideos recordVideos={recordVideos || []} />
            </>
          }
          signaturesContent={
            <SignatureContainer title={t('signatureOfTheResponsible')}>
              <AtoSignatureCard
                situation={rdoFormsData?.findDataByRecord.situation}
                recordSignatures={
                  rdoFormsData?.findDataByRecord.recordSignatures
                }
                approver={rdoFormsData?.findDataByRecord.userTypes.find(
                  (user) => user.userType === 'ClientApprover'
                )}
                elaborator={rdoFormsData?.findDataByRecord.userTypes.find(
                  (user) => user.userType === 'Elaborator'
                )}
                verify={rdoFormsData?.findDataByRecord.userTypes.find(
                  (user) => user.userType === 'Verifier'
                )}
              />
            </SignatureContainer>
          }
        />
      )}
    </>
  );
};
