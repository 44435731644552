import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  gql,
  useQuery
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createContext, useContext, useEffect, useState } from 'react';
import { BsExclamation } from 'react-icons/bs';
import { io } from 'socket.io-client';
import { NotificationListType, UserData } from '../@Types/types';
import { ProjectContext } from '../Context/ContextAPI';
import {
  ExclamationStyle,
  UserCardStyle,
  UserDiv
} from '../Styles/UserBadgeStyle';
import { useListUserNotificationLazy } from '../data/hooks/use-list-user-notification';
import { API_URL } from '../utils/const';
import NotificationCard from './Notifications/NotificationCard';
import NotificationModal from './Notifications/NotificationModal';
import UserCard from './UserCard';

export const NotificationContext = createContext({});

function UserBadge() {
  const [showUserCard, setShowUserCard] = useState<boolean>(false);
  const [showNotificationsCard, setShowNotificationsCard] =
    useState<boolean>(false);
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const [lastNotificationItemsList, setLastNotificationItemsList] = useState<
    NotificationListType[]
  >([]);
  const [allNotificationItemsList, setAllNotificationItemsList] = useState<
    NotificationListType[]
  >([]);
  const [hasNotification, setHasNotification] = useState<boolean | null>(null);
  const [notificationModal, setNotificationModal] = useState<boolean>(false);
  const [showModalSupport, setShowModalSupport] = useState<boolean>(false);
  const { listUserNotification } = useListUserNotificationLazy();

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_URL as string);

    const handleSocketOpen = () => {
      socket.on(userData.id, (data) => {
        if (data.length > 0) {
          setHasNotification(!hasNotification);
        }
      });
    };

    socket.on('connect', handleSocketOpen);

    return () => {
      socket.disconnect();
      socket.off('instrument-notification');
    };
  }, [hasNotification, userData.id]);

  const fetchLastNotificationItens = async () => {
    const httpLink = new HttpLink({
      uri: `${API_URL}/graphql`
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: userData.token
        }
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    });

    const response = await client.query({
      query: gql`
        query ListLatestUserNotification {
          listLatestUserNotification {
            latestNotifications {
              alertLevel
              installLocation
              instrumentNotificationId
              readingDate
              sentAt
              structureName
              unread
            }
            unreadCount
          }
        }
      `
    });
    if (response.data) {
      setLastNotificationItemsList(
        response.data.listLatestUserNotification.latestNotifications
      );
    }
  };

  const fetchAllNotificationItens = async () => {
    setAllNotificationItemsList([
      {
        alertLevel: 'Alerta',
        alertLevelValue: 978,
        id: '7ab01201-d643-4a93-9fb2-d30e513b257b',
        installLocation: 'Ombreira Direita',
        name: 'PZ-EMAIL-TEST-1',
        reading: 978,
        readingDate: '2024-02-03T00:05:55.041Z',
        sentAt: '2024-05-06T00:05:55.041Z',
        structureName: 'Structure 1 - Tellus 1',
        unread: true
      },
      {
        alertLevel: 'Alerta',
        alertLevelValue: 978,
        id: '7ab01201-d643-4a93-9fb2-d30e513b257b',
        installLocation: 'Ombreira Direita',
        name: 'PZ-EMAIL-TEST-1',
        reading: 978,
        readingDate: '2024-02-03T00:05:55.041Z',
        sentAt: '2024-04-06T00:05:55.041Z',
        structureName: 'Structure 1 - Tellus 1',
        unread: true
      },
      {
        alertLevel: 'Alerta',
        alertLevelValue: 978,
        id: '7ab01201-d643-4a93-9fb2-d30e513b257b',
        installLocation: 'Ombreira Direita',
        name: 'PZ-EMAIL-TEST-1',
        reading: 978,
        readingDate: '2024-02-03T00:05:55.041Z',
        sentAt: '2024-03-06T00:05:55.041Z',
        structureName: 'Structure 1 - Tellus 1',
        unread: true
      },
      {
        alertLevel: 'Alerta',
        alertLevelValue: 978,
        id: '7ab01201-d643-4a93-9fb2-d30e513b257b',
        installLocation: 'Ombreira Direita',
        name: 'PZ-EMAIL-TEST-1',
        reading: 978,
        readingDate: '2024-02-03T00:05:55.041Z',
        sentAt: '2024-02-06T00:05:55.041Z',
        structureName: 'Structure 1 - Tellus 1',
        unread: false
      },
      {
        alertLevel: 'Alerta',
        alertLevelValue: 978,
        id: '7ab01201-d643-4a93-9fb2-d30e513b257b',
        installLocation: 'Ombreira Direita',
        name: 'PZ-EMAIL-TEST-1',
        reading: 978,
        readingDate: '2024-02-03T00:05:55.041Z',
        sentAt: '2024-01-06T00:05:55.041Z',
        structureName: 'Structure 1 - Tellus 1',
        unread: false
      }
    ]);

    const response = await listUserNotification({
      variables: {
        filters: {
          //   StartDate: null,
          //   endDate: null,
          //   unread: null
        }
      }
    });
    // ToastifyLoading("Carregando Notificações...");
    if (response.data) {
      // ToastfyDimiss("toastLoading");
    }
  };

  const getFirstAndLastLetters = (str: string) => {
    if (!str.trim()) {
      return '';
    }

    const words = str.trim().split(' ');
    const firstLetter = words[0][0];
    const lastLetter = words[words.length - 1][0];
    if (words.length === 1) {
      return `${firstLetter}`.toUpperCase();
    }

    return `${firstLetter}${lastLetter}`.toUpperCase();
  };

  return (
    <NotificationContext.Provider
      value={{
        lastNotificationItemsList,
        allNotificationItemsList,
        setAllNotificationItemsList
      }}
    >
      <UserDiv
        onMouseEnter={() => setShowUserCard(true)}
        onMouseLeave={() => setShowUserCard(false)}
        style={showUserCard || showModalSupport? UserCardStyle : {}}
      >
        {showUserCard || showModalSupport ? (
          <UserCard
            setShowUserCard={setShowUserCard}
            fetchLastNotificationItens={fetchLastNotificationItens}
            setShowNotificationsCard={setShowNotificationsCard}
            hasNotification={hasNotification}
            setHasNotification={setHasNotification}
            getFirstAndLastLetters={getFirstAndLastLetters}
            showModalSupport={showModalSupport}
            setShowModalSupport={setShowModalSupport}
          />
        ) : (
          <>
            {getFirstAndLastLetters(userData.name)}
            {hasNotification !== null && (
              <ExclamationStyle style={{ top: 'unset', bottom: 0, right: -1 }}>
                <BsExclamation style={{ color: 'white' }} />
              </ExclamationStyle>
            )}
          </>
        )}
        {showNotificationsCard && (
          <NotificationCard
            setShowNotificationsCard={setShowNotificationsCard}
            setNotificationModal={setNotificationModal}
            fetchAllNotificationItens={fetchAllNotificationItens}
          />
        )}
      </UserDiv>
      {notificationModal && (
        <NotificationModal onClose={() => setNotificationModal(false)} />
      )}
    </NotificationContext.Provider>
  );
}

export default UserBadge;
