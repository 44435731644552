import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MssGenericDataInput } from '../../../../data/graphql/base-schema';
import { IdentifiesContainer, IdentifiesHeader } from './DamMassifStyles';
import { YesNoBodyTable } from './YesNoBodyTable';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';

export function PresenceOfTreesAnimalsInsects({
  setStepStates,
  stepStates,
  error,
  inspectionId
}: {
  setStepStates: Dispatch<SetStateAction<any>>;
  stepStates: MssGenericDataInput[];
  error: boolean;
  inspectionId: string;
}) {
  const { t }: any = useTranslation();

  const topics = [
    'Presença de árvores ou arbustos',
    'Vegetação de cor mais destacadas',
    'Formigueiros / Cupins / Roedores',
    'Animais (verificar pegadas / fezes)',
    'Presença de tocas ou buracos'
  ];

  return (
    <IdentifiesContainer>
      <IdentifiesHeader>
        <span>{t('PresenceOfTrees/Animals/Insects')}</span>
      </IdentifiesHeader>
      <TableComponentStyle>
        <thead>
          <tr>
            <th></th>
            <th>{t('Value')}</th>
            <th>{t('Observation')}</th>
            <th>{t('Image')}</th>
          </tr>
        </thead>
        <tbody>
          {topics.map((topic, index) => (
            <YesNoBodyTable
              setState={setStepStates}
              state={stepStates}
              topic={topic}
              key={index}
              error={error}
              inspectionId={inspectionId}
            />
          ))}
        </tbody>
      </TableComponentStyle>
    </IdentifiesContainer>
  );
}
