import { useMutation } from '@apollo/client';
import { ListPiezometersByStructureDocument } from '../graphql/query/generated/listPiezometersByStructure.query';
import { ListPluviometersByStructureDocument } from '../graphql/query/generated/listPluviometersByStructure.query';
import { ListSurfaceMarkersByStructureDocument } from '../graphql/query/generated/listSurfaceMarkersByStructure.query';
import { ListWaterLevelByStructureDocument } from '../graphql/query/generated/listWaterLevelByStructure.query';
import {
  DeleteInstrumentAlertLevelDocument,
  DeleteInstrumentAlertLevelMutation,
  DeleteInstrumentAlertLevelMutationVariables
} from '../graphql/generated/deleteInstrumentAlertLevel.mutation';

export function useDeleteInstrumenAlertLevel(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteInstrumentAlertLevel, { data, error, loading }] = useMutation<
    DeleteInstrumentAlertLevelMutation,
    DeleteInstrumentAlertLevelMutationVariables
  >(DeleteInstrumentAlertLevelDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [
      ListPiezometersByStructureDocument,
      ListPluviometersByStructureDocument,
      ListSurfaceMarkersByStructureDocument,
      ListWaterLevelByStructureDocument
    ],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteInstrumentAlertLevel,
    data,
    error,
    loading
  };
}
