import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const TableContent = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const DivContainerActions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const TableHeaderCellTh = styled.th`
  border-bottom: 1px solid ${Color.Brown3};
`;

export const DivContentFooter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  button {
    margin-top: 10px;
  }
`;

export const DivMessageNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
  color: ${Color.Gray1};
`;
