import styled from 'styled-components';
import { Color, FontSize } from '../../Styles/Styles';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

export const GeneralDataContainer = styled.div`
  flex: 1;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const ClientLogo = styled.div`
  width: 30%;
  height: 70%;
`;
export const LogoImage = styled.img.attrs(() => ({ draggable: 'false' }))`
  width: 100%;
  object-fit: contain;
  user-select: none;
`;

export const CompanyData = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${Color.White};
  padding: 25px;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;

export const GeneralStructureData = styled.div`
  flex: 3;
  background-color: ${Color.White};
  padding: 10px 10px 0px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DataArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: max-content;
  padding: 5px;
`;

export const CarrouselArea = styled.div`
  display: flex;
  align-items: center;
  width: 430px;
`;
export const Carrousel = styled.div`
  overflow-x: hidden;
  white-space: nowrap;
  width: 100%;
  margin: 0 auto;
`;

export const CarrouselButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${Color.Title2};
  cursor: pointer;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 7px;
  margin-left: 5px;
`;

export const GeotecnicalContainer = styled.div`
  max-width: 2050px;
  flex: 4;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
export const GeotecnicalHeader = styled.div`
  background-color: ${Color.White};
  max-height: 70px;
  flex: 0.8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-right: 10px;
`;

export const GeotecnicalData = styled.div`
  background-color: ${Color.White};
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const MapArea = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const GeotecnicalInfos = styled.div`
  background-color: ${Color.White};
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  /* grid-template-columns: repeat(2, 1fr); */
`;

// Rest of your JSX code

export const Title = styled.span`
  font-size: ${FontSize.XMedium};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Color.Text1};
`;

export const SubTitle = styled.span`
  font-size: ${FontSize.Xsmall};
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  color: ${Color.Title2};
`;

export const Text = styled.span`
  font-size: ${FontSize.Xsmall};
  color: ${Color.Text1};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
  position: relative;

  span {
    margin-right: 20px;
    padding: 2px;
  }
`;

export const LineDivision = styled.hr`
  width: 100%;
  height: 1px;
  border-width: 0;
  background-color: ${Color.Brown1};
`;
