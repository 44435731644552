import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

interface DatepickerInputProps {
  width?: string;
  error?: boolean;
  disabled?: boolean;
}

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ReactDatePickerContainer = styled.div<DatepickerInputProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    fill: ${(props) =>
      props.error ? Color.Red1 : props.disabled ? Color.Gray2 : Color.Brown1};
    padding: 0.3rem;
    left: 140px;
  }

  .react-datepicker__month {
    border: 1px solid ${Color.Brown3};
  }

  .custom-datepicker {
    background-color: transparent;
    border: none;
    color: ${Color.Text1};
    cursor: pointer;
    width: 120px;
  }

  .react-datepicker-time__input {
    background-color: ${Color.White};
    border: none;
    border-radius: 3px;
    & input {
      border: none;
    }
  }

  .react-datepicker-time__input:hover {
    background-color: ${Color.White};
  }

  .react-datepicker__input-container {
    & input {
      background-color: ${Color.White};
      border: none;
      width: ${(props) => props.width || '150px'};
      color: ${(props) => (props.error ? Color.Red1 : Color.Brown1)};
    }

    & input::placeholder {
      color: ${(props) =>
        props.error ? Color.Red1 : props.disabled ? Color.Gray2 : Color.Brown1};
    }
  }

  .react-datepicker__header {
    background-color: ${Color.Brown3};
    color: white;
    height: 80px;
    width: 300px;
  }

  .react-datepicker {
    border-color: ${Color.Brown2};
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 0px;
    transform: translate(58px, 0px);
    background-color: ${Color.Brown2};
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: ${Color.Brown2};
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: ${Color.Brown2};
  }

  .datapicker-separator {
    color: ${Color.Brown2};
    font-size: 20px;
    margin-left: 20px;
  }
`;

export const DivDatepickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ErrorStyled = styled.span`
  color: ${Color.Red1};
  font-size: ${FontSize.Xsmall};
  height: 15px;
  margin-bottom: 5px;
`;

export const DatepickerInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivInputDatepickerFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
