import React, { SetStateAction, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {
  CompanyDataProps,
  ModuleData,
  StructureSelectType,
  UserData
} from '../../@Types/types';
import HeaderMenu from '../../Components/HeaderMenu';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../Components/Toastify';
import { ProjectContext } from '../../Context/ContextAPI';
import ToastifyModel from '../../Models/ToastifyModel';
import { useStructureList } from '../../data/hooks/use-structure-list.query';
import StructureScreen from '../StructureScreen/StructureScreen';
import { Outlet, useParams } from 'react-router-dom';
import { DefaultLogo } from '../../Styles/Styles';
import LogoGS3 from '../../Images/logoGS3.svg';
import MainScreenTemplate from '../../Components/V2/Templates/MainScreenTemplate/MainScreenTemplate';
import { useModuleList } from '../../data/hooks/use-modules-by-structure.query';

function MainScreen() {
  document.title = 'GS3';
  const { t } = useTranslation();
  const { userData, setStructures, setCompanyData, setModulesByStructure } =
    useContext(ProjectContext) as {
      userData: UserData;
      setStructures: React.Dispatch<SetStateAction<StructureSelectType[]>>;
      setCompanyData: React.Dispatch<SetStateAction<CompanyDataProps>>;
      setModulesByStructure: React.Dispatch<SetStateAction<ModuleData[]>>;
    };

  const { data: structureData, loading, error } = useStructureList(userData.id);
  const { structureId } = useParams();

  useEffect(() => {
    if (error) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loading) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (structureData) {
      toastfyDimiss('toastLoading');
      setStructures(
        structureData.getUserStructuresList.structures.map(
          (structure, index) => ({
            id: structure.id,
            label: structure.name,
            value: structure.id
          })
        ) || []
      );
      setCompanyData({
        name: structureData.getUserStructuresList.name,
        id: structureData.getUserStructuresList.id,
        status: structureData.getUserStructuresList.status,
        cnpj: structureData.getUserStructuresList.cnpj,
        address: {
          city: structureData.getUserStructuresList.address.city,
          addressLine: structureData.getUserStructuresList.address.addressLine,
          country: structureData.getUserStructuresList.address.country,
          number: structureData.getUserStructuresList.address.number,
          state: structureData.getUserStructuresList.address.state,
          zipCode: structureData.getUserStructuresList.address.zipCode
        }
      });
    }
  }, [structureData]);

  const structureInfo = {
    structureId: structureId || ''
  };

  const {
    data: moduleList,
    loading: loadingModule,
    error: errorModule
  } = useModuleList(userData.id, structureInfo);

  useEffect(() => {
    if (errorModule) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loadingModule) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (moduleList) {
      toastfyDimiss('toastLoading');
      setModulesByStructure(
        moduleList.getModuleByStructure.map((module) => {
          return {
            moduleName: module.name,
            moduleId: module.id,
            role: module.role || ''
          };
        })
      );
    } else {
      setModulesByStructure([
        {
          moduleName: '',
          moduleId: '',
          role: ''
        }
      ]);
      toastfyDimiss('toastLoading');
    }
  }, [moduleList, structureId]);

  // TODO: Implementar skeleton loading

  return (
    <>
      {!loading && !loadingModule && (
        <MainScreenTemplate
          header={<HeaderMenu />}
          sidebar={<StructureScreen />}
          content={structureId ? <Outlet /> : <DefaultLogo src={LogoGS3} />}
        />
      )}
    </>
  );
}

export default MainScreen;
