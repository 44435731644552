import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  DeleteActionPlanCommentDocument,
  DeleteActionPlanCommentMutation,
  DeleteActionPlanCommentMutationVariables
} from '../../../../data/graphql/generated/deleteActionPlanComment.mutation';
import {
  SaveActionPlanCommentDocument,
  SaveActionPlanCommentMutation,
  SaveActionPlanCommentMutationVariables
} from '../../../../data/graphql/generated/saveActionPlanComment.mutation';
import {
  FindActionPlanGeneratedDocument,
  FindActionPlanGeneratedQuery
} from '../../../../data/services/ActionPlanGeneratedService';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import {
  CommentsDeleteData,
  ModalCommentsProps
} from '../../Pages/ActionPlanGeneratedPage/ActionPlanGeneratedPage.interface';
import Comment from './../../Organisms/Comment/Comment';
import { ActionPlanCommentsProps } from './ActionPlanComments.interface';
import {
  DivContainerAllComment,
  DivContainerComment,
  DivContainerSeeComments
} from './ActionPlanComments.styles';

const ActionPlanComments = ({
  disabled,
  actionPlanGeneratedData
}: ActionPlanCommentsProps) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [modalConfirm, setModalConfirm] = useState<ModalCommentsProps>({
    isOpen: false,
    commentData: {
      id: '',
      comment: '',
      userName: ''
    }
  });
  const { actionPlanId } = useParams();

  const commentSchema = z.object({
    comment: z
      .string()
      .refine((val) => val.length > 0, { message: t('fieldIsRequired') })
  });

  type commentSchemaType = z.infer<typeof commentSchema>;

  const {
    register: registerComment,
    handleSubmit: handleSubmitComment,
    formState: { errors: errorsComment }
  } = useForm<commentSchemaType>({
    resolver: zodResolver(commentSchema),
    values: {
      comment: ''
    }
  });

  const [saveActionPlanComment] = useMutation<
    SaveActionPlanCommentMutation,
    SaveActionPlanCommentMutationVariables
  >(SaveActionPlanCommentDocument);

  const [deleteActionPlanComment] = useMutation<
    DeleteActionPlanCommentMutation,
    DeleteActionPlanCommentMutationVariables
  >(DeleteActionPlanCommentDocument);

  const handleCreateComment = (commentData: commentSchemaType) => {
    saveActionPlanComment({
      variables: {
        actionPlanId: actionPlanId ?? '',
        comment: commentData.comment
      },
      onCompleted: () => {
        toastfySuccess(t('registeredSuccessfully'));
        setComment('');
      },
      onError: ({ graphQLErrors }) => {
        const errorMessege = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessege);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindActionPlanGeneratedDocument,
          variables: {
            actionPlanId: actionPlanId ?? ''
          }
        }) as FindActionPlanGeneratedQuery | undefined;

        const updatedData = {
          ...existingData,
          findActionPlanData: {
            ...existingData?.findActionPlanData,
            comments: [
              ...(existingData?.findActionPlanData.comments ?? []),
              {
                id: data.saveActionPlanComment.id,
                comment: data.saveActionPlanComment.comment,
                __typename: 'SavedActionPlanCommentType'
              }
            ]
          }
        };

        cache.writeQuery({
          query: FindActionPlanGeneratedDocument,
          variables: {
            actionPlanId: actionPlanId ?? ''
          },
          data: updatedData
        });
      }
    });
  };

  const handleDeleteComment = (commentData: CommentsDeleteData) => {
    deleteActionPlanComment({
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
        setModalConfirm({
          isOpen: false,
          commentData: {
            id: '',
            comment: '',
            userName: ''
          }
        });
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessage);
      },
      variables: {
        commentId: commentData.id
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindActionPlanGeneratedDocument,
          variables: {
            actionPlanId: actionPlanId ?? ''
          }
        }) as FindActionPlanGeneratedQuery | undefined;

        const updatedData = {
          ...existingData,
          findActionPlanData: {
            ...existingData?.findActionPlanData,
            comments: existingData?.findActionPlanData.comments?.filter(
              (comment) => comment.id !== commentData.id
            )
          }
        };
        cache.writeQuery({
          query: FindActionPlanGeneratedDocument,
          variables: {
            actionPlanId: actionPlanId ?? ''
          },
          data: updatedData
        });
      }
    });
  };

  const isWithinLastTenMinutes = (createdAt: any) => {
    const diffMilliseconds =
      new Date().getTime() - new Date(createdAt).getTime();
    const diffMinutes = diffMilliseconds / (1000 * 60);
    return diffMinutes < 10;
  };

  const getCommentActions = (
    comment: any,
    isLastComment: boolean,
    isMoreThanTenMinutes: boolean
  ) => {
    if (isLastComment && isMoreThanTenMinutes) {
      return [
        {
          icon: <MdDelete />,
          name: 'Delete',
          onClick: () =>
            setModalConfirm({
              isOpen: true,
              commentData: {
                id: comment.id,
                comment: comment.comment,
                userName: comment.userName
              }
            }),
          canShow: 'delete'
        }
      ];
    }

    return undefined;
  };

  return (
    <>
      <DivContainerAllComment>
        <DivContainerComment>
          <InputTextarea
            name={'comment'}
            label={t('comment')}
            width="100%"
            errorMessage={errorsComment.comment?.message}
            register={registerComment}
            error={!!errorsComment.comment}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            //text area não tem a propriedade disabled
            //disabled={disabled}
          />
          <Button
            variant={'primary'}
            text="Send"
            size="small"
            onClick={handleSubmitComment(handleCreateComment)}
            disabled={disabled}
          />
        </DivContainerComment>
        <DivContainerSeeComments>
          {actionPlanGeneratedData?.findActionPlanData.comments
            .slice()
            .reverse()
            .map((comment, index) => {
              const isLastComment = index === 0;
              const isMoreThanTenMinutes = isWithinLastTenMinutes(
                comment.createdAt
              );
              const actions = getCommentActions(
                comment,
                isLastComment,
                isMoreThanTenMinutes
              );

              return (
                <Comment
                  key={comment.id}
                  acronym={comment.userName.slice(0, 2)}
                  date={
                    new Date(comment.createdAt).toLocaleDateString('pt-BR') ||
                    ''
                  }
                  user={comment.userName}
                  comment={comment.comment}
                  actions={actions}
                />
              );
            })}
        </DivContainerSeeComments>
      </DivContainerAllComment>
      {modalConfirm.isOpen && (
        <ModalConfirm
          title="Delete"
          text={t('AreYouSureDesiredToDeleteThisComment')}
          onClose={() =>
            setModalConfirm({
              isOpen: false,
              commentData: {
                id: '',
                comment: '',
                userName: ''
              }
            })
          }
          onConfirm={() => handleDeleteComment(modalConfirm.commentData)}
        />
      )}
    </>
  );
};

export default ActionPlanComments;
