import React from 'react';
import { useTranslation } from 'react-i18next';
import { ViewInstrumentsButtonInterface } from '../../../@Types/Instruments/instruments';
import ViewInstrument from '../../../Controllers/Instruments/ViewInstruments';
import { MonitoringScreenButtonsStyled } from '../ButtonsStyle';

export const ViewInstrumentsButton = ({
  showModal,
  setShowModal
}: ViewInstrumentsButtonInterface) => {
  const { t } = useTranslation();

  return (
    <MonitoringScreenButtonsStyled
      onClick={() => ViewInstrument.ShowModal({ showModal, setShowModal })}
    >
      {t('Instrumentation')}
    </MonitoringScreenButtonsStyled>
  );
};
