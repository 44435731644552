import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { UserData } from '../../../../@Types/types';
import { ProjectContext } from '../../../../Context/ContextAPI';
import {
  RecordReprovalFlowDocument,
  RecordReprovalFlowMutation,
  RecordReprovalFlowMutationVariables
} from '../../../../data/graphql/generated/recordReprovalFlow';
import {
  SignAtoRecordDocument,
  SignAtoRecordMutation,
  SignAtoRecordMutationVariables
} from '../../../../data/graphql/generated/signAtoRecord';
import { FindDataByRecordDocument } from '../../../../data/graphql/query/generated/findDataByRecord';
import { useRecordApprovalFlow } from '../../../../data/services/ATOService';
import ToastifyModel from '../../../../Models/ToastifyModel';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import SignatureAto from '../SignatureAto/SignatureAto';
import {
  DivButtonComments,
  DivButtons,
  DivContainer,
  DivFileInputs,
  DivImageFiles,
  DivMarginTop
} from './AtoSignatureCard.styles';
import { AtoSignatureCardProps } from './AtoSignatureCardProps.interface';

export const AtoSignatureCard = ({
  situation,
  recordSignatures,
  elaborator,
  verify,
  approver,
  isEdit = true
}: AtoSignatureCardProps) => {
  const { recordId } = useParams();
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const { t } = useTranslation();
  const elaboratorSignature = recordSignatures?.find(
    (recordSignature) => recordSignature.userType === 'Elaborator'
  );
  const verifySignature = recordSignatures?.find(
    (recordSignature) => recordSignature.userType === 'Verifier'
  );

  const approverSignature = recordSignatures?.find(
    (recordSignature) => recordSignature.userType === 'ClientApprover'
  );

  const addAtoCommentSchema = z.object({
    commentary: z.string().min(1, { message: t('fieldIsRequired') })
  });

  type AddAtoCommentSchemaType = z.infer<typeof addAtoCommentSchema>;

  const [signAtoRecord] = useMutation<
    SignAtoRecordMutation,
    SignAtoRecordMutationVariables
  >(SignAtoRecordDocument);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<AddAtoCommentSchemaType>({
    resolver: zodResolver(addAtoCommentSchema)
  });

  const { recordApprovalFlow } = useRecordApprovalFlow();
  const [recordReprovalFlow] = useMutation<
    RecordReprovalFlowMutation,
    RecordReprovalFlowMutationVariables
  >(RecordReprovalFlowDocument);

  const showInputElaborator =
    userData.id === elaborator?.id &&
    (situation === 'Progress' ||
      situation === 'Reproved' ||
      situation === 'Late');
  const showInputVerifier =
    userData.id === verify?.id && situation === 'Review';
  const showInputApprover =
    userData.id === approver?.id && situation === 'Approved';

  const handleSubmitSignatures = async () => {
    signAtoRecord({
      variables: {
        recordId: recordId!
      },
      onCompleted: () => {
        toastfySuccess(t('registeredSuccessfully'));
      },
      onError: () => {
        toastfyError(t(ToastifyModel().toastifyMessage.error));
      },
      refetchQueries: [FindDataByRecordDocument]
    });
  };

  const handleReproval = (data: AddAtoCommentSchemaType) => {
    recordReprovalFlow({
      variables: {
        recordId: recordId!,
        commentary: data.commentary
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessage);
      }
    });
  };

  const handleApprove = () => {
    recordApprovalFlow({
      variables: {
        recordId: recordId!
      },
      onCompleted: () => {
        toastfySuccess(t('registeredSuccessfully'));
      },
      onError: () => {
        toastfyError(t(ToastifyModel().toastifyMessage.error));
      }
    });
  };

  return (
    <DivButtonComments>
      <DivContainer>
        <DivFileInputs>
          <DivImageFiles>
            {
              <>
                <SignatureAto signUrl={elaboratorSignature?.signature} />
                {isEdit &&
                  !elaboratorSignature?.signature &&
                  showInputElaborator && (
                    <Button
                      text={t('Sign')}
                      variant={'primary'}
                      size="small"
                      onClick={handleSubmitSignatures}
                    />
                  )}
              </>
            }
          </DivImageFiles>
          <DivImageFiles>
            {
              <>
                <SignatureAto signUrl={verifySignature?.signature} />
                {isEdit && !verifySignature?.signature && showInputVerifier && (
                  <Button
                    text={t('Sign')}
                    variant={'primary'}
                    size="small"
                    onClick={handleSubmitSignatures}
                  />
                )}
              </>
            }
          </DivImageFiles>
          <DivImageFiles>
            {
              <>
                <SignatureAto signUrl={approverSignature?.signature} />
                {isEdit &&
                  !approverSignature?.signature &&
                  showInputApprover && (
                    <Button
                      text={t('Sign')}
                      variant={'primary'}
                      size="small"
                      onClick={handleSubmitSignatures}
                    />
                  )}
              </>
            }
          </DivImageFiles>
        </DivFileInputs>
      </DivContainer>
      <DivMarginTop>
        {isEdit && (showInputVerifier || showInputApprover) && (
          <div>
            <InputTextarea
              errorMessage={errors.commentary?.message}
              error={!!errors.commentary}
              height="100px"
              label={t('comment')}
              name="commentary"
              register={register}
              width="500px"
            />
            <DivButtons>
              <Button
                text={t('Approve')}
                variant={'secondary'}
                size="small"
                onClick={handleApprove}
              />
              <Button
                text={t('Reprove')}
                variant={'secondary'}
                size="small"
                onClick={handleSubmit(handleReproval)}
              />
            </DivButtons>
          </div>
        )}
        {isEdit &&
          elaborator &&
          userData.id === elaborator?.id &&
          situation === 'Progress' && (
            <Button
              text={t('RequestApproval')}
              variant={'secondary'}
              size="small"
              onClick={handleApprove}
            />
          )}
      </DivMarginTop>
    </DivButtonComments>
  );
};
