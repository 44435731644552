import React, { Dispatch, useContext, useRef, useState } from 'react';
import { BsExclamation } from 'react-icons/bs';
import { IoNotifications } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../@Types/types';
import {
  ExclamationStyle,
  Exit,
  UserCardDivStyle,
  UserDivStyle,
  UserName,
  UserNotificationStyle,
  LanguageSelect,
  DivHeader,
  DivSignatureAndSupport
} from '../Styles/UserBadgeStyle';
import ChangeLocale from './ChangeLocale';
import { useTranslation } from 'react-i18next';
import Text from './V2/Atoms/Text/Text';
import { API_URL } from '../utils/const';
import { ProjectContext } from '../Context/ContextAPI';
import { toastfyError, toastfySuccess } from './Toastify';
import ViewModal from './V2/Molecules/ViewModal/ViewModal';
import SupportForm from './V2/Organisms/SupportForm/SupportForm';

interface UserCardProps {
  setShowUserCard: Dispatch<boolean>;
  fetchLastNotificationItens: () => void;
  setShowNotificationsCard: Dispatch<boolean>;
  hasNotification: boolean | null;
  setHasNotification: Dispatch<boolean | null>;
  getFirstAndLastLetters: (name: string) => string;
  showModalSupport: boolean;
  setShowModalSupport: Dispatch<boolean>;
}

export default function UserCard({
  setShowUserCard,
  fetchLastNotificationItens,
  setShowNotificationsCard,
  hasNotification,
  setHasNotification,
  getFirstAndLastLetters,
  showModalSupport,
  setShowModalSupport
}: UserCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const refUploadSignature = useRef<HTMLInputElement>(null);
  const { userData } = useContext(ProjectContext) as { userData: UserData };

  const logout = () => {
    navigate('/login');
  };

  const handleClickUploadSignature = () => {
    const input = refUploadSignature.current;
    if (input) {
      input.click();
    }
  };

  const fetchUploadSignature = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('signature', file);
      const response = await fetch(
        `${API_URL}/user/${userData.id}/signature/upload`,
        {
          method: 'POST',
          body: formData,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: userData.token
          }
        }
      );
      if (response.ok) {
        toastfySuccess(t('registeredSuccessfully'));
      }

      if (!response.ok) {
        toastfyError(t('errorRegistering'));
      }
    }
  };

  return (
    <UserCardDivStyle>
      <DivHeader>
        <UserDivStyle>{getFirstAndLastLetters(userData.name)}</UserDivStyle>
        <UserName>{userData.name}</UserName>
        <IoNotifications
          style={UserNotificationStyle}
          onClick={() => {
            setShowNotificationsCard(true);
            fetchLastNotificationItens();
            setHasNotification(null);
          }}
        />
      </DivHeader>
      <DivSignatureAndSupport>
        <Text
          type="span"
          color="brown"
          onClick={handleClickUploadSignature}
          style={{
            cursor: 'pointer'
          }}
        >
          {t('UploadSignature')}
        </Text>
        <input
          type="file"
          accept="image/*"
          name="signature"
          style={{
            display: 'none'
          }}
          ref={refUploadSignature}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            fetchUploadSignature(event);
          }}
        />
        <Text
          type="span"
          color="brown"
          onClick={() => setShowModalSupport(true)}
          style={{
            cursor: 'pointer'
          }}
        >
          {t('Support')}
        </Text>
      </DivSignatureAndSupport>
      <LanguageSelect>
        <ChangeLocale
          hasMargin={false}
          setShowCard={() => setShowUserCard(false)}
        />
      </LanguageSelect>
      <Exit onClick={() => logout()}>{t('Logout')}</Exit>
      {hasNotification !== null && (
        <ExclamationStyle>
          <BsExclamation style={{ color: 'white' }} />
        </ExclamationStyle>
      )}
      {showModalSupport && (
        <ViewModal
          component={<SupportForm setShowModalSupport={setShowModalSupport} />}
          title={t('ContactSupport')}
          onClose={() => {
            setShowModalSupport(false);
            setShowUserCard(false);
          }}
          width={40}
          height={85}
        />
      )}
    </UserCardDivStyle>
  );
}
