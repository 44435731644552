import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  SaveActionPlanTeamMembersDocument,
  SaveActionPlanTeamMembersMutation,
  SaveActionPlanTeamMembersMutationVariables
} from '../../../../data/graphql/generated/saveActionPlanTeamMembers.mutation';
import {
  FindActionPlanMembersDocument,
  FindActionPlanMembersQuery,
  FindActionPlanMembersQueryVariables
} from '../../../../data/graphql/query/generated/findActionPlanMembers.query';
import {
  FindActionPlanGeneratedDocument,
  FindActionPlanGeneratedQuery
} from '../../../../data/services/ActionPlanGeneratedService';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import { DivContainerButtons } from '../../Pages/ActionPlanTeamPage/ActionPlanTeamPage.styles';
import { OptionItem } from '../CreateActionPlan/CreateActionPlan.interface';
import { ActionPlanSaveMemberProps } from './ActionPlanSaveMember.interface';

const ActionPlanSaveMember = ({
  setSaveModalIsOpen
}: ActionPlanSaveMemberProps) => {
  const { t: translate } = useTranslation();
  const { actionPlanId } = useParams();

  const [saveActionPlanTeamMembers] = useMutation<
    SaveActionPlanTeamMembersMutation,
    SaveActionPlanTeamMembersMutationVariables
  >(SaveActionPlanTeamMembersDocument);

  const { data: dataMembers, loading: loadingMembers } = useQuery<
    FindActionPlanMembersQuery,
    FindActionPlanMembersQueryVariables
  >(FindActionPlanMembersDocument, {
    variables: {
      actionPlanId: actionPlanId || ''
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const actionPlanTeamSchema = z.object({
    member: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fieldIsRequired')
      })
  });

  type actionPlanTeamSchemaType = z.infer<typeof actionPlanTeamSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<actionPlanTeamSchemaType>({
    resolver: zodResolver(actionPlanTeamSchema)
  });

  const nameOptions: OptionItem[] =
    dataMembers?.findActionPlanMembers.map((member) => ({
      label: member.name,
      value: member.id
    })) ?? [];

  const handleSaveMember = (data: actionPlanTeamSchemaType) => {
    saveActionPlanTeamMembers({
      variables: {
        actionPlanId: actionPlanId ?? '',
        usersId: [data.member?.value]
      },
      onCompleted: () => {
        toastfySuccess(
          `${translate('member')} ${translate('registeredSuccessfully')}`
        );
        setSaveModalIsOpen(false);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
        setSaveModalIsOpen(false);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindActionPlanGeneratedDocument,
          variables: {
            actionPlanId: actionPlanId ?? ''
          }
        }) as FindActionPlanGeneratedQuery | undefined;

        const updatedData = {
          ...existingData,
          findActionPlanData: {
            ...existingData?.findActionPlanData,
            team: data?.saveActionPlanTeamMembers
          }
        };
        cache.writeQuery({
          query: FindActionPlanGeneratedDocument,
          variables: {
            actionPlanId: actionPlanId ?? ''
          },
          data: updatedData
        });
      }
    });
  };

  return loadingMembers ? (
    <ViewModal
      title={`${translate('Add')} ${translate('team')}`}
      onClose={() => {}}
      width={30}
      height="auto"
      component={
        <div>
          <Skeleton height={35} width={400} />
          <DivContainerButtons>
            <Skeleton height={40} width={100} />
            <Skeleton height={40} width={100} />
          </DivContainerButtons>
        </div>
      }
    />
  ) : (
    <ViewModal
      title={`${translate('Add')} ${translate('team')}`}
      onClose={() => setSaveModalIsOpen(false)}
      width={30}
      height="auto"
      component={
        <div>
          <InputSelectSearch
            errorMessage={errors.member?.message}
            label={translate('member')}
            name="member"
            options={nameOptions}
            width="300px"
            error={!!errors.member}
            control={control}
          />
          <DivContainerButtons>
            <Button
              onClick={() => setSaveModalIsOpen(false)}
              text="Cancel"
              variant="secondary"
              size="small"
            />
            <Button
              onClick={handleSubmit(handleSaveMember)}
              text="Confirm"
              variant="primary"
              size="small"
            />
          </DivContainerButtons>
        </div>
      }
    />
  );
};

export default ActionPlanSaveMember;
