import { useMutation } from '@apollo/client';
import {
  EditPiezometerControlCoteMutation,
  EditPiezometerControlCoteMutationVariables,
  EditPiezometerControlCoteDocument
} from '../../graphql/generated/editPiezometerControlCote.mutation';
import { ListPiezometersByStructureDocument } from '../../graphql/query/generated/listPiezometersByStructure.query';

export function useEditPiezometerControlCote(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editPiezometerControlCote, { data, error, loading }] = useMutation<
    EditPiezometerControlCoteMutation,
    EditPiezometerControlCoteMutationVariables
  >(EditPiezometerControlCoteDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListPiezometersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editPiezometerControlCote,
    data,
    error,
    loading
  };
}
