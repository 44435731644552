import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

interface TableThProps {
  orderBy?: boolean;
}

interface TableTdProps {
  clickRow?: boolean;
}

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
  overflow: hidden;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TableStyle = styled.table`
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const TableHead = styled.thead`
  background-color: ${Color.White};
  color: ${Color.Brown1};
  font-weight: bold;
  width: 100%;
`;

export const TableBody = styled.tbody`
  width: 100%;
  background-color: ${Color.White};
  color: ${Color.Text1};
  max-height: 500px;
  overflow-y: scroll;
`;

export const TableTd = styled.td<TableTdProps>`
  padding: 10px;
  text-align: center;
  border-top: 1px solid ${Color.Brown3};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  height: 30px;
  cursor: ${(props) => (props.clickRow ? 'pointer' : 'default')};

  &.action {
    width: 20%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;
  }
`;

export const TableTh = styled.th<TableThProps>`
  background-color: ${Color.White};
  position: sticky;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  user-select: none;
  cursor: ${(props) => (props.orderBy ? 'pointer' : 'default')};
`;

export const DivTh = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const TableTr = styled.tr`
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const PopupFilterContainer = styled.div`
  position: absolute;
  background-color: ${Color.White};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border: 1px solid #ddd;
  z-index: 1000;
`;
