import React, { useContext } from 'react';
import Select, { components } from 'react-select';
import { ProjectContext } from '../Context/ContextAPI';
import {
  StyleLanguageSelect,
  UserBadgeLanguageSelect
} from '../Styles/LanguageSelect';

type LanguageOptions = {
  id: number;
  label: string;
  value: number;
  language: string;
  flag: string;
};

interface Props {
  hasMargin: boolean;
  setShowCard?: any;
}

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <img
        src={props.data.flag}
        alt={props.data.label}
        style={{ width: '20px', marginRight: '10px' }}
      />
      {props.data.label}
    </components.Option>
  );
};

const CustomSingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <img
        src={props.data.flag}
        alt={props.data.label}
        style={{ width: '20px', marginRight: '10px' }}
      />
      {props.data.label}
    </components.SingleValue>
  );
};

function ChangeLocale(props: Props) {
  const { hasMargin, setShowCard } = props;
  const { selectedLanguage, handleLanguage } = useContext(ProjectContext) as {
    selectedLanguage: LanguageOptions;
    handleLanguage: (e: LanguageOptions) => void;
  };
  const { languageOptions } = useContext(ProjectContext) as {
    languageOptions: LanguageOptions[];
  };

  return (
    <>
      <Select
        isSearchable={false}
        hideSelectedOptions
        defaultValue={selectedLanguage}
        options={languageOptions}
        styles={hasMargin ? StyleLanguageSelect : UserBadgeLanguageSelect}
        onChange={(e: any) => {
          handleLanguage(e);
          setShowCard(false);
        }}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
      />
    </>
  );
}

export default ChangeLocale;
