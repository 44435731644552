import React from 'react';
import {
  Container,
  Content,
  DivContent,
  Header,
  Sidebar
} from './MainScreenTemplateStyled';

function MainScreenTemplate({
  header,
  sidebar,
  content
}: {
  header: React.ReactNode;
  sidebar: React.ReactNode;
  content: React.ReactNode;
}) {
  return (
    <Container>
      <Header>{header}</Header>
      <DivContent>
        <Sidebar>{sidebar}</Sidebar>
        <Content>{content}</Content>
      </DivContent>
    </Container>
  );
}

export default MainScreenTemplate;
