import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListPiezometersByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListPiezometersByStructureQuery = { __typename?: 'Query', listPiezometersByStructure: Array<{ __typename?: 'ListPiezometerInaByStructure', activationDate?: Date | null, coordinateE: number, coordinateN: number, deactivationDate?: Date | null, id: string, installLocation?: string | null, installationDate?: Date | null, isUTM: boolean, name: string, operationalStatus: Types.InstrumentStatusEnum, readingType: string, status: boolean, alertLevels?: Array<{ __typename?: 'ListPiezometerInaAlertLevelsType', coteValue: number, drained?: string | null, id: string, name: string, undrained?: string | null }> | null, controlCotes?: Array<{ __typename?: 'ListControlCotesType', bottomCote: number, id: string, referenceDate: Date, topCote: number }> | null, type: { __typename?: 'BasicInstrumentTypeType', id: string, instrumentType: string } }> };


export const ListPiezometersByStructureDocument = gql`
    query ListPiezometersByStructure($structureInfo: StructureInfoInput!) {
  listPiezometersByStructure(structureInfo: $structureInfo) {
    activationDate
    alertLevels {
      coteValue
      drained
      id
      name
      undrained
    }
    controlCotes {
      bottomCote
      id
      referenceDate
      topCote
    }
    coordinateE
    coordinateN
    deactivationDate
    id
    installLocation
    installationDate
    isUTM
    name
    operationalStatus
    readingType
    status
    type {
      id
      instrumentType
    }
  }
}
    `;
export type ListPiezometersByStructureQueryResult = Apollo.QueryResult<ListPiezometersByStructureQuery, ListPiezometersByStructureQueryVariables>;