import React, { useContext, useState } from 'react';
import {
  ActionsRow,
  CellWithTooltip,
  TableComponentStyle,
  TableDivStyle
} from './styles';
import { t } from 'i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { ProjectContext } from '../../../Context/ContextAPI';
import { ModuleData } from '../../../@Types/types';
import { FaCircleInfo } from 'react-icons/fa6';
import ModalConfirm from '../../V2/Molecules/ModalConfirm/ModalConfirm';
import { useDeleteInstrumen } from '../../../data/hooks/use-delete-instrument';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import { useParams } from 'react-router-dom';

interface TableInstrumentComponentProps {
  dados: any;
  setEdit: React.Dispatch<React.SetStateAction<{ status: boolean; data: any }>>;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

const locationTranslations: Record<string, string> = {
  Foundation: 'Fundação',
  Massive: 'Maciço',
  Shoulder: 'Ombreira',
  DrainingMat: 'Tapete Drenante',
  VerticalFilter: 'Filtro Vertical',
  Other: 'Outro'
};

const operationalStatusTranslations: Record<string, string> = {
  Active: 'Ativo',
  Inactive: 'Inativo'
};

export const TableInstrumentComponent = ({
  dados,
  setEdit,
  setShowEditModal,
  setData
}: TableInstrumentComponentProps) => {
  const { structureId } = useParams();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [idDelete, setIdDelete] = useState<string>('');
  const { deleteInstruments } = useDeleteInstrumen();

  const colunasOrdenadas = [
    'name',
    'operationalStatus',
    'coordinateE',
    'coordinateN',
    'installationDate',
    'activationDate',
    'installLocation',
    'readingType',
    'deactivationDate',
    'actions'
  ];

  const handleDeleteInstrument = async (instrumentId: string) => {
    const response = await deleteInstruments({
      variables: {
        structureId: structureId!,
        instrumentId: instrumentId
      }
    });
    toastifyLoading('Deletando Instrumento...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      toastfySuccess('Instrumento Deletada Com Sucesso');
      toastfyDimiss('toastfySuccess');
      setData(dados.filter((item: any) => item.id !== instrumentId));
      setIdDelete('');
    } else if (response.errors) {
      toastfyDimiss('toastLoading');
      toastfyError(
        response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
      );
      setIdDelete('');
    }
  };

  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('Monitoring');

  return (
    <TableDivStyle>
      <TableComponentStyle>
        <thead>
          <tr>
            {colunasOrdenadas.map((coluna) => (
              <th key={coluna}>{t(coluna)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dados.map((row: any, index: number) => (
            <tr
              key={index}
              onClick={() =>
                setSelectedRow(index === selectedRow ? null : index)
              }
            >
              {colunasOrdenadas.map((key) => (
                <CellWithTooltip key={key}>
                  {key === 'actions' ? (
                    <ActionsRow>
                      {userRoleInModule === 'viewer' ? (
                        <FaCircleInfo
                          size={18}
                          onClick={() => {
                            setEdit({ status: true, data: row });
                            setShowEditModal(true);
                          }}
                        />
                      ) : (
                        <>
                          <BiSolidPencil
                            onClick={() => {
                              setEdit({ status: true, data: row });
                              setShowEditModal(true);
                            }}
                          />
                          <MdDelete
                            onClick={() => {
                              setIdDelete(row.id);
                            }}
                          />
                        </>
                      )}
                    </ActionsRow>
                  ) : key === 'activationDate' ||
                    key === 'installationDate' ||
                    key === 'deactivationDate' ? (
                    row['operationalStatus'] === 'Active' &&
                    key === 'deactivationDate' ? (
                      t('Instrumento Ativo')
                    ) : row[key] ? (
                      new Date(row[key]).toLocaleDateString()
                    ) : (
                      t('NoDataAvailable')
                    )
                  ) : key === 'operationalStatus' ? (
                    operationalStatusTranslations[row[key]] ||
                    t('NoDataAvailable')
                  ) : key === 'installLocation' ? (
                    locationTranslations[row[key]] || t('NoDataAvailable')
                  ) : (
                    row[key] || t('NoDataAvailable')
                  )}
                </CellWithTooltip>
              ))}
            </tr>
          ))}
        </tbody>
      </TableComponentStyle>
      {idDelete && (
        <ModalConfirm
          title={t('Deletar Instrumento')}
          text={t('ConfirmDelete')}
          subtitle={t('ActionCannotBeUndone')}
          onClose={() => setIdDelete('')}
          onConfirm={() => {
            handleDeleteInstrument(idDelete);
          }}
        />
      )}
    </TableDivStyle>
  );
};
