import { useState } from 'react';
import { HolderButtonsMonitoringScreen } from '../../Components/Buttons/ButtonsStyle';
import { GenerateGraphButton } from '../../Components/Buttons/Instruments/GenerateGraphButton';
import { ReadingsButton } from '../../Components/Buttons/Instruments/ReadingsButton';
import { ViewInstrumentsButton } from '../../Components/Buttons/Instruments/ViewInstrumentsButton';
import { InstrumentGraph } from '../../Components/Graphs/Monitoring/Instrument/InstrumentGraph';
import { ReadingsComponent } from '../../Components/Instruments/Readings/ReadingsComponent';
import ViewInstrumentsPage from '../../Components/Instruments/ViewInstruments/ViewInstrumentsPage';
import { MonitoringContainer } from './MonitoringScreenStyle';
import MapGoogleDraw from '../../Components/Map/MapGoogleDraw';

export const MonitoringScreen = () => {
  const [showViewInstrumentsModal, setShowViewInstrumentsModal] =
    useState<boolean>(false);
  const [showMonitoringGraphModal, setShowMonitoringGraphModal] =
    useState<boolean>(false);
  const [showReadingsModal, setShowReadingsModal] = useState<boolean>(false);

  return (
    <MonitoringContainer>
      <HolderButtonsMonitoringScreen>
        <ViewInstrumentsButton
          showModal={showViewInstrumentsModal}
          setShowModal={setShowViewInstrumentsModal}
        />
        <ReadingsButton
          showModal={showReadingsModal}
          setShowModal={setShowReadingsModal}
        />
        <GenerateGraphButton
          showModal={showMonitoringGraphModal}
          setShowModal={setShowMonitoringGraphModal}
        />
      </HolderButtonsMonitoringScreen>
      <MapGoogleDraw />
      {showViewInstrumentsModal ? (
        <ViewInstrumentsPage
          showModal={showViewInstrumentsModal}
          setShowModal={setShowViewInstrumentsModal}
        />
      ) : null}
      {showReadingsModal ? (
        <ReadingsComponent
          showModal={showReadingsModal}
          setShowModal={setShowReadingsModal}
        />
      ) : null}
      {showMonitoringGraphModal ? (
        <InstrumentGraph
          showModal={showMonitoringGraphModal}
          setShowModal={setShowMonitoringGraphModal}
        />
      ) : null}
    </MonitoringContainer>
  );
};
