import { t } from 'i18next';
import { useState } from 'react';
import { AiFillPlayCircle } from 'react-icons/ai';
import Text from '../../Atoms/Text/Text';
import Video from '../../Atoms/Video/Video';
import FileViewer from '../FileViewer/FileViewer';
import { FileType } from '../FileViewer/FileViewer.interfaces';
import { VideosGalleryProps } from './VideosGallery.interfaces';
import {
  DivContainerVideos,
  HolderVideosNotFound,
  Overlay,
  PlayButton,
  VideoContainer
} from './VideosGallery.styles';
export const VideosGallery = ({ videos, videosToShow }: VideosGalleryProps) => {
  const [selectVideo, setSelectVideo] = useState<FileType | null>(null);
  const limitedVideos = videos.slice(0, videosToShow);

  return (
    <>
      {videos.length === 0 && (
        <HolderVideosNotFound>
          <Text type={'h5'}>{t('VideosNotFound')}</Text>
        </HolderVideosNotFound>
      )}
      <DivContainerVideos>
        {limitedVideos.map((video, index) => (
          <VideoContainer key={index}>
            <Overlay />
            <PlayButton onClick={() => setSelectVideo(video)}>
              <AiFillPlayCircle size={64} />
            </PlayButton>
            <Video
              key={video.id}
              src={video.url || URL.createObjectURL(video as any)}
              width="100%"
              height="100%"
              poster={video.thumb}
              controls={false}
            />
          </VideoContainer>
        ))}
        {selectVideo && (
          <FileViewer
            files={videos}
            selectedFile={selectVideo}
            setSelectedFile={setSelectVideo}
            onClose={() => setSelectVideo(null)}
          />
        )}
      </DivContainerVideos>
    </>
  );
};
