import { NotificationListType } from '../../@Types/types';
import NotificationItemModal from './NotificationItemModal';
import { DivNotificationListModalContainer } from './NotificationStyle';

export default function NotificationListModal({
  filteredData
}: {
  filteredData: NotificationListType[];
}) {
  return (
    <DivNotificationListModalContainer>
      {filteredData.map((e, index) => (
        <NotificationItemModal
          key={`${index}${e.sentAt}${e.installLocation}`}
          index={index}
          title={e.alertLevel}
          date={e.sentAt}
          instrument={e.name}
          controlLevel={e.alertLevel}
          structure={e.structureName}
          installationLocation={e.installLocation}
          reading={e.reading}
          controlLevelValue={e.alertLevelValue}
          unread={e.unread}
        />
      ))}
    </DivNotificationListModalContainer>
  );
}
