import {
  CreateStructureDamRaisingInput,
  CreateStructureTailingVolumeInput,
  DatumEnum
} from '../../../../data/graphql/base-schema';

export interface StepOne {
  companyId: string;
  responsibleId: string;
  name: string;
  type: string;
}

export const __initialStepOne = {
  companyId: '',
  responsibleId: '',
  name: '',
  type: ''
};

export interface MandatoryStepOne {
  companyId: boolean;
  responsibleId: boolean;
  name: boolean;
  type: boolean;
}

export const __initialMandatoryStepOne: MandatoryStepOne = {
  companyId: true,
  responsibleId: true,
  name: true,
  type: true
};

export interface StepTwo {
  ANMClassification: string;
  coordinateE: number;
  coordinateN: number;
  datumName: string;
  maxHeight: number;
  operationEnd: Date | undefined;
  operationStart: Date | undefined;
  operationalStatus: string;
  potentialDamage: string;
  riskClassification: string;
  utmZone: string;
  structureId: string;
  isUTM: boolean;
}

export const __initialStepTwo = {
  ANMClassification: '',
  coordinateE: Number(),
  coordinateN: Number(),
  datumName: '',
  maxHeight: Number(),
  operationEnd: undefined,
  operationStart: undefined,
  operationalStatus: '',
  potentialDamage: '',
  riskClassification: '',
  utmZone: '',
  structureId: '',
  isUTM: false
};

export interface MandatoryStepTwo {
  ANMClassification: boolean;
  coordinateE: boolean;
  coordinateN: boolean;
  datumName: boolean;
  maxHeight: boolean;
  operationEnd: boolean;
  operationStart: boolean;
  operationalStatus: boolean;
  potentialDamage: boolean;
  riskClassification: boolean;
  utmZone: boolean;
  structureId: boolean;
}

export const __initialMandatoryStepTwo: MandatoryStepTwo = {
  ANMClassification: true,
  coordinateE: true,
  coordinateN: true,
  datumName: true,
  maxHeight: true,
  operationEnd: true,
  operationStart: true,
  operationalStatus: true,
  potentialDamage: true,
  riskClassification: true,
  utmZone: true,
  structureId: true
};

export interface DataItemStepThree {
  id: string;
  stage: string;
  method: string;
  elevation: number;
  date: Date;
}

export interface StepThree {
  data: CreateStructureDamRaisingInput[];
}

export const __initialStepLightThree = {
  id: '',
  stage: '',
  method: '',
  elevation: Number(),
  date: null
};

export const __initialStepThree = {
  data: []
};

export interface MandatoryStepThree {
  id: boolean;
  stage: boolean;
  method: boolean;
  elevation: boolean;
  date: boolean;
}

export const __initialMandatoryStepThree: MandatoryStepThree = {
  id: true,
  stage: true,
  method: true,
  elevation: true,
  date: true
};

export interface StepFour {
  wasteType: string;
  downstreamInstallations: string;
  downstreamHumanOccupation: string;
  downstreamEnvironmentalInterest: string;
}

export const __initialStepFour = {
  wasteType: '',
  downstreamInstallations: '',
  downstreamHumanOccupation: '',
  downstreamEnvironmentalInterest: ''
};

export interface MandatoryStepFour {
  wasteType: boolean;
  downstreamInstallations: boolean;
  downstreamHumanOccupation: boolean;
  downstreamEnvironmentalInterest: boolean;
}

export const __initialMandatoryStepFour: MandatoryStepFour = {
  wasteType: true,
  downstreamInstallations: true,
  downstreamHumanOccupation: true,
  downstreamEnvironmentalInterest: true
};

export interface StepFive {
  upstreamSlopeInclination: string;
  slopeBetweenBerms: string;
  globalSlopeDownstream: string;
  crestWidth: number;
  crestLength: number;
  crestElevation: number;
  bermLength: number;
}

export const __initialStepFive = {
  upstreamSlopeInclination: '',
  slopeBetweenBerms: '',
  globalSlopeDownstream: '',
  crestWidth: Number(),
  crestLength: Number(),
  crestElevation: Number(),
  bermLength: Number()
};

export interface MandatoryStepFive {
  upstreamSlopeInclination: boolean;
  slopeBetweenBerms: boolean;
  globalSlopeDownstream: boolean;
  crestWidth: boolean;
  crestLength: boolean;
  crestElevation: boolean;
  bermLength: boolean;
}

export const __initialMandatoryStepFive: MandatoryStepFive = {
  upstreamSlopeInclination: true,
  slopeBetweenBerms: true,
  globalSlopeDownstream: true,
  crestWidth: true,
  crestLength: true,
  crestElevation: true,
  bermLength: true
};

export interface StepSix {
  volumeTotalElevationCrest: number;
  spillwayType: string;
  spillwaySill: number;
  reservoirContributionArea: number;
  returnPeriod: number;
  projectRainfall: number;
  outflowRate: number;
  inflowRate: number;
  criticalDuration: number;
  NAMaximoMaximorum: number;
}

export const __initialStepSix = {
  volumeTotalElevationCrest: Number(),
  spillwayType: '',
  spillwaySill: Number(),
  reservoirContributionArea: Number(),
  returnPeriod: Number(),
  projectRainfall: Number(),
  outflowRate: Number(),
  inflowRate: Number(),
  criticalDuration: Number(),
  NAMaximoMaximorum: Number()
};

export interface MandatoryStepSix {
  volumeTotalElevationCrest: boolean;
  spillwayType: boolean;
  spillwaySill: boolean;
  reservoirContributionArea: boolean;
  returnPeriod: boolean;
  projectRainfall: boolean;
  outflowRate: boolean;
  inflowRate: boolean;
  criticalDuration: boolean;
  NAMaximoMaximorum: boolean;
}

export const __initialMandatoryStepSix: MandatoryStepSix = {
  volumeTotalElevationCrest: true,
  spillwayType: true,
  spillwaySill: true,
  reservoirContributionArea: true,
  returnPeriod: true,
  projectRainfall: true,
  outflowRate: true,
  inflowRate: true,
  criticalDuration: true,
  NAMaximoMaximorum: true
};

export interface StepSeven {
  waterVolume: number;
  tailingVolumeHistory: CreateStructureTailingVolumeInput[];
  reservoirTotalVolume: number;
  reservoirOccupiedVolume: number;
  reservoirOccupiedArea: number;
}

export const __initialStepSeven = {
  waterVolume: Number(),
  tailingVolumeHistory: [],
  reservoirTotalVolume: Number(),
  reservoirOccupiedVolume: Number(),
  reservoirOccupiedArea: Number()
};

export const __initialStepSevenArray = {
  date: new Date(),
  volume: Number()
};

export interface MandatoryStepSeven {
  waterVolume: boolean;
  tailingVolumeHistory: boolean;
  reservoirTotalVolume: boolean;
  reservoirOccupiedVolume: boolean;
  reservoirOccupiedArea: boolean;
}

export const __initialMandatoryStepSeven: MandatoryStepSeven = {
  waterVolume: true,
  tailingVolumeHistory: true,
  reservoirTotalVolume: true,
  reservoirOccupiedVolume: true,
  reservoirOccupiedArea: true
};

export const installationLocation = [
  { id: 'Dique', label: 'Dique', value: 'Dique' },
  { id: 'Pilha', label: 'Pilha', value: 'Pilha' },
  { id: 'Barragem', label: 'Barragem', value: 'Barragem' }
];

export const operationalStatus = [
  { id: 'Em Construção', label: 'Em Construção', value: 'Em Construção' },
  { id: 'Em Operação', label: 'Em Operação', value: 'Em Operação' },
  {
    id: 'Em Construção/Operação',
    label: 'Em Construção/Operação',
    value: 'Em Construção/Operação'
  },
  {
    id: 'Em Descaracterização',
    label: 'Em Descaracterização',
    value: 'Em Descaracterização'
  },
  {
    id: 'Descaracterizado',
    label: 'Descaracterizado',
    value: 'Descaracterizado'
  },
  { id: 'Paralisado', label: 'Paralisado', value: 'Paralisado' }
];

export const datumEnum = [
  {
    id: DatumEnum.ASTROCHUA,
    label: DatumEnum.ASTROCHUA,
    value: DatumEnum.ASTROCHUA
  },
  {
    id: DatumEnum.CORREGOALEGRE,
    label: DatumEnum.CORREGOALEGRE,
    value: DatumEnum.CORREGOALEGRE
  },
  { id: DatumEnum.SAD69, label: DatumEnum.SAD69, value: DatumEnum.SAD69 },
  {
    id: DatumEnum.SIRGAS2000,
    label: DatumEnum.SIRGAS2000,
    value: DatumEnum.SIRGAS2000
  },
  { id: DatumEnum.WGS84, label: DatumEnum.WGS84, value: DatumEnum.WGS84 }
];
