import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { checkBoxStyle } from '../../../../Components/Map/MapStyle';
import { IdentifiesContainer, IdentifiesHeader } from './DamMassifStyles';
import { YesNoBodyTable } from './YesNoBodyTable';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';

export function InternalDrainage({
  setStepStates,
  stepStates,
  setIsSelectedInternalDrainage,
  isSelectedInternalDrainage,
  error,
  inspectionId
}: {
  setStepStates: Dispatch<SetStateAction<any>>;
  stepStates: any;
  setIsSelectedInternalDrainage: Dispatch<SetStateAction<boolean>>;
  isSelectedInternalDrainage: boolean;
  error: boolean;
  inspectionId: string;
}) {
  const { t }: any = useTranslation();

  const topics = [
    'Assoreamento da saída do dreno/colóide',
    'Carreamento visível de sólidos',
    'Alteração significativa da vazão',
    'Saturação ao redor do dreno',
    'Medidor de vazão operante'
  ];
  return (
    <IdentifiesContainer>
      <IdentifiesHeader>
        <span>{t('InternalDrainage')}</span>
        <span
          id="repressions"
          onClick={() =>
            setIsSelectedInternalDrainage(!isSelectedInternalDrainage)
          }
        >
          {t('DoesNotApply/NonExistent')}{' '}
          {isSelectedInternalDrainage ? (
            <MdCheckBox style={checkBoxStyle} />
          ) : (
            <MdCheckBoxOutlineBlank style={checkBoxStyle} />
          )}
        </span>
      </IdentifiesHeader>
      {!isSelectedInternalDrainage && (
        <TableComponentStyle>
          <thead>
            <tr>
              <th></th>
              <th>{t('Value')}</th>
              <th>{t('Observation')}</th>
              <th>{t('Image')}</th>
            </tr>
          </thead>
          <tbody>
            {topics.map((topic, index) => (
              <YesNoBodyTable
                setState={setStepStates}
                state={stepStates}
                topic={topic}
                key={index}
                error={error}
                inspectionId={inspectionId}
              />
            ))}
          </tbody>
        </TableComponentStyle>
      )}
    </IdentifiesContainer>
  );
}
