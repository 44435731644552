import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { sendPSBFile } from '../../../../data/services/PSBService';
import { StructureSelectType } from '../../../Map/types';
import { toastfyError } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import ModalStep from '../../Molecules/ModalStep/ModalStep';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import PSBList from '../../Organisms/PSBList/PSBList';
import { PSBListFiles } from '../../Organisms/PSBListFiles/PSBListFiles';
import { PSBListVolumes } from '../../Organisms/PSBListVolumes/PSBListVolumes';
import { Comment } from '../../Organisms/VolumeCheckbox/VolumeCheckbox.interface';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';

export default function PSBScreen() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [psbId, setPsbId] = useState<string>('');
  const [comments, setComments] = useState<Comment[]>([]);
  const [files, setFiles] = useState<FileType[]>([]);
  const [error, setError] = useState(false);
  const { structureId } = useParams();

  const handleSubmit = async (): Promise<boolean> => {
    if (comments.length !== 0 && files.length !== 0) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file.file as any);
      });
      // TODO: Ajustar o tipo de fileIds
      formData.append(
        'psbDataIds',
        comments.map((comment) => comment.id) as any
      );
      const res = await sendPSBFile(formData, structureId!);
      if (res === 200) {
        handleCloseModalAdd();
      }

      return true;
    } else {
      if (files.length === 0) {
        setError(true);
      }

      toastfyError(t('fillRequiredFields'));
      return false;
    }
  };

  const handleCloseModalAdd = () => {
    setShowModal(false);
    setFiles([]);
    setComments([]);
    setError(false);
  };

  return (
    <>
      <SimpleContentTemplate
        title={<Text type={'h1'}>{t('PSB')}</Text>}
        button={
          <Button
            onClick={() => setShowModal(true)}
            text={`${t('Add')} ${t('File')}`}
            size="medium"
            variant="primary"
          />
        }
        content={<PSBList setPsbId={setPsbId} />}
      />
      {showModal && (
        <ModalStep
          title={t('Add')}
          onClose={() => handleCloseModalAdd()}
          steps={[
            {
              name: '',
              component: (
                <PSBListVolumes
                  setShowModal={setShowModal}
                  comments={comments}
                  setComments={setComments}
                  files={files}
                  setFiles={setFiles}
                  error={error}
                  setError={setError}
                />
              ),
              onNext: () => handleSubmit()
            }
          ]}
        />
      )}
      {psbId !== '' && (
        <ViewModal
          title={`${t('View')} ${t('File')}`}
          onClose={() => setPsbId('')}
          component={<PSBListFiles psbId={psbId} />}
          width={60}
        />
      )}
    </>
  );
}
