import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SetInstrumentNotificationUnreadStatusMutationVariables = Types.Exact<{
  notificationId: Types.Scalars['String']['input'];
}>;


export type SetInstrumentNotificationUnreadStatusMutation = { __typename?: 'Mutation', setInstrumentNotificationUnreadStatus: string };


export const SetInstrumentNotificationUnreadStatusDocument = gql`
    mutation SetInstrumentNotificationUnreadStatus($notificationId: String!) {
  setInstrumentNotificationUnreadStatus(notificationId: $notificationId)
}
    `;
export type SetInstrumentNotificationUnreadStatusMutationFn = Apollo.MutationFunction<SetInstrumentNotificationUnreadStatusMutation, SetInstrumentNotificationUnreadStatusMutationVariables>;
export type SetInstrumentNotificationUnreadStatusMutationResult = Apollo.MutationResult<SetInstrumentNotificationUnreadStatusMutation>;
export type SetInstrumentNotificationUnreadStatusMutationOptions = Apollo.BaseMutationOptions<SetInstrumentNotificationUnreadStatusMutation, SetInstrumentNotificationUnreadStatusMutationVariables>;