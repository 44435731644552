import Accordion from '../../Molecules/Accordion/Accordion';
import { Volume } from '../Volume/Volume';
import { IGISTMList } from './GISTMList.interface';
import { DivContainerGISTM } from './GISTMList.style';
import { useFindAllGistmData } from '../../../../data/hooks/gistm/findAllGistmData';
import { useParams } from 'react-router-dom';

export default function GISTMList({ setGistmId }: IGISTMList) {
  const { structureId } = useParams();
  const { data: findAllGistm } = useFindAllGistmData(structureId || '');
  return (
    <DivContainerGISTM>
      {findAllGistm &&
        findAllGistm.findAllGistmData.map((volumeItem: any) => (
          <Accordion
            key={volumeItem.id}
            component={
              volumeItem.nested &&
              volumeItem.nested.map((contentItem: any) => {
                if (contentItem.nested) {
                  for (const i of contentItem.nested) {
                    if (i.nested && i.nested.length > 0) {
                      i.nested.map((contentSonItem: any) => {
                        return (
                          <Accordion
                            key={contentSonItem.id}
                            component={Volume({
                              data: [contentSonItem],
                              setId: setGistmId
                            })}
                            title={contentSonItem.name}
                          />
                        );
                      });
                    } else {
                      return (
                        <Accordion
                          key={contentItem.id}
                          component={Volume({
                            data: contentItem.nested,
                            setId: setGistmId
                          })}
                          title={contentItem.name}
                        />
                      );
                    }
                  }
                }
              })
            }
            title={volumeItem.name}
          />
        ))}
    </DivContainerGISTM>
  );
}
