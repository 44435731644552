import { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa6';
import { InstrumentItemType } from '../../../../@Types/Instruments/instruments';
import AsideMenu from '../../../../Controllers/AsideMenuController';
import { useListInstrumentsByStructure } from '../../../../data/hooks/use-list-instuments-by-structure.query';
import SectionsSubMenuGraph from './SectionsSubMenuGraph';
import {
  GrapAsideMenu,
  SpanTitle,
  UlMenu,
  UlSubMenu
} from './StyleInstrumentGraph';
import SubMenuGraphs from './SubMenuGraphs';
import { useParams } from 'react-router-dom';

interface GraphMenuProps {
  selectedGraphs: InstrumentItemType[];
  setSelectedGraphs: Dispatch<InstrumentItemType[]>;
  rainGauge: InstrumentItemType[];
  piezometer: InstrumentItemType[];
  shallowLandmark: InstrumentItemType[];
  ina: InstrumentItemType[];
  reservoirWaterLevel: InstrumentItemType[];
  setRainGauge: Dispatch<InstrumentItemType[]>;
  setPiezometer: Dispatch<InstrumentItemType[]>;
  setShallowLandmark: Dispatch<InstrumentItemType[]>;
  setReservoirWaterLevel: Dispatch<InstrumentItemType[]>;
  setIna: Dispatch<InstrumentItemType[]>;
  startDate: Date;
  endDate: Date;
}

export default function GraphMenu({
  selectedGraphs,
  setSelectedGraphs,
  rainGauge,
  piezometer,
  shallowLandmark,
  reservoirWaterLevel,
  setRainGauge,
  setPiezometer,
  setShallowLandmark,
  setReservoirWaterLevel,
  ina,
  setIna,
  startDate,
  endDate
}: GraphMenuProps) {
  const { t } = useTranslation();
  const { structureId } = useParams();

  const { data: instrumentsList } = useListInstrumentsByStructure(
    structureId || ''
  );

  const [showMenuGeneralInstruction, setShowMenuGeneralInstruction] =
    useState<boolean>(false);
  const [showMenuSections, setShowMenuSections] = useState<boolean>(false);

  return (
    <GrapAsideMenu>
      <nav>
        <UlMenu>
          <li
            onClick={(e) =>
              AsideMenu.showMenu({
                e,
                show: showMenuGeneralInstruction,
                setShow: setShowMenuGeneralInstruction
              })
            }
          >
            <SpanTitle>
              {showMenuGeneralInstruction ? <FaAngleDown /> : <FaAngleRight />}{' '}
              Instrumentos Gerais{' '}
            </SpanTitle>
          </li>
          {showMenuGeneralInstruction && (
            <UlSubMenu>
              {instrumentsList?.listInstrumentsByStructure.length &&
                instrumentsList.listInstrumentsByStructure[0].typesList.map(
                  ({ instruments, type }, i) => {
                    const strategy = {
                      piezometer: () => (
                        <SubMenuGraphs
                          text={type}
                          state={piezometer}
                          setState={setPiezometer}
                          selectedGraphs={selectedGraphs}
                          setSelectedGraphs={setSelectedGraphs}
                          instruments={instruments}
                          startDate={startDate}
                          endDate={endDate}
                          key={i}
                        />
                      ),
                      pluviometer: () => (
                        <SubMenuGraphs
                          text={type}
                          state={rainGauge}
                          setState={setRainGauge}
                          selectedGraphs={selectedGraphs}
                          setSelectedGraphs={setSelectedGraphs}
                          instruments={instruments}
                          startDate={startDate}
                          endDate={endDate}
                          key={i}
                        />
                      ),
                      ina: () => (
                        <SubMenuGraphs
                          text={type}
                          state={ina}
                          setState={setIna}
                          selectedGraphs={selectedGraphs}
                          setSelectedGraphs={setSelectedGraphs}
                          instruments={instruments}
                          startDate={startDate}
                          endDate={endDate}
                          key={i}
                        />
                      ),
                      waterlevel: () => (
                        <SubMenuGraphs
                          text={type}
                          state={reservoirWaterLevel}
                          setState={setReservoirWaterLevel}
                          selectedGraphs={selectedGraphs}
                          setSelectedGraphs={setSelectedGraphs}
                          instruments={instruments}
                          startDate={startDate}
                          endDate={endDate}
                          key={i}
                        />
                      ),
                      surfacemarker: () => (
                        <SubMenuGraphs
                          text={type}
                          state={shallowLandmark}
                          setState={setShallowLandmark}
                          selectedGraphs={selectedGraphs}
                          setSelectedGraphs={setSelectedGraphs}
                          instruments={instruments}
                          startDate={startDate}
                          endDate={endDate}
                          key={i}
                        />
                      )
                    };
                    return strategy[
                      type.toLowerCase() as keyof typeof strategy
                    ]();
                  }
                )}
            </UlSubMenu>
          )}
        </UlMenu>
        <UlMenu>
          <li
            onClick={(e) =>
              AsideMenu.showMenu({
                e,
                show: showMenuSections,
                setShow: setShowMenuSections
              })
            }
          >
            <SpanTitle>
              {showMenuSections ? <FaAngleDown /> : <FaAngleRight />}{' '}
              {t('Sections')}
            </SpanTitle>
          </li>
          {showMenuSections && (
            <UlSubMenu>
              {instrumentsList?.listInstrumentsByStructure.length &&
                instrumentsList.listInstrumentsByStructure[0].sectionsList.map(
                  ({ name, typesList, id }) => {
                    return (
                      <SectionsSubMenuGraph
                        key={id}
                        text={name}
                        typesList={typesList}
                        piezometer={piezometer}
                        setPiezometer={setPiezometer}
                        rainGauge={rainGauge}
                        setRainGauge={setRainGauge}
                        ina={ina}
                        setIna={setIna}
                        reservoirWaterLevel={reservoirWaterLevel}
                        setReservoirWaterLevel={setReservoirWaterLevel}
                        shallowLandmark={shallowLandmark}
                        setShallowLandmark={setShallowLandmark}
                        startDate={startDate}
                        endDate={endDate}
                        selectedGraphs={selectedGraphs}
                        setSelectedGraphs={setSelectedGraphs}
                      />
                    );
                  }
                )}
            </UlSubMenu>
          )}
        </UlMenu>
      </nav>
    </GrapAsideMenu>
  );
}
