import { useMutation } from '@apollo/client';
import {
  EditPiezometerDocument,
  EditPiezometerMutation,
  EditPiezometerMutationVariables
} from '../../graphql/generated/editPiezometer.mutation';
import { ListPiezometersByStructureDocument } from '../../graphql/query/generated/listPiezometersByStructure.query';

export function useEditPiezometer(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editPiezometer, { data, error, loading }] = useMutation<
    EditPiezometerMutation,
    EditPiezometerMutationVariables
  >(EditPiezometerDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListPiezometersByStructureDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editPiezometer,
    data,
    error,
    loading
  };
}
// O hook acima edita as infos gerais do piezômetro ou ina, tais quais:
// activationDate?:
// coordinateE
// coordinateN
// deactivationDate?
// installLocation?
// installationDate?
// instrumentId
// name
// operationalStatus:
// readingType
// sectionsId
// tudo que está com (?) é opcional na chamada, é possível ver essas variáveis ao clicar em EditPiezometerMutationVariables
