import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

interface InputStyledProps {
  hasValue: boolean;
}

export const InputContainer = styled.div`
  padding-top: 20px;
`;

export const InputStyled = styled.div<InputStyledProps>`
  position: relative;

  label {
    position: absolute;
    top: ${({ hasValue }) => (hasValue ? '-20px' : '10px')};
    left: 5px;
    pointer-events: none;
    transition: all 0.2s ease;
  }

  input:focus ~ label {
    top: -20px;
  }
`;

export const ErrorStyled = styled.span`
  position: absolute;
  left: 5px;
  font-size: ${FontSize.Xsmall};
  color: ${Color.Red1};
`;
