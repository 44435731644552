import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveActionPlanCommentMutationVariables = Types.Exact<{
  comment: Types.Scalars['String']['input'];
  actionPlanId: Types.Scalars['String']['input'];
}>;


export type SaveActionPlanCommentMutation = { __typename?: 'Mutation', saveActionPlanComment: { __typename?: 'SavedActionPlanCommentType', id: string, comment: string } };


export const SaveActionPlanCommentDocument = gql`
    mutation SaveActionPlanComment($comment: String!, $actionPlanId: String!) {
  saveActionPlanComment(comment: $comment, actionPlanId: $actionPlanId) {
    id
    comment
  }
}
    `;
export type SaveActionPlanCommentMutationFn = Apollo.MutationFunction<SaveActionPlanCommentMutation, SaveActionPlanCommentMutationVariables>;
export type SaveActionPlanCommentMutationResult = Apollo.MutationResult<SaveActionPlanCommentMutation>;
export type SaveActionPlanCommentMutationOptions = Apollo.BaseMutationOptions<SaveActionPlanCommentMutation, SaveActionPlanCommentMutationVariables>;